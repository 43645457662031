import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { NavContext } from '../../ContextProviders/NavProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';

const queryString = require('query-string');

const PaneWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const ChaptersWrapper = styled.ul`
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	font-size: 0;
`;

const ChaptersNumbersWrapper = styled.li`
	padding: 3px;
	display: inline-block;
	min-width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	color: #6a7583;
	font-size: 14px;
	line-height: 20px;
`;

const ChapterNumber = styled.a`
	display: block;
	padding: 3px;
	font-weight: 400;
	color: #6a7583;
	text-align: center;
	border-radius: 3px;
	background: #f3f3f3;
	&:hover {
		background-color: #ececec;
	}
`;

const Chapters = props => {
	const [currentBookId, setcurrentBookId] = useState(0);
	const { handleOpenVerses } = useContext(NavContext);
	const { libraryState } = useContext(LibraryProviderState);
	const { chapterHistoryDispatch, chapterHistoryState } = useContext(ChapterHistoryContext);

	const handleChooseChapter = (e, value) => {
		e.preventDefault();
		handleOpenVerses({ bookId: value.book_id, chapterId: value.id, originPaneKey: props.uniqueKey, proUser: props.proUser });
	};

	useEffect(() => {
		let parsed = queryString.parse(window.location.search);
		const shareLinks = JSON.parse(localStorage.getItem('share-links'))
		if(shareLinks){
			chapterHistoryDispatch({ type: 'SET_URL_QUERY_RENDERED', payload: true });
			let { book, chapter, verseNumber = null } = shareLinks;
			handleOpenVerses({ bookId: book, chapterId: chapter, verseNumber: parseInt(verseNumber), proUser: props.proUser });
			localStorage.setItem('share-links', null)
		}

		if (parsed && parsed['libsearch'] && parsed['chapter'] && parsed['book'] && !chapterHistoryState.hasUrlQueryRendered) {
			chapterHistoryDispatch({ type: 'SET_URL_QUERY_RENDERED', payload: true });
			let { book, chapter, verseNumber = null } = parsed;
			handleOpenVerses({ bookId: book, chapterId: chapter, verseNumber: parseInt(verseNumber), proUser: props.proUser });
		}
	}, []);

	/** Returns the chapter number or OD1/OD2 for the Official Declarations */
	const retrieveChapterNumber = ({ order, search }) => {
		if (search.includes('Doctrine and Covenants') && (order === 139 || order === 140)) {
			if (order === 139) return 'OD1';
			return 'OD2';
		}
		return order;
	};

	return (
		<PaneWrapper>
			<ChaptersWrapper>
				{props.chapters.map((value, key) => {
					return (
						<ChaptersNumbersWrapper
							key={key}
							onClick={e => {
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									return window.open(
										`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
										'_blank'
									);
								} else {
									handleChooseChapter(e, value);
								}
							}}
						>
							<ChapterNumber>{retrieveChapterNumber(value)}</ChapterNumber>
						</ChaptersNumbersWrapper>
					);
				})}
			</ChaptersWrapper>
		</PaneWrapper>
	);
};

export default Chapters;

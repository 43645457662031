import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import SNLogo from '../../assets/logo_color.svg';
import { Scrollbars } from 'react-custom-scrollbars';
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const PaneContainer = styled.div`
	margin: 0 30px 0 30px;
	padding: 15px 0 15px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 100%;
`;

const PaneTitle = styled.h3`
	font-size: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;
const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const Logo = styled.img`
	max-width: 50%;
`;
const LinksContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Link = styled.a`
	margin-bottom: 5px;
	border: 2px solid blue;
	padding: 5px 10px;
	color: blue;
	font-weight: 800;
`;
const LoginForm = styled.form``;
const FormGroup = styled.div`
	width: 350px;
`;

const InputLabel = styled.label`
	padding-left: 5px;
	margin-bottom: 5px;
	color: #000;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 15px;
`;

const FormInput = styled.input`
	display: block;
	width: 100%;
	padding: 10px 10px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	line-height: 15px;
	border: none;
	border-radius: 0;
	color: #000;
	background-color: #ebebeb;
	border: 2px solid #fff;
	&:focus {
		border: 2px solid #000;
		outline-width: 0;
	}
`;
const FormBtn = styled.button`
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	margin-top: 5px;
	background-color: inherit;
	border: 2px solid #000;
	width: 90px;
	height: 35px;
	font-weight: bold;
	&:hover {
		color: #f8f8ff;
		background-color: #000;
	}
	@media (max-width: 680px) {
		font-family: -apple-system, BlinkMacSystemFont, sans-serif;
		background-color: inherit;
		border: 2px solid #000;
		width: 110px;
		height: 50px;
	}
`;
const BtnContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const AuthPane = props => {
	// Global Storage
	const { inAppLoginUser } = useContext(AuthContext);
	//State
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	//Ref
	const login = e => {
		e.preventDefault();
		if (email === '' || password === '') {
			return alert('Please include email and password.');
		}
		try {
			inAppLoginUser(email, password, props).then(res => {
				// let lr = fetch(
				// 	`${process.env.REACT_APP_BASE_URL}/analytics/addLoginRecord`,{
				// 		method: 'POST',
				// 		headers: {
				//          				'Content-Type': 'application/json', // <-- Specifying the Content-Type
				// 					},
				// 				body: JSON.stringify({email:`${email}`}) // <-- Post parameters
				// 			})
				// .then(response => response.json())
				// .then(lr => {
				// 	return lr;
				// });

				if (res && res.message && res.error === true) {
					alert(res.message);
				} else {
					props.closePane(props.id);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<>
			<PaneContainer>
				{/* <ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='up' forceDirection={true}>
						<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
					</Tooltip>
				</ClosePaneWrapper>
				
				<TitleDiv {...props.dragHandleProps}>
					<TitleContainer>
						<PaneTitle>AuthPane</PaneTitle>
					</TitleContainer>
				</TitleDiv> */}
				<Scrollbars>
					<LogoContainer>
						<Logo src={SNLogo} />
					</LogoContainer>
					<br />
					<p>Welcome to Scripture Notes. On the left you are viewing shared content.</p>
					<p>If you have an account with us, sign in below to open this content in your account.</p>
					<p>
						If you don't have an account with us, please watch our demo video to see what an incredibly powerful and flexible tool Scripture
						Notes is. You've never experienced something like this. Then sign up for a free trial.
					</p>
					<LinksContainer>
						<Link href='https://www.youtube.com/watch?v=7hb_jZor1cc' target='_blank'>
							Watch Demo
						</Link>
						<Link href='https://app.scripturenotes.com/register' target='_blank'>
							Sign up here!
						</Link>
					</LinksContainer>
					<LoginForm>
						<FormGroup>
							<InputLabel htmlFor='inputEmail'>Email</InputLabel>
							<FormInput name='email' type='email' id='inputEmail' value={email} onChange={e => setEmail(e.target.value)} autoFocus={false} />
						</FormGroup>
						<FormGroup>
							<InputLabel htmlFor='inputPassword'>Password</InputLabel>
							<FormInput name='password' type='password' id='inputPassword' value={password} onChange={e => setPassword(e.target.value)} />
						</FormGroup>
						<BtnContainer>
							<FormBtn onClick={e => login(e)} type='submit'>
								Log in
							</FormBtn>
						</BtnContainer>
					</LoginForm>
				</Scrollbars>
			</PaneContainer>
		</>
	);
};

export default AuthPane;

import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClearHistory from '../Helpers/ClearHistory';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import Tooltip from 'react-tooltip-lite';
import {
	faLink,
	faTimes,
	faEdit,
	faSearch,
	faEye,
	faTrash,
	faArrowsAlt,
	faObjectUngroup,
	faUnderline,
	faItalic,
	faBold,
	faMinus,
} from '@fortawesome/fontawesome-free-solid/';
import { VerseOptionsContext } from '../../ContextProviders/VerseOptionsProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { VersesContext } from '../../ContextProviders/VersesProvider';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';

let converter = require('hex2dec');
const btnColor = '#b2b7be';
const hoverColor = '#1a56eb';
const greenEyeColor = '#0ead3a';
const bookAbbreviations = {
	Judges: 'jdg',
	Ruth: 'rth',
	'Song of Solomon': 'sng',
	John: 'jhn',
	Philippians: 'phl',
	Philemon: 'phm',
	James: 'jas',
	Jude: 'jde',
};
const Separator = styled.div`
	border-top: #4c4747 solid 1px;
	width: 44px;
	position: absolute;
	left: 0px;
`;
const FloatingOptions = styled.div`
	position: absolute;
	transition: linear 0.4s all;
	background-color: #fff;
	padding: 15px 13px;
	border-radius: 31px;
	z-index: 99;
	border: #000 solid 1px;
`;
const ReadActionDropdownItem = styled.li`
	margin-bottom: 5px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 14px;
	line-height: 18px;
	list-style: none;
`;
const ReadActionLink = styled.a`
	color: #000 !important;
`;
const ReadActionItem = styled.div`
	color: ${btnColor};
	font-size: 16px;
	border: none;
	position: relative;
	margin-bottom: 3px;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		color: ${hoverColor};
	}
`;

const ReadActionDropdownList = styled.ul`
	list-style: none;
	padding-left: 5px;
	margin-bottom: 0;
`;
const ReadActionsDropdownWrapper = styled.ul`
	position: absolute;
	right: 0;
	top: calc(100% - 0px);
	z-index: 2000;
	width: 335px;
	padding: 10px;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
`;
const ReadActionWrapper = styled.div`
	display: flex;
`;

const ReadActionGroup = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
`;
const CloseNotesModalWrapper = styled.div`
	color: #b2b7be;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	&:hover {
		color: #47494c;
	}
`;
const ReadActionListItem = styled.li`
	font-size: 12px;
	color: #6a7583;
`;
const HyperlinkItem = styled.div`
	color: #000 !important;
	display: flex;
	align-items: center;
	margin: 4px 0 4px 8px;
`;

const HyperlinkName = styled.a`
	color: #000 !important;
`;

const HyperlinkInfo = styled.a`
	color: blue !important;
	margin-right: 12px;
	font-weight: 700;
`;
const ICIV = styled.div`
	margin-left: 0px;
`;

const VerseOptions = props => {
	const {
		selectedTextState,
		setSelectedTextState,
		selectedVerseId,
		optionXPosition,
		optionYPosition,
		optionOpacity,
		setOptionYPosition,
		setOptionXPosition,
		setOptionOpacity,
	} = useContext(VerseOptionsContext);
	const { handleAddNewCollectionNote, handleAddCollection } = useContext(NavContext);
	const { getAllFootnotes, versesState, versesDispatch } = useContext(VersesContext);
	const { updateVerseNote, saveUserFootnote } = useContext(CollectionsContext);
	const [showFootnotes, setShowFootnotes] = useState(false);
	const [greenEye, setGreenEye] = useState(btnColor);
	const [userMarkUp, setUserMarkUp] = useState('');
	const [userFootnotes, setUserFootnotes] = useState([]);
	const [showHyperlinks, setShowHyperlinks] = useState(false);
	const [newText, setNewText] = useState('');
	const [jst, setJst] = useState({});
	const { isProUser, authState } = useContext(AuthContext);
	const handleGreenEye = async () => {
		setShowFootnotes(!showFootnotes);
	};
	
	const closeOption = () => {
		setOptionOpacity('0.2');
		setOptionXPosition('15px');
		setOptionYPosition('15px');
	};

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}
	const blueBibleLink = () => {
		let bookName = props.optionVerseProps.title;
		let firstThree = bookName.toLowerCase().replace(' ', '').substring(0, 3);
		let bookAbbr = bookAbbreviations[bookName] || firstThree;
		return `https://www.blueletterbible.org/kjv/${bookAbbr}/${props.optionVerseProps.order}/${props.optionVerseProps.number}/`;
	};
	/** Returns the LDS Citation Index link for the current verse */
	const citationIndexLink = () => {
		const citationIndexRef = {
			'Book of Mormon': '0c9',
			'Old Testament': '065',
			'New Testament': '08c',
			'Doctrine and Covenants': '12e',
			'Pearl of Great Price': '191',
		};

		let { order, volume } = props.optionVerseProps.chapter.book;

		let startingHexAsNumber = converter.hexToDec(citationIndexRef[volume.title]);
		let bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 1).toString(), { prefix: false });
		if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;

		// These are special contingencies to keep accurate order with the LDS Citation Index site, as they
		// have a few differences in their database as compared to ours
		if (volume.title === 'Book of Mormon') {
			if (props.optionVerseProps.title === 'Title Page') return 'https://scriptures.byu.edu/#0c9::c0c9';
			if (props.optionVerseProps.title === 'Introduction') return 'https://scriptures.byu.edu/#0ca::c0ca';

			bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order + 1).toString(), { prefix: false });
			if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		} else if (volume.title === 'Pearl of Great Price' && order > 2) {
			bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order).toString(), { prefix: false });
			if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		}

		let chapterIndex = converter.decToHex(props.optionVerseProps.order.toString(), { prefix: false });
		if (chapterIndex.length < 2) chapterIndex = `0${chapterIndex}`;

		let fullHex = `${bookIndex}${chapterIndex}${props.optionVerseProps.number}`;
		return `https://scriptures.byu.edu/#${fullHex}::c${bookIndex}${chapterIndex}`;
	};
	const handleShowHyperLinks = () => {
		if (showHyperlinks) {
			setShowHyperlinks(false);
		} else {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/verses/getJospehSmithTranslation/${props.optionVerseProps.chapter.book.title}/${props.optionVerseProps.chapter.order}/${props.optionVerseProps.number}`
			)
				.then(res => res.json())
				.then(result => {
					setJst(result);
					setShowHyperlinks(true);
				});
		}
	};
	const prevGreenEye = usePrevious(greenEye);
	return (
		<>
			{props.isOpenOptions === true && (
				<FloatingOptions style={{ left: optionXPosition, top: optionYPosition, opacity: optionOpacity }}>
					<ReadActionWrapper>
						<ReadActionGroup>
							<ReadActionItem>
								<Tooltip content='Close Option'>
									<FontAwesomeIcon icon={faTimes} onClick={closeOption} style={{ cursor: 'pointer' }} />
								</Tooltip>
							</ReadActionItem>
							<ReadActionItem>
								<Tooltip content='Create Collection Note'>
									<FontAwesomeIcon
										icon={faEdit}
										style={{ cursor: 'pointer' }}
										onClick={e => {
											if (!isProUser()) {
												alert('You must be a pro member to access this feature.');
												return;
											}
											e.stopPropagation();
											e.persist();
											if (e.ctrlKey || e.metaKey) {
												return window.open(
													`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=new&verseId=${props.optionVerseProps.id}&bookId=${props.optionVerseProps.book_id}&chapterId=${props.optionVerseProps.chapter_id}`,
													'_blank'
												);
											} else {
												props.optionVerseProps.userMarkup = {};
												props.optionVerseProps.userMarkup.verse_text_with_markup = userMarkUp;
												handleAddNewCollectionNote([props.optionVerseProps], props.optionPaneUniqueId);
											}
										}}
									/>
								</Tooltip>
							</ReadActionItem>
							<ReadActionItem>
								<Tooltip content='See Notes'>
									<FontAwesomeIcon
										icon={faEye}
										style={{ cursor: 'pointer', color: showFootnotes ? hoverColor : greenEye }}
										onClick={() => {
											handleGreenEye();
											// getAllFootnotes({ userId: props.userId, verseId: props.optionVerseProps.id, uniqueKey: props.uniqueKey });
										}}
										onMouseOver={() => {
											setGreenEye(hoverColor);
										}}
										onMouseOut={() => {
											setGreenEye(prevGreenEye);
										}}
									/>
								</Tooltip>
								{showFootnotes && (
									<ReadActionsDropdownWrapper>
										<CloseNotesModalWrapper>
											<FontAwesomeIcon icon={faTimes} onClick={() => handleGreenEye()} />
										</CloseNotesModalWrapper>
										<ReadActionDropdownItem>
											<ReadActionLink>Footnotes</ReadActionLink>
											<ReadActionDropdownList>
												{userFootnotes.length > 0
													? userFootnotes.map((note, index) => {
															return (
																<ReadActionListItem key={index}>
																	<span>{note.footnote}</span>
																</ReadActionListItem>
															);
													  })
													: null}
											</ReadActionDropdownList>
										</ReadActionDropdownItem>

										<ReadActionDropdownItem>
											<ReadActionLink>LDS Footnotes</ReadActionLink>
											<ReadActionDropdownList>
												{/* {ldsFootnotes.length > 0 && ldsFootnotes.map((note, index) => {
											return (
												<ReadActionListItem
													key={`${index}-${note.id}`}
												>
													{`${note.superscript}:${note.footnote}`}
												</ReadActionListItem>
											)
										})} */}
												<ReadActionListItem>Coming Soon</ReadActionListItem>
											</ReadActionDropdownList>
										</ReadActionDropdownItem>

										{props.optionVerseProps.collectionNotes && props.optionVerseProps.collectionNotes.length !== 0 ? (
											<ReadActionDropdownItem>
												<ReadActionLink>Collection Notes</ReadActionLink>
												<ReadActionDropdownList>
													{props.optionVerseProps.collectionNotes.map(value => {
														return (
															<ReadActionListItem
																key={value.id}
																onClick={() => handleAddCollection(value.collection_id, props.uniqueKey)}
															>
																<span>{value.title}</span>
															</ReadActionListItem>
														);
													})}
												</ReadActionDropdownList>
											</ReadActionDropdownItem>
										) : null}
									</ReadActionsDropdownWrapper>
								)}
							</ReadActionItem>
							<ReadActionItem>
								<Tooltip content='Links'>
									<FontAwesomeIcon icon={faLink} style={{ cursor: 'pointer' }} onClick={() => handleShowHyperLinks()} />
								</Tooltip>
								{showHyperlinks && (
									<ReadActionsDropdownWrapper>
										<CloseNotesModalWrapper>
											<FontAwesomeIcon icon={faTimes} onClick={() => setShowHyperlinks(!showHyperlinks)} />
										</CloseNotesModalWrapper>
										<ReadActionDropdownItem>
											{['Old Testament', 'New Testament'].includes(props.optionVerseProps.chapter.book.volume.title) && (
												<div>
													<HyperlinkItem>
														<HyperlinkInfo href='https://scripturenotes.com/resource-7-bible-hub' target='_blank'>
															i
														</HyperlinkInfo>
														<HyperlinkName
															href={`https://biblehub.com/${props.optionVerseProps.title.toLowerCase().replace(' ', '_')}/${
																props.optionVerseProps.order
															}-${props.optionVerseProps.number}.htm`}
															target='_blank'
														>
															Bible Hub
														</HyperlinkName>
													</HyperlinkItem>
													<HyperlinkItem>
														<HyperlinkInfo
															href='https://scripturenotes.com/resource-2-strongs-exhaustive-concordance-of-the-bible'
															target='_blank'
														>
															i
														</HyperlinkInfo>
														<HyperlinkName href={blueBibleLink()} target='_blank'>
															Blue Letter Bible
														</HyperlinkName>
													</HyperlinkItem>
												</div>
											)}
											<HyperlinkItem>
												<HyperlinkInfo href='https://scripturenotes.com/resource-3-lds-citation-index' target='_blank'>
													i
												</HyperlinkInfo>
												<HyperlinkName href={citationIndexLink()} target='_blank'>
													LDS Citation Index
												</HyperlinkName>
											</HyperlinkItem>

											{Object.keys(jst).length !== 0 && (
												<div>
													<HyperlinkItem>
														<HyperlinkInfo href='https://scripturenotes.com/resource-4-joseph-smith-translation' target='_blank'>
															i
														</HyperlinkInfo>
														<HyperlinkName href={jst.jst_link} target='_blank'>
															Joseph Smith Translation
														</HyperlinkName>
														<ICIV>
															<HyperlinkName style={{ marginLeft: 5, marginRight: 5 }}>|</HyperlinkName>
															<HyperlinkName href={jst.inline_link} target='_blank'>
																Inline
															</HyperlinkName>
														</ICIV>
													</HyperlinkItem>
												</div>
											)}
										</ReadActionDropdownItem>
									</ReadActionsDropdownWrapper>
								)}
							</ReadActionItem>
						</ReadActionGroup>
					</ReadActionWrapper>
				</FloatingOptions>
			)}
		</>
	);
};
export default VerseOptions;

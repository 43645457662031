import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { NavContext } from '../../ContextProviders/NavProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
// import ReactTooltip from 'react-tooltip';
// import Tooltip from 'react-tooltip-lite';
// import '../../assets/css/chapterHeadingTip.css'

const queryString = require('query-string');

const PaneWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const ChaptersWrapper = styled.ul`
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	font-size: 0;
`;

const ChaptersNumbersWrapper = styled.li`
	padding: 3px;
	display: inline-block;
	min-width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
`;

const ChapterNumber = styled.a`
	display: block;
	padding: 3px;
	font-weight: 400;
	text-align: center;
	border-radius: 3px;
	background: #f3f3f3;
	&:hover {
		background-color: #ececec;
	}
`;

const ChapterNamesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 95%;
	cursor: pointer;
`;
const PageRange = styled.span`
	font-style: italic;
`;
const ChapterNameSpan = styled.span`
	width: 80%;
	margin-right: 10px;
`;
const ChapterName = styled.a`
	padding: 3px;
	display: flex;
	// justify-content: space-;
	min-width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
	padding: 3px;
	font-weight: 400;
	border-radius: 3px;
	background: #f3f3f3;
	margin-bottom: 5px;
	width: 95%;
	&:hover {
		background-color: #ececec;
	}
`;

const Chapters = props => {
	const [currentBookId, setcurrentBookId] = useState(0);
	const { handleOpenVerses } = useContext(NavContext);
	const { libraryState } = useContext(LibraryProviderState);
	const { chapterHistoryDispatch, chapterHistoryState } = useContext(ChapterHistoryContext);

	const handleChooseChapter = (e, value) => {
		e.preventDefault();
		handleOpenVerses({ bookId: value.book_id, chapterId: value.id, originPaneKey: props.uniqueKey, proUser: props.proUser });
	};

	useEffect(() => {
		let parsed = queryString.parse(window.location.search);
		const shareLinks = JSON.parse(localStorage.getItem('share-links'));
		if (shareLinks) {
			chapterHistoryDispatch({ type: 'SET_URL_QUERY_RENDERED', payload: true });
			let { book, chapter, verseNumber = null } = shareLinks;
			handleOpenVerses({ bookId: book, chapterId: chapter, verseNumber: parseInt(verseNumber), proUser: props.proUser });
			localStorage.setItem('share-links', null);
		}

		if (parsed && parsed['libsearch'] && parsed['chapter'] && parsed['book'] && !chapterHistoryState.hasUrlQueryRendered) {
			chapterHistoryDispatch({ type: 'SET_URL_QUERY_RENDERED', payload: true });
			let { book, chapter, verseNumber = null } = parsed;
			handleOpenVerses({ bookId: book, chapterId: chapter, verseNumber: parseInt(verseNumber), proUser: props.proUser });
		}
	}, []);

	/** Returns the chapter number or OD1/OD2 for the Official Declarations */
	const retrieveChapterNumber = ({ order, search, chapter_name }) => {
		if (chapter_name !== null && chapter_name.length <= 4) {
			return chapter_name;
		}
		if (search.includes('Doctrine and Covenants') && (order === 139 || order === 140)) {
			if (order === 139) return 'OD1';
			return 'OD2';
		}
		return order;
	};

	const chapterNames = props.chapters
		.filter(e => e.chapter_name !== null && e.chapter_name.length > 4)
		.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
	const chapterNumbers = props.chapters.filter(e => e.chapter_name === null);
	return (
		<PaneWrapper>
			{/* Had to use an inline style below for the text color, it wouldn't work on the styled component.  */}
			{props.chapters.map((value, key) => {
				return !value.chapter_name || value.chapter_name.length < 5 ? (
					// !value.chapter_heading ?
					<ChaptersNumbersWrapper
						key={key}
						onClick={e => {
							if (props.userId) {
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									return window.open(
										`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
										'_blank'
									);
								} else {
									handleChooseChapter(e, value);
								}
							} else alert('Please sign in or sign up to use Scripture Notes.');
						}}
					>
						<ChapterNumber>{retrieveChapterNumber(value)}</ChapterNumber>
					</ChaptersNumbersWrapper>
				) : (
					// :
					// <ChaptersNumbersWrapper
					// 	key={key}
					// 	onClick={e => {
					// 		e.stopPropagation();
					// 		e.persist();
					// 		if (e.ctrlKey || e.metaKey) {
					// 			return window.open(
					// 				`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
					// 				'_blank'
					// 			);
					// 		} else {
					// 			handleChooseChapter(e, value);
					// 		}
					// 	}}

					// >
					// 	{/* <ReactTooltip event='click' delayShow={1000} id={`chapter-heading-tip`} place='right' effect='solid' /> */}
					// 	<Tooltip content={value.chapter_heading} direction='right' tagName='chapterHeading'>
					// 		<ChapterNumber
					// 		// data-tip={value.chapter_heading}
					// 		// data-for={`chapter-heading-tip`}
					// 		>
					// 			{retrieveChapterNumber(value)}
					// 		</ChapterNumber>
					// 	</Tooltip>
					// </ChaptersNumbersWrapper>
					// !value.chapter_heading ?
					<div style={{ display: 'flex', justifyContent: 'space-between' }} key={key}>
						<ChapterName
							onClick={e => {
								if (props.userId) {
									e.stopPropagation();
									e.persist();
									if (e.ctrlKey || e.metaKey) {
										return window.open(
											`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
											'_blank'
										);
									} else {
										handleChooseChapter(e, value);
									}
								} else alert('Please sign in or sign up to use Scripture Notes.');
							}}
						>
							<ChapterNameSpan>{value.chapter_name}</ChapterNameSpan>
							{value.chapter_pages && <PageRange>pg: {value.chapter_pages.trim()}</PageRange>}
						</ChapterName>
					</div>
				);
				// :
				// <div>
				// 	{/* <ReactTooltip
				// 		delayShow={5000}
				// 		id={`chapter-heading-tip`}
				// 		place='left'
				// 		effect='solid'
				// 	/> */}
				// 	<ChapterName
				// 		key={key}
				// 		// data-tip={value.chapter_heading}
				// 		// data-for={`chapter-heading-tip`}
				// 		onClick={e => {
				// 			e.stopPropagation();
				// 			e.persist();
				// 			if (e.ctrlKey || e.metaKey) {
				// 				return window.open(
				// 					`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
				// 					'_blank'
				// 				);
				// 			} else {
				// 				handleChooseChapter(e, value);
				// 			}
				// 		}}>
				// 		{value.chapter_name}
				// 	</ChapterName>
				// </div>
			})}
			{/* {chapterNames.length <= 0 ? (
				<ChaptersWrapper style={{ color: '#6a7583' }}>
					{props.chapters.map((value, key) => {
						return (
							<ChaptersNumbersWrapper
								key={key}
								onClick={e => {
									e.stopPropagation();
									e.persist();
									if (e.ctrlKey || e.metaKey) {
										return window.open(
											`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
											'_blank'
										);
									} else {
										handleChooseChapter(e, value);
									}
								}}
							>
								<ChapterNumber>{retrieveChapterNumber(value)}</ChapterNumber>
							</ChaptersNumbersWrapper>
						);
					})}
				</ChaptersWrapper>
			) : (
				<ChapterNamesWrapper style={{ color: '#6a7583' }}>
					{chapterNumbers.length > 0 && (
						<ChaptersWrapper style={{ marginBottom: '10px' }}>
							{chapterNumbers.map((value, key) => {
								return (
									<ChaptersNumbersWrapper
										key={key}
										onClick={e => {
											e.stopPropagation();
											e.persist();
											if (e.ctrlKey || e.metaKey) {
												return window.open(
													`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
													'_blank'
												);
											} else {
												handleChooseChapter(e, value);
											}
										}}
									>
										<ChapterNumber>{retrieveChapterNumber(value)}</ChapterNumber>
									</ChaptersNumbersWrapper>
								);
							})}
						</ChaptersWrapper>
					)}
					{chapterNames.map((value, key) => {
						return (
							<ChapterName
								key={key}
								onClick={e => {
									e.stopPropagation();
									e.persist();
									if (e.ctrlKey || e.metaKey) {
										return window.open(
											`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
											'_blank'
										);
									} else {
										handleChooseChapter(e, value);
									}
								}}
							>
								{value.chapter_name}
							</ChapterName>
						);
					})}
				</ChapterNamesWrapper>
			)} */}
		</PaneWrapper>
	);
};

export default Chapters;

import React, { useReducer } from 'react';

// Create a State Context and a Dispatch Context so that you can import them separately if needed
// Not all components that need access to state need to be able to update it; and vice-versa
export const StudyHelpsContext = React.createContext();

// Initialize Context State
const initialState = {
	topicalGuide: [],
	bibleDictionary: [],
	tripleIndex: [],
};

// Reducer accepts action types and payloads and executes functions accordingly
const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_BIBLE_DICTIONARY': {
			return {
				...state,
				bibleDictionary: action.payload.bibleDictionary,
				topicalGuide: state.topicalGuide,
				tripleIndex: state.tripleIndex,
			};
		}
		case 'SET_TOPICAL_GUIDE': {
			return {
				...state,
				topicalGuide: action.payload.topicalGuide,
				bibleDictionary: state.bibleDictionary,
				tripleIndex: state.tripleIndex,
			};
		}
		case 'SET_TRIPLE_INDEX': {
			return {
				...state,
				topicalGuide: state.topicalGuide,
				bibleDictionary: state.bibleDictionary,
				tripleIndex: action.payload.tripleIndex,
			};
		}
		case 'SET_ALL': {
			return {
				...state,
				topicalGuide: action.payload.topicalGuide,
				bibleDictionary: action.payload.bibleDictionary,
				tripleIndex: action.payload.tripleIndex,
			};
		}
		// case 'TOGGLE_SH_OPEN':
		// 	return { ...state, isOpen: true };
		// case 'TOGGLE_SH_CLOSE':
		// 	return { ...state, isOpen: false };
		case 'ADD_TOPICAL_GUIDE_ITEMS': {
			let newTopicalGuide = [...action.payload.newItems, state.topicalGuide];
			return {
				...state,
				topicalGuide: newTopicalGuide,
				bibleDictionary: state.bibleDictionary,
			};
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

// Main Context Provider Wrapper to be imported and Wrapped around components that need access to it
export const StudyHelpsProvider = ({ children }) => {
	const [studyHelpsState, studyHelpsDispatch] = useReducer(reducer, initialState);

	return <StudyHelpsContext.Provider value={{ studyHelpsState, studyHelpsDispatch }}>{children}</StudyHelpsContext.Provider>;
};

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { faTrashAlt, faInfoCircle, faCaretRight, faCaretDown, faEdit } from '@fortawesome/fontawesome-free-solid/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
import { PortalWithState } from 'react-portal';
import { AuthContext } from '../../ContextProviders/AuthProvider';

const NewTaskButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 5px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const ProjectDiv = styled.div``;

const DeleteProjectWrapper = styled.div`
	display: block;
	cursor: pointer;
	background-size: cover;
	background-repeat: no-repeat;
	right: 55px;
`;

const Titlebar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #6d7886;
	color: #fff;
	cursor: pointer;
	margin-top: 5px;
	height: 35px;
`;
const TitleContainer = styled.div`
    display: flex;
    align-items; center;
    text-align: center;
    justify-content: center;
    height: 100%;
    line-height: 0;
	padding-top: 3px;
`;
const ProjectTitle = styled.h3`
	font-size: 21px;
	margin-left: 10px;
	margin-bottom: 0px;
`;
const ArrowIcon = styled.div`
	height: 100%;
	margin-left: 10px;
	display: flex;
	alignitems: center;
	margin-top: 8px;
`;
const TitleBarTools = styled.div`
	display: flex;
	align-items: center;
	width: 15%;
	justify-content: space-around;
	margin-right: 15px;
`;
const TasksContainer = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 5px;
`;

const TaskInputForm = styled.form`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0;
	width: 100%;
`;
const ProjectTitleEditForm = styled.form`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0;
	width: 100%;
	background-color: #6d7886;
`;
const SaveTaskButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 20px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 50%;
	margin: 0 5px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const CancelButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 15px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 50%;
	margin: 0 5px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const TaskTextInput = styled.input`
	height: 36px;
	width: 80%;
	margin-left: 5px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
`;

const TaskDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 95%;
	margin: 5px 20px;
`;
const TaskCheckTitle = styled.div`
	display: flex;
	margin: 0;
	padding: 0;
	align-items: center;
`;
const TaskTitle = styled.p`
	margin-left: 5px;
	margin-bottom: 0;
	padding: 0;
`;
const TaskButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
`;

const TaskContainer = styled.div`
	display: flex;
	width: 100%
	justify-content: space-bewtween;
`;
const TodoExpandable = props => {
	const [expanded, setExpanded] = useState(false);
	const [tasks, setTasks] = useState(props.tasks);
	const [unfinishedTasks, setUnfinishedTasks] = useState([]);
	const [hiddenInput, setHiddenInput] = useState(true);
	const [taskText, setTaskText] = useState('');
	const [showCompleted, setShowCompleted] = useState(props.showCompleted);
	const [editProjectTitle, setEditProjectTitle] = useState(false);
	const [editProjectText, setEditProjectText] = useState(props.title);
	const { authState } = useContext(AuthContext);
	useEffect(() => {
		filterTasks(props.tasks);
		if (props.expandedArr.includes(props.id)) {
			setExpanded(true);
		}
	}, []);

	useEffect(() => {
		filterTasks(props.tasks);
	}, [props.tasks]);

	useEffect(() => {
		setShowCompleted(props.showCompleted);
	}, [props.showCompleted]);

	useEffect(() => {
		const socket = props.socket;

		socket.on(`get_updated_project_tasks_${props.userId}`, ({ projectId }) => {
			projectId === props.id && handleFetchProjectTasks(projectId);
		});
		return () => {
			socket.off('get_updated_project_tasks_' + authState.user.id);
		};
	}, [authState]);

	//*METHODS
	const handleFetchProjectTasks = projectId => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/getProjectTasksById/${props.userId}/${projectId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(tasks => {
				filterTasks(tasks);
			});
	};
	const filterTasks = tasksArr => {
		let completedTasks = tasksArr.filter(task => task.completed);
		let unfinishedTasks = tasksArr.filter(task => !task.completed);
		setUnfinishedTasks(unfinishedTasks);
		setTasks([...unfinishedTasks, ...completedTasks]);
		return;
	};

	const handleDeleteProjectTask = (projectId, taskId) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/deleteProjectTask/${props.userId}/${projectId}/${taskId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(tasks => {
				filterTasks(tasks);
				props.socket.emit(`update_project_tasks`, { userId: props.userId, projectId: props.id });
			});
	};
	const handleChangeProjectTaskStatus = (projectId, taskId, completed) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/changeProjectTaskStatus/${props.userId}/${projectId}/${taskId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ completed }),
		})
			.then(res => res.json())
			.then(tasks => {
				filterTasks(tasks);
				props.socket.emit(`update_project_tasks`, { userId: props.userId, projectId: props.id });
			});
	};
	const handleSaveTask = e => {
		e.preventDefault();
		if (taskText) {
			if (props.title === 'Quick Tasks') {
				props.handleAddQuickTask(taskText);
				setTaskText('');
				setHiddenInput(true);
			} else {
				fetch(`${process.env.REACT_APP_BASE_URL}/todo/addProjectTask/${props.userId}/${props.id}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ task: taskText }),
				})
					.then(res => res.json())
					.then(tasks => {
						filterTasks(tasks);
						setTaskText('');
						setHiddenInput(true);
						props.socket.emit(`update_project_tasks`, { userId: props.userId, projectId: props.id });
					});
			}
		}
	};

	const handleEditTask = (taskId, taskText, projectId = null) => {
		if (props.title === 'Quick Tasks') {
			props.handleEditQuickTaskText(taskId, taskText);
		} else {
			fetch(`${process.env.REACT_APP_BASE_URL}/todo/editProjectTaskText/${props.userId}/${projectId}/${taskId}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ taskText: taskText }),
			})
				.then(res => res.json())
				.then(tasks => {
					filterTasks(tasks);
					props.socket.emit(`update_project_tasks`, { userId: props.userId, projectId: props.id });
				});
		}
	};

	//*MAPPED ARRAYS
	const mappedTasks =
		tasks &&
		tasks.map((task, index) => {
			return (
				<Task
					key={index}
					task={task.task}
					projectId={task.project_id}
					id={task.id}
					completed={task.completed}
					handleDeleteProjectTask={handleDeleteProjectTask}
					handleChangeProjectTaskStatus={handleChangeProjectTaskStatus}
					handleChangeQuickTaskStatus={props.handleChangeQuickTaskStatus}
					handleDeleteQuickTask={props.handleDeleteQuickTask}
					title={props.title}
					handleEditTask={handleEditTask}
				/>
				// <TaskDiv key={index}>
				// 	<TaskCheckTitle>
				// 		<FontAwesomeIcon
				// 			icon={task.completed ? ['far', 'check-circle'] : ['far', 'circle']}
				// 			size={'1x'}
				// 			onClick={() =>
				// 				props.title === 'Quick Tasks'
				// 					? props.handleChangeQuickTaskStatus(task.id, task.completed)
				// 					: handleChangeProjectTaskStatus(props.id, task.id, task.completed)
				// 			}
				// 		/>
				// 		<TaskTitle>{task.task}</TaskTitle>
				// 	</TaskCheckTitle>
				// 	<FontAwesomeIcon
				// 		icon={faTrashAlt}
				// 		onClick={() => (props.title === 'Quick Tasks' ? props.handleDeleteQuickTask(task.id) : handleDeleteProjectTask(props.id, task.id))}
				// 	/>
				// </TaskDiv>
			);
		});
	const mappedFinishedTasks =
		unfinishedTasks &&
		unfinishedTasks.map((task, index) => {
			return (
				<Task
					key={index}
					task={task.task}
					projectId={task.project_id}
					id={task.id}
					completed={task.completed}
					handleDeleteProjectTask={handleDeleteProjectTask}
					handleChangeProjectTaskStatus={handleChangeProjectTaskStatus}
					handleChangeQuickTaskStatus={props.handleChangeQuickTaskStatus}
					handleDeleteQuickTask={props.handleDeleteQuickTask}
					title={props.title}
					handleEditTask={handleEditTask}
				/>
				// <TaskDiv key={index}>
				// 	<TaskCheckTitle>
				// 		<FontAwesomeIcon
				// 			icon={task.completed ? ['far', 'check-circle'] : ['far', 'circle']}
				// 			size={'1x'}
				// 			onClick={() =>
				// 				props.title === 'Quick Tasks'
				// 					? props.handleChangeQuickTaskStatus(task.id, task.completed)
				// 					: handleChangeProjectTaskStatus(props.id, task.id, task.completed)
				// 			}
				// 		/>
				// 		<TaskTitle>{task.task}</TaskTitle>
				// 	</TaskCheckTitle>
				// 	<FontAwesomeIcon
				// 		icon={faTrashAlt}
				// 		onClick={() => (props.title === 'Quick Tasks' ? props.handleDeleteQuickTask(task.id) : handleDeleteProjectTask(props.id, task.id))}
				// 	/>
				// </TaskDiv>
			);
		});
	return (
		<ProjectDiv>
			{!editProjectTitle ? (
				<Titlebar>
					<TitleContainer
						onClick={() => {
							expanded ? props.removeExpanded(props.id) : props.addExpanded(props.id);
							setExpanded(!expanded);
						}}
					>
						<ArrowIcon>
							<FontAwesomeIcon icon={expanded ? faCaretDown : faCaretRight} />
						</ArrowIcon>
						<ProjectTitle>{props.title}</ProjectTitle>
					</TitleContainer>
					{props.title !== 'Quick Tasks' && (
						<TitleBarTools>
							<Tooltip
								content={unfinishedTasks.length === 1 ? `1 task remainging` : `${unfinishedTasks.length} tasks remaining`}
								direction='up'
								forceDirection={true}
							>
								<FontAwesomeIcon icon={faInfoCircle} />
							</Tooltip>
							<PortalWithState closeOnOutsideClick closeOnEsc>
								{({ openPortal, closePortal, isOpen, portal }) => (
									<>
										<DeleteProjectWrapper>
											<Tooltip content='Delete Project'>
												<FontAwesomeIcon key='ProjectPortal' icon={faTrashAlt} onClick={openPortal} />
											</Tooltip>
										</DeleteProjectWrapper>
										{portal(
											<div>
												<div
													style={{
														top: 0,
														zIndex: 9999999,
														background: '#ddd',
														width: '100%',
														height: '100%',
														minHeight: '392px',
														opacity: '0.6',
														position: 'absolute',
													}}
												/>

												<DeletePopUpWrapper>
													<p>Are you sure, you want to delete this project??</p>
													<DeletePopUpBtnWrapper>
														<DeletePopUpBtn
															onClick={closePortal}
															onClickCapture={() => {
																props.deleteProject(props.id);
															}}
														>
															Delete
														</DeletePopUpBtn>
														<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
													</DeletePopUpBtnWrapper>
												</DeletePopUpWrapper>
											</div>
										)}
									</>
								)}
							</PortalWithState>
							<Tooltip content={`Edit Project Title`} direction='up' forceDirection={true}>
								<FontAwesomeIcon icon={faEdit} onClick={() => setEditProjectTitle(true)} />
							</Tooltip>
						</TitleBarTools>
					)}
				</Titlebar>
			) : (
				<ProjectTitleEditForm onSubmit={() => props.handleUpdateProjectTitle(props.id, editProjectText)}>
					<TaskTextInput placeholder={'Edit Project Title...'} value={editProjectText} onChange={e => setEditProjectText(e.target.value)} />
					<TaskButtonWrap>
						<SaveTaskButton
							type='submit'
							onClick={e => {
								if (editProjectText) {
									e.preventDefault();
									props.handleEditProjectTitle(props.id, editProjectText);
									setEditProjectTitle(false);
								}
							}}
						>
							Save
						</SaveTaskButton>
						<CancelButton
							type='button'
							onClick={() => {
								setEditProjectText('');
								setEditProjectTitle(false);
							}}
						>
							Cancel
						</CancelButton>
					</TaskButtonWrap>
				</ProjectTitleEditForm>
			)}

			{expanded && (
				<TasksContainer>
					{hiddenInput ? (
						<NewTaskButton type='button' onClick={() => setHiddenInput(false)}>
							+ Add a new task
						</NewTaskButton>
					) : (
						<TaskInputForm onSubmit={handleSaveTask}>
							<TaskTextInput placeholder={'Add task here...'} value={taskText} onChange={e => setTaskText(e.target.value)} />
							<TaskButtonWrap>
								<SaveTaskButton type='submit' onClick={e => handleSaveTask(e)}>
									Save
								</SaveTaskButton>
								<CancelButton
									type='button'
									onClick={() => {
										setTaskText('');
										setHiddenInput(true);
									}}
								>
									Cancel
								</CancelButton>
							</TaskButtonWrap>
						</TaskInputForm>
					)}
					{showCompleted ? mappedTasks : mappedFinishedTasks}
				</TasksContainer>
			)}
		</ProjectDiv>
	);
};

const Task = props => {
	const [editMode, setEditMode] = useState(false);
	const [editText, setEditText] = useState(props.task);

	return (
		<TaskDiv>
			{!editMode ? (
				<TaskDiv>
					<TaskCheckTitle>
						<FontAwesomeIcon
							icon={props.completed ? ['far', 'check-circle'] : ['far', 'circle']}
							size={'1x'}
							onClick={() =>
								props.title === 'Quick Tasks'
									? props.handleChangeQuickTaskStatus(props.id, props.completed)
									: props.handleChangeProjectTaskStatus(props.projectId, props.id, props.completed)
							}
						/>
						<TaskTitle>{props.task}</TaskTitle>
					</TaskCheckTitle>
					<TitleBarTools>
						<FontAwesomeIcon
							icon={faTrashAlt}
							onClick={() =>
								props.title === 'Quick Tasks'
									? props.handleDeleteQuickTask(props.id)
									: props.handleDeleteProjectTask(props.projectId, props.id)
							}
						/>
						<FontAwesomeIcon icon={faEdit} onClick={() => setEditMode(true)} />
					</TitleBarTools>
				</TaskDiv>
			) : (
				<TaskInputForm
					onSubmit={() =>
						props.projectTitle === 'Quick Tasks'
							? props.handleEditTask(props.id, editText)
							: props.handleEditTask(props.id, editText, props.projectId)
					}
				>
					<TaskTextInput placeholder={'Edit Task...'} value={editText} onChange={e => setEditText(e.target.value)} />
					<TaskButtonWrap>
						<SaveTaskButton
							type='submit'
							onClick={e => {
								if (editText) {
									if (props.projectTitle === 'Quick Tasks') {
										e.preventDefault();
										props.handleEditTask(props.id, editText);
										setEditMode(false);
									} else {
										e.preventDefault();
										props.handleEditTask(props.id, editText, props.projectId);
										setEditMode(false);
									}
								}
							}}
						>
							Save
						</SaveTaskButton>
						<CancelButton
							type='button'
							onClick={() => {
								setEditMode(false);
							}}
						>
							Cancel
						</CancelButton>
					</TaskButtonWrap>
				</TaskInputForm>
			)}
		</TaskDiv>
	);
};

export default TodoExpandable;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import { faTimes, faPrint } from '@fortawesome/fontawesome-free-solid/';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
const { REACT_APP_BASE_URL } = process.env;
const hoverColor = '#1a56eb';
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const PaneContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 99%;
`;

const PaneTitle = styled.h3`
	font-size: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	margin: 20px 0px;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	width: 75%;
`;
const WordContainer = styled.div`
	display: flex;
	width: 80%;
	justify-content: space-between;
`;
const Word = styled.p`
	cursor: pointer;
`;
const WordsContainer = styled.div`
	height: 88%;
`;
const WordCountSpan = styled.span`
	font-size: 14px;
	// -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	// touch-action: manipulation;
	// line-height: 32px;
	// margin: 0;
	// padding: 0;
	// color: #6d7886;
	// white-space: nowrap;
	// overflow: hidden;
	// text-overflow: ellipsis;
`;
const PrintIconWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 25px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;

const WordUseReport = props => {
	
	//Props
	const { documents, booksString, volumes, books } = props;
	// Global Storage
	const { handleAddSearchResult } = useContext(NavContext);

	//State
	const [numberFilter, setNumberFilter] = useState(1);
	//Methods
	const handleOnPrint = () => {
		// let stringHtml = '',
		// 	doc = new jsPDF();

		// const hyphenFix = new RegExp(/—/g);
		// const apostropheFix = new RegExp(/’/g);

		const mappedVolumes = volumes.map((volume, i) => volume.label);
		const mappedBooks = books.map((book, i) => book.label);
		const mappedPrintDocs = documents
			.sort((a, b) => (a.word.trim() < b.word.trim() ? -1 : a.word.trim() > b.word.trim() ? 1 : 0))
			.filter(e => e.count === numberFilter)
			.map((e, i) => {
				const { word, count } = e;
				return `<p >
					<p>${word}</p>
				</p>`;
			});

		{
			let child = window.open('about:blank', `Word Use Report`);
			child.document.write(`<html>
			  <head><title>Word Use Report</title></head>
			  <body>
			  <h1>Word Use Report for ${numberFilter} occurrence:</h1>
			  <h2>${mappedDocuments.length} words</h2>
			  <p>${mappedVolumes.length > 0 && `<p><b>Volumes:</b> ${mappedVolumes.join(', ')}</p>`}</p>
			  <p><b>Books:</b> ${mappedBooks.join(', ')}</p>
			  <div>
			  <h3><b>Words:</b></h3>
			  	${mappedPrintDocs.join('')}
			  </div>
			  </body>
			 </html>`);
			child.document.close();
		}
	};
	const handleOpenSearch = word => {
		handleAddSearchResult(props.uniqueKey, word, 'scripturesOnly', 'none', 'none', booksString, 'scripturesOnly', true);
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;

		setTimeout(() => {
			var nwidth = oldwidth;
			var toscrollright = nwidth - rightposition;
			if (remaingLeft > nwidth && toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 500);
	};
	//Mapped Items
	const mappedDocuments = documents
		.sort((a, b) => (a.word.trim() < b.word.trim() ? -1 : a.word.trim() > b.word.trim() ? 1 : 0))
		.filter(e => e.count === numberFilter)
		.map((e, i) => {
			const { word, count } = e;
			return (
				<WordContainer key={i}>
					<Word onClick={() => handleOpenSearch(word)}>{word}</Word>
					<p>{count}</p>
				</WordContainer>
			);
		});

	const numbers = [...Array(20)].map((num, i) => (
		<option value={i + 1} key={i}>
			{i + 1}
		</option>
	));
	return (
		<>
			<PaneContainer>
				<ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='up' forceDirection={true}>
						<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
					</Tooltip>
				</ClosePaneWrapper>
				<PrintIconWrapper>
					<Tooltip content='Print Report' direction='left' forceDirection={true}>
						<FontAwesomeIcon icon={faPrint} onClick={handleOnPrint} />
					</Tooltip>
				</PrintIconWrapper>
				<TitleDiv {...props.dragHandleProps}>
					<TitleContainer>
						<PaneTitle>
							Word Use Report: <WordCountSpan> {mappedDocuments.length} words</WordCountSpan>
						</PaneTitle>
					</TitleContainer>
					<select value={numberFilter} onChange={e => setNumberFilter(parseInt(e.target.value))}>
						{numbers}
					</select>
				</TitleDiv>
				<WordsContainer>
					<Scrollbars>{mappedDocuments}</Scrollbars>
				</WordsContainer>
			</PaneContainer>
		</>
	);
};

export default WordUseReport;

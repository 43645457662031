import React, { useState, useEffect, useContext, useRef } from 'react';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimes,
	faPlay,
	faPause,
	faAngleRight,
	faAngleLeft,
	faStopwatch,
	faVolumeUp,
	faPencilAlt,
	faPlayCircle,
	faVideo,
	faFile,
	faInfoCircle
} from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import ReactPlayer from 'react-player';
import socket from 'socket.io-client/lib/socket';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const SearchInputContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 100%;
`;

const LibraryText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	// width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const EpisodeText = styled.h3`
	font-size: 15px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center
`;
const InfoContainer = styled.div`
    color: #324599;
	margin-left: 10px;
`
const PlayerButtons = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 75px;
	justify-content: space-between;
	// border: 1px solid pink
	// position: static;
`;
const PlayButton = styled.button`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: #324599;
	color: #fff;
	text-align: center;
	margin: 0px 10px;
	border: none;
`;
const ProgressBar = styled.input`
	width: 100%;
`;
const PlayerControls = styled.div`
	display: flex;
	align-items: center;
	font-size: 20px;
	// border: 1px solid red;
	width: 33%;
	justify-content: center;
`;

const SeekButtons = styled.button`
	background-color: transparent;
	border: none;
	color: #7d8085;
	&:hover {
		color: #1a56eb;
	}
`;
const VolumeContainer = styled.div`
	color: #7d8085;
	display: flex;
	align-items: center;
	// border: 1px solid blue;
	width: 33%;
	justify-content: flex-start;
`;

const VolumeSlider = styled.input`
	width: 50px;
`;
const SettingsContainer = styled.div`
	color: #7d8085;
	display: flex;
	// border: 1px solid black;
	align-items: center;
	width: 33%;
	justify-content: flex-end;
	&:hover {
		cursor: pointer;
	}
`;

const PlaybackRateButtons = styled.div`
	width: 50px;
	height: 75px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #7e8084;
	justify-content: space-evenly;
	z-index: 100;
	margin-right: 5px;
	padding-right: 0px;
	justify-content: flex-start;
`;
const PlaybackRatebutton = styled.button`
	background-color: transparent;
	border: none;
	outline: none;
	width: 100%;
	font-size: 12px;
	&:hover {
		background-color: #ececec;
	}
`;

const TimeStampsConatiner = styled.div`
	margin-top: 10px;
	width: 100%;
	height: 40%;
	// overflow: hidden;
`;
const TimeStamps = styled.textarea`
	width: 100%;
	min-height: 235px;
	// max-height: 90%;
	&:focus {
		outline: none;
	}
`;
const Player = styled.div`
	// height: 70%;
`;

const InactiveButton = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	outline: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:focus {
		outline: none;
	}
`;
const ActiveButton = styled.button`
	color: #324599;
	font-size: 17px;
	border: none;
	outline: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
	&:focus {
		outline: none;
	}
`;
const EpisodeButtonsContainer = styled.div`
	display: flex;
`;
const CompletedContainer = styled.div``;
const Checkbox = styled.input`
	margin-left: 5px;
`;

const UserButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const PodcastPreview = props => {
	// Global Storage
	const { libraryState, libraryDispatch, fetchVolumesBooksNChapters } = useContext(LibraryProviderState);
	const { handleOpenVolume, handleOpenBook, handleAddStudyHelpPane, state, dispatch } = useContext(NavContext);
	const { chapterHistoryDispatch, chapterHistoryState } = useContext(ChapterHistoryContext);
	const { authState } = useContext(AuthContext);
	//State
	const [playing, setPlaying] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1.0);
	const [played, setPlayed] = useState(0);
	const [volume, setVolume] = useState(0.5);
	const [volumeHidden, setVolumeHidden] = useState(true);
	const [pbRateHidden, setPbRateHidden] = useState(true);
	const [duration, setDuration] = useState('');
	const [progress, setProgress] = useState('');
	const [watched, setWatched] = useState(props.checked);
	// console.log(props)
	useEffect(() => {
		if (authState && authState.user && authState.user.id) {
			const socket = props.socket;

			socket.on('update_watched_status_' + authState.user.id, ({ episodeName, status }) => {
				if (episodeName.trim() === episode.show_title.trim()) {
					setWatched(status);
				}
			});

			return () => socket.off('update_watched_status_' + authState.user.id);
		}
	}, [authState]);
	//Ref
	const player = useRef(null);

	// console.log(props)
	const toggleChecked = async () => {
		watched
			? await fetch(
				`${process.env.REACT_APP_BASE_URL}/podcast/removeUserWatchedEpisode/${props.user.id}/${episode.podcast_id}/${episode.show_title}`,
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
				.then(res => res.json())
				.then(episodes => {
					setWatched(false);
					props.socket.emit('update_watched_status', { userId: props.user.id, episodeName: episode.show_title, status: false });
					dispatch({ type: 'UPDATE_PODCAST_PLAYER_PANE', payload: { checked: false, paneKey: props.uniqueKey } });
				})
			: await fetch(
				`${process.env.REACT_APP_BASE_URL}/podcast/addUserWatchedEpisode/${props.user.id}/${episode.podcast_id}/${episode.show_title}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)
				.then(res => res.json())
				.then(episodes => {
					setWatched(true);
					props.socket.emit('update_watched_status', { userId: props.user.id, episodeName: episode.show_title, status: true });
					dispatch({ type: 'UPDATE_PODCAST_PLAYER_PANE', payload: { checked: true, paneKey: props.uniqueKey } });
				});
	};
	const handleSeek = value => {
		player.current.seekTo(value);
	};
	const handleSkip = () => {
		const currentTime = player.current.getCurrentTime();
		player.current.seekTo(currentTime + 10, 'seconds');
	};
	const handleBack = () => {
		const currentTime = player.current.getCurrentTime();
		player.current.seekTo(currentTime - 10, 'seconds');
	};
	const handlePlaybackRate = value => {
		setPlaybackRate(value);
	};
	const getTimeHours = value => {
		//1:43
		// console.log(Math.floor(time % 60))
		// return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
		const sec = parseInt(value, 10); // convert value to number if it's string
		let hours = Math.floor(sec / 3600); // get hours
		let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
		let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
		// add 0 if value < 10; Example: 2 => 02
		if (hours < 10) {
			hours = hours;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		if (seconds < 10) {
			seconds = '0' + seconds;
		}
		return hours + ':' + minutes + ':' + seconds;
	};
	const getTimeMinutes = time => {
		return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
	};
	const unique = () => {
		return new Date().getTime().toString(36);
	};
	const openPlayer = (event, link, type, episode) => {
		if (link !== '') {
			if (event.ctrlKey || event.metaKey) {
				return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
			} else {
				dispatch({
					type: 'HANDLE_ADD_PANE',
					originPaneKey: props.uniqueKey,
					payload: {
						key: `podcastPreview_${unique()}`,
						type: 'podcastPreview',
						link,
						podcast: podcast,
						episode: episode,
						preview: false,
						linkType: type,
					},
				});
				navScroll();
			}
		} else alert(`We're sorry but theres no ${type} link for this episode.`);
	};
	const openShowNotes = link => {
		link !== '' ? window.open(link) : alert(`We're sorry but there are no show notes for this episode.`);
	};
	const openOtherLinks = link => {
		link !== '' ? window.open(link) : alert(`We're sorry but there are no show notes for this episode.`);
	};
	const navScroll = () => {
		setTimeout(function () {
			const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			panelsBlock.scroll({
				left: panelsBlock.scrollLeft + 500,
				behavior: 'smooth',
			});
		}, 100);
	};

	const { link, episode, preview, podcast, linkType } = props;
	return (
		<>
			<SearchInputContainer>
				<ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='up' forceDirection={true}>
						<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
					</Tooltip>
				</ClosePaneWrapper>
				{/* <Scrollbars autohide> */}
				<TitleDiv {...props.dragHandleProps}>
					{props.preview ? (
						<LibraryText>Preview</LibraryText>
					) : (
						<div>
							<TitleContainer>
								<LibraryText>{podcast}</LibraryText>
								<InfoContainer>
									<Tooltip
										content={<p>Due to issues we're investigating media panes open on the left and only one of each at a time.<br />Close one to open a new one.</p>}
										direction='right'
										forceDirection={true}
									>
										<FontAwesomeIcon icon={faInfoCircle} />
									</Tooltip>
								</InfoContainer>
							</TitleContainer>
							<UserButtonsContainer>
								<EpisodeButtonsContainer>
									{episode.audio_link.length > 0 ? (
										<ActiveButton>
											<Tooltip content='Play Audio'>
												<FontAwesomeIcon icon={faPlayCircle} onClick={event => openPlayer(event, episode.audio_link, 'audio', episode)} />
											</Tooltip>
										</ActiveButton>
									) : (
										<InactiveButton>
											<FontAwesomeIcon icon={faPlayCircle} />
										</InactiveButton>
									)}
									{episode.video_link.length > 0 ? (
										<ActiveButton>
											<Tooltip content='Play Video'>
												<FontAwesomeIcon icon={faVideo} onClick={event => openPlayer(event, episode.video_link, 'video', episode)} />
											</Tooltip>
										</ActiveButton>
									) : (
										<InactiveButton>
											<FontAwesomeIcon icon={faVideo} />
										</InactiveButton>
									)}
									{episode.show_notes_link.length > 0 ? (
										<ActiveButton>
											<Tooltip content='Show Notes'>
												<FontAwesomeIcon icon={faPencilAlt} onClick={() => openShowNotes(episode.show_notes_link)} />
											</Tooltip>
										</ActiveButton>
									) : (
										<InactiveButton>
											<FontAwesomeIcon icon={faPencilAlt} />
										</InactiveButton>
									)}
									{episode.other_link.length > 0 ? (
										<ActiveButton>
											<Tooltip content='Extra Content'>
												<FontAwesomeIcon icon={faFile} onClick={() => openOtherLinks(episode.other_link)} />
											</Tooltip>
										</ActiveButton>
									) : (
										<InactiveButton>
											<FontAwesomeIcon icon={faFile} />
										</InactiveButton>
									)}
								</EpisodeButtonsContainer>
								<CompletedContainer>
									Completed
									<Checkbox onChange={() => toggleChecked()} type='checkbox' checked={watched} />
								</CompletedContainer>
							</UserButtonsContainer>
							<EpisodeText>Episode: {episode.show_title}</EpisodeText>
						</div>
					)}
				</TitleDiv>

				<Scrollbars>
					<ReactPlayer
						ref={player}
						url={props.link}
						playing={playing}
						volume={volume}
						width='100%'
						height={linkType === 'audio' ? '0%' : '50%'}
						onProgress={e => {
							setProgress(e.playedSeconds);
							setPlayed(e.played);
						}}
						onDuration={e => setDuration(e)}
						playbackRate={playbackRate}
						onPause={() => setPlaying(false)}
						onPlay={() => setPlaying(true)}
						controls
					/>
					<ProgressBar type='range' min={0} max={0.999999} step='any' value={played} onChange={e => handleSeek(e.target.value)} />
					<span>{`${progress > 3600 ? getTimeHours(progress) : getTimeMinutes(progress)} / ${duration > 3600 ? getTimeHours(duration) : getTimeMinutes(duration)
						}`}</span>
					<PlayerButtons>
						<VolumeContainer>
							<FontAwesomeIcon onClick={() => setVolumeHidden(!volumeHidden)} icon={faVolumeUp} />
							{!volumeHidden && (
								<VolumeSlider
									type='range'
									value={volume}
									onChange={e => setVolume(e.target.value)}
									step='any'
									min={0}
									max={0.999999}
									orient='vertical'
								/>
							)}
						</VolumeContainer>
						<PlayerControls>
							<SeekButtons onClick={() => handleBack()}>
								<FontAwesomeIcon icon={faAngleLeft} />
							</SeekButtons>
							<PlayButton onClick={() => setPlaying(!playing)}>
								<Tooltip content='Play/Pause'>
									<FontAwesomeIcon icon={!playing ? faPlay : faPause} />
								</Tooltip>
							</PlayButton>
							<SeekButtons onClick={() => handleSkip()}>
								<FontAwesomeIcon icon={faAngleRight} />
							</SeekButtons>
						</PlayerControls>
						<SettingsContainer>
							{!pbRateHidden && (
								<PlaybackRateButtons>
									<Scrollbars autoHideDuration={10}>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(0.8)}>.8</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(1)}>1.0</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(1.2)}>1.2</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(1.2)}>1.2</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(1.4)}>1.4</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(1.6)}>1.6</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(1.8)}>1.8</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(2)}>2</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(2.2)}>2.2</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(2.5)}>2.5</PlaybackRatebutton>
										<PlaybackRatebutton onClick={() => handlePlaybackRate(3.0)}>3.0</PlaybackRatebutton>
									</Scrollbars>
								</PlaybackRateButtons>
							)}
							<FontAwesomeIcon onClick={() => setPbRateHidden(!pbRateHidden)} icon={faStopwatch} style={{ marginRight: '15px' }} />
						</SettingsContainer>
					</PlayerButtons>
					{/* <Scrollbars autoHide> */}
					{episode.time_stamps &&
						<TimeStampsConatiner>
							<EpisodeText>Time Stamps</EpisodeText>
							{/* <Scrollbars autohide> */}
							<TimeStamps value={episode.time_stamps} />
							{/* </Scrollbars> */}
						</TimeStampsConatiner>
					}
				</Scrollbars>
			</SearchInputContainer>
		</>
	);
};

export default PodcastPreview;

import styled from 'styled-components';

const DeletePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 30%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	max-height: 392px;
	overflow-y: scroll;
`;
const ShareModalWrapper = styled.div`
	left: 15%;
	right: 15%;
	top: 20%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	max-height: 392px;
	overflow-y: scroll;
`;
const EditorPopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 30%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	max-height: 392px;
	overflow-y: scroll;
`;
const ImagePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 30%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	max-height: 392px;
	overflow-y: scroll;
`;
const CollectionsPopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 30%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 10px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	max-height: 392px;
	overflow-y: scroll;
`;

const DeletePopUpBtnWrapper = styled.div`
	display: inline;
`;

const DeletePopUpBtn = styled.button`
	padding: 13px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	margin-right: 15px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	color: #ac323d;
	background-color: #fff;
	border: 2px solid #ac323d;
	&:hover {
		background-color: #ac323d;
		color: #fff;
	}
`;
const SaveBtn = styled.button`
	padding: 13px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	margin-right: 15px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
`;
const SaveChangesBtn = styled.button`
	padding: 13px;
	line-height: 10px;
	height: 100%;
	// width: 125px;
	margin-right: 15px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
`;

const ClosePopUpBtn = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	margin-right: 0;
	padding: 13px;
	cursor: pointer;
	border-radius: 10px;
	font-size: 14px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
`;

export {
	DeletePopUpBtn,
	DeletePopUpBtnWrapper,
	DeletePopUpWrapper,
	ClosePopUpBtn,
	SaveBtn,
	CollectionsPopUpWrapper,
	ImagePopUpWrapper,
	EditorPopUpWrapper,
	SaveChangesBtn,
	ShareModalWrapper,
};

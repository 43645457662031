import React, { useState, useEffect, useContext } from 'react';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import StudyHelps from '../StudyHelps/StudyHelps';
import Chapters from './PodcastChapters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faPlayCircle, faClock } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import Fuse from 'fuse.js';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { PortalWithState } from 'react-portal';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
// import { StudyHelpsContext } from '../../ContextProviders/StudyHelpsProvider';

const queryString = require('query-string');

// The following styles are everything above the Old Testament Text.
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const SearchInputContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
`;

const LibraryText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 10px;
`;

const SearchBarContainer = styled.div`
	position: relative;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
	font-size: 15.5px;
`;

const SearchIconWrapper = styled.button`
	color: #7d8085;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
// The following styles are everything below the search input.
const LibraryVolumeContainer = styled.div`
	padding-left: 25px;
	position: relative;
	padding-bottom: 100px;
	height: 97%;
	min-width: 315px;
	margin-top: 20px;
`;

const BackButtonDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;

const VolumeDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	font-weight: 500;
	&:hover {
		background-color: #ececec;
	}
`;

const ShowCompleted = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	&:hover {
		cursor: pointer;
	}
`;
const ActionItem = styled.div`
	margin-right: 10px;
	margin-left: 5px;
	font-size: 14px;
	line-height: 32px;
`;
const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 35%;
`;
const InputTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;
const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const Input = styled.input`
	width: 100px;
	margin-right: 5px;
`;

const Text = styled.p`
	font-size: 10px;
	width: 100px;
	margin-right: 10px;
	font-weight: bold;
`;
const TextPlay = styled.p`
	font-size: 10px;
	width: 110px;
	margin-right: 10px;
	font-weight: bold;
`;

const PlayIcon = styled.div`
	// border: 1px solid red;
	width: 15px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -5px;
	cursor: pointer;
`;
const AddLinksWrapper = styled.div`
	&:hover {
		cursor: pointer;
	}
`;
const TextArea = styled.textarea`
	width: 100px;
	margin-right: 5px;
	height: 29.5px;
`;
const DeletePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 20%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	display: flex;
	flex-direction: column;
`;

const TimeStampArea = styled.textarea`
	height: 300px;
	margin-bottom: 10px;
`;
const TimeStampIcon = styled.button`
	width: 100px;
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	border: none;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;

const Podcast = props => {
	const [volumes, setVolumes] = useState([]);
	const [books, setBooks] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [search, setSearch] = useState('');
	const [focused, setFocused] = useState(false);
	const [showTitle, setShowTitle] = useState('');
	const [audioLink, setAudioLink] = useState('');
	const [videoLink, setVideoLink] = useState('');
	const [showNotesLink, setShowNotesLink] = useState('');
	const [otherLink, setOtherLink] = useState('');
	const [timeStamps, setTimeStamps] = useState('');
	const [selectedChapters, setSelectedChapters] = useState([]);
	const [pullLinks, setPullLinks] = useState(false);
	const [chaptersWithLinks, setChaptersWithLinks] = useState([]);
	console.log(props);
	const [available, setAvailable] = useState(props.podcast.public);
	// Global Storage
	const { libraryState, libraryDispatch, fetchVolumesBooksNChaptersPodcast } = useContext(LibraryProviderState);
	const { handleOpenVolume, handleOpenBook, handleAddStudyHelpPane, state, dispatch } = useContext(NavContext);
	const { chapterHistoryDispatch, chapterHistoryState } = useContext(ChapterHistoryContext);
	const { authState } = useContext(AuthContext);
	const { podcast } = props;
	// const {studyHelpsState, studyHelpsDispatch} = useContext(StudyHelpsContext)
	useEffect(() => {
		fetchVolumesBooksNChaptersPodcast(props.uniqueKey);

		let currentPaneIndex = state.findIndex(({ key }) => key === props.uniqueKey);
		if (currentPaneIndex === -1) currentPaneIndex = state.length - 1;

		if (state[currentPaneIndex]['volumeTitle']) {
			if (state[currentPaneIndex]['bookName']) {
				libraryDispatch({
					type: 'SET_SEARCH',
					payload: { search: `${state[currentPaneIndex]['volumeTitle']} / ${state[currentPaneIndex]['bookName']}`, uniqueKey: props.uniqueKey },
				});
			} else {
				libraryDispatch({ type: 'SET_SEARCH', payload: { search: state[currentPaneIndex]['volumeTitle'], uniqueKey: props.uniqueKey } });
			}
		}

		if (state[currentPaneIndex]['selectedTopic']) {
			// setShowStudyHelps(true)
		}

		let parsed = queryString.parse(window.location.search);
		if (parsed && parsed['libsearch'] && !chapterHistoryState.hasLibraryQueryRendered) {
			chapterHistoryDispatch({ type: 'SET_LIBRARY_URL_QUERY_RENDERED', payload: true });
			let { libsearch } = parsed;
			libraryDispatch({ type: 'SET_SEARCH', payload: { search: libsearch, uniqueKey: props.uniqueKey } });
		}
		fetchChaptersWithLinks();
	}, []);

	useEffect(() => {
		if (libraryState[props.uniqueKey]) {
			addProperties();
			setSearch(libraryState[props.uniqueKey].search);
		}
	}, [libraryState[props.uniqueKey]]);

	const addProperties = () => {
		let volumes = libraryState[props.uniqueKey].volumes;
		let books = libraryState[props.uniqueKey].books;
		let chapters = libraryState[props.uniqueKey].chapters;
		volumes = _.map(volumes, volume => {
			volume.books = _(books)
				.filter({ volume_id: volume.id })
				.map(book => {
					book.best = false;
					book.chapters = _(chapters)
						.filter({ book_id: book.id })
						.map(chapter => {
							if (!chapter.search) chapter.search = book.title + ' / ' + chapter.order;
							if (!chapter.full) chapter.full = volume.title + ' / ' + book.title + ' / ' + chapter.order;
							return chapter;
						})
						.value();
					return book;
				})
				.value();
			return volume;
		});
		setVolumes(volumes);
		setBooks(books);
		setChapters(chapters);
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (search.length > 0) {
			// term = first part of the string / verse term is the last part
			var parts = search.match(/^([\d\s]*[a-zA-Z\'\s]+)(\d+)?([\s\:\,\.\-\;]?)(\d+)?$/);

			var bookTerm = parts ? parts[1] : null;
			var chapterTerm = parts ? parts[2] : null;
			var colonTerm = parts ? parts[3] : null;
			var verseTerm = parts ? parts[4] : null;
		}
	};

	const handleChooseBook = (e, volume, book) => {
		e.preventDefault();
		if (book.id) {
			libraryDispatch({ type: 'SET_SEARCH', payload: { search: volume.title + ' / ' + book.title, uniqueKey: props.uniqueKey } });
		}
	};

	const handleBack = e => {
		e.preventDefault();
		libraryDispatch({ type: 'SET_SEARCH', payload: { search: '', uniqueKey: props.uniqueKey } });
	};

	const handleChooseVolume = (e, volume) => {
		e.preventDefault();
		if (volume.id) {
			libraryDispatch({ type: 'SET_SEARCH', payload: { search: volume.title, uniqueKey: props.uniqueKey } });
		}
	};
	const handleClearInputs = () => {
		setShowTitle('');
		setAudioLink('');
		setVideoLink('');
		setShowNotesLink('');
		setOtherLink('');
		setTimeStamps('');
	};
	const fetchChaptersWithLinks = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getChaptersWithLinks/${podcast.id}`)
			.then(res => res.json())
			.then(chapters => {
				setChaptersWithLinks(chapters);
			});
	};
	const handleAddPodcastLinks = async obj => {
		if (showTitle.length > 0 && selectedChapters.length > 0 && (audioLink || videoLink || showNotesLink || otherLink || timeStamps)) {
			await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/addPodcastLinks/${podcast.id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ showTitle, audioLink, videoLink, showNotesLink, otherLink, selectedChapters, timeStamps }),
			})
				.then(res => res.json())
				.then(podcasts => {
					setSelectedChapters([]);
					handleClearInputs();
					setPullLinks(true);
					fetchChaptersWithLinks();
				});
		} else alert('Make sure to fill out title, add at least one link, and select at least on chapter');
	};
	const searchVolumes = (term, volumes, books, chapters) => {
		term = term ? term.toLowerCase().trim() : '';

		// term = first part of the string / verse term is the last part
		term = term.replace(/(\d*)([\s\:\,\.\-\;]*)(\d*)$/, '');

		if (term.length > 0) {
			let exact_chapters_by_book = searchExactBook(term, volumes, books, chapters);

			if (exact_chapters_by_book.length >= 1) {
				chapters = exact_chapters_by_book;
			} else {
				// chapters = [];
				let exact_chapters_by_volume = searchExactVolume(term, volumes, books, chapters);

				if (exact_chapters_by_volume.length >= 1) {
					let mainVolumes = ['old testament', 'new testament', 'doctrine and covenants', 'book of mormon', 'pearl of great price', 'apocrypha'];
					let match = mainVolumes.find(v => term.includes(v));

					chapters = match ? exact_chapters_by_volume : [];

					// chapters = mainVolumes.includes(term) ? exact_chapters_by_volume : [];
					// chapters = exact_chapters_by_volume;
				} else {
					chapters = searchFuzzy(term, chapters);
				}
			}

			// Book of "Mormon"
			// "Mormon"
		} else {
			chapters = searchFuzzy(term, chapters);
		}

		let book_ids = _(chapters).map('book_id').uniq().value();
		books = _.filter(books, x => _.includes(book_ids, x.id));

		let volume_ids = _(books).map('volume_id').uniq().value();
		// volumes = _.filter(volumes, x => _.includes(volume_ids, x.id) || x.title === 'Topical Guide');
		volumes = _.filter(volumes, x => _.includes(volume_ids, x.id));

		volumes = _.map(volumes, volume => {
			volume.books = _(books)
				.filter({ volume_id: volume.id })
				.map(book => {
					book.chapters = _(chapters).filter({ book_id: book.id }).value();
					book.best = !!_.find(book.chapters, { best: true });
					return book;
				})
				.value();
			return volume;
		});

		return volumes;
	};

	const unique = () => {
		return new Date().getTime().toString(36);
	};

	const searchExactBook = (term, volumes, books, chapters) => {
		term = term ? term.toLowerCase().trim() : '';

		if (term.length > 0) {
			chapters = _.filter(chapters, x => x.search.toLowerCase().indexOf(term) === 0);

			// loop through the chapters and assign a score from 0 to 1
			chapters = chapters.map((x, i) => {
				x.best = i === 0;
				x.score = 1;
				return x;
			});
		}

		return chapters;
	};

	const searchExactVolume = (term, volumes, books, chapters) => {
		term = term ? term.toLowerCase().trim() : '';

		if (term.length > 0) {
			chapters = _.filter(chapters, x => x.full.toLowerCase().indexOf(term) >= 0);

			// loop through the chapters and assign a score from 0 to 1
			chapters = chapters.map((x, i) => {
				x.best = i === 0;
				x.score = 1;
				return x;
			});
		}

		return chapters;
	};

	const searchFuzzy = (term, chapters) => {
		term = term ? term.toLowerCase().trim() : '';
		if (term.length > 0) {
			let options = {
				tokenize: true,
				matchAllTokens: true,
				includeScore: true,
				threshold: 0.5,
				location: 0,
				distance: 20,
				maxPatternLength: 32,
				minMatchCharLength: 0,
				keys: ['search'],
			};

			let fuse = new Fuse(chapters, options); // "list" is the item array
			chapters = _(fuse.search(term))
				.map((x, i) => {
					x.item.best = i === 0;
					x.item.score = x.score;
					return x;
				})
				.map('item')
				.value();
		}
		return chapters;
	};

	let volumesAndBooks = volumes.length !== 0 ? searchVolumes(search, volumes, books, chapters) : [];
	let currentPaneIndex = state.findIndex(({ key }) => key === props.uniqueKey);
	// const handleCloseSHPane = (id, selectedTopic = false) => {
	// 	if(!selectedTopic){
	// 		studyHelpsDispatch({type: 'TOGGLE_SH_CLOSE'})
	// 		props.closePane(id)
	// 	} else{
	// 		props.closePane(id)
	// 	}
	// }
	const togglePublic = async () => {
		available
			? await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/togglePublic/${props.podcast.id}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ available: !available }),
			})
				.then(res => res.json())
				.then(podcast => {
					// setAvailable([podcast].public)
					props.socket.emit('update_podcast_list', { userId: props.user.id });
				})
			: await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/togglePublic/${props.podcast.id}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ available: !available }),
			})
				.then(res => res.json())
				.then(podcast => {
					props.socket.emit('update_podcast_list', { userId: props.user.id });
					// setAvailable([podcast].public)
				});
	};

	const navScroll = () => {
		setTimeout(function () {
			const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			panelsBlock.scroll({
				left: panelsBlock.scrollLeft + 500,
				behavior: 'smooth',
			});
		}, 100);
	};

	return (
		<>
			{props.studyPaneOpen ? (
				<StudyHelps
					id={props.id}
					user={authState.user}
					uniqueKey={props.uniqueKey}
					dragHandleProps={props.dragHandleProps}
					closePane={id => props.closePane(id)}
					backToMain={() => handleAddStudyHelpPane({ originPaneKey: props.uniqueKey, open: false })}
					selectedOption={
						(currentPaneIndex || currentPaneIndex === 0) && state[currentPaneIndex] && state[currentPaneIndex]['selectedOption']
							? state[currentPaneIndex]['selectedOption']
							: null
					}
					selectedTopic={
						(currentPaneIndex || currentPaneIndex === 0) && state[currentPaneIndex] && state[currentPaneIndex]['selectedTopic']
							? state[currentPaneIndex]['selectedTopic']
							: null
					}
					selectedLetter={
						(currentPaneIndex || currentPaneIndex === 0) && state[currentPaneIndex] && state[currentPaneIndex]['selectedLetter']
							? state[currentPaneIndex]['selectedLetter']
							: null
					}
				/>
			) : (
				<>
					<ClosePaneWrapper>
						<Tooltip content='Close Pane' direction='up' forceDirection={true}>
							<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
						</Tooltip>
					</ClosePaneWrapper>
					<SearchInputContainer>
						<TitleDiv>
							<LibraryText {...props.dragHandleProps}>{podcast.podcast_name}</LibraryText>
							<ShowCompleted>
								<input
									type='checkbox'
									checked={available}
									onChange={() => {
										setAvailable(!available);
										togglePublic();
									}}
								/>
								<ActionItem>Public</ActionItem>
							</ShowCompleted>
						</TitleDiv>
						<SearchBarContainer>
							<form onSubmit={handleSubmit}>
								<SearchInput
									type='text'
									className='input'
									value={search}
									onChange={e => setSearch(e.target.value)}
									autoComplete='off'
									onFocus={() => setFocused(true)}
									// onBlur={() => libraryDispatch({ type: 'SET_SEARCH', payload: { search, uniqueKey: props.uniqueKey } })}
									autoFocus={false}
									name='text'
								/>

								<SearchIconWrapper type='submit'>
									<FontAwesomeIcon icon={faSearch} />
								</SearchIconWrapper>
							</form>
						</SearchBarContainer>
					</SearchInputContainer>
					<InputTextWrapper>
						{/* <Text>Chapter</Text> */}
						<Text>Show Title</Text>
						<TextPlay>Audio Link</TextPlay>
						<TextPlay>Video Link</TextPlay>
						<Text>Show Notes URL</Text>
						<Text>Other</Text>
						<Text>Time Stamps</Text>
					</InputTextWrapper>
					<InputWrapper>
						<Input value={showTitle} onChange={e => setShowTitle(e.target.value)}></Input>
						<Input value={audioLink} onChange={e => setAudioLink(e.target.value)}></Input>
						<PlayIcon>
							<FontAwesomeIcon
								icon={faPlayCircle}
								onClick={e => {
									if (audioLink.length > 0) {
										e.stopPropagation();
										e.persist();
										if (e.ctrlKey || e.metaKey) {
											return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
										} else {
											dispatch({
												type: 'HANDLE_ADD_PANE',
												originPaneKey: props.uniqueKey,
												payload: {
													key: `podcastPreview_${unique()}`,
													type: 'podcastPreview',
													link: audioLink,
													podcast: '',
													episode: {},
													preview: true,
													linkType: 'audio',
												},
											});
											navScroll();
										}
									}
								}}
							/>
						</PlayIcon>
						<Input value={videoLink} onChange={e => setVideoLink(e.target.value)}></Input>
						<PlayIcon>
							<FontAwesomeIcon
								icon={faPlayCircle}
								onClick={e => {
									if (videoLink.length > 0) {
										e.stopPropagation();
										e.persist();
										if (e.ctrlKey || e.metaKey) {
											return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
										} else {
											dispatch({
												type: 'HANDLE_ADD_PANE',
												payload: {
													key: `podcastPreview_${unique()}`,
													type: 'podcastPreview',
													link: videoLink,
													podcast: '',
													episode: {},
													preview: true,
													linkType: 'video',
												},
											});
											navScroll();
										}
									}
								}}
							/>
						</PlayIcon>
						<Input value={showNotesLink} onChange={e => setShowNotesLink(e.target.value)}></Input>
						<Input value={otherLink} onChange={e => setOtherLink(e.target.value)}></Input>
						<PortalWithState closeOnOutsideClick closeOnEsc>
							{({ openPortal, closePortal, isOpen, portal }) => (
								<>
									<TimeStampIcon>
										<Tooltip content='Add Time Stamps'>
											<FontAwesomeIcon key='ProjectPortal' icon={faClock} onClick={openPortal} />
										</Tooltip>
									</TimeStampIcon>
									{portal(
										<div>
											<div
												style={{
													top: 0,
													zIndex: 9999999,
													background: '#ddd',
													width: '100%',
													height: '100%',
													minHeight: '392px',
													opacity: '0.6',
													position: 'absolute',
												}}
											/>

											<DeletePopUpWrapper>
												{/* <textarea>{e.time_stamps}</textarea> */}
												<TimeStampArea onChange={e => setTimeStamps(e.target.value)} value={timeStamps}></TimeStampArea>
												<DeletePopUpBtnWrapper>
													<ClosePopUpBtn onClick={closePortal}>Save</ClosePopUpBtn>
													<DeletePopUpBtn
														onClick={() => {
															closePortal();
															setTimeStamps('');
														}}
													>
														Cancel
													</DeletePopUpBtn>
												</DeletePopUpBtnWrapper>
											</DeletePopUpWrapper>
										</div>
									)}
								</>
							)}
						</PortalWithState>
						{/* <button onClick={() => handleAddPodcastLinks()}>+</button> */}
						<AddLinksWrapper>
							<Tooltip content='Add Links' direction='up' forceDirection={true}>
								<FontAwesomeIcon icon={['fas', 'plus-circle']} size={'2x'} color={'#324599'} onClick={() => handleAddPodcastLinks()} />
							</Tooltip>
						</AddLinksWrapper>
					</InputWrapper>
					<LibraryVolumeContainer>
						{search.length > 0 ? (
							<BackButtonDisplay
								onClick={e => {
									handleBack(e);
								}}
							>
								◀ Back
							</BackButtonDisplay>
						) : null}
						<Scrollbars autoHide>
							{volumesAndBooks ? (
								<div>
									{volumesAndBooks.map((volume, id) => {
										return (
											<div key={id}>
												<VolumeDisplay
													onClick={e => {
														e.stopPropagation();
														e.persist();
														if (e.altKey) {
															handleOpenVolume({ volumeTitle: volume.title, originPaneKey: props.uniqueKey });
														} else if (e.ctrlKey || e.metaKey) {
															window.open(`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${volume.title}`, '_blank');
														} else {
															handleChooseVolume(e, volume);
														}
													}}
												>
													<h5>{volume.title}</h5>
												</VolumeDisplay>
												<div>
													{volume.books && search.length > 0
														? volume.books.map((book, id) => {
															return (
																<div key={id}>
																	{/* Fix styling */}
																	{/* Add a click event to update the search string to be the title of the book we clicked */}
																	<VolumeDisplay
																		onClick={e => {
																			handleChooseBook(e, volume, book);
																		}}
																	>
																		{volume.books.length === 1 ? null : ''}
																		{book.title.includes('Doctrine and Covenants') ? null : book.title}
																		{/* {lds_book.name} */}
																	</VolumeDisplay>
																	<div>
																		{volume.books.length === 1 ? (
																			<Chapters
																				selectedChapters={selectedChapters}
																				setSelectedChapters={setSelectedChapters}
																				chapters={book.chapters}
																				uniqueKey={props.uniqueKey}
																				podcastId={podcast.id}
																				pullLinks={pullLinks}
																				setPullLinks={setPullLinks}
																				chaptersWithLinks={chaptersWithLinks}
																				fetchChaptersWithLinks={fetchChaptersWithLinks}
																			/>
																		) : null}
																	</div>
																</div>
															);
														})
														: null}
												</div>
											</div>
										);
									})}
								</div>
							) : null}
							<div></div>
						</Scrollbars>
					</LibraryVolumeContainer>
				</>
			)}
		</>
	);
};

export default Podcast;

import React, { createContext, useState, useEffect, useRef } from 'react';
import { bbwIsSelectionValid } from '../components/BBWFormatter/BBWFormatter';

export const VerseOptionsContext = createContext();
export const VerseOptionsProvider = ({ children }) => {
	const [optionVerseId, setOptionVerseId] = useState(0);
	const [optionPaneUniqueId, setOptionPaneUniqueId] = useState('');
	const [optionVerseProps, setOptionVerseProps] = useState([]);
	const [optionXPosition, setOptionXPosition] = useState('10px');
	const [optionYPosition, setOptionYPosition] = useState('15px');
	const [optionOpacity, setOptionOpacity] = useState('0.2');
	const [selectedTextState, setSelectedTextState] = useState('');
	const [selectedVerseId, setSelectedVerseId] = useState('');
	const [isOpenOptions, setIsOpenOptions] = useState(false);
	const [selectedFullLi, setSelectedFullLi] = useState('');

	useEffect(() => {
		document.addEventListener('mouseup', bbwToolbarRomover);
		return () => {
			document.removeEventListener('mouseup', bbwToolbarRomover);
		};
	}, []);

	const bbwToolbarRomover = () => {
		setTimeout(() => {
			if (!bbwIsSelectionValid()) {
				
				var textFormatterClasses = document.querySelectorAll('.TextFormatterClass');
				textFormatterClasses.forEach(textFormatterClass => {
					textFormatterClass.style.display = 'none';
				});
			}
		}, 200);
	};

	return (
		<VerseOptionsContext.Provider
			value={{
				optionVerseId,
				setOptionVerseId,
				optionPaneUniqueId,
				setOptionPaneUniqueId,
				optionVerseProps,
				setOptionVerseProps,
				isOpenOptions,
				setIsOpenOptions,
				optionXPosition,
				setOptionXPosition,
				optionYPosition,
				setOptionYPosition,
				optionOpacity,
				setOptionOpacity,
				selectedTextState,
				setSelectedTextState,
				selectedVerseId,
				setSelectedVerseId,
				selectedFullLi,
				setSelectedFullLi,
			}}
		>
			{children}
		</VerseOptionsContext.Provider>
	);
};

import { Quill } from 'react-quill';

export function link(value) {
	if (value) {
		let range = this.quill.getSelection();
		if (range == null || range.length === 0) return;
		let tooltip = this.quill.theme.tooltip;
		tooltip.root.querySelector('input[data-link]').dataset.link = 'Some text or link';
		tooltip.edit('link', '');
	} else {
		this.quill.format('link', false);
	}
}

// export function Def(value) {
// 	if (value) {
// 		const cursorPosition = this.quill.getSelection().index;
// 		this.quill.insertText(cursorPosition, value);
// 		this.quill.setSelection(cursorPosition + value.length);
// 	} else {
// 		return;
// 	}
// }

export function h1(value) {
	if (value) {
		let range = this.quill.getSelection();
		if (range === null || range.length === 0) return;
		let text = this.quill.getText(range.index, range.length);
		window.open(`http://webstersdictionary1828.com/Dictionary/${text.trim()}`);
	} else {
		return;
	}
}

// export function h2(value) {
// 	if (value) {
// 		let range = this.quill.getSelection();
// 		if (range === null || range.length === 0) return;
// 		let text = this.quill.getText(range.index, range.length);
// 		window.open(`https://www.etymonline.com/search?q=${text.trim()}`);
// 	} else {
// 		return;
// 	}
// }

// export function h3(value) {
// 	if (value) {
// 		let range = this.quill.getSelection();
// 		if (range === null || range.length === 0) return;
// 		let text = this.quill.getText(range.index, range.length);
// 		window.open(`https://www.thesaurus.com/browse/${text.trim()}`);
// 	} else {
// 		return;
// 	}
// }

// export function h4(value) {
// 	if (value) {
// 		let range = this.quill.getSelection();
// 		if (range === null || range.length === 0) return;
// 		let text = this.quill.getText(range.index, range.length);
// 		window.open(`https://www.rhymer.com/double-rhymes/${text.trim()}.html`);
// 	} else {
// 		return;
// 	}
// }

Quill.register('modules/footnotes', quill => {
	let container = quill.addContainer('ql-footnote');

	quill.container.addEventListener('click', evt => {
		if (evt.target.tagName === 'A') {
			container.innerHTML = evt.target.attributes[0].value;
			if (container.innerHTML === 'ql-close') {
				quill.container.children[3].style.display = 'none';
			} else quill.container.children[3].style.display = 'block';

			let rootBounds = container.getBoundingClientRect();
			let leftOffsetCurrent = evt.target.offsetLeft + (evt.target.offsetWidth / 2 - rootBounds.width / 2);
			let minLeftOffset = 0;
			let maxLeftOffset = quill.root.offsetWidth - rootBounds.width / 2;

			if (leftOffsetCurrent < minLeftOffset) leftOffsetCurrent = minLeftOffset;
			if (leftOffsetCurrent > maxLeftOffset) leftOffsetCurrent = maxLeftOffset;

			quill.container.children[3].style.left = `${leftOffsetCurrent}px`;
			quill.container.children[3].style.top = `${evt.target.offsetTop}px`;
			quill.container.children[3].style.marginTop = '20px';
		} else {
			container.innerHTML = '';
			quill.container.children[3].style.display = 'none';
		}
	});
});

import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavContext } from '../../ContextProviders/NavProvider';

const PremiumAccountBanner = styled.h2`
	background: rgb(255, 246, 151);
	border: 1px solid rgb(255, 162, 162);
	padding: 5px;
	margin-top: 10px;
	cursor: pointer;
`;

const TextWrapper = styled.p`
	font-size: 12px;
	color: #000;
	text-align: center;
	margin: 0;

	&:hover {
		text-decoration: underline;
		color: #000;
	}
`;

const unique = () => {
	return new Date().getTime().toString(36);
};

const FreeBanner = props => {
	const { dispatch } = useContext(NavContext);

	return (
		<PremiumAccountBanner
			onClick={() => {
				if (props.setPanel) {
					props.setPanel('account');
				} else {
					dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `dashboard_${unique()}`, type: 'dashboard', panel: 'account' } });
				}
			}}
		>
			<TextWrapper>Upgrade to Pro for Premium Features.</TextWrapper>
		</PremiumAccountBanner>
	);
};

export default FreeBanner;

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faPlayCircle, faTrash, faClock } from '@fortawesome/fontawesome-free-solid/';
import { NavContext } from '../../ContextProviders/NavProvider';
import Tooltip from 'react-tooltip-lite';
import { PortalWithState } from 'react-portal';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, ClosePopUpBtn, SaveBtn } from '../Shared/SharedStyles';

const LinkSectionContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const LinkSection = styled.p`
	margin-right: 5px;
	width: 100px;
	font-size: 10px;
	overflow: hidden;
	wrap: nowrap;
	&:hover {
		color: #1a56eb;
	}
	// border: 1px solid blue;
`;

const PlayIcon = styled.div`
	// border: 1px solid red;
	width: 15px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -4px;
	height: 22px;
`;
const DeleteIconWrapper = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const TimeStampIcon = styled.button`
	width: 100px;
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	border: none;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const DeletePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 20%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	display: flex;
	flex-direction: column;
`;

const TimeStampArea = styled.textarea`
	height: 300px;
	margin-bottom: 10px;
`;
const AddLinksWrapper = styled.div`
	&:hover {
		cursor: pointer;
	}
`;

const PodcastLinks = props => {
	const { links, handleDeleteLinks } = props;
	const [open, setOpen] = useState(false);
	// const [podcastLinks, setPodcastLinks] = useState([])
	const [showTitle, setShowTitle] = useState(links.show_title);
	const [audioLink, setAudioLink] = useState(links.audio_link);
	const [videoLink, setVideoLink] = useState(links.video_link);
	const [showNotesLink, setShowNotesLink] = useState(links.show_notes_link);
	const [otherLink, setOtherLink] = useState(links.other_link);
	const [timeStamps, setTimeStamps] = useState(links.time_stamps);

	const { dispatch } = useContext(NavContext);
	const { chapter, handleSelectChapter, selectedChapters, podcastId, setPodcastLinks } = props;

	const unique = () => {
		return new Date().getTime().toString(36);
	};

	const navScroll = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		panelsBlock.scroll({
			left: panelsBlock.scrollLeft + 500,
			behavior: 'smooth',
		});
	};

	const handleUpdateLinks = async input => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/update${input}/${podcastId}/${links.chapter_id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ showTitle, audioLink, videoLink, showNotesLink, otherLink, timeStamps, oldShowTitle: props.links.show_title }),
		})
			.then(res => res.json())
			.then(podcasts => {
				setPodcastLinks(podcasts);
			});
	};

	return (
		<LinkSectionContainer>
			<PortalWithState closeOnOutsideClick closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => (
					<>
						<LinkSection onClick={openPortal}>{showTitle}</LinkSection>

						{portal(
							<div>
								<div
									style={{
										top: 0,
										zIndex: 9999999,
										background: '#ddd',
										width: '100%',
										height: '100%',
										minHeight: '392px',
										opacity: '0.6',
										position: 'absolute',
									}}
								/>

								<DeletePopUpWrapper>
									{/* <textarea>{e.time_stamps}</textarea> */}
									<TimeStampArea onChange={e => setShowTitle(e.target.value)} value={showTitle}></TimeStampArea>
									<DeletePopUpBtnWrapper>
										<SaveBtn
											onClick={closePortal}
											onClickCapture={() => {
												handleUpdateLinks('ShowTitle');
											}}
										>
											Save
										</SaveBtn>
										<DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn>
									</DeletePopUpBtnWrapper>
								</DeletePopUpWrapper>
							</div>
						)}
					</>
				)}
			</PortalWithState>

			<PortalWithState closeOnOutsideClick closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => (
					<>
						<LinkSection onClick={openPortal}>{audioLink}</LinkSection>

						{portal(
							<div>
								<div
									style={{
										top: 0,
										zIndex: 9999999,
										background: '#ddd',
										width: '100%',
										height: '100%',
										minHeight: '392px',
										opacity: '0.6',
										position: 'absolute',
									}}
								/>

								<DeletePopUpWrapper>
									{/* <textarea>{e.time_stamps}</textarea> */}
									<TimeStampArea onChange={e => setAudioLink(e.target.value)} value={audioLink}></TimeStampArea>
									<DeletePopUpBtnWrapper>
										<SaveBtn
											onClick={closePortal}
											onClickCapture={() => {
												handleUpdateLinks('AudioLink');
											}}
										>
											Save
										</SaveBtn>
										<DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn>
									</DeletePopUpBtnWrapper>
								</DeletePopUpWrapper>
							</div>
						)}
					</>
				)}
			</PortalWithState>
			<PlayIcon>
				<FontAwesomeIcon
					icon={faPlayCircle}
					onClick={e => {
						if (audioLink.length > 0) {
							e.stopPropagation();
							e.persist();
							if (e.ctrlKey || e.metaKey) {
								return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
							} else {
								dispatch({
									type: 'HANDLE_ADD_PANE',
									originPaneKey: props.uniqueKey,
									payload: {
										key: `podcastPreview_${unique()}`,
										type: 'podcastPreview',
										link: audioLink,
										podcast: '',
										episode: {},
										preview: true,
										linkType: 'audio',
									},
								});
								navScroll();
							}
						}
					}}
				/>
			</PlayIcon>

			<PortalWithState closeOnOutsideClick closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => (
					<>
						<LinkSection onClick={openPortal}>{videoLink}</LinkSection>

						{portal(
							<div>
								<div
									style={{
										top: 0,
										zIndex: 9999999,
										background: '#ddd',
										width: '100%',
										height: '100%',
										minHeight: '392px',
										opacity: '0.6',
										position: 'absolute',
									}}
								/>

								<DeletePopUpWrapper>
									{/* <textarea>{e.time_stamps}</textarea> */}
									<TimeStampArea onChange={e => setVideoLink(e.target.value)} value={videoLink}></TimeStampArea>
									<DeletePopUpBtnWrapper>
										<SaveBtn
											onClick={closePortal}
											onClickCapture={() => {
												handleUpdateLinks('VideoLink');
											}}
										>
											Save
										</SaveBtn>
										<DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn>
									</DeletePopUpBtnWrapper>
								</DeletePopUpWrapper>
							</div>
						)}
					</>
				)}
			</PortalWithState>
			<PlayIcon>
				<FontAwesomeIcon
					icon={faPlayCircle}
					onClick={e => {
						if (videoLink.length > 0) {
							e.stopPropagation();
							e.persist();
							if (e.ctrlKey || e.metaKey) {
								return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
							} else {
								dispatch({
									type: 'HANDLE_ADD_PANE',
									originPaneKey: props.uniqueKey,
									payload: {
										key: `podcastPreview_${unique()}`,
										type: 'podcastPreview',
										link: videoLink,
										podcast: '',
										episode: {},
										preview: true,
										linkType: 'video',
									},
								});
								navScroll();
							}
						}
					}}
				/>
			</PlayIcon>

			<PortalWithState closeOnOutsideClick closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => (
					<>
						<LinkSection onClick={openPortal}>{showNotesLink}</LinkSection>

						{portal(
							<div>
								<div
									style={{
										top: 0,
										zIndex: 9999999,
										background: '#ddd',
										width: '100%',
										height: '100%',
										minHeight: '392px',
										opacity: '0.6',
										position: 'absolute',
									}}
								/>

								<DeletePopUpWrapper>
									{/* <textarea>{e.time_stamps}</textarea> */}
									<TimeStampArea onChange={e => setShowNotesLink(e.target.value)} value={showNotesLink}></TimeStampArea>
									<DeletePopUpBtnWrapper>
										<SaveBtn
											onClick={closePortal}
											onClickCapture={() => {
												handleUpdateLinks('ShowNotesLink');
											}}
										>
											Save
										</SaveBtn>
										<DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn>
									</DeletePopUpBtnWrapper>
								</DeletePopUpWrapper>
							</div>
						)}
					</>
				)}
			</PortalWithState>

			<PortalWithState closeOnOutsideClick closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => (
					<>
						<LinkSection onClick={openPortal}>{otherLink}</LinkSection>

						{portal(
							<div>
								<div
									style={{
										top: 0,
										zIndex: 9999999,
										background: '#ddd',
										width: '100%',
										height: '100%',
										minHeight: '392px',
										opacity: '0.6',
										position: 'absolute',
									}}
								/>

								<DeletePopUpWrapper>
									{/* <textarea>{e.time_stamps}</textarea> */}
									<TimeStampArea onChange={e => setOtherLink(e.target.value)} value={otherLink}></TimeStampArea>
									<DeletePopUpBtnWrapper>
										<SaveBtn
											onClick={closePortal}
											onClickCapture={() => {
												handleUpdateLinks('OtherLink');
											}}
										>
											Save
										</SaveBtn>
										<DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn>
									</DeletePopUpBtnWrapper>
								</DeletePopUpWrapper>
							</div>
						)}
					</>
				)}
			</PortalWithState>
			<PortalWithState closeOnOutsideClick closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => (
					<>
						<TimeStampIcon>
							<Tooltip content='Time Stamps'>
								<FontAwesomeIcon key='ProjectPortal' icon={faClock} onClick={openPortal} />
							</Tooltip>
						</TimeStampIcon>
						{portal(
							<div>
								<div
									style={{
										top: 0,
										zIndex: 9999999,
										background: '#ddd',
										width: '100%',
										height: '100%',
										minHeight: '392px',
										opacity: '0.6',
										position: 'absolute',
									}}
								/>

								<DeletePopUpWrapper>
									{/* <textarea>{e.time_stamps}</textarea> */}
									<TimeStampArea onChange={e => setTimeStamps(e.target.value)} value={timeStamps}></TimeStampArea>
									<DeletePopUpBtnWrapper>
										<SaveBtn
											onClick={closePortal}
											onClickCapture={() => {
												handleUpdateLinks('TimeStamps');
											}}
										>
											Save
										</SaveBtn>
										<DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn>
									</DeletePopUpBtnWrapper>
								</DeletePopUpWrapper>
							</div>
						)}
					</>
				)}
			</PortalWithState>

			{/* <TimeStampIcon>
                    <FontAwesomeIcon icon={faClock} />
                </TimeStampIcon> */}

			<DeleteIconWrapper onClick={() => handleDeleteLinks(links)}>
				<FontAwesomeIcon icon={faTrash} />
			</DeleteIconWrapper>
		</LinkSectionContainer>
	);
};
export default PodcastLinks;

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faEdit, faTrash } from '@fortawesome/fontawesome-free-solid/';
import Loader from 'react-loader-spinner';
/* Popup style */
const PopupWrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 9999;
`;

const Box = styled.div`
	position: relative;
	width: 85%;
	max-width: 450px;
	margin: 0 auto;
	height: auto;
	max-height: 70vh;
	margin-top: calc(100vh - 85vh - 20px);
	background: #fff;
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #999;
	overflow: auto;
`;

const CloseIcon = styled.span`
	content: 'x';
	cursor: pointer;
	position: fixed;
	right: calc(15% - 30px);
	top: calc(100vh - 85vh - 33px);
	background: #ededed;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	border: 1px solid #999;
	font-size: 20px;
`;
const CCNumberInput = styled.input`
	width: 100%;
	height: 45px;
	background-color: #f1f1f1;
	border: #979797 solid 1px;
	padding: 0px 10px;
`;
const UpdateButton = styled.button`
	width: 100%;
	margin-top: 14px;
	border: none;
	padding: 10px 0px;
	font-size: 17px;
	background-color: #282c37;
	color: #fff;
	border-radius: 3px;
`;
const SmallHeading = styled.label`
	margin-top: 15px;
	margin-bottom: 10px;
	display: block;
	font-size: 16px;
	font-weight: bold;
`;
const CardListTable = styled.table`
	width: 100%;
`;

const SentMessageWrapper = styled.span``;

const SetDefault = styled.span`
	cursor: pointer;
	color: white;
	background-color: #2e83ba;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 3px;
`;

const PopupCC = props => {
	const [userId, setUserId] = useState('');
	const [stripeId, setStripeId] = useState('');
	const { authState, isProUser, isTrialUser, hasProAccount } = useContext(AuthContext);
	const [oldEmail, setOldEmail] = useState(authState.user.email);
	const [sentMessage, setSentMessage] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const [currentCards, setCurrentCards] = useState([]);
	const [ccNumber, setccNumber] = useState('');
	const [expMonth, setexpMonth] = useState('');
	const [expYear, setexpyear] = useState('');
	const [cvc, setcvc] = useState('');
	const [defaultPayment, setDefaultPayment] = useState('');

	const [newEmail, setNewEmail] = useState('');

	const logout = () => {
		localStorage.clear();
		localStorage.setItem('prev-email', authState.user.email);
		props.history.push('/login');
	};

	useEffect(() => {
		const getCards = async () => {
			try {
				let user = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${authState.user.email}`)
					.then(res => res.json())
					.then(user => {
						setStripeId(user.stripe_id);
						
						try {
							let cards = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getCardList/${user.stripe_id}`)
								.then(response => response.json())
								.then(cards => {
									
									if (cards.data) {
										setCurrentCards(cards.data);
									}
									return cards;
								});

							let defaultPay = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getDefaultPayment/${user.stripe_id}`)
								.then(response => response.json())
								.then(defaultPay => {
									
									setDefaultPayment(defaultPay);
									return defaultPay;
								});
						} catch (err) {
							
						}
					});
			} catch (err) {
				
			}
		};
		getCards();
	}, [defaultPayment]);

	const maxLengthCheck = e => {
		
		if (e.value.length > e.maxLength) {
			e.value = e.value.slice(0, e.maxLength);
		}
	};
	const ShowCards = props => {
		
		const list = currentCards.map((cardData, index) => {
			

			return (
				<tr>
					<td>
						{cardData.card.brand} **{cardData.card.last4}
					</td>
					<td>
						{cardData.card.exp_month}/{cardData.card.exp_year}
					</td>
					<td>{defaultPayment == cardData.id ? 'Default' : <SetDefault onClick={() => setItDefault(cardData.id)}>Set default</SetDefault>}</td>

					<td>
						{defaultPayment != cardData.id && (
							<FontAwesomeIcon
								icon={faTrash}
								color='red'
								style={{ cursor: 'pointer' }}
								onClick={() => {
									if (window.confirm('Delete the Card?')) {
										deleteCC(cardData.id);
									}
								}}
							/>
						)}
					</td>
				</tr>
			);
		});
		return <CardListTable>{list}</CardListTable>;
	};

	const deleteCC = async paymentMethodId => {
		try {
			let deleteCCRes = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/deleteCC`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ paymentMethodId: paymentMethodId }),
			})
				.then(res => res.json())
				.then(defaultccRes => {
					setDefaultPayment(deleteCCRes);
				});
		} catch (err) {
			
		}
	};

	const setItDefault = async paymentMethodId => {
		
		try {
			let defaultccRes = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/setDefaultCC`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ customer: `${stripeId}`, paymentMethodId: paymentMethodId }),
			})
				.then(res => res.json())
				.then(defaultccRes => {
					setDefaultPayment(defaultccRes);
				});
		} catch (err) {
			
		}
	};
	const updateCard = async () => {
		setShowLoader(true);
		
		try {
			let updateccRes = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/addPaymentMethod`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ customer: `${stripeId}`, ccNumber: ccNumber, expMonth: expMonth, expYear: expYear, cvc: cvc }),
			}).then(res => res.json());
			if (ccNumber == '') {
				setShowLoader(false);
				setSentMessage('Please enter Number.');
			} else if (updateccRes.id) {
				setShowLoader(false);
				setDefaultPayment(updateccRes.id);
				setSentMessage('This card has been set as default.');
			} else if (updateccRes.message) {
				setSentMessage(updateccRes.message);
				setShowLoader(false);
			}
			
		} catch (err) {
			
		}
	};
	return (
		<>
			<PopupWrapper>
				<Box>
					{showLoader && (
						<Loader
							type='TailSpin'
							color='#fff'
							height={25}
							width={25}
							style={{
								position: 'absolute',
								right: '34px',
								bottom: '31px',
							}}
						/>
					)}
					<FontAwesomeIcon
						icon={faTimes}
						style={{
							float: 'right',
							cursor: 'pointer',
							margin: '0px 0px 15px 0px',
						}}
						onClick={props.handleClose}
					/>
					<SentMessageWrapper>{sentMessage}</SentMessageWrapper>

					<SmallHeading>Add new card to pay</SmallHeading>
					<CCNumberInput
						type='number'
						maxLength='18'
						name='newCCNumber'
						placeholder='Card Number'
						value={ccNumber}
						onChange={e => setccNumber(e.target.value)}
						onInput={e => maxLengthCheck(e.target)}
					/>
					<CCNumberInput
						type='number'
						maxLength='2'
						name='expMonth'
						placeholder='MM'
						value={expMonth}
						onChange={e => setexpMonth(e.target.value)}
						onInput={e => maxLengthCheck(e.target)}
					/>
					<CCNumberInput
						type='number'
						maxLength='2'
						name='expyear'
						placeholder='YY'
						value={expYear}
						onChange={e => setexpyear(e.target.value)}
						onInput={e => maxLengthCheck(e.target)}
					/>
					<CCNumberInput
						type='number'
						maxLength='4'
						name='cvc'
						placeholder='CVC'
						value={cvc}
						onChange={e => setcvc(e.target.value)}
						onInput={e => maxLengthCheck(e.target)}
					/>
					<UpdateButton type='button' onClick={() => updateCard()}>
						Update
					</UpdateButton>
					<SmallHeading>Card List</SmallHeading>

					<ShowCards />
				</Box>
			</PopupWrapper>
		</>
	);
};

export default PopupCC;

import React, { useReducer, createContext } from 'react';
import _ from 'lodash';

export const SearchContext = createContext();

const initialState = {};

const sortVerses = (verses = []) => {
	if (verses && verses.message) return [];
	if (verses.length && verses[0].verses) return verses;

	verses = _.sortBy(
		verses,
		[verse => verse.chapter.book.volume.order, verse => verse.chapter.book.order, verse => verse.chapter.order, verse => verse.number],
		['asc', 'asc', 'asc', 'asc']
	);

	return verses;
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_SEARCH_RESULT_LIST': {
			let sortedVerses = action.payload.verseSearch ? sortVerses(action.payload.verses) : action.payload.verses;
			return {
				...state,
				[action.payload.uniqueKey]: sortedVerses,
			};
		}
		case 'ADD_TO_SEARCH_RESULT_LIST': {
			let stateCopy = { ...state };
			let paneVerses = stateCopy[action.payload.uniqueKey];

			if (paneVerses.find(v => v.id === action.payload.newVerse.id)) return stateCopy;
			paneVerses.push(action.payload.newVerse);

			let sortedVerses = sortVerses(paneVerses);

			paneVerses = sortedVerses;
			stateCopy[action.payload.uniqueKey] = sortedVerses;

			return stateCopy;
		}
		case 'REMOVE_FROM_SEARCH_RESULT_LIST': {
			let stateCopy = { ...state };
			let paneVerses = stateCopy[action.payload.uniqueKey];
			if (paneVerses.find(v => v.id === action.payload.verseId)) {
				stateCopy[action.payload.uniqueKey] = paneVerses.filter(v => v.id !== action.payload.verseId);
			}
			return stateCopy;
		}
		case 'SET_SEARCH': {
			return {
				...state,
				[action.payload.uniqueKey]: {
					library: action.payload.library,
					categories: action.payload.allCategories,
					books: action.payload.books,
					searchHistory: action.payload.searchHistory,
					tagText: '',
					userTags: [],
				},
			};
		}
		case 'SET_SEARCH_USER_TAGS': {
			return {
				...state,
				[action.payload.uniqueKey]: {
					library: state[action.payload.uniqueKey].library,
					categories: state[action.payload.uniqueKey].allCategories,
					books: state[action.payload.uniqueKey].books,
					tagText: '',
					userTags: action.payload.tags,
				},
			};
		}
		case 'GET_ALL_FOOTNOTES':
			let versesCopy = [...state[action.payload.uniqueKey]];
			let verseIndex = versesCopy.findIndex(verse => action.payload.verseId === verse.id);
			versesCopy[verseIndex].allFootnotes = action.payload.allFootnotes;
			return {
				...state,
				[action.payload.uniqueKey]: versesCopy,
			};
		case 'UPDATE_SEARCH_VERSE_NOTE': {
			const { uniqueKey, index, noteText } = action.payload;
			let stateCopy = { ...state };
			if (stateCopy[uniqueKey][index].verseNote) {
				stateCopy[uniqueKey][index].verseNote.verse_note = noteText;
			} else stateCopy[uniqueKey][index].verseNote = { verse_note: noteText };
			return stateCopy;
		}
		case 'UPDATE_SEARCH_VERSE_BOOKMARK': {
			const { uniqueKey, index, bookmarkId } = action.payload;
			let stateCopy = { ...state };
			stateCopy[uniqueKey][index].bookmarkId = bookmarkId;
			return stateCopy;
		}
		case 'UPDATE_SEARCH_VERSE_MARKUP': {
			const { uniqueKey, verseId, text } = action.payload;
			let stateCopy = { ...state };
			const index = stateCopy[uniqueKey].findIndex(({ id }) => id === parseInt(verseId));
			if (stateCopy[uniqueKey][index].userMarkup) {
				stateCopy[uniqueKey][index].userMarkup.verse_text_with_markup = text;
			} else stateCopy[uniqueKey][index].userMarkup = { verse_text_with_markup: text };
			return stateCopy;
		}
		case 'DELETE_SEARCH_PANE': {
			const { uniqueKey } = action.payload;
			let stateCopy = { ...state };
			delete stateCopy[uniqueKey];
			return stateCopy;
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

export const SearchProvider = ({ children }) => {
	const [searchState, searchDispatch] = useReducer(reducer, initialState);

	const getSearchResults = obj => {
		const sendTime = new Date().getTime();
		let { searchSection } = obj;
		let apiRoute;
		if (searchSection === 'scripturesOnly') {
			apiRoute = `${process.env.REACT_APP_BASE_URL}/verses/search/${obj.searchText}/${obj.userId}/${obj.books || 'none'}/none/none`;
		} else if (searchSection === 'collectionNotesOnly') {
			apiRoute = `${process.env.REACT_APP_BASE_URL}/collections/getCollectionNoteByTitle/${obj.searchText}/${obj.userId}/${obj.categories}/${obj.tags}`;
		} else if (searchSection === 'verseNotesOnly') {
			apiRoute = `${process.env.REACT_APP_BASE_URL}/verses/search/${obj.searchText}/${obj.userId}/${obj.books || 'none'}/true/none`;
		} else if (searchSection === 'footnote') {
			apiRoute = `${process.env.REACT_APP_BASE_URL}/verses/search/${obj.searchText}/${obj.userId}/${obj.books || 'none'}/none/true`;
		}

		fetch(apiRoute)
			.then(response => response.json())
			.then(verses => {
				if (!obj.isProUser) {
					verses = verses.filter(e => e.chapter.book.volume.id <= 5);
				}
				searchDispatch({
					type: 'SET_SEARCH_RESULT_LIST',
					payload: { verses, uniqueKey: obj.uniqueKey, verseSearch: searchSection !== 'collectionNotesOnly' },
				});
			})
			.catch(err => console.log('Error getting search results:', err));
	};
	const getSearchResultsNonUser = obj => {
		const sendTime = new Date().getTime();
		let { searchSection } = obj;
		let apiRoute;
		if (searchSection === 'scripturesOnly') {
			apiRoute = `${process.env.REACT_APP_BASE_URL}/verses/searchNonUser/${obj.searchText}`;
		}

		fetch(apiRoute)
			.then(response => response.json())
			.then(verses => {
				if (!obj.isProUser) {
					verses = verses.filter(e => e.chapter.book.volume.id <= 5);
				}
				searchDispatch({
					type: 'SET_SEARCH_RESULT_LIST',
					payload: { verses, uniqueKey: obj.uniqueKey, verseSearch: searchSection !== 'collectionNotesOnly' },
				});
			})
			.catch(err => console.log('Error getting search results:', err));
	};

	const addVerseToResults = async ({ userId, verseId, chapterId, bookId, uniqueKey }) => {
		try {
			// this just searches the verse that is passed in and sees if the verse is real and its not already there
			let newVerse = await fetch(`${process.env.REACT_APP_BASE_URL}/verses/single/${userId}/${bookId}/${chapterId}/${verseId}`).then(res =>
				res.json()
			);

			if (newVerse && newVerse.chapter.id === chapterId) {
				searchDispatch({ type: 'ADD_TO_SEARCH_RESULT_LIST', payload: { uniqueKey, newVerse } });
			}
		} catch (err) {
			console.log('Error adding verse to search results:', err);
		}
	};

	const removeVerseFromResults = async ({ verseId, uniqueKey }) => {
		searchDispatch({ type: 'REMOVE_FROM_SEARCH_RESULT_LIST', payload: { uniqueKey, verseId } });
	};

	const getAllFootnotesSearchResults = async obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getAllFootnotes/${obj.userId}/${obj.verseId}`)
			.then(res => res.json())
			.then(allFootnotes => {
				searchDispatch({
					type: 'GET_ALL_FOOTNOTES',
					payload: {
						uniqueKey: obj.uniqueKey,
						allFootnotes: allFootnotes,
						verseId: obj.verseId,
					},
				});
			});
	};

	const getLibraryNCategories = async obj => {
		// const library = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getVolumesWithBooksAndChapters/`)
		// 	.then(response => response.json())
		// 	.then(libraryData => libraryData);
		const library = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getUserVolumes/${obj.userId}`)
			.then(res => res.json())
			.then(userVolumes => userVolumes);

		const allCategories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
			.then(res => res.json())
			.then(categories => categories);
		const searchHistory = await fetch(`${process.env.REACT_APP_BASE_URL}/searchHistory/getSearchHistory/${obj.userId}`)
			.then(res => res.json())
			.then(searchHistory => searchHistory);

		library.volumes.forEach(volume => {
			volume.books = [];
			library.books.forEach(book => {
				book.chapters = [];
				library.chapters.forEach(chapter => {
					if (chapter.book_id === book.id) {
						book.chapters.push(chapter);
					}
				});
				if (volume.id === book.volume_id) {
					volume.books.push(book);
				}
			});
		});
		searchDispatch({ type: 'SET_SEARCH', payload: { uniqueKey: obj.uniqueKey, allCategories, library: library.volumes, searchHistory } });
	};

	const handleGetTags = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/tags/searchTagList/${obj.userId}/${obj.text}/`)
			.then(response => response.json())
			.then(tags => {
				return searchDispatch({ type: 'SET_SEARCH_USER_TAGS', payload: { uniqueKey: obj.uniqueKey, tags } });
			});
	};

	return (
		<SearchContext.Provider
			value={{
				searchState,
				searchDispatch,
				handleGetTags,
				getSearchResults,
				getSearchResultsNonUser,
				getLibraryNCategories,
				addVerseToResults,
				removeVerseFromResults,
				getAllFootnotesSearchResults,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
};

import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
// import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PortalWithState } from 'react-portal';
import {
	faLink,
	faTimes,
	faArrowAltCircleUp,
	faArrowAltCircleDown,
	faMinusSquare,
	faEdit,
	faEye,
	faArrowsAlt,
	faStar,
	faObjectUngroup,
	faSearch,
	faArrowUp,
	faArrowDown,
	faBookmark,
} from '@fortawesome/fontawesome-free-solid/';
import * as quill from '../Helpers/quill';
import '../../assets/css/collectionNoteVerse.css';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import _ from 'lodash';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { SearchContext } from '../../ContextProviders/SearchProvider';
import { StudyHelpsContext } from '../../ContextProviders/StudyHelpsProvider';
import { CollectionsPopUpWrapper } from '../Shared/SharedStyles';
import {
	removeMarkTag,
	bbwBold,
	bbwUnderline,
	bbwItalic,
	bbwStrike,
	bbwProcessToggle,
	goodToProceedToggle,
	bbwClear,
	replaceAllMarks,
	bbwIsSelectionValid,
} from '../BBWFormatter/BBWFormatter';
import Tooltip from 'react-tooltip-lite';
// import Editor from './QuillEditor';

import { AuthContext } from '../../ContextProviders/AuthProvider';

let converter = require('hex2dec');

const bookAbbreviations = {
	Judges: 'jdg',
	Ruth: 'rth',
	'Song of Solomon': 'sng',
	John: 'jhn',
	Philippians: 'phl',
	Philemon: 'phm',
	James: 'jas',
	Jude: 'jde',
};

const DraggableWrapper = styled.div`
	width: 100%;
	background: #fafafa;
	margin-bottom: 5px;
	padding: 5px;
	display: flex;
	//background-clip: content-box;
	//box-shadow: inset 5px 0 0 yellow;
`;

const FullTextWrapper = styled.div`
	position: relative;
	width: 100%;
	// min-height: 1px;
	line-height: 20px;
	padding-right: 15px;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	display: inline-flex;
	padding-left: 0px;
`;

const BookChapterVerseHeading = styled.div`
	display: flex;
	justify-content: space-between;
	color: #324599;
	text-decoration: underline;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 700;
	// font-size: 19px;
	line-height: 21px;
	text-decoration-color: rgba(50, 69, 153, 0.3);
	&:hover {
		text-decoration: none;
	}
`;

const HoverEditClickWrapper = styled.div`
	margin-bottom: 0;
	color: #393f47;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	// line-height: 20px;
	white-space: pre-wrap;
	// &:hover {
	// 	background-color: #e1f0fd;
	// }
`;

const VerseNoteTextboxWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
`;

const ReadActionWrapper = styled.div`
	display: flex;
	border: 1, solid, red;
`;

const ReadActionGroup = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	margin-right: 12px;
`;
const ReadActionItemInput = styled.input`
	cursor: pointer;
`;

//color: #FFF700;
//background-color: #FFF700;

const btnColor = '#b2b7be';
const hoverColor = '#1a56eb';
const greenEyeColor = '#0ead3a';

const ReadActionItem = styled.div`
	color: ${btnColor};
	font-size: 16px;
	border: none;
	position: relative;
	margin-bottom: 5px;
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	&:hover {
		color: ${hoverColor};
	}
`;

const GreenEyeItem = styled.div`
	&:hover {
		color: ${hoverColor};
	}
`;

const ReadActionsDropdownWrapper = styled.ul`
	position: absolute;
	right: 0;
	top: calc(100% - 0px);
	z-index: 100000;
	width: 335px;
	padding: 10px;
	border: 1px solid #ebebeb;
	background-color: #fff;
	max-height: 200px;
	overflow: scroll;
	min-height: 55px;
`;

const CloseNotesModalWrapper = styled.div`
	color: #b2b7be;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	&:hover {
		color: #47494c;
	}
`;

const ReadActionDropdownItem = styled.li`
	margin-bottom: 5px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 14px;
	line-height: 18px;
	list-style: none;
`;

const ReadActionLink = styled.a`
	color: #000 !important;
`;

const ReadActionDropdownList = styled.ul`
	list-style: none;
	padding-left: 5px;
	margin-bottom: 0;
`;

const ReadActionListItem = styled.li`
	font-size: 12px;
	color: #6a7583;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
`;
const StudyHelpEntry = styled.span`
	font-size: 12px;
	color: #6a7583;
	cursor: pointer;
	&:hover {
		color: blue;
	}
`;
const ReadActionCnItem = styled.li`
	font-size: 12px;
	color: #6a7583;
	&:hover {
		text-decoration: underline;
	}
`;

const HyperlinkItem = styled.div`
	color: #000 !important;
	display: flex;
	align-items: center;
	margin: 4px 0 4px 8px;
	&:hover {
		text-decoration: underline;
	}
`;

const HyperlinkName = styled.a`
	color: #000 !important;
`;

const HyperlinkInfo = styled.a`
	color: blue !important;
	margin-right: 12px;
	font-weight: 700;
`;

const SimpleVerseWrapper = styled.div`
	margin: 24px 5px;
`;

const SimpleVerseHeader = styled.div`
	// font-size: 20px;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const SimpleVerseText = styled.div`
	font-size: 15px;
`;

const ToggleSimpleVerse = styled.div`
	border: 1px solid black;
	border-radius: 16px;
	padding: 4px 8px;
	box-sizing: border-box;
	margin: 8px;
	font-size: 10px;
	font-weight: 500;
	cursor: pointer;
`;
const SelectionCount = styled.div`
	position: absolute;
	top: -15px;
	right: -15px;
	text-align: center;
	font-size: 0.8rem;
	height: 25px;
	width: 25px;
	border-radius: 50%;
	background-color: blue;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ICIV = styled.div`
	margin-left: 0px;
`;
const MappedCollectionsContainer = styled.div``;
const ModalTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;
const SearchCNContainer = styled.div``;
const SearchCNInput = styled.input``;
const SearchInput = styled.input`
	padding-right: 40px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
`;
const SearchIconWrapper = styled.div`
	color: #7d8085;
	position: absolute;
	right: 20px;
	top: 53px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const CollectionTitle = styled.div`
	color: #7d8085;
	margin-bottom: 10px;
	&:hover {
		cursor: pointer;
	}
`;
const PublicCollectionBoxesContainer = styled.div`
	// width: 100%;
	display: flex;
	// align-items: center;
	// justify-content: flex-end;
`;
const CheckboxLabel = styled.label`
	font-size: 12px;
	color: #6a7583;
	cursor: pointer;
`;
const VerseReferenceTitle = styled.p`
	display: flex;
	justify-content: space-between;
	color: #324599;
	text-decoration: underline;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 700;
	// font-size: 19px;
	line-height: 21px;
	text-decoration-color: rgba(50, 69, 153, 0.3);
	&:hover {
		text-decoration: none;
	}
`;
const LdsReferenceTitle = styled.p`
	color: black;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 700;
`;
const BookmarkContainer = styled.div`
	color: #b2b7be;
	position: absolute;
	top: 10px;
	right: 35px;
	cursor: pointer;
	font-size: 14px;
	&:hover {
		color: #47494c;
	}
`;
const getItemStyle = (isDragging, draggableStyle) => ({
	background: isDragging && '#d3fad0',
	display: 'flex',
	...draggableStyle,
});

const CollectionNoteVerse = props => {
	const [showFootnotes, setShowFootnotes] = useState(false);
	const [noteText, setNoteText] = useState('');
	const [lastNoteText, setLastNoteText] = useState('');
	const [userMarkUp, setUserMarkUp] = useState('');
	const [userFootnotes, setUserFootnotes] = useState([]);
	const [showHyperlinks, setShowHyperlinks] = useState(false);
	const [verse, setVerse] = useState(props.verse);
	const [readOnly, setReadOnly] = useState(false);
	const [greenEye, setGreenEye] = useState(btnColor);
	const [ldsFootnotes, setLdsFootnotes] = useState([]);
	const [tgEntries, setTGEntries] = useState([]);
	const [bdEntries, setBDEntries] = useState([]);
	const [tcEntries, setTCEntries] = useState([]);
	const [verseEdit, setVerseEdit] = useState(true);
	const [isVerseSimple, setIsVerseSimple] = useState(props.isSimple || false);
	const [verseNoteUpdating, setVerseNoteUpdating] = useState(false);
	const [selected, setSelected] = useState(false);
	const [jst, setJst] = useState({});
	const [collections, setCollections] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [verseCollections, setVerseCollections] = useState([]);
	const [hideSeen, setHideSeen] = useState(false);
	const [hideMine, setHideMine] = useState(false);
	const [publicCollections, setPublicCollections] = useState([]);
	const [referenceView, setReferenceView] = useState(false);
	const [verseReferences, setVerseReferences] = useState([]);
	const [selectedReference, setSelectedReference] = useState('');
	// Code to Modify?
	//const [promotedVerses, setPromotedVerses] = useState([]);
	const {
		collectionState,
		deleteVerseFromCn,
		filterVerseFromVerses,
		addNextVerseToCn,
		addVerseToNewCn,
		addPrevVerseToCn,
		updateVerseNote,
		saveUserFootnote,
		getAllFootnotes,
		togglePromotedVerseStatus,
		collectionDispatch,
	} = useContext(CollectionsContext);

	const { handleAddNewCollectionNote, handleOpenVerses, handleAddCollection, removeVerseFromCollectionNote, dispatch, handleOpenStudyTopic } =
		useContext(NavContext);
	const { addVerseToResults, removeVerseFromResults, searchState, getAllFootnotesSearchResults, searchDispatch } = useContext(SearchContext);
	const { studyHelpsDispatch } = useContext(StudyHelpsContext);

	const { isProUser, authState } = useContext(AuthContext);

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}
	const prevGreenEye = usePrevious(greenEye);
	const verseObjectRef = useRef(null);
	const getFormatter = (id, verseArr, ypos) => {
		const selectedText = window.getSelection().toString();
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			props.textFormatter.current.style.display = 'none';
			return;
		}
		console.log(bbwIsSelectionValid());
		try {
			removeMarkTag();
		} catch {}
		if (selectedText != '') {
			// alert(id)
			var textFormatterClasses = document.querySelectorAll('.TextFormatterClass');
			textFormatterClasses.forEach(textFormatterClass => {
				textFormatterClass.style.display = 'none';
			});
			verseObjectRef.current = verseArr;
			const yscroll = props.verseScrollRef.current.getScrollTop();
			props.textFormatter.current.style.display = 'block';
			props.textFormatter.current.setAttribute('data-id', id);
			props.textFormatter.current.setAttribute('data-verseid', verseArr.id);
			props.textFormatter.current.setAttribute('data-verseobject', verseArr);
			props.textFormatter.current.setAttribute('data-userid', authState.user.id);
			props.textFormatter.current.style.top = ypos - 60 + 'px';

			//--------------------REMOVING data-bbw-content ----------------- START
			const bbwContents = document.querySelectorAll('[data-bbw-content]');
			bbwContents.forEach(me => {
				me.removeAttribute('data-bbw-content');
			});
			document.getElementById(id).setAttribute('data-bbw-content', 'true');
			//--------------------REMOVING data-bbw-content ----------------- END
		} else {
			props.textFormatter.current.style.display = 'none';
		}
	};
	useEffect(() => {
		if (collectionState[props.uniqueKey]) {
			let verseCopy = { ...props.verse };
			verseCopy.collection_id = collectionState[props.uniqueKey].collection.id;
			setVerse(verseCopy);
		}
		if (props.verse && props.verse.verseNote && !props.verse.promoted) {
			setNoteText(props.verse.verseNote.verse_note);
		}
		if (props.verse.collectionIds[0] !== null || props.verse.hasUserFootnote) {
			setGreenEye(greenEyeColor);
			// props.socket.emit('update_verse_greeneye', { userId: authState.user.id, verseId: props.verse.id, greenEyeColor: greenEyeColor });
		}
	}, [collectionState[props.uniqueKey]]);

	useEffect(() => {
		if (props.verse && props.verse.userMarkup && !props.toggleLdsFootnotes) {
			setUserMarkUp(props.verse.userMarkup.verse_text_with_markup);
			setVerseEdit(true);
		}
	}, [props.verse.userMarkup]);
	useEffect(() => {
		if (props.customMarkup && props.verse && props.verse.verse_markup) {
			setUserMarkUp(props.verse.verse_markup);
		}
		if (!props.customMarkup && props.verse && props.verse.userMarkup && !props.toggleLdsFootnotes) {
			setUserMarkUp(props.verse.userMarkup.verse_text_with_markup);
			setVerseEdit(true);
		}
		if (!props.customMarkup && props.verse && !props.verse.userMarkup) {
			setUserMarkUp('');
			setVerseEdit(true);
		}
	}, [props.customMarkup]);

	useEffect(() => {
		if (props.isSimple !== isVerseSimple) setIsVerseSimple(props.isSimple);
	}, [props.isSimple]);

	useEffect(() => {
		if (collectionState && collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection.verses) {
			let verseIndex = collectionState[props.uniqueKey].collection.verses.findIndex(verse => props.verse.id === verse.id);

			if (verseIndex !== -1) {
				let allFootnotes = collectionState[props.uniqueKey].collection.verses[verseIndex].allFootnotes;

				if (allFootnotes && allFootnotes.length > 0) {
					setUserFootnotes(collectionState[props.uniqueKey].collection.verses[verseIndex].allFootnotes[0]);
					// setLdsFootnotes(collectionState[props.uniqueKey].collection.verses[verseIndex].allFootnotes[1]);
					setLdsFootnotes(removeDuplicates(collectionState[props.uniqueKey].collection.verses[verseIndex].allFootnotes[1], 'superscript'));
				}
			}
		}
	}, [collectionState]);

	useEffect(() => {
		if (searchState && searchState[props.uniqueKey]) {
			let verseIndex = searchState[props.uniqueKey].findIndex(verse => props.verse.id === verse.id);
			if (verseIndex !== -1) {
				let allFootnotes = searchState[props.uniqueKey][verseIndex].allFootnotes;
				if (allFootnotes && allFootnotes.length > 0) {
					setUserFootnotes(searchState[props.uniqueKey][verseIndex].allFootnotes[0]);
					// setLdsFootnotes(searchState[props.uniqueKey][verseIndex].allFootnotes[1]);
					setLdsFootnotes(removeDuplicates(searchState[props.uniqueKey][verseIndex].allFootnotes[1], 'originVerseId'));
				}
			}
		}
	}, [searchState]);

	useEffect(() => {
		setVerse(props.verse);
		if (verse && verse.verseNote) {
			setNoteText(props.verse.verseNote.verse_note);
		}
		if (verse.collectionNotes.length > 0 || verse.hasUserFootnote) {
			setGreenEye(greenEyeColor);
			props.socket.emit('update_verse_greeneye', {
				userId: authState.user.id,
				verseId: props.verse.id,
				greenEyeColor: greenEyeColor,
				uniqueKey: props.uniqueKey,
			});
		}
	}, []);

	useEffect(() => {
		const { selectAllVerses } = props;
		if (selectAllVerses === true) {
			setSelected(true);
		} else setSelected(false);
	}, [props.selectAllVerses]);

	useEffect(() => {
		handleBulkSelectedVerses();
	}, [selected]);

	useEffect(() => {
		const { bulkMoveState } = props;
		if (!bulkMoveState.some(e => e.draggableId === props.draggableId)) {
			setSelected(false);
		}
	}, [props.bulkMoveState]);

	useEffect(() => {
		if (authState && authState.user && authState.user.id) {
			// socket.on('get_updated_verse_notes_' + authState.user.id, ({ verseId, newNoteText }) => {
			props.socket.on('get_updated_verse_notes_' + authState.user.id, ({ verseId, newNoteText, uniqueKey }) => {
				if (props.uniqueKey !== uniqueKey) {
					if (verseId === props.verse.id && newNoteText !== noteText && newNoteText !== lastNoteText) {
						setNoteText(newNoteText);
					}
				}
			});

			// socket.on('get_updated_verse_markup_' + authState.user.id, ({ verseId, markupText }) => {
			props.socket.on('get_updated_verse_markup_' + authState.user.id, ({ verseId, markupText, paneId }) => {
				// if (props.uniqueKey !== uniqueKey) {
				// console.log('get_updated_verse_markup CN VERSE');
				if (parseInt(verseId) === props.verse.id && props.uniqueKey !== paneId) {
					setVerseEdit(true);
					setUserMarkUp(markupText);
				}
				// }
			});
			props.socket.on(`update_bookmark_list_${authState.user.id}`, ({ verseId, bookmarkId }) => {
				if (props.verse.id === parseInt(verseId)) {
					if (props.uniqueKey.includes('collectionNote')) {
						collectionDispatch({
							type: 'UPDATE_CN_VERSE_BOOKMARK',
							payload: {
								uniqueKey: props.uniqueKey,
								index: props.index,
								bookmarkId: bookmarkId,
							},
						});
					} else if (props.uniqueKey.includes('search_result')) {
						searchDispatch({
							type: 'UPDATE_SEARCH_VERSE_BOOKMARK',
							payload: {
								uniqueKey: props.uniqueKey,
								index: props.index,
								bookmarkId: bookmarkId,
							},
						});
					}
				}
			});
			// props.socket.on(`get_updated_verse_greeneye_${authState.user.id}`, ({ greenEyeColor, verseId, uniqueKey }) => {
			// 	if (props.uniqueKey !== uniqueKey) {
			// 		console.log('greeneye');
			// 		if (verseId === props.verse.id) {
			// 			setGreenEye(greenEyeColor);
			// 		}
			// 	}
			// });
			return () => {
				// socket.off('get_updated_verse_notes_' + authState.user.id);
				// socket.off('get_updated_verse_markup_' + authState.user.id);
				props.socket.off('get_updated_verse_notes_' + authState.user.id);
				props.socket.off('get_updated_verse_markup_' + authState.user.id);
				// props.socket.off('get_updated_verse_greeneye_' + authState.user.id);
			};
		}
	}, [authState]);

	useEffect(() => {
		if ((userMarkUp && userMarkUp.includes('href')) || verse.collectionIds[0] !== null) {
			setGreenEye(greenEyeColor);
			props.socket.emit('update_verse_greeneye', {
				userId: authState.user.id,
				verseId: props.verse.id,
				greenEyeColor: greenEyeColor,
				uniqueKey: props.uniqueKey,
			});
		} else {
			setGreenEye('#B2B7BE');
		}
	}, [userMarkUp, verse]);

	// const AUTOSAVE_INTERVAL = 100;
	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		if (lastNoteText != noteText) {
	// 			updateVerseNote({ verseId: verse.id, userId: props.user.id, noteText: noteText });
	// 			setLastNoteText(noteText);
	// 		}
	// 	}, AUTOSAVE_INTERVAL);

	// 	return () => clearTimeout(timer);
	// }, [noteText]);

	const handleReadStatus = () => {
		if (readOnly) setReadOnly(false);
	};

	const handleChangeFootnote = key => (text, delta) => {
		if (
			(delta.ops.length === 1 &&
				(delta.ops[0].hasOwnProperty('delete') ||
					delta.ops[0].hasOwnProperty('insert') ||
					(delta.ops[0].hasOwnProperty('retain') && delta.ops[0].retain === 1))) ||
			(delta.ops.length >= 2 &&
				(delta.ops[1].hasOwnProperty('delete') ||
					delta.ops[1].hasOwnProperty('insert') ||
					(delta.ops[1].hasOwnProperty('retain') && delta.ops[1].retain === 1)))
		) {
			setReadOnly(true);
		} else {
			setUserMarkUp(text);
			// handleUpdateVerseMarkup(text);
			saveUserFootnote({
				userId: props.user.id,
				delta: delta,
				text: text,
				verseId: verse.id,
			});

			// const socket = io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] });
			const socket = props.socket;
			socket.emit('update_verse_markup', { verseId: props.verse.id, markupText: text, userId: authState.user.id, uniqueKey: props.uniqueKey });
		}
	};
	function removeDuplicates(array, key) {
		let lookup = new Set();
		return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
	}
	const handleTGEntry = async obj => {
		let topicalGuide = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuide/${props.user.id}/${'a'}`)
			.then(res => res.json())
			.catch(err => console.log('Topical err', err));
		const topicalGuideItems = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuideTitleInfo/${props.user.id}/${obj.tg_id}`, {
			title: obj.tg_title,
		})
			.then(res => res.json())
			.catch(err => console.log(props.id, 'TG:', err));

		const index = topicalGuide.findIndex(e => e.title === obj.tg_title);

		topicalGuide[index].references = removeDuplicates(topicalGuideItems, 'verseId');

		studyHelpsDispatch({ type: 'SET_TOPICAL_GUIDE', payload: { topicalGuide } });

		handleOpenStudyTopic({
			originPaneKey: props.uniqueKey,
			open: true,
			selectedOption: 'topicalGuide',
			selectedLetter: obj.tg_title.slice(0, 1).toLowerCase(),
			selectedTopic: { combinedIndex: index, overallIndex: index, title: obj.tg_title, seeAlso: obj.tg_subtitle, index },
		});
		scrollRight();
	};
	const handleBDEntry = async obj => {
		let bibleDictionary = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionary/${props.user.id}/${'a'}`)
			.then(res => res.json())
			.catch(err => console.log('Topical err', err));
		const bibleDictionaryItems = await fetch(
			`${process.env.REACT_APP_BASE_URL}/volumes/getBibleDictionaryTitleInfo/${props.user.id}/${obj.bd_text_id}`,
			{
				title: obj.title,
			}
		)
			.then(res => res.json())
			.catch(err => console.log(props.id, 'TG:', err));
		console.log(bibleDictionary, bibleDictionaryItems);
		const index = bibleDictionary.findIndex(e => e.title === obj.title);

		bibleDictionary[index].references = removeDuplicates(bibleDictionaryItems.references, 'verseId');
		bibleDictionary[index].text = bibleDictionaryItems.text;

		studyHelpsDispatch({ type: 'SET_BIBLE_DICTIONARY', payload: { bibleDictionary } });

		handleOpenStudyTopic({
			originPaneKey: props.uniqueKey,
			open: true,
			selectedOption: 'bibleDictionary',
			selectedLetter: obj.title.slice(0, 1).toLowerCase(),
			selectedTopic: { combinedIndex: index, overallIndex: index, title: obj.title, index, studyHelp: 'BD' },
		});
		scrollRight();
	};
	const handleTCEntry = async obj => {
		let tripleIndex = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndex/${props.user.id}/${'a'}`)
			.then(res => res.json())
			.catch(err => console.log('Triple err', err));
		const tripleIndexItems = await fetch(
			`${process.env.REACT_APP_BASE_URL}/volumes/getTripleIndexTitleInfo/${props.user.id}/${obj.triple_title_id}`,
			{
				title: obj.triple_title,
			}
		)
			.then(res => res.json())
			.catch(err => console.log(props.id, 'TG:', err));
		console.log(tripleIndexItems);
		const index = tripleIndex.findIndex(e => e.title === obj.triple_title);

		tripleIndex[index].references = removeDuplicates(tripleIndexItems.references, 'verseId');
		tripleIndex[index].text = tripleIndexItems.text;

		studyHelpsDispatch({ type: 'SET_TRIPLE_INDEX', payload: { tripleIndex } });

		handleOpenStudyTopic({
			originPaneKey: props.uniqueKey,
			open: true,
			selectedOption: 'tripleIndex',
			selectedLetter: obj.triple_title.slice(0, 1).toLowerCase(),
			selectedTopic: { combinedIndex: index, overallIndex: index, title: obj.triple_title, index, studyHelp: 'TC' },
		});
		scrollRight();
	};
	const scrollRight = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;

		var newwidth = children.offsetWidth;
		var nwidth = newwidth;
		nwidth = oldwidth;
		var toscrollright = nwidth - rightposition;
		setTimeout(() => {
			if (toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 500);
	};
	const handleRemoveVerse = () => {
		// socket.emit('update_verse', { verseId: props.verse.id, userId: authState.user.id });
		// socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id });
		props.socket.emit('update_verse', { verseId: props.verse.id, userId: authState.user.id, uniqueKey: props.uniqueKey });
		props.socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id, uniqueKey: props.uniqueKey });
		if (props.isSearchResult) {
			removeVerseFromResults({ uniqueKey: props.uniqueKey, verseId: verse.id });
		} else {
			if (collectionState[props.uniqueKey].collection.id) {
				deleteVerseFromCn({ userId: props.user.id, collectionId: collectionState[props.uniqueKey].collection.id, verseId: verse.id });
				filterVerseFromVerses({ uniqueKey: props.uniqueKey, verseId: verse.id });
				removeVerseFromCollectionNote(props.uniqueKey, verse.id);
			} else {
				filterVerseFromVerses({ uniqueKey: props.uniqueKey, verseId: verse.id });
				removeVerseFromCollectionNote(props.uniqueKey, verse.id);
			}
		}
	};

	// Code to modify?

	const handleUpdateVerseNote = () => {
		if (props.uniqueKey.includes('collectionNote')) {
			collectionDispatch({
				type: 'UPDATE_CN_VERSE_NOTE',
				payload: {
					uniqueKey: props.uniqueKey,
					index: props.index,
					noteText,
				},
			});
		} else if (props.uniqueKey.includes('search_result')) {
			searchDispatch({
				type: 'UPDATE_SEARCH_VERSE_NOTE',
				payload: {
					uniqueKey: props.uniqueKey,
					index: props.index,
					noteText,
				},
			});
		}
	};

	const handleShowHyperLinks = () => {
		if (showHyperlinks) {
			setShowHyperlinks(false);
		} else {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/verses/getJospehSmithTranslation/${props.verse.chapter.book.title}/${props.verse.chapter.order}/${props.verse.number}`
			)
				.then(res => res.json())
				.then(result => {
					setJst(result);
					setShowHyperlinks(true);
				});
		}
	};
	const handleNextVerse = currentVerseObj => {
		if (props.isSearchResult) {
			props.socket.emit('update_verse', { verseId: verse.id + 1, userId: authState.user.id, uniqueKey: props.uniqueKey });
			// socket.emit('update_verse', { verseId: verse.id + 1, userId: authState.user.id });
			addVerseToResults({
				uniqueKey: props.uniqueKey,
				userId: props.user.id,
				verseId: verse.id + 1,
				bookId: verse.chapter.book.id,
				chapterId: verse.chapter.id,
			});
		} else {
			const verseAlreadyExists = props.allCnVerses.findIndex(verses => verses.id === currentVerseObj.id + 1);
			if (collectionState[props.uniqueKey].collection.id && verseAlreadyExists === -1) {
				// socket.emit('update_verse', { verseId: verse.id + 1, userId: authState.user.id });
				// socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id });
				props.socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id, uniqueKey: props.uniqueKey });
				props.socket.emit('update_verse', { verseId: verse.id + 1, userId: authState.user.id, uniqueKey: props.uniqueKey });

				// The '+1' to represent the NEXT verse is added in the function definition in CollectionsProvider.js (line 314 atm, or search 'addNextVerseToCn')
				addNextVerseToCn({ uniqueKey: props.uniqueKey, verse: currentVerseObj });
				props.verseAdded();
			} else if (verseAlreadyExists === -1) {
				addVerseToNewCn({ uniqueKey: props.uniqueKey, verse: currentVerseObj, userId: props.user.id, offsetAmount: 1 });
				props.verseAdded();
				return null;
			} else {
				props.verseAdded();
				return null;
			}
		}
	};

	const handlePrevVerse = currentVerseObj => {
		if (props.isSearchResult) {
			// socket.emit('update_verse', { verseId: verse.id - 1, userId: authState.user.id });
			props.socket.emit('update_verse', { verseId: verse.id - 1, userId: authState.user.id, uniqueKey: props.uniqueKey });
			addVerseToResults({
				uniqueKey: props.uniqueKey,
				userId: props.user.id,
				verseId: verse.id - 1,
				bookId: verse.chapter.book.id,
				chapterId: verse.chapter.id,
			});
		} else {
			const verseOrderIsZero = currentVerseObj.number - 1 === 0;
			const verseAlreadyExists = props.allCnVerses.findIndex(verses => verses.id === currentVerseObj.id - 1);

			if (collectionState[props.uniqueKey].collection.id && verseAlreadyExists === -1 && !verseOrderIsZero) {
				// socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id });
				// socket.emit('update_verse', { verseId: vverse.id - 1, userId: authState.user.id });
				props.socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id, uniqueKey: props.uniqueKey });
				props.socket.emit('update_verse', { verseId: verse.id - 1, userId: authState.user.id, uniqueKey: props.uniqueKey });

				addPrevVerseToCn({ uniqueKey: props.uniqueKey, verse: currentVerseObj });
				props.verseAdded();
			} else if (verseAlreadyExists === -1) {
				addVerseToNewCn({ uniqueKey: props.uniqueKey, verse: currentVerseObj, userId: props.user.id, offsetAmount: -1 });
				props.verseAdded();
				return null;
			} else {
				console.log('wont add duplicate verse');
				props.verseAdded();
				return null;
			}
		}
	};

	const handleBulkSelectedVerses = () => {
		if (selected) {
			props.addVerse({ draggableId: props.draggableId, index: props.index, droppableId: props.uniqueKey });
		}
		if (!selected) {
			props.removeVerse(props.draggableId);
		}
	};
	/** Returns the Blue Letter Bible link for the current verse */
	const blueBibleLink = () => {
		// let bookName = props.verse.title;
		let bookName = props.verse.chapter.book.title;
		let firstThree = bookName.toLowerCase().replace(' ', '').substring(0, 3);
		let bookAbbr = bookAbbreviations[bookName] || firstThree;
		return `https://www.blueletterbible.org/kjv/${bookAbbr}/${props.verse.chapter.order}/${props.verse.number}/`;
	};

	/** Returns the LDS Citation Index link for the current verse */
	const citationIndexLink = () => {
		const citationIndexRef = {
			'Book of Mormon': '0c9',
			'Old Testament': '065',
			'New Testament': '08c',
			'Doctrine and Covenants': '12e',
			'Pearl of Great Price': '191',
		};

		let { order, volume } = props.verse.chapter.book;

		let startingHexAsNumber = converter.hexToDec(citationIndexRef[volume.title]);
		if (volume.title === 'Doctrine and Covenants' && order === 3) order = 1;
		let bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 1).toString(), { prefix: false });
		if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		// These are special contingencies to keep accurate order with the Citation Index site, as they have
		// a few differences in their database as compared to ours
		if (volume.title === 'Book of Mormon') {
			if (props.verse.chapter.book.title === 'Title Page') return 'https://scriptures.byu.edu/#0c9::c0c9';
			if (props.verse.chapter.book.title === 'Introduction') return 'https://scriptures.byu.edu/#0ca::c0ca';
			if (props.verse.title === 'Title Page') return 'https://scriptures.byu.edu/#0c9::c0c9';
			if (props.verse.title === 'Introduction') return 'https://scriptures.byu.edu/#0ca::c0ca';
			if (props.verse.title === 'Testimony of Three Witnesses') return 'https://scriptures.byu.edu/#0cb::c0cb';
			if (props.verse.title === 'Testimony of Eight Witnesses') return 'https://scriptures.byu.edu/#0cc::c0cc';
			if (props.verse.title === 'Testimony of the Prophet Joseph Smith') return;
			if (props.verse.title === 'Brief Explanation about the Book of Mormon') return;

			bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 3).toString(), { prefix: false });
			if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		} else if (volume.title === 'Pearl of Great Price' && order > 2) {
			bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order).toString(), { prefix: false });
			if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		}
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.book.order === 1) return 'https://scriptures.byu.edu/#12d::c12d';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 139) return 'https://scriptures.byu.edu/#12f01::c12f010';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 140) return 'https://scriptures.byu.edu/#12f02::c12f020';
		let chapterIndex = converter.decToHex(props.verse.chapter.order.toString(), { prefix: false });
		if (chapterIndex.length < 2) chapterIndex = `0${chapterIndex}`;

		let fullHex = `${bookIndex}${chapterIndex}${props.verse.number}`;
		return `https://scriptures.byu.edu/#${fullHex}::c${bookIndex}${chapterIndex}`;
	};
	const mapIndexLink = () => {
		let { order, volume } = props.verse.chapter.book;
		const chapterOrder = props.verse.chapter.order;
		const bookNumber = {
			'Book of Mormon': order - 2,
			'Old Testament': order,
			'New Testament': order + 39,
			'Doctrine and Covenants': 2,
			'Pearl of Great Price': order,
		};
		const volumeNumber = {
			'Book of Mormon': 2,
			'Old Testament': 1,
			'New Testament': 1,
			'Doctrine and Covenants': 3,
			'Pearl of Great Price': 4,
		};

		if (volume.title === 'Book of Mormon') {
			if (props.verse.title === 'Title Page') return 'https://scriptures.byu.edu/mapscrip/#3:201';
			if (props.verse.title === 'Introduction') return 'https://scriptures.byu.edu/mapscrip/#3:202';
			if (props.verse.title === 'Testimony of Three Witnesses') return 'https://scriptures.byu.edu/mapscrip/#3:203';
			if (props.verse.title === 'Testimony of Eight Witnesses') return 'https://scriptures.byu.edu/mapscrip/#3:204';
			if (props.verse.title === 'Testimony of the Prophet Joseph Smith') return;
			if (props.verse.title === 'Brief Explanation about the Book of Mormon') return;

			// bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order - 3).toString(), { prefix: false });
			// if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		} else if (volume.title === 'Pearl of Great Price' && order > 2) {
			// bookIndex = converter.decToHex((parseInt(startingHexAsNumber) + order).toString(), { prefix: false });
			// if (bookIndex.length < 3) bookIndex = `0${bookIndex}`;
		}
		// if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.book.order === 1) return 'https://scriptures.byu.edu/#12d::c12d';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 139) return 'https://scriptures.byu.edu/mapscrip/#0:303:1';
		if (volume.title === 'Doctrine and Covenants' && props.verse.chapter.order === 140) return 'https://scriptures.byu.edu/mapscrip/#0:303:2';
		// let chapterIndex = converter.decToHex(props.verse.chapter.order.toString(), { prefix: false });
		// if (chapterIndex.length < 2) chapterIndex = `0${chapterIndex}`;

		return `https://scriptures.byu.edu/mapscrip/#0:${volumeNumber[volume.title]}${
			`${bookNumber[volume.title]}`.length < 2 ? `0${bookNumber[volume.title]}` : bookNumber[volume.title]
		}:${chapterOrder}`;
	};

	let icons = ReactQuill.Quill.import('ui/icons');
	// icons['link'] = '<span class="footnote-icon">FN</span>';
	icons['strike'] = '<span class="footnote-icon double-underline">U</span>';
	// icons['Def'] = '<span class="footnote-icon">Def</span>';
	icons['h1'] = '<span class="footnote-icon">1828</span>';
	// icons['h2'] = '<span class="footnote-icon">Ety</span>';
	// icons['h3'] = '<span class="footnote-icon">Thes</span>';
	// icons['h4'] = '<span class="footnote-icon">Rhy</span>';

	const handleGreenEye = async () => {
		setShowFootnotes(!showFootnotes);
		if (props.verse.collectionIds[0] !== null || props.verse.hasUserFootnote === true) {
			setGreenEye(greenEyeColor);
		} else setGreenEye(btnColor);
	};

	const replaceAll = (str, find, replace) => {
		return str.replace(new RegExp(find, 'g'), replace);
	};

	const replaceTags = (str, arr, replace) => {
		for (var i = 0; i < arr.length; i++) {
			str = str.replace(new RegExp(arr[i], 'g'), replace);
		}
		return str;
	};

	const renderVerse = () => {
		if (!props.customMarkup) {
			if (!verseEdit && !props.toggleLdsFootnotes) {
				return (
					<HoverEditClickWrapper
					// onMouseEnter={() => setVerseEdit(!verseEdit)}
					// data-toggle='tooltip'
					// title='Click to edit'
					>
						{props.verse.text}
					</HoverEditClickWrapper>
				);
			} else if (verseEdit && !props.toggleLdsFootnotes) {
				let modifiedVerseText = userMarkUp;
				modifiedVerseText = replaceTags(modifiedVerseText, ['<mark>', '</mark>', '<p>', '</p>'], '');
				modifiedVerseText = replaceTags(modifiedVerseText, ['<b>'], '<strong>');
				modifiedVerseText = replaceTags(modifiedVerseText, ['</b>'], '</strong>');
				modifiedVerseText = replaceTags(modifiedVerseText, ['<i>'], '<em>');
				modifiedVerseText = replaceTags(modifiedVerseText, ['</i>'], '</em>');

				return (
					<>
						<div
							className='singleVerseText'
							id={props.uniqueKey + '-' + props.verse.id}
							onPointerUp={e => getFormatter(props.uniqueKey + '-' + props.verse.id, props.verse, e.clientY)}
							dangerouslySetInnerHTML={{ __html: userMarkUp.length > 0 ? modifiedVerseText : props.verse.text }}
						/>
					</>
				);
			} else if (props.toggleLdsFootnotes) {
				return (
					<div
						style={{ fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif', color: '#393f47' }}
						dangerouslySetInnerHTML={{ __html: props.verse.ldsFootnoteMarkup ? props.verse.ldsFootnoteMarkup : props.verse.text }}
					></div>
				);
			}
		}
		if (props.customMarkup) {
			let modifiedVerseText = props.verse.verse_markup ? props.verse.verse_markup : '';
			modifiedVerseText = replaceTags(modifiedVerseText, ['<mark>', '</mark>', '<p>', '</p>'], '');
			modifiedVerseText = replaceTags(modifiedVerseText, ['<b>'], '<strong>');
			modifiedVerseText = replaceTags(modifiedVerseText, ['</b>'], '</strong>');
			modifiedVerseText = replaceTags(modifiedVerseText, ['<i>'], '<em>');
			modifiedVerseText = replaceTags(modifiedVerseText, ['</i>'], '</em>');

			return (
				<>
					<div
						className='singleVerseText'
						id={props.uniqueKey + '-' + props.verse.id}
						onPointerUp={e => getFormatter(props.uniqueKey + '-' + props.verse.id, props.verse, e.clientY)}
						dangerouslySetInnerHTML={{ __html: props.verse.verse_markup ? modifiedVerseText : props.verse.text }}
					/>
				</>
			);
		}
	};

	// This function renders the star on the verse wrapper ONLY WHEN IT APPEARS IN A COLLECTION NOTE
	const renderVersePromotionStar = verse => {
		if (props.collectionObj) {
			return (
				<div style={{ marginRight: '12px' }}>
					<Tooltip content='Favorite'>
						<ReadActionItem
							onClick={() => {
								// These if/else conditions necessary because 'verse' is a not a COLLECTION NOTE VERSE. The table cannot change promoted because the
								// promoted column is only in the table 'collection_verse' not 'verse'

								if (verse.collection_id == undefined) {
									alert('Please Save collection note before using the verse promotion utility');
								} else {
									if (collectionState[props.uniqueKey].collection.user_id === authState.user.id) {
										togglePromotedVerseStatus(props.uniqueKey, verse);
										if (collectionState[props.uniqueKey].collection.id) {
											props.verseAdded();
										}
									} else alert(`Sorry, you can't favorite verses on collections that don't belong to you.`);
								}
							}}
						>
							<FontAwesomeIcon icon={faStar} color={verse.promoted == true ? '#cccc12' : '#b2b7be'} />
						</ReadActionItem>
					</Tooltip>
				</div>
			);
		}
	};
	// console.log(verse);
	const fetchCollections = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getCollectionList/${props.user.id}/null/null/false`)
			.then(res => res.json())
			.then(collections => {
				setCollections(collections);
			});
		return;
	};
	const handleSubmit = async e => {
		e.preventDefault();
		if (searchText === '') {
			return fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getCollectionList/${props.user.id}/null/null/false`)
				.then(res => res.json())
				.then(collections => setCollections(collections));
		}
		fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/searchCollectionList/${props.user.id}/${searchText}/null/null/false`)
			.then(res => res.json())
			.then(collections => setCollections(collections));
	};
	const publicCollectionsFilter = array => {
		if (props.hideDone && props.hideMine)
			return array.filter(cn => cn.name !== authState.user.name && !props.seenList.some(el => cn.id === el.collection_id));
		if (props.hideDone && !props.hideMine) return array.filter(cn => !props.seenList.some(el => cn.id === el.collection_id));
		if (!props.hideDone && props.hideMine) return array.filter(cn => cn.name !== authState.user.name);
		return array;
	};
	const toggleSeen = async (seen, collectionId) => {
		if (seen) {
			const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/removeFromSeenList/${authState.user.id}/${collectionId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(publicCollections => {
					return publicCollections;
				});
			// if (hideSeen) collectionsSort(collectionState[props.uniqueKey].collections.filter((e, i) => !seenList.some(el => e.id === el.collection_id)));
			props.setSeenList(seenList);
			return;
		}
		const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/addToSeenList/${authState.user.id}/${collectionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(publicCollections => {
				return publicCollections;
			});
		// if(hideSeen) collectionsSort(collectionState[props.uniqueKey].collections.filter((e,i) => !seenList.some((el) => e.id === el.collection_id)))
		props.setSeenList(seenList);
	};
	const handleSaveToCN = async collection => {
		if (selected) {
			props.bulkMoveModal({ collectionId: collection.id, uniqueKey: props.uniqueKey, draggableId: props.draggableId });
			props.socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id, uniqueKey: props.uniqueKey });
		} else {
			await fetch(
				`${process.env.REACT_APP_BASE_URL}/collections/addVerse/${props.verse.number}/${props.verse.chapter_id}/${props.verse.id}/${collection.id}/${props.user.id}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			).then(res => {
				props.socket.emit('update_cn_list', { fromVerse: true, userId: authState.user.id, uniqueKey: props.uniqueKey });
			});
		}
	};
	if (isVerseSimple && true)
		return (
			<SimpleVerseWrapper ref={props.dragProvided.innerRef} {...props.dragProvided.draggableProps} isDragDisabled={true}>
				<SimpleVerseHeader>
					<div {...props.dragProvided.dragHandleProps}>
						<div style={{ display: 'flex', cursor: 'pointer', height: '23px' }}>
							<Tooltip content={props.verse.chapter.book.volume.title}>{props.verse.chapter.book.volume.abbreviation} </Tooltip>
							<p> / </p>
							{props.verse.chapter.book.title !== props.verse.chapter.book.volume.title && (
								<Tooltip content={props.verse.chapter.book.title}>{props.verse.chapter.book.abbreviation}</Tooltip>
							)}
							{props.verse.chapter.book.title !== props.verse.chapter.book.volume.title && <p> / </p>}
							{props.verse.chapter.abbreviation ? (
								<Tooltip content={props.verse.chapter.chapter_name ? props.verse.chapter.chapter_name : null}>
									{props.verse.chapter.abbreviation}
								</Tooltip>
							) : (
								<p>{props.verse.chapter.order}</p>
							)}
							<p> : </p>
							<p>{props.verse.number}</p>
						</div>
					</div>
					<ToggleSimpleVerse onClick={() => setIsVerseSimple(false)}>Show Edit Mode</ToggleSimpleVerse>
				</SimpleVerseHeader>
				<SimpleVerseText>{props.verse.text}</SimpleVerseText>
			</SimpleVerseWrapper>
		);

	const formattedBookTitle = (title, order, number) => {
		if ((title === 'Doctrine and Covenants' && order === 139) || (title === 'Doctrine and Covenants' && order === 140)) {
			title = 'Official Declaration';
			return `${title} ${order === 139 ? (order = 1) : (order = 2)}:${number}`;
		} else if (title === 'Doctrine and Covenants') {
			return `${(title = 'D&C')} ${order}:${number}`;
		} else return `${title} ${order}:${number}`;
	};
	const handleVerseRefFetch = async (ldsFootnoteId, selectedRef) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getLdsVerseRefs/${ldsFootnoteId}`)
			.then(response => response.json())
			.then(verses => {
				verses = verses = _.sortBy(
					verses,
					[verse => verse.volumeOrder, verse => verse.bookOrder, verse => verse.chapterOrder, verse => verse.number],
					['asc', 'asc', 'asc', 'asc']
				);
				setVerseReferences(verses);
				setReferenceView(true);
				setSelectedReference(selectedRef);
				// console.log(notes);
			})
			.catch(err => console.log('Error getting search results:', err));
	};
	const handleNotesFetch = async () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getAllNotes/${props.user.id}/${props.verse.id}`)
			.then(response => response.json())
			.then(notes => {
				setVerseCollections(notes);
				setShowFootnotes(true);
			})
			.catch(err => console.log('Error getting search results:', err));
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getPublicNotesByVerseId/${props.verse.id}`)
			.then(response => response.json())
			.then(notes => {
				setPublicCollections(notes);
				// console.log(notes);
			})
			.catch(err => console.log('Error getting search results:', err));

		const seenList = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getSeenPublicCollections/${authState.user.id}`)
			.then(res => res.json())
			.then(publicCollections => {
				return publicCollections;
			});
		// if (hideSeen) collectionsSort(collectionState[props.uniqueKey].collections.filter((e, i) => !seenList.some(el => e.id === el.collection_id)));
		props.setSeenList(seenList);
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getLdsFootnotesByVerse/${props.verse.id}/${props.verse.book_id}/${props.verse.chapter.id}`)
			.then(response => response.json())
			.then(footnotes => {
				setLdsFootnotes(footnotes.footnotes);
				setTGEntries(footnotes.tgEntries);
				setBDEntries(footnotes.bdEntries);
				setTCEntries(footnotes.tcEntries);
				// console.log(notes);
			})
			.catch(err => console.log('Error getting search results:', err));
	};
	const toggleHideMine = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/accountSettings/toggleHideMine/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				bool: !props.hideMine,
			}),
		})
			.then(res => res.json())
			.then(res => {
				props.setHideMine(!props.hideMine);
			})
			.catch(err => console.log(err));
	};
	const toggleHideDone = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/accountSettings/toggleHideDone/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				bool: !props.hideDone,
			}),
		})
			.then(res => res.json())
			.then(res => {
				props.setHideDone(!props.hideDone);
			})
			.catch(err => console.log(err));
	};
	const handleBookmark = async () => {
		if (props.verse.bookmarkId) {
			await fetch(`${process.env.REACT_APP_BASE_URL}/verses/deleteBookmark/${props.user.id}/${props.verse.bookmarkId}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(bookmarks => {
					if (props.uniqueKey.includes('collectionNote')) {
						collectionDispatch({
							type: 'UPDATE_CN_VERSE_BOOKMARK',
							payload: {
								uniqueKey: props.uniqueKey,
								index: props.index,
								bookmarkId: null,
							},
						});
					} else if (props.uniqueKey.includes('search_result')) {
						searchDispatch({
							type: 'UPDATE_SEARCH_VERSE_BOOKMARK',
							payload: {
								uniqueKey: props.uniqueKey,
								index: props.index,
								bookmarkId: null,
							},
						});
					}
					props.socket.emit('update_bookmark_list', { userId: authState.user.id, verseId: props.verse.id, bookmarkId: null });
				});
		} else {
			await fetch(`${process.env.REACT_APP_BASE_URL}/verses/bookmarkVerse/${props.user.id}/${props.verse.id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(response => response.json())
				.then(response => {
					console.log(response);
					if (props.uniqueKey.includes('collectionNote')) {
						collectionDispatch({
							type: 'UPDATE_CN_VERSE_BOOKMARK',
							payload: {
								uniqueKey: props.uniqueKey,
								index: props.index,
								bookmarkId: response[0].id,
							},
						});
					} else if (props.uniqueKey.includes('search_result')) {
						searchDispatch({
							type: 'UPDATE_SEARCH_VERSE_BOOKMARK',
							payload: {
								uniqueKey: props.uniqueKey,
								index: props.index,
								bookmarkId: response[0].id,
							},
						});
					}
					props.socket.emit('update_bookmark_list', { userId: authState.user.id, verseId: props.verse.id, bookmarkId: response[0].id });
				})
				.catch(err => console.log('Error getting search results:', err));
		}
	};
	return (
		<DraggableWrapper
			ref={props.dragProvided.innerRef}
			{...props.dragProvided.draggableProps}
			style={getItemStyle(props.isDragging, props.dragProvided.draggableProps.style)}
		>
			<FullTextWrapper
				className='quill'
				style={{
					whiteSpace: 'pre-wrap',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div spellCheck={false} style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<BookChapterVerseHeading
						onClick={e => {
							e.persist();
							e.stopPropagation();
							if (e.ctrlKey || e.metaKey) {
								return window.open(
									`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${verse.chapter.book.title}&chapter=${verse.chapter_id}&book=${verse.chapter.book_id}&verseNumber=${verse.number}`,
									'_blank'
								);
							} else {
								handleOpenVerses({
									chapterId: verse.chapter_id,
									initialChapterId: verse.chapter_id,
									bookId: verse.chapter.book_id,
									originPaneKey: props.uniqueKey,
									verseNumber: verse.number,
									proUser: isProUser(),
								});
							}
						}}
						style={{ /*backgroundColor: verse.promoted == true ? '#E8E810' : '#FFFFFF',*/ cursor: 'pointer', height: '23px' }}
					>
						<div style={{ display: 'flex', cursor: 'pointer', height: '23px' }}>
							<Tooltip content={props.verse.chapter.book.volume.title}>{props.verse.chapter.book.volume.abbreviation}</Tooltip>
							<p> / </p>
							{props.verse.chapter.book.title !== props.verse.chapter.book.volume.title && (
								<Tooltip content={props.verse.chapter.book.title}>{props.verse.chapter.book.abbreviation}</Tooltip>
							)}
							{props.verse.chapter.book.title !== props.verse.chapter.book.volume.title && <p> / </p>}
							{props.verse.chapter.abbreviation ? (
								<Tooltip content={props.verse.chapter.chapter_name}>{props.verse.chapter.abbreviation}</Tooltip>
							) : (
								<p>{props.verse.chapter.order}</p>
							)}
							<p> : </p>
							<p>{props.verse.number}</p>
						</div>
						{/* <div style={{ cursor: 'pointer', height: '23px' }}>{`${formattedBookTitle(
							props.verse.chapter.book.title,
							props.verse.chapter.order,
							props.verse.number
						)}`}</div> */}
					</BookChapterVerseHeading>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						{props.promotedRender && (
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ marginRight: '7px' }}>
									<Tooltip content='Move verse up'>
										<ReadActionItem
											onClick={() => {
												if (!props.canEdit()) {
													props.promotedRenderIndex > 0 && props.changeFavoriteOrder(props.promotedRenderIndex, -1);
												}
											}}
										>
											<FontAwesomeIcon icon={faArrowUp} />
										</ReadActionItem>
									</Tooltip>
								</div>

								<div style={{ marginRight: '7px' }}>
									<Tooltip content='Move verse down'>
										<ReadActionItem
											onClick={() => {
												if (!props.canEdit()) {
													props.lastPromoted && props.changeFavoriteOrder(props.promotedRenderIndex, 1);
												}
											}}
										>
											<FontAwesomeIcon icon={faArrowDown} />
										</ReadActionItem>
									</Tooltip>
								</div>
							</div>
						)}
						{renderVersePromotionStar(verse)}
						<PortalWithState closeOnOutsideClick closeOnEsc>
							{({ openPortal, closePortal, isOpen, portal }) => (
								<>
									<ReadActionItem {...props.dragProvided.dragHandleProps}>
										<Tooltip
											content={selected ? `Double-click to add to CN or drag Selected Verses ` : 'Double-click to add to CN or drag verse'}
										>
											<FontAwesomeIcon
												key='DragNDropPortal'
												icon={selected ? faObjectUngroup : faArrowsAlt}
												onDoubleClick={async () => {
													await fetchCollections();
													openPortal();
												}}
											/>
										</Tooltip>
									</ReadActionItem>
									{portal(
										<div>
											<div
												style={{
													top: 0,
													zIndex: 9999999,
													background: '#ddd',
													width: '100%',
													height: '100%',
													minHeight: '392px',
													opacity: '0.6',
													position: 'absolute',
												}}
											/>

											<CollectionsPopUpWrapper>
												<ModalTitleContainer>
													<p>Add Verse to collection</p>
													<Tooltip content={'Close'}>
														<FontAwesomeIcon
															icon={faTimes}
															onClick={() => {
																closePortal();
															}}
														/>
													</Tooltip>
												</ModalTitleContainer>
												<SearchCNContainer>
													<form onSubmit={handleSubmit}>
														<SearchInput
															type='text'
															placeholder='Title Search'
															name='text'
															value={searchText}
															onChange={e => setSearchText(e.target.value)}
															autoComplete='off'
															autoFocus
														/>
														<SearchIconWrapper>
															<FontAwesomeIcon icon={faSearch} onClick={e => handleSubmit(e)} />
														</SearchIconWrapper>
													</form>
												</SearchCNContainer>
												<MappedCollectionsContainer>
													{collections.length > 0 &&
														collections.map((e, i) => {
															return (
																<div key={i}>
																	<CollectionTitle
																		onClick={async () => {
																			await handleSaveToCN(e);
																			closePortal();
																		}}
																	>
																		{e.title}
																	</CollectionTitle>
																</div>
															);
														})}
												</MappedCollectionsContainer>
											</CollectionsPopUpWrapper>
										</div>
									)}
								</>
							)}
						</PortalWithState>
					</div>
				</div>

				<div style={{ paddingLeft: props.verse.verseIndent * 20, paddingRight: props.verse.verseIndent * 30 }}>{renderVerse()}</div>
			</FullTextWrapper>

			{props.show && (
				<VerseNoteTextboxWrapper>
					<TextareaAutosize
						// disabled={!isProUser()}
						style={{ backgroundColor: verseNoteUpdating ? '#efefef10' : 'white', zIndex: 1 }}
						className='text-area-wrapper'
						// disabled={(verse && verse.note && verse.note.loading) || this.props.user.data.stripePlanName === 'Free' ? true : false}
						maxRows={20}
						name={verse.id}
						value={noteText}
						onBlur={() => {
							// const socket = io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] });
							updateVerseNote({ verseId: verse.id, userId: props.user.id, noteText: noteText });
							const socket = props.socket;
							socket.emit('update_verse_note', {
								newNoteText: noteText,
								verseId: props.verse.id,
								userId: authState.user.id,
								uniqueKey: props.uniqueKey,
							});
							setVerseNoteUpdating(false);
							handleUpdateVerseNote();
						}}
						onChange={e => {
							if (!verseNoteUpdating) setVerseNoteUpdating(true);
							setNoteText(e.target.value);
						}}
						tabIndex='1'
					/>
				</VerseNoteTextboxWrapper>
			)}

			{/* Got about 3 of the tooltip icons in here*/}
			<ReadActionWrapper>
				<ReactTooltip place='left' effect='solid' />
				<ReadActionGroup>
					<Tooltip content='Add Previous Verse'>
						<ReadActionItem
							onClick={() => {
								if (!props.canEdit()) {
									authState.user.id && handlePrevVerse(verse);
								}
							}}
						>
							<FontAwesomeIcon icon={faArrowAltCircleUp} />
						</ReadActionItem>
					</Tooltip>
					<Tooltip content='Remove Verse'>
						<ReadActionItem
							onClick={() => {
								// isProUser() &&
								if (!props.canEdit()) {
									authState.user.id && handleRemoveVerse();
								}
							}}
						>
							<FontAwesomeIcon icon={faMinusSquare} />
						</ReadActionItem>
					</Tooltip>
					<Tooltip content='Add Next Verse'>
						<ReadActionItem
							onClick={() => {
								if (!props.canEdit()) {
									authState.user.id && handleNextVerse(verse);
								}
							}}
						>
							<FontAwesomeIcon icon={faArrowAltCircleDown} />
						</ReadActionItem>
					</Tooltip>
				</ReadActionGroup>

				<ReadActionGroup>
					{/* This is this FIRST button on the side of the draggable wrapper */}
					<Tooltip content='Create Collection Note'>
						<ReadActionItem
							onClick={e => {
								if (!isProUser()) {
									alert('You must be a pro member to acess this feature.');
									return;
								}
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									return window.open(
										`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=new&verseId=${verse.id}&bookId=${verse.chapter.book_id}&chapterId=${verse.chapter.id}`,
										'_blank'
									);
								} else {
									if (!props.verse.userMarkup) {
										handleAddNewCollectionNote([props.verse], props.uniqueKey);
										props.verse.userMarkup = {};
										props.verse.userMarkup.verse_text_with_markup = userMarkUp;
									} else handleAddNewCollectionNote([props.verse], props.uniqueKey);
								}
							}}
						>
							<FontAwesomeIcon icon={faEdit} />
						</ReadActionItem>
					</Tooltip>

					{/* This is this SECOND button on the side of the draggable wrapper */}
					<ReadActionItem>
						<Tooltip content='See Notes'>
							<GreenEyeItem
								style={{
									color: showFootnotes ? hoverColor : greenEye,
								}}
								onClick={() => {
									if (authState.user.id) {
										handleGreenEye();
										if (collectionState[props.uniqueKey] && collectionState[props.uniqueKey].collection) {
											showFootnotes ? setShowFootnotes(false) : handleNotesFetch();
											// getAllFootnotes({ userId: props.user.id, verseId: props.verse.id, uniqueKey: props.uniqueKey });
										} else {
											// getAllFootnotesSearchResults({ userId: props.user.id, verseId: props.verse.id, uniqueKey: props.uniqueKey });
											showFootnotes ? setShowFootnotes(false) : handleNotesFetch();
										}
									}
								}}
								onMouseOver={() => {
									setGreenEye(hoverColor);
									// props.socket.emit('update_verse_greeneye', { userId: authState.user.id, verseId: props.verse.id, greenEyeColor: hoverColor });
								}}
								onMouseOut={() => {
									setGreenEye(prevGreenEye);
									// props.socket.emit('update_verse_greeneye', { userId: authState.user.id, verseId: props.verse.id, greenEyeColor: prevGreenEye });
								}}
							>
								<FontAwesomeIcon icon={faEye} />
							</GreenEyeItem>
						</Tooltip>

						{showFootnotes &&
							(referenceView ? (
								<ReadActionsDropdownWrapper>
									<CloseNotesModalWrapper onClick={() => setReferenceView(false)}>
										{/* <FontAwesomeIcon icon={faTimes} onClick={() => setReferenceView(false)} /> */}
										Back
									</CloseNotesModalWrapper>
									<ReadActionDropdownItem>
										<ReadActionLink>{selectedReference}</ReadActionLink>
										<ReadActionDropdownList>
											{verseReferences.length > 0 &&
												verseReferences.map((verse, index) => {
													return (
														<ReadActionListItem
															key={`${index}-${verse.verseId}`}
															onClick={e => {
																e.persist();
																e.stopPropagation();
																if (e.ctrlKey || e.metaKey) {
																	return window.open(
																		`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${verse.bookTitle}&chapter=${verse.chapterId}&book=${verse.bookId}&verseNumber=${verse.number}`,
																		'_blank'
																	);
																} else {
																	handleOpenVerses({
																		chapterId: verse.chapterId,
																		initialChapterId: verse.chapterId,
																		bookId: verse.bookId,
																		originPaneKey: props.uniqueKey,
																		verseNumber: verse.number,
																		proUser: isProUser(),
																	});
																}
															}}
														>
															<VerseReferenceTitle>
																{verse.bookAbbreviation}.{verse.chapterOrder}:{verse.number}
															</VerseReferenceTitle>
															<p>{verse.text}</p>
														</ReadActionListItem>
													);
												})}
											{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
										</ReadActionDropdownList>
									</ReadActionDropdownItem>
								</ReadActionsDropdownWrapper>
							) : (
								<ReadActionsDropdownWrapper>
									<CloseNotesModalWrapper>
										<FontAwesomeIcon icon={faTimes} onClick={() => handleGreenEye()} />
									</CloseNotesModalWrapper>
									<ReadActionDropdownItem>
										{/* <ReadActionLink>Footnotes</ReadActionLink>
									<ReadActionDropdownList>
										{userFootnotes.length > 0
											? userFootnotes.map((note, index) => (
												<ReadActionListItem key={index}>
													<span>{note.footnote}</span>
												</ReadActionListItem>
											))
											: null}
									</ReadActionDropdownList> */}
									</ReadActionDropdownItem>

									<ReadActionDropdownItem>
										<ReadActionLink>LDS Footnotes</ReadActionLink>
										<ReadActionDropdownList>
											{ldsFootnotes.length > 0 &&
												ldsFootnotes.map(note => (
													<ReadActionListItem
														onClick={() => handleVerseRefFetch(note.id, note.footnote)}
														key={note.id}
													>{`${note.superscript}: ${note.footnote} `}</ReadActionListItem>
												))}
											{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
										</ReadActionDropdownList>
									</ReadActionDropdownItem>
									{tgEntries.length > 0 && (
										<ReadActionDropdownItem>
											<ReadActionLink>TG Entries</ReadActionLink>
											<ReadActionDropdownList>
												{tgEntries.length > 0 &&
													tgEntries.map((entry, index) => {
														return (
															<StudyHelpEntry onClick={() => handleTGEntry(entry)} key={`${index}-${entry.id}`}>
																{`TG ${entry.tg_title}${index === tgEntries.length - 1 ? '' : '; '}`}
															</StudyHelpEntry>
														);
													})}
												{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
											</ReadActionDropdownList>
										</ReadActionDropdownItem>
									)}
									{bdEntries.length > 0 && (
										<ReadActionDropdownItem>
											<ReadActionLink>BD Entries</ReadActionLink>
											<ReadActionDropdownList>
												{bdEntries.length > 0 &&
													bdEntries.map((entry, index) => {
														return (
															<StudyHelpEntry onClick={() => handleBDEntry(entry)} key={`${index}-${entry.id}`}>
																{`BD ${entry.title}${index === bdEntries.length - 1 ? '' : '; '}`}
															</StudyHelpEntry>
														);
													})}
												{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
											</ReadActionDropdownList>
										</ReadActionDropdownItem>
									)}
									{tcEntries.length > 0 && (
										<ReadActionDropdownItem>
											<ReadActionLink>TC Entries</ReadActionLink>
											<ReadActionDropdownList>
												{tcEntries.length > 0 &&
													tcEntries.map((entry, index) => {
														return (
															<StudyHelpEntry onClick={() => handleTCEntry(entry)} key={`${index}-${entry.id}`}>
																{`TC ${entry.triple_title}${index === tcEntries.length - 1 ? '' : '; '}`}
															</StudyHelpEntry>
														);
													})}
												{/* <ReadActionListItem>Coming Soon</ReadActionListItem> */}
											</ReadActionDropdownList>
										</ReadActionDropdownItem>
									)}
									<ReadActionDropdownItem>
										<ReadActionLink>Collection Notes</ReadActionLink>
										<ReadActionDropdownList>
											{verseCollections.length !== 0 ? (
												verseCollections.map(value => {
													return (
														<ReadActionListItem key={value.id} onClick={() => handleAddCollection(value.id, props.uniqueKey)}>
															<span>{value.title}</span>
														</ReadActionListItem>
													);
												})
											) : (
												<ReadActionListItem style={{ fontStyle: 'italic' }}>none</ReadActionListItem>
											)}
										</ReadActionDropdownList>
									</ReadActionDropdownItem>
									{publicCollections.length !== 0 && (
										<ReadActionDropdownItem>
											<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
												<ReadActionLink>Public Collections</ReadActionLink>
												<PublicCollectionBoxesContainer>
													<input
														type='checkbox'
														value={'hide-seen'}
														checked={props.hideDone}
														id='hide-seen'
														onChange={() => toggleHideDone()}
														style={{ cursor: 'pointer', marginRight: '5px' }}
													/>
													<CheckboxLabel style={{ cursor: 'pointer', marginRight: '5px' }} htmlFor='hide-seen'>
														Hide Done
													</CheckboxLabel>
													<input
														type='checkbox'
														value={'hide-mine'}
														checked={props.hideMine}
														id='hide-mine'
														onChange={() => toggleHideMine()}
														style={{ cursor: 'pointer', marginRight: '5px' }}
													/>
													<CheckboxLabel style={{ cursor: 'pointer', marginRight: '5px' }} htmlFor='hide-mine'>
														Hide Mine
													</CheckboxLabel>
												</PublicCollectionBoxesContainer>
											</div>

											<ReadActionDropdownList>
												{publicCollections.length !== 0 &&
													publicCollectionsFilter(publicCollections).map(value => {
														return (
															<ReadActionListItem key={value.id}>
																<span>
																	<input
																		type='checkbox'
																		value={'seen'}
																		checked={props.seenList.map(e => e.collection_id).includes(value.id)}
																		id='seen'
																		onChange={() =>
																			toggleSeen(props.seenList.map(e => e.collection_id).includes(value.id), value.id)
																		}
																		style={{ cursor: 'pointer', marginRight: '5px' }}
																	/>
																</span>
																<span
																	onClick={() => {
																		props.scrollRight();
																		dispatch({
																			type: 'HANDLE_ADD_PANE',
																			originPaneKey: props.uniqueKey,
																			payload: {
																				key: `collectionNote_${props.unique()}`,
																				type: 'collectionNote',
																				collectionId: null,
																				shareCode: value.share_code,
																			},
																		});
																	}}
																>
																	{value.title} <span style={{ color: 'blue' }}>{value.name}</span>
																</span>
															</ReadActionListItem>
														);
													})}
											</ReadActionDropdownList>
										</ReadActionDropdownItem>
									)}
								</ReadActionsDropdownWrapper>
							))}
					</ReadActionItem>
					{/* </Tooltip> */}

					<Tooltip content='Links' eventOff='onClick'>
						<ReadActionItem>
							<FontAwesomeIcon icon={faLink} onClick={() => authState.user.id && handleShowHyperLinks()} />
							{showHyperlinks && (
								<ReadActionsDropdownWrapper>
									<CloseNotesModalWrapper>
										<FontAwesomeIcon icon={faTimes} onClick={() => setShowHyperlinks(!showHyperlinks)} />
									</CloseNotesModalWrapper>
									<BookmarkContainer>
										<Tooltip content='Bookmark Verse'>
											<FontAwesomeIcon
												onClick={handleBookmark}
												icon={faBookmark}
												style={props.verse.bookmarkId ? { color: '#324599' } : {}}
											/>
										</Tooltip>
									</BookmarkContainer>
									{verse.chapter.book.volume.id <= 5 ? (
										<ReadActionDropdownItem>
											<HyperlinkItem
												onClick={() => {
													dispatch({
														type: 'HANDLE_ADD_PANE',
														originPaneKey: props.uniqueKey,
														payload: {
															key: `chatgpt_${props.unique()}`,
															type: 'chatgpt',
															verse: {
																volume: props.verse.chapter.book.volume.title,
																book: props.verse.chapter.book.title,
																chapter: props.verse.chapter.order,
																verseNumber: props.verse.number,
															},
														},
													});
												}}
											>
												<HyperlinkInfo>i</HyperlinkInfo>
												<HyperlinkName>
													AI Assistant - Daniel <span style={{ color: '#324599' }}>{`(in Beta)`}</span>
												</HyperlinkName>
											</HyperlinkItem>
											{['Old Testament', 'New Testament'].includes(props.verse.chapter.book.volume.title) && (
												<div>
													<HyperlinkItem>
														<HyperlinkInfo href='https://scripturenotes.com/resource-7-bible-hub' target='_blank'>
															i
														</HyperlinkInfo>
														<HyperlinkName
															href={`https://biblehub.com/${props.verse.chapter.book.title.toLowerCase().replace(' ', '_')}/${
																props.verse.chapter.order
															}-${props.verse.number}.htm`}
															target='_blank'
														>
															Bible Hub
														</HyperlinkName>
													</HyperlinkItem>
													<HyperlinkItem>
														<HyperlinkInfo
															href='https://scripturenotes.com/resource-2-strongs-exhaustive-concordance-of-the-bible'
															target='_blank'
														>
															i
														</HyperlinkInfo>
														<HyperlinkName href={blueBibleLink()} target='_blank'>
															Blue Letter Bible
														</HyperlinkName>
													</HyperlinkItem>
												</div>
											)}
											<HyperlinkItem>
												<HyperlinkInfo href='https://scripturenotes.com/resource-3-lds-citation-index' target='_blank'>
													i
												</HyperlinkInfo>
												<HyperlinkName href={citationIndexLink()} target='_blank'>
													LDS Citation Index
												</HyperlinkName>
											</HyperlinkItem>
											<HyperlinkItem>
												<HyperlinkInfo href='https://scripturenotes.com/resource-3-lds-citation-index' target='_blank'>
													i
												</HyperlinkInfo>
												<HyperlinkName href={mapIndexLink()} target='_blank'>
													Maps
												</HyperlinkName>
											</HyperlinkItem>

											{Object.keys(jst).length !== 0 && (
												<div>
													<HyperlinkItem>
														<HyperlinkInfo href='https://scripturenotes.com/resource-4-joseph-smith-translation' target='_blank'>
															i
														</HyperlinkInfo>
														<HyperlinkName href={jst.jst_link} target='_blank'>
															Joseph Smith Translation
														</HyperlinkName>
														<ICIV>
															<HyperlinkName style={{ marginLeft: 5, marginRight: 5 }}>-</HyperlinkName>
															<HyperlinkName href={jst.inline_link} target='_blank'>
																Inline
															</HyperlinkName>
														</ICIV>
													</HyperlinkItem>
												</div>
											)}
										</ReadActionDropdownItem>
									) : (
										<ReadActionDropdownItem>
											<HyperlinkItem
												onClick={() => {
													dispatch({
														type: 'HANDLE_ADD_PANE',
														originPaneKey: props.uniqueKey,
														payload: {
															key: `chatgpt_${props.unique()}`,
															type: 'chatgpt',
															verse: {
																volume: props.verse.chapter.book.volume.title,
																book: props.verse.chapter.book.title,
																chapter: props.verse.chapter.order,
																verseNumber: props.verse.number,
															},
														},
													});
												}}
											>
												<HyperlinkInfo>i</HyperlinkInfo>
												<HyperlinkName>
													AI Assistant - Daniel <span style={{ color: '#324599' }}>{`(in Beta)`}</span>
												</HyperlinkName>
											</HyperlinkItem>
											{/* <ReadActionLink>No links available.</ReadActionLink> */}{' '}
										</ReadActionDropdownItem>
									)}
								</ReadActionsDropdownWrapper>
							)}
						</ReadActionItem>
					</Tooltip>
				</ReadActionGroup>
				{props.bulkMove && (
					<ReadActionItem style={{ marginRight: '10px' }}>
						<Tooltip content='Bulk Select'>
							<ReadActionItemInput name='selected' type='checkbox' checked={selected} onChange={e => setSelected(!selected)} />
						</Tooltip>
					</ReadActionItem>
				)}
			</ReadActionWrapper>
			{props.bulkMoveDragging && <SelectionCount>{props.bulkMoveState.length}</SelectionCount>}
		</DraggableWrapper>
	);
};

export default CollectionNoteVerse;

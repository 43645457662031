import React, { useState, useContext } from 'react';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
import { PortalWithState } from 'react-portal';
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid/';
import styled from 'styled-components';
import StudyHelps from '../StudyHelps/StudyHelps';

const PaneWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const VolumesWrapper = styled.ul`
	padding-left: 0;
	margin-bottom: 60px;
	list-style: none;
`;

const VolumesText = styled.li`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;
const VolumeTitleContainer = styled.div`
	display: flex;
`;
const InfoIconContainer = styled.div`
	color: #6a7583;
	// padding-top: 5px;
	margin-left: 10px;
	&:hover {
		color: #324599;
	}
`;
const Volumes = props => {
	const { authState, isProUser } = useContext(AuthContext);
	const { handleOpenStudyTopic, handleAddStudyHelpPane } = useContext(NavContext);
	const [showStudyHelps, setShowStudyHelps] = useState(false);
	const scriptureVolumes = props.volumes.filter(e => !e.pro_volume);
	const proVolumes = props.volumes.filter(e => e.pro_volume).sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));

	return (
		<>
			{showStudyHelps ? (
				<StudyHelps
					id={props.id}
					user={authState.user}
					uniqueKey={props.uniqueKey}
					dragHandleProps={props.dragHandleProps}
					closePane={id => props.closePane(id)}
					backToMain={() => setShowStudyHelps(false)}
					selectedOption={null}
					selectedTopic={null}
				/>
			) : (
				<PaneWrapper>
					<VolumesWrapper>
						{scriptureVolumes.map((volume, id) => {
							return (
								<VolumeTitleContainer key={id}>
									<VolumesText
										onClick={() => {
											props.handleChooseVolume(volume.id, volume);
										}}
									>
										<h5>{volume.title}</h5>
									</VolumesText>
									<PortalWithState closeOnOutsideClick closeOnEsc>
										{({ openPortal, closePortal, isOpen, portal }) => (
											<>
												{volume.about !== null && (
													<InfoIconContainer>
														<Tooltip content='About'>
															<FontAwesomeIcon key='ProjectPortal' icon={faInfoCircle} onClick={openPortal} />
														</Tooltip>
													</InfoIconContainer>
												)}
												{portal(
													<div>
														<div
															style={{
																top: 0,
																zIndex: 9999999,
																background: '#ddd',
																width: '100%',
																height: '100%',
																minHeight: '392px',
																opacity: '0.6',
																position: 'absolute',
															}}
														/>
														<DeletePopUpWrapper>
															<p dangerouslySetInnerHTML={{ __html: volume.about }}></p>
															<DeletePopUpBtnWrapper>
																<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
															</DeletePopUpBtnWrapper>
														</DeletePopUpWrapper>
													</div>
												)}
											</>
										)}
									</PortalWithState>
								</VolumeTitleContainer>
							);
						})}
						<VolumesText
							key={'study-helps'}
							onClick={(e) => {
								// e.stopPropagation();
								// e.persist();
								handleAddStudyHelpPane({ open: true, versesOriginKey: props.uniqueKey });
							}}
						>
							<h5>Study Helps</h5>
						</VolumesText>
						{isProUser() && proVolumes.map((volume, id) => {
							return (
								<VolumeTitleContainer key={id}>
									<VolumesText
										onClick={() => {
											props.handleChooseVolume(volume.id, volume);
										}}
									>
										<h5>{volume.title}</h5>
									</VolumesText>
									<PortalWithState closeOnOutsideClick closeOnEsc>
										{({ openPortal, closePortal, isOpen, portal }) => (
											<>
												{volume.about !== null && (
													<InfoIconContainer>
														<Tooltip content='About'>
															<FontAwesomeIcon key='ProjectPortal' icon={faInfoCircle} onClick={openPortal} />
														</Tooltip>
													</InfoIconContainer>
												)}
												{portal(
													<div>
														<div
															style={{
																top: 0,
																zIndex: 9999999,
																background: '#ddd',
																width: '100%',
																height: '100%',
																minHeight: '392px',
																opacity: '0.6',
																position: 'absolute',
															}}
														/>
														<DeletePopUpWrapper>
															<p dangerouslySetInnerHTML={{ __html: volume.about }}></p>
															<DeletePopUpBtnWrapper>
																<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
															</DeletePopUpBtnWrapper>
														</DeletePopUpWrapper>
													</div>
												)}
											</>
										)}
									</PortalWithState>
								</VolumeTitleContainer>
							);
						})}
					</VolumesWrapper>
				</PaneWrapper>
			)}
		</>
	);
};

export default Volumes;

import React from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import { PortalWithState } from 'react-portal';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
import { faInfoCircle } from '@fortawesome/fontawesome-free-solid/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from 'react-tooltip-lite';

const BookWrapper = styled.ul`
	padding-left: 0;
	margin-bottom: 60px;
	list-style: none;
	margin-top: 0;
`;

const BookListItem = styled.li`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;
const BookContainer = styled.div`
	display: flex;
	align-items: center;
`;
const InfoIconContainer = styled.div`
	// display: flex;
	// align-items: center;
	// padding-top: 5px;
	margin-left: 10px;
	&:hover {
		color: #324599;
	}
	color: #6a7583;
`;

const Books = props => {
	useEffect(() => {
		// If there's only one book for a volume navigate straight to the display
		// of that book's chapters (currently just a case for D&C)
		if (props.books && props.books.length === 1) {
			props.handleChooseBook(props.books[0].id, props.books[0]);
		}
	}, []);

	return (
		<BookWrapper>
			{props.books.map((book, id) => {
				return (
					<BookContainer>
						<BookListItem key={id} onClick={() => props.handleChooseBook(book.id, book)}>
							<a>— {book.title}</a>
						</BookListItem>
						<PortalWithState closeOnOutsideClick closeOnEsc>
							{({ openPortal, closePortal, isOpen, portal }) => (
								<>
									{book.about !== null && (
										<InfoIconContainer>
											<Tooltip content='About'>
												<FontAwesomeIcon key='ProjectPortal' icon={faInfoCircle} onClick={openPortal} />
											</Tooltip>
										</InfoIconContainer>
									)}
									{portal(
										<div>
											<div
												style={{
													top: 0,
													zIndex: 9999999,
													background: '#ddd',
													width: '100%',
													height: '100%',
													minHeight: '392px',
													opacity: '0.6',
													position: 'absolute',
												}}
											/>

											<DeletePopUpWrapper>
												<p dangerouslySetInnerHTML={{ __html: book.about }}></p>
												<DeletePopUpBtnWrapper>
													<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
												</DeletePopUpBtnWrapper>
											</DeletePopUpWrapper>
										</div>
									)}
								</>
							)}
						</PortalWithState>
					</BookContainer>
				);
			})}
		</BookWrapper>
	);
};

export default Books;

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../assets/css/trainingModal.css';
import YouTube from 'react-youtube';
import { useState } from 'react';
import axios from 'axios';
import { faTv } from '@fortawesome/fontawesome-free-solid/';
import Tooltip from 'react-tooltip-lite';
const TrainingModal = props => {
	const { handleTrainingModal } = props;
	const { link, link_text, title, video_id } = props.data;
	const [openVideo, setOpenVideo] = useState(false);
	const [tutorials, setTutorials] = useState([]);
	useEffect(() => {
		getTutorials();
	}, []);
	const getTutorials = async () => {
		await axios.get(`${process.env.REACT_APP_BASE_URL}/webHelp/getAllTutorials`).then(res => setTutorials(res.data));
	};
	const handleOpenVideo = () => {
		openVideo === false ? setOpenVideo(true) : setOpenVideo(false);
	};
	return (
		<div className='TrainingModal'>
			<div className='content'>
				{title ? (
					<div className='training-div'>
						<div className='training-title-div'>
							<div className='title-linkText'>
								<p className='training-title'>{title} </p>
								<p className='training-vid-link' onClick={handleOpenVideo}>
									{link_text}
								</p>
								<p style={{ marginLeft: '10px' }}>
									<Tooltip content='Open in media player.'>
										<FontAwesomeIcon
											icon={faTv}
											style={{ color: '#7e8087' }}
											className='training-vid-link'
											onClick={() => {
												props.setTrainingModalIsOpen(false);
												props.openTrainingPane(`https://www.youtube.com/watch?v=${video_id}`);
											}}
										/>
									</Tooltip>
								</p>
							</div>
							{openVideo && <YouTube className='training-vid' videoId={video_id} />}
						</div>
					</div>
				) : (
					<div className='training-div'>
						<div className='training-title-div'>
							<a className='training-vid-link' href={link} target='_blank'>
								{link_text}
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TrainingModal;

import React, { useReducer, createContext } from 'react';

export const TagsContext = createContext();

const initialState = {};

const reducer = (state, action) => {
	switch (action.type) {
		case 'GET_TAG_LIST':
			return {
				...state,
				[action.payload.uniqueKey]: {
					tags:
						state[action.payload.uniqueKey] && state[action.payload.uniqueKey].tags
							? state[action.payload.uniqueKey].tags
							: action.payload.data,
					collectionNotes:
						state[action.payload.uniqueKey] && state[action.payload.uniqueKey].collectionNotes
							? state[action.payload.uniqueKey].collectionNotes
							: [],
					text: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].text !== '' ? state[action.payload.uniqueKey].text : '',
					allTags: action.payload.data || [],
				},
			};
		case 'GET_COLLECTION':
			return {
				...state,
				[action.payload.uniqueKey]: {
					tags: state[action.payload.uniqueKey].tags,
					allTags: state[action.payload.uniqueKey].allTags,
					collectionNotes: action.payload.data,
				},
			};
		case 'SEARCH_TAG_LIST':
			return {
				...state,
				[action.payload.uniqueKey]: {
					tags: action.payload.data,
					allTags: state[action.payload.uniqueKey].allTags,
					collectionNotes: [],
					text: action.payload.text,
				},
			};
		case 'DELETE_TAGS_PANE': {
			let stateCopy = { ...state };
			delete stateCopy[action.payload.uniqueKey];
			return stateCopy;
		}

		default:
			return state;
	}
};

export const TagsProvider = ({ children }) => {
	const [tagsState, tagsDispatch] = useReducer(reducer, initialState);

	const fetchUserTagsList = async obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/tags/getAllTagsForUser/${obj.userId}`)
			.then(res => res.json())
			.then(tags => {
				let allTags = tags.reduce((acc, cur) => {
					let labelSections = cur.label.split(':');
					let parentLabels = labelSections.slice(0, labelSections.length - 1);
					if (labelSections.length > 1 && !tags.find(tag => tag.label === parentLabels.join(':'))) {
						acc.push({ label: parentLabels.join(':'), value: `${cur.value}-parent` });
						tags.push({ label: parentLabels.join(':'), value: `${cur.value}-parent` });
					}
					acc.push(cur);
					return acc;
				}, []);

				let tagsWithParentIds = allTags.map(tag => {
					let parentId;
					let labelSections = tag.label.split(':');
					if (labelSections.length === 1) parentId = null;
					else {
						let parentLabels = labelSections.slice(0, labelSections.length - 1);
						let parent = allTags.find(tag => tag.label === parentLabels.join(':'));
						if (!parent) {
							parentId = null;
						} else parentId = parent.value;
					}
					return { ...tag, parentId };
				});

				const treeTags = [];

				tagsWithParentIds.forEach(node => {
					// No parentId means top level
					if (!node.parentId) return treeTags.push(node);

					// Insert node as child of parent in tagsWithParentIds array
					const parentIndex = tagsWithParentIds.findIndex(el => el.value === node.parentId);
					if (!tagsWithParentIds[parentIndex].children) {
						return (tagsWithParentIds[parentIndex].children = [node]);
					}

					tagsWithParentIds[parentIndex].children.push(node);
				});

				tagsDispatch({ type: 'GET_TAG_LIST', payload: { uniqueKey: obj.uniqueKey, data: treeTags } });
				// tagsDispatch({ type: 'GET_TAG_LIST', payload: { uniqueKey: obj.uniqueKey, data: tags } });
			});
	};

	const fetchCollectionNotesByTagId = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/tags/getCollectionByTagId/${obj.value}`)
			.then(response => response.json())
			.then(tags => {
				tagsDispatch({ type: 'GET_COLLECTION', payload: { uniqueKey: obj.uniqueKey, data: tags } });
			});
	};

	const searchUserTagsList = async obj => {
		let filteredTags = tagsState[obj.uniqueKey].allTags.filter(tag => {
			if (tag.label.toLowerCase().includes(obj.text.toLowerCase())) {
				return true;
			} else if (tag.children && tag.children.length) {
				let childMatches = tag.children.some(tg => tg.label.toLowerCase().includes(obj.text.toLowerCase()));
				return childMatches;
			}

			return false;
		});
		tagsDispatch({ type: 'SEARCH_TAG_LIST', payload: { uniqueKey: obj.uniqueKey, data: filteredTags, text: obj.text } });
	};

	return (
		<TagsContext.Provider
			value={{
				tagsState,
				tagsDispatch,
				fetchUserTagsList,
				fetchCollectionNotesByTagId,
				searchUserTagsList,
			}}
		>
			{children}
		</TagsContext.Provider>
	);
};

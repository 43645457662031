import React, { useReducer, createContext } from 'react';

export const LibraryProviderState = createContext();

const initialState = {};

const reducer = (state, action) => {
	switch (action.type) {
		case `FETCH_VOLUMES_BOOKS_CHAPTERS`: {
			return {
				...state,
				[action.payload.uniqueKey]: {
					volumes: action.payload.volumes,
					books: action.payload.books,
					chapters: action.payload.chapters,
					search: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].search !== '' ? state[action.payload.uniqueKey].search : '',
				},
			};
		}
		case `SET_SEARCH`: {
			return {
				...state,
				[action.payload.uniqueKey]: {
					volumes: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].volumes ? state[action.payload.uniqueKey].volumes : [],
					books: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].books ? state[action.payload.uniqueKey].books : [],
					chapters: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].chapters ? state[action.payload.uniqueKey].chapters : [],
					search: action.payload.search,
				},
			};
		}
		case `DELETE_LIBRARY_PANE`: {
			let stateCopy = { ...state };
			delete stateCopy[action.payload.uniqueKey];
			return stateCopy;
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

export const LibraryProvider = ({ children }) => {
	const [libraryState, libraryDispatch] = useReducer(reducer, initialState);

	const fetchVolumesBooksNChapters = async (key, userId) => {
		// let volumes = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getAllVolumes`)
		// 	.then(response => response.json())
		// 	.then(volumes => {
		// 		return volumes;
		// 	});
		// let books = await fetch(`${process.env.REACT_APP_BASE_URL}/books/getAllBooks`)
		// 	.then(response => response.json())
		// 	.then(books => {
		// 		return books;
		// 	});
		// let chapters = await fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getAllChapters`)
		// 	.then(response => response.json())
		// 	.then(chapters => {
		// 		return chapters;
		// 	});
		const data = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getUserVolumes/${userId}`)
			.then(res => res.json())
			.then(userVolumes => userVolumes);
		
		// const topicalGuide = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getTopicalGuide`)
		// 	.then(res => res.json());

		// topicalGuide.forEach(({ title }, i) => {
		// 	chapters.push({
		// 		book_id: 20000 + i,
		// 		search: 'Topical Guide',
		// 		full: 'Topical Guide'
		// 	});
		// 	books.push({
		// 		title,
		// 		chapters: [{
		// 			book_id: 20000 + i,
		// 			search: 'Topical Guide',
		// 			full: 'Topical Guide'
		// 		}],
		// 		volume_id: 6
		// 	});
		// });

		// let tgBooks = topicalGuide.map((topic, i) => ({
		// 	id: 20000 + i,
		// 	title: topic.title,
		// 	order: i,
		// 	volume_id: 6,
		// 	chapters: [{
		// 		book_id: 20000 + i,
		// 		search: 'Topical Guide'
		// 	}]
		// }));

		// const tgVolume = {
		// 	id: 6,
		// 	title: 'Topical Guide',
		// 	order: 6,
		// 	books: tgBooks
		// };

		// volumes.push(tgVolume);

		
		
		// 	title: topic.title,
		// 	order: i,
		// 	volume_id: 6,
		// 	chapters: []
		// })));
		
		
		
		

		libraryDispatch({
			type: 'FETCH_VOLUMES_BOOKS_CHAPTERS',
			payload: { volumes: data.volumes, books: data.books, chapters: data.chapters, uniqueKey: key },
		});
		// libraryDispatch({ type: 'FETCH_VOLUMES_BOOKS_CHAPTERS', payload: { volumes, books, chapters, topicalGuide, uniqueKey: key } });
	};
	const fetchVolumesBooksNChaptersPodcast = async key => {
		let volumes = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getAllVolumes`)
			.then(response => response.json())
			.then(volumes => {
				return volumes;
			});
		let books = await fetch(`${process.env.REACT_APP_BASE_URL}/books/getAllBooks`)
			.then(response => response.json())
			.then(books => {
				return books;
			});
		let chapters = await fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getAllChapters`)
			.then(response => response.json())
			.then(chapters => {
				return chapters;
			});
		libraryDispatch({ type: 'FETCH_VOLUMES_BOOKS_CHAPTERS', payload: { volumes: volumes, books: books, chapters: chapters, uniqueKey: key } });
	};

	return (
		<LibraryProviderState.Provider value={{ libraryState, libraryDispatch, fetchVolumesBooksNChapters, fetchVolumesBooksNChaptersPodcast }}>
			{children}
		</LibraryProviderState.Provider>
	);
};

import React, { useReducer, createContext } from 'react';

// const ChapterHistorychapterHistoryState = createContext();
export const ChapterHistoryContext = createContext();

const initialState = {
	hasUrlQueryRendered: false,
	hasLibraryQueryRendered: false,
	hasSearchQueryRendered: false,
};

const reducer = (chapterHistoryState, action) => {
	switch (action.type) {
		case 'SET_URL_QUERY_RENDERED': {
			return {
				hasUrlQueryRendered: action.payload,
				hasLibraryQueryRendered: chapterHistoryState.hasLibraryQueryRendered,
				hasSearchQueryRendered: chapterHistoryState.hasSearchQueryRendered,
			};
		}
		case 'SET_LIBRARY_URL_QUERY_RENDERED': {
			return {
				hasUrlQueryRendered: chapterHistoryState.hasUrlQueryRendered,
				hasLibraryQueryRendered: action.payload,
				hasSearchQueryRendered: chapterHistoryState.hasSearchQueryRendered,
			};
		}
		case 'SET_SEARCH_URL_QUERY_RENDERED': {
			return {
				hasUrlQueryRendered: chapterHistoryState.hasUrlQueryRendered,
				hasLibraryQueryRendered: chapterHistoryState.hasLibraryQueryRendered,
				hasSearchQueryRendered: action.payload,
			};
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

export const ChapterHistoryProvider = ({ children }) => {
	const [chapterHistoryState, chapterHistoryDispatch] = useReducer(reducer, initialState);

	return <ChapterHistoryContext.Provider value={{ chapterHistoryState, chapterHistoryDispatch }}>{children}</ChapterHistoryContext.Provider>;
};

import React, { useState, useEffect, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrashAlt } from '@fortawesome/fontawesome-free-solid';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';

const PlayersContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const PlayerContainer = styled.div`
    display: flex;
    justify-content: space-between;
	align-items: center;
`
const TaskInputForm = styled.form`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 5px 0;
	width: 100%;
`;
const TaskTextInput = styled.input`
	height: 36px;
	width: 80%;
	margin-left: 5px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
`;
const TaskButtonWrap = styled.div`
	display: flex;
	flex-direction: row;
`;
const SaveTaskButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 20px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 50%;
	margin: 0 5px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const CancelButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 15px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 50%;
	margin: 0 5px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const NewTaskButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 5px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const PlayerName = styled.p`
	margin-left: 5px;
	margin-bottom: 0;
	padding: 0;
	padding: 3px;
	width: 65%;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
	padding: 3px;
	font-weight: 400;
	border-radius: 3px;
	background: #f3f3f3;
	margin-bottom: 5px;
	width: 95%;
	&:hover {
		background-color: #ececec;
	}
	
`;
const InstructionP = styled.p`
	text-align: center;
	font-weight: 800;
`


const Players = props => {
    const {players, setPlayers, setView, setSelectedPlayer} = props
    // Global Storage
    //State
    const [playerText, setPlayerText] = useState('')
    const [hiddenInput, setHiddenInput] = useState(true)
    //Ref

    //Methods
    const handleSavePlayer = async (e) => {
        console.log('hitting save')
        e.preventDefault()
        fetch(`${process.env.REACT_APP_BASE_URL}/games/addPlayer/${props.userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: playerText }),
        })
            .then(res => res.json())
            .then(players => {
                setPlayers(players)
                setPlayerText('');
                setHiddenInput(true);
            });
    }

    const deletePlayer = async (playerId) => {
        console.log('hitting delete')
        fetch(`${process.env.REACT_APP_BASE_URL}/games/deletePlayer/${props.userId}/${playerId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => res.json())
            .then(players => {
                setPlayers(players)
                setPlayerText('');
                setHiddenInput(true);
            });
    }
    
    const mappedPlayers = players.length > 0 && players.map((player, ind) => {
        return (
        <PlayerContainer key={ind}>
            <PlayerName 
                onClick={() => {
                    setSelectedPlayer(player)
                    setView('landing')
                }}
            >
                {player.player_name}
            </PlayerName>
            <FontAwesomeIcon
							icon={faTrashAlt}
							onClick={() =>
								deletePlayer(player.id)
							}
						/>
        </PlayerContainer>
        )
    })
    return (
        <PlayersContainer>
			<InstructionP>Select a player to continue.</InstructionP>
            {mappedPlayers}
            {hiddenInput ? (
						<NewTaskButton type='button' onClick={() => setHiddenInput(false)}>
							+ Add a new Player
						</NewTaskButton>
					) : (
						<TaskInputForm onSubmit={handleSavePlayer}>
							<TaskTextInput placeholder={'Add player here...'} value={playerText} onChange={e => setPlayerText(e.target.value)} />
							<TaskButtonWrap>
								<SaveTaskButton type='submit' onClick={e => handleSavePlayer(e)}>
									Save
								</SaveTaskButton>
								<CancelButton
									type='button'
									onClick={() => {
										setPlayerText('');
										setHiddenInput(true);
									}}
								>
									Cancel
								</CancelButton>
							</TaskButtonWrap>
						</TaskInputForm>
					)}
        </PlayersContainer>
    );
};

export default Players;
import React, { useState, useEffect, useContext } from 'react';
import CustomCheckboxTree from '../Search/CustomCheckboxTree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import '../../assets/css/tags.css';
import { TagsContext } from '../../ContextProviders/TagsProvider';
import { NavContext } from '../../ContextProviders/NavProvider';

// The following styles are above the Tags & CN Titles Text.
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const TagsContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	/* Dont think the line under me is doing anything? */
	/* height: var(--title-height); */
`;

const TagsText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6a7583;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 23px;
`;

const SearchBarContainer = styled.div`
	position: relative;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
`;

const SearchIconWrapper = styled.div`
	color: #7d8085;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
// The following are the Tag & CN Title text and below styles.
const TagCNContainer = styled.div`
	display: flex;
	padding-bottom: 13px;
	height: 85%;
	min-width: 315px;
`;

const TagPaneTitles = styled.div`
	display: flex;
`;

const TagHeader = styled.h3`
	font-size: 24px;
	line-height: 32px;
	text-overflow: ellipsis;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	margin-bottom: 35px;
`;

const TagsCNTitleContainer = styled.div`
	width: 50%;
	height: 100%;
	visibility: visible;
	overflow: hidden;
`;

const CnTitleWrapper = styled.div`
	margin-left: 30px;
	width: 50%;
	height: 95%;
	visibility: visible;
`;

const TagsDisplayWrapper = styled.div`
	width: 100%;
`;

const CnDisplayWrapper = styled.div`
	width: 100%;
`;

const ScrollWrapper = styled.div`
	display: flex;
	width: 100%;
	background: #fafafa;
`;

const CNTitleContainer = styled.div`
	margin-bottom: 13px;
	cursor: pointer;
	font-size: 18px;
	line-height: 26px;
	font-weight: bold;
`;

const CNTitleDisplay = styled.span`
	color: #6a7583;
	font-size: 14px;
	&:hover {
		background-color: #ececec;
	}
`;

const Tags = props => {
	// Local State
	const [text, setText] = useState('');
	// Global State
	const { tagsState, tagsDispatch, fetchUserTagsList, fetchCollectionNotesByTagId, searchUserTagsList } = useContext(TagsContext);

	const { handleAddCollection } = useContext(NavContext);

	useEffect(() => {
		fetchUserTagsList({ uniqueKey: props.uniqueKey, userId: props.user.id });
	}, []);

	useEffect(() => {
		if (tagsState[props.uniqueKey] && tagsState[props.uniqueKey].text) {
			setText(tagsState[props.uniqueKey].text);
		}
	}, [tagsState[props.uniqueKey]]);

	const handleGetCollectionNotes = tag => {
		if (typeof tag.value === 'number' || !tag.value.includes('parent')) {
			fetchCollectionNotesByTagId({ uniqueKey: props.uniqueKey, value: tag.value });
			setTimeout(() => {
				const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + 500,
					behavior: 'smooth',
				});
			}, 100);
		}
	};

	const handleSubmit = event => {
		event.preventDefault();
		searchUserTagsList({ uniqueKey: props.uniqueKey, text: text, userId: props.user.id });
	};

	return (
		<>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon
						icon={faTimes}
						onClick={() => {
							props.closePane(props.id);
							tagsDispatch({ type: 'DELETE_TAGS_PANE', payload: { uniqueKey: props.uniqueKey } });
						}}
					/>
				</Tooltip>
			</ClosePaneWrapper>

			<TagsContainer>
				<TagsText {...props.dragHandleProps}>Tags</TagsText>
				<SearchBarContainer>
					<form onSubmit={handleSubmit}>
						<SearchInput
							type='text'
							name='text'
							value={text}
							onChange={e => e && setText(e.target.value)}
							autoComplete='off'
							autoFocus={false}
						/>
						<SearchIconWrapper>
							<FontAwesomeIcon icon={faSearch} onClick={event => handleSubmit(event)} />
						</SearchIconWrapper>
					</form>
				</SearchBarContainer>
			</TagsContainer>

			<TagPaneTitles>
				<TagHeader>Tags</TagHeader>
				<TagHeader>CN Titles</TagHeader>
			</TagPaneTitles>
			<TagCNContainer>
				<TagsCNTitleContainer>
					<Scrollbars>
						<ScrollWrapper>
							<TagsDisplayWrapper>
								{!tagsState[props.uniqueKey] || tagsState[props.uniqueKey].tags.length === 0 ? (
									<p>Tags not found</p>
								) : (
									<CustomCheckboxTree
										nodes={tagsState[props.uniqueKey].tags.sort((a, b) => {
											let aLabel = a.label.toLowerCase();
											let bLabel = b.label.toLowerCase();
											return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
										})}
										onClick={handleGetCollectionNotes}
										capFirstChar={true}
										onCheck={node => {}}
										checked={[]}
									/>
								)}
							</TagsDisplayWrapper>
						</ScrollWrapper>
					</Scrollbars>
				</TagsCNTitleContainer>
				<CnTitleWrapper>
					<Scrollbars>
						<CnDisplayWrapper>
							{tagsState[props.uniqueKey] &&
								tagsState[props.uniqueKey].collectionNotes
									.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
									.map((collectionNote, i) => {
										return (
											<CNTitleContainer
												key={i}
												onClick={e => {
													if (e.ctrlKey || e.metaKey) {
														return window.open(
															`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=${collectionNote.id}`,
															'_blank'
														);
													} else {
														handleAddCollection(collectionNote.id, props.uniqueKey);
													}
												}}
											>
												<CNTitleDisplay>{collectionNote.title}</CNTitleDisplay>
											</CNTitleContainer>
										);
									})}
						</CnDisplayWrapper>
					</Scrollbars>
				</CnTitleWrapper>
			</TagCNContainer>
		</>
	);
};

export default Tags;

import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faPlayCircle, faTrash, faClock } from '@fortawesome/fontawesome-free-solid/';
import { NavContext } from '../../ContextProviders/NavProvider';
import Tooltip from 'react-tooltip-lite';
import { PortalWithState } from 'react-portal';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
import PodcastLinks from './PodcastLinks';

const ChaptersNumbersWrapper = styled.li`
	// padding: 3px;
	// 	display: inline-block;
	// 	min-width: 40px;
	// 	cursor: pointer;
	// 	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	// 	font-size: 14px;
	// 	line-height: 20px;

	padding: 3px;
	display: flex;
	flex-direction: row;
	width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
`;

const ChapterNumber = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px;
	font-weight: 400;
	text-align: center;
	border-radius: 3px;
	min-width: 35px;
	min-height: 35px;
	max-width: 35px;
	max-height: 35px;
	background: #f3f3f3;
	&:hover {
		background-color: #ececec;
	}
`;
const ToggleOpenPaneWrapper = styled.div`
	display: flex;
	z-index: 1;
	align-items: center;
	font-size: 18px;
	max-height: 35px;
	color: #7d8085;
	cursor: pointer;
	margin-right: 2px;
	// border: 1px solid blue;
	&:hover {
		color: #1a56eb;
	}
`;

const LinksContainer = styled.div``;
const LinkSectionContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const LinkSection = styled.p`
	margin-right: 5px;
	width: 100px;
	font-size: 10px;
	overflow: hidden;
	wrap: nowrap;

	// border: 1px solid blue;
`;
const InputTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;
const InputWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const Input = styled.input`
	width: 100px;
	margin-right: 5px;
`;
const TextArea = styled.textarea`
	width: 100px;
	margin-right: 5px;
	height: 29.5px;
`;

const Text = styled.p`
	font-size: 10px;
	font-weight: bold;
	width: 100px;
	margin-right: 10px;
`;
const TextPlay = styled.p`
	font-size: 10px;
	font-weight: bold;
	width: 110px;
	margin-right: 10px;
`;

const PlayIcon = styled.div`
	// border: 1px solid red;
	width: 15px;
	margin-right: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -4px;
	height: 22px;
`;
const DeleteIconWrapper = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const TimeStampIcon = styled.button`
	width: 100px;
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	border: none;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const DeletePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 20%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	display: flex;
	flex-direction: column;
`;

const TimeStampArea = styled.textarea`
	height: 300px;
	margin-bottom: 10px;
`;
const AddLinksWrapper = styled.div`
	&:hover {
		cursor: pointer;
	}
`;

const PodcastChapter = props => {
	const [open, setOpen] = useState(false);
	const [podcastLinks, setPodcastLinks] = useState([]);
	const [showTitle, setShowTitle] = useState('');
	const [audioLink, setAudioLink] = useState('');
	const [videoLink, setVideoLink] = useState('');
	const [showNotesLink, setShowNotesLink] = useState('');
	const [otherLink, setOtherLink] = useState('');
	const [timeStamps, setTimeStamps] = useState('');

	const { dispatch } = useContext(NavContext);
	const { chapter, handleSelectChapter, selectedChapters, podcastId, chaptersWithLinks } = props;

	useEffect(() => {
		if (open && props.pullLinks) {
			fetchPodcastLinks();
			props.setPullLinks(false);
		}
	}, [props.pullLinks]);

	const retrieveChapterNumber = ({ order, search }) => {
		if (search.includes('Doctrine and Covenants') && (order === 139 || order === 140)) {
			if (order === 139) return 'OD1';
			return 'OD2';
		}
		return order;
	};

	const unique = () => {
		return new Date().getTime().toString(36);
	};

	const navScroll = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		panelsBlock.scroll({
			left: panelsBlock.scrollLeft + 500,
			behavior: 'smooth',
		});
	};
	const fetchPodcastLinks = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getChaptersPodcasts/${podcastId}/${chapter.id}`)
			.then(res => res.json())
			.then(podcasts => {
				setPodcastLinks(podcasts);
			});
	};
	const handleOpenChapter = async () => {
		if (!open) {
			await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getChaptersPodcasts/${podcastId}/${chapter.id}`)
				.then(res => res.json())
				.then(podcasts => {
					setPodcastLinks(podcasts);
					setOpen(true);
				});
		} else {
			setPodcastLinks([]);
			setOpen(false);
		}
	};
	const handleClearInputs = () => {
		setShowTitle('');
		setAudioLink('');
		setVideoLink('');
		setShowNotesLink('');
		setOtherLink('');
		setTimeStamps('');
	};
	const handleAddPodcastLinks = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/addSinglePodcastLInk/${podcastId}/${chapter.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ showTitle, audioLink, videoLink, showNotesLink, otherLink, timeStamps }),
		})
			.then(res => res.json())
			.then(podcasts => {
				props.fetchChaptersWithLinks();
				setPodcastLinks(podcasts);
				handleClearInputs();
			});
	};
	const handleDeleteLinks = async obj => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/deletePodcastLinks/${obj.id}/${obj.podcast_id}/${obj.chapter_id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(podcasts => {
				podcasts.length <= 0 && props.fetchChaptersWithLinks();
				setPodcastLinks(podcasts);
			});
	};

	const mappedPodcastLinks =
		podcastLinks.length > 0 &&
		podcastLinks.map((e, i) => {
			return (
				<PodcastLinks links={e} key={i} handleDeleteLinks={handleDeleteLinks} podcastId={podcastId} setPodcastLinks={setPodcastLinks} />
				// <LinkSectionContainer key={i}>
				//     <LinkSection>{e.show_title}</LinkSection>
				//     <LinkSection>{e.audio_link}</LinkSection>
				//     <PlayIcon>
				//         <FontAwesomeIcon icon={faPlayCircle} onClick={e => {
				//             if (e.audio_link.length > 0) {
				//                 e.stopPropagation();
				//                 e.persist();
				//                 if (e.ctrlKey || e.metaKey) {
				//                     return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
				//                 } else {
				//                     dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `podcastPreview_${unique()}`, type: 'podcastPreview', videoLink: videoLink, audioLink: audioLink, previewLinkType: 'audio' } });
				//                     navScroll();
				//                 }
				//             }
				//         }}
				//         />
				//     </PlayIcon>
				//     <LinkSection>{e.video_link}</LinkSection>
				//     <PlayIcon>
				//         <FontAwesomeIcon icon={faPlayCircle} onClick={e => {
				//             if (e.video_link.length > 0) {
				//                 e.stopPropagation();
				//                 e.persist();
				//                 if (e.ctrlKey || e.metaKey) {
				//                     return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
				//                 } else {
				//                     dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `podcastPreview_${unique()}`, type: 'podcastPreview', videoLink: videoLink, audioLink: audioLink, previewLinkType: 'video' } });
				//                     navScroll();
				//                 }
				//             }
				//         }}
				//         />
				//     </PlayIcon>
				//     <LinkSection>{e.show_notes_link}</LinkSection>
				//     <LinkSection>{e.other_link}</LinkSection>
				//     <PortalWithState closeOnOutsideClick closeOnEsc>
				//         {({ openPortal, closePortal, isOpen, portal }) => (
				//             <>
				//                 <TimeStampIcon>
				//                     <Tooltip content='Time Stamps'>
				//                         <FontAwesomeIcon key='ProjectPortal' icon={faClock} onClick={openPortal} />
				//                     </Tooltip>
				//                 </TimeStampIcon>
				//                 {portal(
				//                     <div>
				//                         <div
				//                             style={{
				//                                 top: 0,
				//                                 zIndex: 9999999,
				//                                 background: '#ddd',
				//                                 width: '100%',
				//                                 height: '100%',
				//                                 minHeight: '392px',
				//                                 opacity: '0.6',
				//                                 position: 'absolute',
				//                             }}
				//                         />

				//                         <DeletePopUpWrapper>
				//                             {/* <textarea>{e.time_stamps}</textarea> */}
				//                             <TimeStampArea value={e.time_stamps}></TimeStampArea>
				//                             <DeletePopUpBtnWrapper>

				//                                 <ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
				//                             </DeletePopUpBtnWrapper>
				//                         </DeletePopUpWrapper>
				//                     </div>
				//                 )}
				//             </>
				//         )}
				//     </PortalWithState>

				//     {/* <TimeStampIcon>
				//         <FontAwesomeIcon icon={faClock} />
				//     </TimeStampIcon> */}

				//     <DeleteIconWrapper onClick={() => handleDeleteLinks(e)}>
				//         <FontAwesomeIcon icon={faTrash} />
				//     </DeleteIconWrapper>
				// </LinkSectionContainer>
			);
		});
	return (
		<ChaptersNumbersWrapper>
			<ChapterNumber
				onClick={e => {
					handleSelectChapter(chapter);
				}}
				style={
					selectedChapters.some(e => e === chapter.id)
						? { backgroundColor: '#bdbdbd' }
						: chaptersWithLinks.some(e => e.chapter_id === chapter.id)
						? { backgroundColor: '#324599', color: '#fff' }
						: null
				}
			>
				{retrieveChapterNumber(chapter)}
			</ChapterNumber>
			<ToggleOpenPaneWrapper>
				<FontAwesomeIcon icon={!open ? faChevronRight : faChevronDown} onClick={() => handleOpenChapter()} />
			</ToggleOpenPaneWrapper>
			{open && (
				<LinksContainer>
					<InputTextWrapper>
						{/* <Text>Chapter</Text> */}
						<Text>Show Title</Text>
						<TextPlay>Audio Link</TextPlay>
						<TextPlay>Video Link</TextPlay>
						<Text>Show Notes URL</Text>
						<Text>Other</Text>
						<Text>Time Stamps</Text>
					</InputTextWrapper>
					{mappedPodcastLinks}

					<InputWrapper>
						<Input value={showTitle} onChange={e => setShowTitle(e.target.value)}></Input>
						<Input value={audioLink} onChange={e => setAudioLink(e.target.value)}></Input>
						<PlayIcon>
							<FontAwesomeIcon
								icon={faPlayCircle}
								onClick={e => {
									if (audioLink.length > 0) {
										e.stopPropagation();
										e.persist();
										if (e.ctrlKey || e.metaKey) {
											return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
										} else {
											dispatch({
												type: 'HANDLE_ADD_PANE',
												originPaneKey: props.uniqueKey,
												payload: {
													key: `podcastPreview_${unique()}`,
													type: 'podcastPreview',
													link: audioLink,
													podcast: '',
													episode: {},
													preview: true,
													linkType: 'audio',
												},
											});
											navScroll();
										}
									}
								}}
							/>
						</PlayIcon>
						<Input value={videoLink} onChange={e => setVideoLink(e.target.value)}></Input>
						<PlayIcon>
							<FontAwesomeIcon
								icon={faPlayCircle}
								onClick={e => {
									if (videoLink.length > 0) {
										e.stopPropagation();
										e.persist();
										if (e.ctrlKey || e.metaKey) {
											return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
										} else {
											dispatch({
												type: 'HANDLE_ADD_PANE',
												originPaneKey: props.uniqueKey,
												payload: {
													key: `podcastPreview_${unique()}`,
													type: 'podcastPreview',
													link: videoLink,
													podcast: '',
													episode: {},
													preview: true,
													linkType: 'video',
												},
											});
											navScroll();
										}
									}
								}}
							/>
						</PlayIcon>
						<Input value={showNotesLink} onChange={e => setShowNotesLink(e.target.value)}></Input>
						<Input value={otherLink} onChange={e => setOtherLink(e.target.value)}></Input>
						<PortalWithState closeOnOutsideClick closeOnEsc>
							{({ openPortal, closePortal, isOpen, portal }) => (
								<>
									<TimeStampIcon>
										<Tooltip content='Add Time Stamps'>
											<FontAwesomeIcon key='ProjectPortal' icon={faClock} onClick={openPortal} />
										</Tooltip>
									</TimeStampIcon>
									{portal(
										<div>
											<div
												style={{
													top: 0,
													zIndex: 9999999,
													background: '#ddd',
													width: '100%',
													height: '100%',
													minHeight: '392px',
													opacity: '0.6',
													position: 'absolute',
												}}
											/>

											<DeletePopUpWrapper>
												{/* <textarea>{e.time_stamps}</textarea> */}
												<TimeStampArea onChange={e => setTimeStamps(e.target.value)} value={timeStamps}></TimeStampArea>
												<DeletePopUpBtnWrapper>
													<ClosePopUpBtn onClick={closePortal}>Save</ClosePopUpBtn>
													<DeletePopUpBtn
														onClick={() => {
															closePortal();
															setTimeStamps('');
														}}
													>
														Cancel
													</DeletePopUpBtn>
												</DeletePopUpBtnWrapper>
											</DeletePopUpWrapper>
										</div>
									)}
								</>
							)}
						</PortalWithState>
						<AddLinksWrapper>
							<Tooltip content='Add Links' direction='up' forceDirection={true}>
								<FontAwesomeIcon icon={['fas', 'plus-circle']} size={'2x'} color={'#324599'} onClick={() => handleAddPodcastLinks()} />
							</Tooltip>
						</AddLinksWrapper>
					</InputWrapper>
				</LinksContainer>
			)}
		</ChaptersNumbersWrapper>
	);
};
export default PodcastChapter;

import React, { useReducer, createContext } from 'react';

export const VersesContext = createContext();

const initialState = {};
function removeDuplicates(array, key) {
	let lookup = new Set();
	return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
}
const reducer = (state, action) => {
	switch (action.type) {
		case 'GET_VERSES':
			return {
				...state,
				[action.payload.uniqueKey]: {
					verses: action.payload.verses,
				},
			};
		case 'GET_NEXT_VERSES':
			return {
				...state,
				[action.payload.uniqueKey]: {
					verses: action.payload.verses,
				},
			};
		case 'SET_VOLUMES_BOOKS_CHAPTERS':
			return {
				...state,
				[action.payload.uniqueKey]: {
					verses: state[action.payload.uniqueKey].verses,
					volumes: action.payload.volumes,
					books: [],
					chapters: [],
				},
			};
		case 'FILTER_BOOKS_BY_VOLUME':
			return {
				...state,
				[action.payload.uniqueKey]: {
					verses: state[action.payload.uniqueKey].verses,
					volumes: state[action.payload.uniqueKey].volumes,
					books: action.payload.books,
					chapters: state[action.payload.uniqueKey].chapters,
				},
			};
		case 'FILTER_CHAPTERS_BY_BOOK':
			return {
				...state,
				[action.payload.uniqueKey]: {
					verses: state[action.payload.uniqueKey].verses,
					volumes: state[action.payload.uniqueKey].volumes,
					books: state[action.payload.uniqueKey].books,
					chapters: action.payload.chapters,
				},
			};
		case 'GET_ALL_FOOTNOTES':
			let versesCopy = [...state[action.payload.uniqueKey].verses];
			let verseIndex = versesCopy.findIndex(verse => action.payload.verseId === verse.id);
			versesCopy[verseIndex].allFootnotes = action.payload.allFootnotes;
			return {
				...state,
				[action.payload.uniqueKey]: {
					verses: versesCopy,
					volumes: state[action.payload.uniqueKey].volumes,
					books: state[action.payload.uniqueKey].books,
					chapters: state[action.payload.uniqueKey].chapters,
				},
			};
		case 'UPDATE_VERSE_NOTE': {
			const { uniqueKey, index, noteText } = action.payload;
			let stateCopy = { ...state };
			if (stateCopy[uniqueKey].verses[index].verseNote) {
				stateCopy[uniqueKey].verses[index].verseNote.verse_note = noteText;
			} else stateCopy[uniqueKey].verses[index].verseNote = { verse_note: noteText };
			return stateCopy;
		}
		case 'UPDATE_VERSE_BOOKMARK': {
			const { uniqueKey, index, bookmarkId } = action.payload;
			let stateCopy = { ...state };
			stateCopy[uniqueKey].verses[index].bookmarkId = bookmarkId;
			return stateCopy;
		}
		case 'UPDATE_VERSE_MARKUP': {
			const { uniqueKey, text, verseId } = action.payload;
			let stateCopy = { ...state };
			const index = stateCopy[uniqueKey].verses.findIndex(({ id }) => id === parseInt(verseId));
			if (stateCopy[uniqueKey].verses[index].userMarkup) {
				stateCopy[uniqueKey].verses[index].userMarkup.verse_text_with_markup = text;
			} else stateCopy[uniqueKey].verses[index].userMarkup = { verse_text_with_markup: text };
			return stateCopy;
		}
		case 'DELETE_VERSES_PANE': {
			const { uniqueKey } = action.payload;
			let stateCopy = { ...state };
			delete stateCopy[uniqueKey];
			return stateCopy;
		}
		default:
			return state;
	}
};

export const VersesProvider = ({ children }) => {
	const [versesState, versesDispatch] = useReducer(reducer, initialState);

	const getVerses = async obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getVerses/${obj.userId}/${obj.bookId}/${obj.chapterId}`)
			.then(res => res.json())
			.then(verses => {
				versesDispatch({ type: 'GET_VERSES', payload: { uniqueKey: obj.uniqueKey, verses: removeDuplicates(verses, 'id') } });
			});
	};
	const getVersesNonUser = async obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getVersesNonUser/${obj.bookId}/${obj.chapterId}`)
			.then(res => res.json())
			.then(verses => {
				versesDispatch({ type: 'GET_VERSES', payload: { uniqueKey: obj.uniqueKey, verses: removeDuplicates(verses, 'id') } });
			});
	};

	const getAllFootnotes = async obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getAllFootnotes/${obj.userId}/${obj.verseId}`)
			.then(res => res.json())
			.then(allFootnotes => {
				versesDispatch({ type: 'GET_ALL_FOOTNOTES', payload: { uniqueKey: obj.uniqueKey, allFootnotes: allFootnotes, verseId: obj.verseId } });
			});
	};

	const handleChooseChapter = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getVerses/${obj.userId}/${obj.bookId}/${obj.chapterId}`)
			.then(res => res.json())
			.then(verses => {
				versesDispatch({ type: 'GET_VERSES', payload: { uniqueKey: obj.uniqueKey, verses: removeDuplicates(verses, 'id') } });
			});
	};

	const handleChooseChapterByNumber = obj => {
		return fetch(`${process.env.REACT_APP_BASE_URL}/verses/getVerses/${obj.userId}/${obj.bookId}/${obj.chapterNumber}/true`)
			.then(res => res.json())
			.then(verses => {
				versesDispatch({ type: 'GET_VERSES', payload: { uniqueKey: obj.uniqueKey, verses: removeDuplicates(verses, 'id') } });
				if (verses && verses.length) {
					return verses[0].chapter_id;
				} else return null;
			});
	};

	const handleSetVolumesBooksNChapters = async obj => {
		const data = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getUserVolumes/${obj.userId}`)
			.then(res => res.json())
			.then(userVolumes => userVolumes);
		// const volumes = await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getAllVolumes`)
		// 	.then(response => response.json())
		// 	.then(volumes => volumes);

		// const books = await fetch(`${process.env.REACT_APP_BASE_URL}/books/getAllBooks`)
		// 	.then(response => response.json())
		// 	.then(books => books);

		// const chapters = await fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getAllChapters`)
		// 	.then(response => response.json())
		// 	.then(chapters => chapters);

		versesDispatch({
			type: 'SET_VOLUMES_BOOKS_CHAPTERS',
			payload: { uniqueKey: obj.uniqueKey, volumes: data.volumes, books: data.books, chapters: data.chapters },
		});
	};

	const handleSetBooks = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getBooksByVolumeId/${obj.volumeId}`)
			.then(response => response.json())
			.then(books => versesDispatch({ type: 'FILTER_BOOKS_BY_VOLUME', payload: { uniqueKey: obj.uniqueKey, books: books } }));
	};

	const handleSetChapters = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/chapters/getChaptersByBookId/${obj.bookId}`)
			.then(response => response.json())
			.then(chapters => versesDispatch({ type: 'FILTER_CHAPTERS_BY_BOOK', payload: { uniqueKey: obj.uniqueKey, chapters } }));
	};

	const updateUserChapterNote = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/userchapternote/saveUserChapterNote/${obj.userId}/${obj.chapterId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: obj.chapterNoteText,
			}),
		}).then(res => res.json());
	};

	return (
		<VersesContext.Provider
			value={{
				versesState,
				versesDispatch,
				getVerses,
				handleChooseChapter,
				handleChooseChapterByNumber,
				handleSetVolumesBooksNChapters,
				handleSetBooks,
				handleSetChapters,
				updateUserChapterNote,
				getAllFootnotes,
				getVersesNonUser,
			}}
		>
			{children}
		</VersesContext.Provider>
	);
};

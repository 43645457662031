import React, { useState, useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { AuthContext } from '../../ContextProviders/AuthProvider';

import styled from 'styled-components';
import { useEffect } from 'react';

const PaneWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const VolumesWrapper = styled.ul`
	padding-left: 0;
	margin-bottom: 60px;
	list-style: none;
`;

const VolumesContainer = styled.li`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
	display: flex;
	width: 90%;
	justify-content: space-between;
`;
const LibraryVolumeContainer = styled.div`
	padding-left: 25px;
	position: relative;
	padding-bottom: 13px;
	height: 97%;
	min-width: 315px;
`;

const SaveButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const SaveButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 5px;
	line-height: 10px;
	font-weight: 700;
	font-size: 15px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	width: 40%;
	height: 80%;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;

const UserLibrary = props => {
	const [selectedVolumes, setSelectedVolumes] = useState([]);
	const [volumes, setVolumes] = useState([]);
	const { isProUser } = useContext(AuthContext);
	useEffect(() => {
		fetchAllVolumes();
		setSelectedVolumes(props.volumes.map(e => e.id));
	}, []);
	const handleSelectVolume = (volumeId, pro_volume) => {
		if (selectedVolumes.includes(volumeId)) {
			let volumesCopy = [...selectedVolumes];
			const index = volumesCopy.findIndex(e => e === volumeId);
			if (index !== -1) {
				volumesCopy.splice(index, 1);
				setSelectedVolumes([...volumesCopy]);
			}
		} else {
			if (pro_volume) {
				isProUser() ? setSelectedVolumes([...selectedVolumes, volumeId]) : alert('Must be a pro user to select pro volumes.');
			}
			if (!pro_volume) setSelectedVolumes([...selectedVolumes, volumeId]);
		}
	};
	const fetchAllVolumes = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/getAllVolumes`)
			.then(response => response.json())
			.then(volumes => {
				return setVolumes(volumes);
			});
	};
	const handleSaveList = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/volumes/saveUserVolumes/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				volumesList: selectedVolumes,
			}),
		})
			.then(res => res.json())
			.then(res => {
				props.fetchVolumesBooksNChapters(props.uniqueKey, props.user.id);
				// props.handleSetVolumes(res)
				props.libraryDispatch({
					type: 'SET_SEARCH',
					payload: { search: ``, uniqueKey: props.uniqueKey },
				});
				props.setDisplayUserLibrary(false);
			});
	};
	return (
		<>
			<PaneWrapper>
				<SaveButtonContainer>
					<SaveButton onClick={() => handleSaveList()}>Save</SaveButton>
				</SaveButtonContainer>
				<LibraryVolumeContainer>
					<Scrollbars>
						<VolumesWrapper>
							{volumes.map((volume, id) => {
								if (
									volume.public ||
									props.user.email === 'test@email.com' ||
									props.user.email === 'oak@oaknorton.com' ||
									props.user.email === 'todd@brightbridgeweb.com'
								) {
									return (
										<VolumesContainer
											key={id}
											onClick={() => {
												handleSelectVolume(volume.id, volume.pro_volume);
											}}
										>
											<h5>{volume.title}</h5>
											<input
												type='checkbox'
												onChange={() => handleSelectVolume(volume.id, volume.pro_volume)}
												checked={selectedVolumes.includes(volume.id)}
											/>
										</VolumesContainer>
									);
								}
							})}
						</VolumesWrapper>
					</Scrollbars>
				</LibraryVolumeContainer>
			</PaneWrapper>
		</>
	);
};

export default UserLibrary;

import React, { useState, useContext, useEffect, useRef } from 'react';
import CollectionNoteVerse from '../CollectionNote/CollectionNoteVerse';
import Loading from '../Helpers/Loading';
import { Resizable } from 're-resizable';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import jsPDF from 'jspdf';
import { PortalWithState } from 'react-portal';
import _ from 'lodash';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import Switch from 'react-switch';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, ClosePopUpBtn, DeletePopUpWrapper } from '../Shared/SharedStyles';
import {
	faTimes,
	faSearch,
	faPrint,
	faChevronRight,
	faChevronLeft,
	faEllipsisV,
	faClone,
	faFileAlt,
	faCopy,
	faShareAlt,
} from '@fortawesome/fontawesome-free-solid/';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { SearchContext } from '../../ContextProviders/SearchProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { BulkMoveContext } from '../../ContextProviders/BulkMoveProvider';
import {
	removeMarkTag,
	bbwBold,
	bbwUnderline,
	bbwItalic,
	bbwStrike,
	bbwProcessToggle,
	goodToProceedToggle,
	bbwClear,
	replaceAllMarks,
	bbwIsSelectionValid,
	unnecessaryTags,
} from '../BBWFormatter/BBWFormatter';
import { CollectionsContext } from '../../ContextProviders/CollectionsProvider';
const hoverColor = '#1a56eb';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: ${hoverColor};
	}
`;

const PrintIconWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 30px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;
const LinkIconWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 50px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: ${hoverColor};
	}
`;

const DraggableAreaWrapper = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	padding-top: 35px;
	width: calc(100% - 25px - 30px);
	height: 130px;
`;

const LdsFootnoteSwitch = styled.label`
	position: absolute;
	left: 0px;
	top: 10px;
	text-align: center;
	padding: 0px;
	display: flex;
	justify-content: center;
	font-size: 13px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	line-height: 16px;
	z-index: 100;
`;

const SearchNCreateWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	// justify-content: space-around;
`;

const SearchWrapper = styled.div`
	padding: 0px 10px 0px 10px;
`;

// const SearchForm = styled.form`
const SearchForm = styled.div`
	display: inline-flex;
	width: 100%;
	margin-bottom: 0;
	line-height: 32px;
`;

const SearchBarContainer = styled.div`
	position: relative;
	width: 100%;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
	font-size: 16px;
	padding-left: 10px;
`;

const SearchIconWrapper = styled.div`
	color: #7d8085;
	position: absolute;
	right: 10px;
	bottom: 2px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
	z-index: 1000px;
`;

const CreateCnWrapper = styled.div`
	text-align: right;
	width: 35%;
	padding: 0px 10px 0px 10px;
`;

// const LoadMoreWrapper = styled.div`
// 	width: 75%;
// 	padding: 0px 10px 0px 10px;
// 	margin: 16px auto;
// `;

const CreateCnBtn = styled.button`
	padding: 12px 5px;
	line-height: 10px;
	width: 100%;
	font-size: 15px;
	max-width: none;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	font-weight: 700;
	cursor: pointer;
	&:hover {
		color: #fff;
		background-color: #324599;
	}
	&:focus {
		outline: none;
	}
`;

const SearchResultWrapper = styled.div`
	position: relative;
	width: 100%;
	display: inline;
	align-items: flex-end;
	flex-direction: column;
	margin-top: 15px;
`;

const ToggleOpenPaneWrapper = styled.div`
	// position: absolute;
	display: flex;
	width: 100%;
	padding-left: 16px;
	justify-content: space-between;
	align-items: center;
	// right: 0;
	font-size: 22px;
	color: #7d8085;
	// cursor: pointer;
	padding-right: 10px;
	&:hover {
		// 	color: #1a56eb;
		cursor: grab;
	}
`;

const ExpandPaneIcon = styled.div`
	&:hover {
		color: #1a56eb;
		cursor: pointer;
	}
`;

// const ToggleOpenPaneWrapper = styled.span`
// 	position: absolute;
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	right: 0;
// 	font-size: 22px;
// 	color: #7d8085;
// 	cursor: pointer;
// 	padding-right: 10px;
// 	&:hover {
// 		color: #1a56eb;
// 	}
// `;

const RenderResultsWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

// Render Results Styles

const RenderResultsDisplayWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const LoadMoreBtnWrapper = styled.div`
	padding-top: 10px;
`;

const LoadMoreBtn = styled.button`
	display: block;
	margin: 0px auto;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 5px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 100%;
	max-width: 155px;
	&:hover {
		color: #fff;
		background-color: #324599;
	}
`;

const PaneExtendWrapper = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	margin-top: 15px;
`;

const SearchResultNumberText = styled.div`
	position: absolute;
	left: 0;
`;

const CnDisplayContainer = styled.div`
	position: relative;
	padding-bottom: 13px;
	height: 100%;
	min-width: 315px;
`;

const PaddingLeftNRightWrapper = styled.div`
	padding-left: 15px;
	padding-right: 15px;
`;

const CnTitleNDescContainer = styled.ul`
	padding-left: 0px;
	margin-bottom: 60px;
	list-style: none;
`;

const CnTitleNDescMap = styled.div`
	margin-bottom: 13px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 18px;
	line-height: 26px;
`;

const CnTitle = styled.span`
	color: #5a5a5a;
	font-size: 14px;
	&:hover {
		color: #bbb3b5;
	}
`;

const CnDescription = styled.p`
	font-style: italic;
	font-size: 14px;
`;

const TotalResults = styled.div`
	font-size: 14px;
	margin-right: 48px;
`;

const MultiDragWrapper = styled.div`
	display: flex;
	cursor: pointer;
	font-size: 18px;
`;
const DraggingItem = styled.div`
	background-color: transparent;
	height: 50px;
	width 50px;
	color: #6a7583;
	font-size: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 150px;
	padding-bottom: 50px;
`;
const TextFormatter = styled.div`
	position: absolute;
	background-color: #444444;
	border-radius: 13px;
	padding: 2px 15px;
	z-index: 999;
`;
const FormatBtn = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	outline: 0;
	font-family: serif;
`;
const FormatBtnBold = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
`;
const FormatBtnBgColor = styled.button`
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
	background-color: #727272;
	padding: 0 2px;
	line-height: 16px;
	margin: 0 4px;
`;

const FormatBtnItalic = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	font-family: serif;
`;
const FormatBtnClear = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	font-style: italic;
	outline: 0;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	font-family: serif;
`;
const FormatBtnUnderline = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	text-decoration: underline;
	outline: 0;
	font-family: serif;
`;
const FormatBtnDobUnderline = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	text-decoration: underline;
	outline: 0;
	font-family: serif;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
`;
const FormatBtnColor = styled.button`
	background-color: transparent;
	border: none;
	color: #cacaca;
	font-weight: 500;
	outline: 0;
	font-family: serif;
	border-bottom: solid #cacaca 1px;
	padding: 0 1px;
	margin: 0 4px;
	line-height: 17.2px;
	position: relative;
`;
const TextColorWrapper = styled.ul`
	position: absolute;
	list-style: none;
	padding: 4px;
	margin: 0;
	width: 96px;
	top: 23px;
	z-index: 99;
	background-color: #444444;
	text-align: left;
	display: none;
`;
const TextColorHolder = styled.li`
	display: inline-block;
	width: 15px;
	height: 15px;
	margin: 2px;
`;
const ShareLink = styled.p`
	overflow-wrap: break-word;
	word-wrap: break-word;
	&:hover {
		cursor: pointer;
	}
`;
const ShareLinkTitle = styled.h3`
	color: #324599;
`;
const EditButtonsContainer = styled.div`
	width: 100%;
	display: flex;
`;
// const getListStyle = isDraggingOver => ({
// 	background: isDraggingOver && 'lightblue',
// 	padding: '5px',
// 	paddingTop: '10px',
// });

const SearchResultList = props => {
	const [show, setShow] = useState(false);
	const [expandedVerses, setExpandedVerses] = useState(false);
	const [width, setWidth] = useState(430);
	const [searchText, setSearchText] = useState(props.searchText);
	const [collectionList, setCollectionList] = useState(null);
	const [verses, setVerses] = useState([]);
	const [toggleLdsFootnotes, setToggleLdsFootnotes] = useState(false);
	const [detailedVerseCount, setDetailedVerseCount] = useState(50);
	const [uniqueKeyRetrieved, setUniqueKeyRetrieved] = useState(null);
	const [bulkMove, setBulkMove] = useState(false);
	const [selectAllVerses, setSelectAllVerses] = useState(false);
	const [affiliateCode, setAffiliateCode] = useState('');
	const [seenList, setSeenList] = useState([]);
	const { searchState, searchDispatch, getSearchResults, getSearchResultsNonUser } = useContext(SearchContext);
	const { handleAddCollection, handleAddSearchResult, handleAddNewCollectionNote, state, dispatch } = useContext(NavContext);
	const { authState, isProUser } = useContext(AuthContext);
	const { bulkMoveState, addVerse, removeVerse, bulkMoveDispatch } = useContext(BulkMoveContext);
	const { updateVerseNote, saveUserFootnote } = useContext(CollectionsContext);
	const textFormatter = useRef(0);
	const showTextColors = useRef(0);
	const showTextBgColors = useRef(0);
	const verseScrollRef = useRef(0);
	// try {
	// 	textFormatter.current.style.display = 'none';
	// } catch { }

	const replaceAll = (str, find, replace) => {
		return str.replace(new RegExp(find, 'g'), replace);
	};

	const makeClear = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwClear();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		//return;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};

		//newTextVar=replaceAll(newTextVar,"<div>","");
		//newTextVar=replaceAll(newTextVar,"</div>","");
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');

		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		searchDispatch({
			type: 'UPDATE_SEARCH_VERSE_MARKUP',
			payload: {
				uniqueKey: props.uniqueKey,
				index: props.index,
				text: newTextVar,
				verseId,
			},
		});
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const makeBold = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		// if(!goodToProceedToggle()){
		//   alert("Invalid selection");
		//   return;
		// }

		bbwBold();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;

		//return;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};

		//newTextVar=replaceAll(newTextVar,"<div>","");
		//newTextVar=replaceAll(newTextVar,"</div>","");
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');

		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		searchDispatch({
			type: 'UPDATE_SEARCH_VERSE_MARKUP',
			payload: {
				uniqueKey: props.uniqueKey,
				index: props.index,
				text: newTextVar,
				verseId,
			},
		});
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const makeItalic = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwItalic();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		searchDispatch({
			type: 'UPDATE_SEARCH_VERSE_MARKUP',
			payload: {
				uniqueKey: props.uniqueKey,
				index: props.index,
				text: newTextVar,
				verseId,
			},
		});
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};
	const makeUnderline = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwUnderline();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		searchDispatch({
			type: 'UPDATE_SEARCH_VERSE_MARKUP',
			payload: {
				uniqueKey: props.uniqueKey,
				index: props.index,
				text: newTextVar,
				verseId,
			},
		});
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};

	const makeDobUnderline = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		bbwStrike();

		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		searchDispatch({
			type: 'UPDATE_SEARCH_VERSE_MARKUP',
			payload: {
				uniqueKey: props.uniqueKey,
				index: props.index,
				text: newTextVar,
				verseId,
			},
		});
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};

	const findMeaning = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;
		window.open('https://webstersdictionary1828.com/Dictionary/' + finalStr, '_blank');
	};
	const findLexicon = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.blueletterbible.org/search/search.cfm?Criteria=' + finalStr + '&t=KJV&lexcSt=2#s=s_lexiconc', '_blank');
	};
	const findThesaurus = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.powerthesaurus.org/' + finalStr + '/synonyms', '_blank');
	};
	const findEtymology = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.appendChild(selectedText);
		selection.insertNode(span);

		let finalStr = span.innerText;

		window.open('https://www.etymonline.com/search?q=' + finalStr, '_blank');
	};

	const adTextColors = color => {
		// alert(color)
		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');

		span.append(selectedText);

		var spansList = span.querySelectorAll('span');
		for (var i = 0; i < spansList.length; i++) {
			spansList[i].style.removeProperty('color');
		}

		span.setAttribute('style', 'color:' + color);

		selection.insertNode(span);
		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		searchDispatch({
			type: 'UPDATE_SEARCH_VERSE_MARKUP',
			payload: {
				uniqueKey: props.uniqueKey,
				index: props.index,
				text: newTextVar,
				verseId,
			},
		});
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};

	const adTextBgColors = color => {
		// alert(color)
		var selection = window.getSelection().getRangeAt(0);
		var selectedText = selection.extractContents();
		var span = document.createElement('span');
		span.append(selectedText);

		var spansList = span.querySelectorAll('span');
		for (var i = 0; i < spansList.length; i++) {
			spansList[i].style.removeProperty('background-color');
		}

		span.setAttribute('style', 'background-color:' + color);

		selection.insertNode(span);
		var liId = textFormatter.current.getAttribute('data-id');
		var userId = textFormatter.current.getAttribute('data-userid');
		var verseId = textFormatter.current.getAttribute('data-verseid');
		var newTextVar = document.getElementById(liId).innerHTML;
		const delta = {
			ops: [
				{
					retain: 4,
				},
				{
					retain: 7,
					attributes: {
						bold: true,
					},
				},
			],
		};
		newTextVar = replaceAllMarks(newTextVar, unnecessaryTags, '');
		saveUserFootnote({
			userId: userId,
			delta: delta,
			text: newTextVar,
			verseId: verseId,
		});
		searchDispatch({
			type: 'UPDATE_SEARCH_VERSE_MARKUP',
			payload: {
				uniqueKey: props.uniqueKey,
				index: props.index,
				text: newTextVar,
				verseId,
			},
		});
		props.socket.emit('update_verse_markup', { verseId: verseId, markupText: newTextVar, userId: authState.user.id, paneId: props.uniqueKey });
	};

	const showColors = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		showTextBgColors.current.style.display = 'none';
		if (showTextColors.current.style.display == 'block') {
			showTextColors.current.style.display = 'none';
		} else {
			showTextColors.current.style.display = 'block';
		}
	};
	const showBgColors = () => {
		if (!bbwIsSelectionValid()) {
			// Kashif has added this line to make sure there is no invalid selection or multiple verses are selected.
			textFormatter.current.style.display = 'none';
			// alert('Invalid verse selection!');
			return;
		}

		showTextColors.current.style.display = 'none';
		if (showTextBgColors.current.style.display == 'block') {
			showTextBgColors.current.style.display = 'none';
		} else {
			showTextBgColors.current.style.display = 'block';
		}
	};
	useEffect(() => {
		authState.user.id && affiliateCheck();
		if (!authState.user.id) {
			dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `authPane_${unique()}`, type: 'authPane' } });
			dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `googleReviews_${unique()}`, type: 'googleReviews' } });
		}
	}, []);
	useEffect(() => {
		if (!uniqueKeyRetrieved) {
			if (authState.user.id) {
				getSearchResults({
					userId: props.user.id,
					uniqueKey: props.uniqueKey,
					searchText: props.searchText,
					searchSection: props.searchSection,
					categories: props.categories || null,
					tags: props.tags || null,
					books: props.books || null,
					volumesFilter: props.volumesFilter,
					isProUser: isProUser(),
				});
			} else {
				getSearchResultsNonUser({
					userId: props.user.id,
					uniqueKey: props.uniqueKey,
					searchText: props.searchText,
					searchSection: props.searchSection,
					categories: props.categories || null,
					tags: props.tags || null,
					books: props.books || null,
					volumesFilter: props.volumesFilter,
					isProUser: isProUser(),
				});
			}

			const socket = props.socket;

			if (props.uniqueKey) {
				setUniqueKeyRetrieved(true);

				socket.on('get_updated_verse_notes_' + authState.user.id, params => {
					getSearchResults({
						userId: props.user.id,
						uniqueKey: props.uniqueKey,
						searchText,
						searchSection: props.searchSection,
						categories: props.categories || null,
						tags: props.tags || null,
						books: props.books || null,
						isProUser: isProUser(),
					});
					// getSearchResults({ userId: props.user.id, uniqueKey: props.uniqueKey, searchText: props.searchText, searchSection: props.searchSection, categories: props.categories || null, tags: props.tags || null, books: props.books || null });
				});

				socket.on('get_updated_verse_markup_' + authState.user.id, params => {
					getSearchResults({
						userId: props.user.id,
						uniqueKey: props.uniqueKey,
						searchText,
						searchSection: props.searchSection,
						categories: props.categories || null,
						tags: props.tags || null,
						books: props.books || null,
						isProUser: isProUser(),
					});
					// getSearchResults({ userId: props.user.id, uniqueKey: props.uniqueKey, searchText: props.searchText, searchSection: props.searchSection, categories: props.categories || null, tags: props.tags || null, books: props.books || null });
				});

				return () => {
					socket.off('get_updated_verse_notes_' + authState.user.id);
					socket.off('get_updated_verse_markup_' + authState.user.id);
				};
			}
		}
	}, [props.uniqueKey, searchText, uniqueKeyRetrieved, authState]);

	useEffect(() => {
		if (['scripturesOnly', 'verseNotesOnly', 'footnote'].includes(props.searchSection)) {
			setCollectionList(null);
			setVerses(searchState[props.uniqueKey]);
		} else if (props.searchSection === 'collectionNotesOnly') {
			setVerses(null);
			setCollectionList(searchState[props.uniqueKey]);
		}
	}, [searchState[props.uniqueKey]]);
	useEffect(() => {
		if (authState.user.id) {
			getSearchResults({
				userId: props.user.id,
				uniqueKey: props.uniqueKey,
				searchText: props.searchText,
				searchSection: props.searchSection,
				categories: props.categories || null,
				tags: props.tags || null,
				books: props.books || null,
				volumesFilter: props.volumesFilter,
				isProUser: isProUser(),
			});
		}
	}, [authState.user.id]);
	useEffect(() => {
		setSearchText(props.searchText);
	}, [props.searchText]);
	const handleOnPrint = () => {
		const mappedVerses = verses.map((verse, i) => {
			const verseFormat = verseText => {
				let verseArr = verseText.split('.');
				verseArr[0] = `<b>${verseArr[0]}</b>`;
				return verseArr.join('.');
			};

			const verseText = verse.userMarkup ? verse.userMarkup.verse_text_with_markup : verse.text;
			// return 'blank';
			return `
			<p style='display: inline-block;'><span><b>${verse.chapter.book.title} ${verse.chapter.order}:</b></span>${verseFormat(verseText)}</p>
			<p style='margin-left: 10px;'>
			${
				verse.verseNote && verse.verseNote.verse_note
					? `<span style="font-weight: bold">Verse Notes: </span> ${verse.verseNote.verse_note.split(`\n`).join('<br />')}`
					: ''
			}</p>`;
		});
		{
			let child = window.open(`${searchText} report`, `Search ${searchText}`);
			child.document.write(`<html>
			  <head><title>${searchText} Report</title></head>
			  <body>
			  <h1>Search Results for: ${searchText}</h1>
			  <div style='margin-left: 20px;'>${mappedVerses.join('')}</div>
			  </body>
			 </html>`);
			child.document.close();
		}
		// let stringHtml = '',
		// 	doc = new jsPDF();

		// const hyphenFix = new RegExp(/—/g);
		// const apostropheFix = new RegExp(/’/g);

		// verses.forEach(value => {
		// 	stringHtml += `<p p > <b>${value.chapter.book.title} ${value.chapter.order}:${value.number}</b></p>
		// 	<p> ${value.userMarkup ? value.userMarkup.verse_text_with_markup : `${value.text}`}</p>
		// 	<p>${value.verseNote && value.verseNote.verse_note ? `<span style="font-weight: bold">Verse Notes: </span> ${value.verseNote.verse_note}` : ''}</p>`;
		// 	stringHtml = stringHtml.replace(hyphenFix, '-').replace(apostropheFix, "'");
		// });
		// const underlineText = new RegExp(/u>/g);
		// doc.setFontSize(22);
		// doc.text('Search results', 15, 15);
		// doc.fromHTML(stringHtml.replace(underlineText, 'strong>'), 15, 20, { width: 180 });

		// window.open(doc.output('bloburl'), '_blank');
	};
	/** Searches again if the user changes the searchText and submits */
	const redoSearch = () => {
		getSearchResults({
			userId: props.user.id,
			uniqueKey: props.uniqueKey,
			searchText: searchText,
			searchSection: props.searchSection,
			categories: props.categories || null,
			tags: props.tags || null,
			books: props.books || null,
			isProUser: isProUser(),
		});
	};

	/** Waits the given amount of milliseconds */
	const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

	/** Recursively renders all verses */
	const loadVerses = async currentVerses => {
		setVerses(currentVerses);
		if (currentVerses.length >= searchState[props.uniqueKey].length) return;
		let updatedVerses = [...currentVerses, ...searchState[props.uniqueKey].slice(currentVerses.length, currentVerses.length + 50)];
		await sleep(1000);
		loadVerses(updatedVerses);
	};

	const handleBulkMove = () => {
		if (bulkMove) {
			setBulkMove(false);
			setSelectAllVerses(false);
			bulkMoveDispatch({ type: 'HANDLE_CLEAR_VERSES' });
		} else setBulkMove(true);
	};
	/** Adds an additional 50 search result verses to the verses being displayed */
	const loadMoreVerses = () => {
		let updatedVerses = [...verses, ...searchState[props.uniqueKey].slice(verses.length, verses.length + 150)];
		setVerses(updatedVerses);
	};
	const copyToClipBoard = () => {
		navigator.clipboard.writeText(
			`https://app.scripturenotes.com/?pane=search&searchQuery=${searchText.split(' ').join('%20')}${
				affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''
			}`
		);
	};
	const fixText = htmlText => {
		const HTMLTagRegex = /(<([^>]+)>)/gi;
		const noHTMLTagRegex = htmlText.replace(HTMLTagRegex, '');

		const ampRegex = /\&(amp)\;/gi;
		const noAmpRegex = noHTMLTagRegex.replace(ampRegex, '&');

		const lessThanRegex = /\&(lt)\;/gi;
		const noLessThanRegex = noAmpRegex.replace(lessThanRegex, '<');

		const greaterThanRegex = /\&(gt)\;/gi;
		const noGreaterThanRegex = noLessThanRegex.replace(greaterThanRegex, '>');

		const spaceRegex = /\&(nbsp)\;/gi;
		const noSpaceRegex = noGreaterThanRegex.replace(spaceRegex, '');

		const truncatedText = noSpaceRegex.length > 45 ? noSpaceRegex.slice(0, 45) + '...' : noSpaceRegex;
		return truncatedText;
	};

	const orderVerses = verses => {
		if (verses && verses.length) {
			verses = _.sortBy(
				verses,
				[verse => verse.chapter.book.volume.order, verse => verse.chapter.book.order, verse => verse.chapter.order, verse => verse.number],
				['asc', 'asc', 'asc', 'asc']
			);
		}
		return verses;
	};

	/** Creates a new CN from the verses in the results */
	const createCollectionNote = () => {
		// if (verses) {
		handleAddNewCollectionNote(verses, props.uniqueKey);
		// }
	};

	const getListStyle = isDraggingOver => ({
		background: isDraggingOver && 'lightblue',
		padding: '5px 10px 5px 5px',
		zIndex: 1000,
	});
	const changeDropperWidth = () => {
		const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
		const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
		setTimeout(() => {
			droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
		}, 600);
	};
	const scrollit = uniqueKey => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;
		setTimeout(() => {
			var newwidth = children.offsetWidth;
			var nwidth = newwidth;
			nwidth = nwidth - oldwidth;
			var toscrollright = nwidth - rightposition;
			if (remaingLeft > nwidth && toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 500);
	};
	const scrollRight = () => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var panewidth = children.offsetWidth;

		var oldwidth = 425;
		var nwidth = panewidth;

		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - nwidth;
		var toscrollright = nwidth - rightposition;

		if (toscrollright > 0) {
			setTimeout(function () {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}, 100);
		}
	};
	const unique = () => {
		return new Date().getTime().toString(36);
	};

	const affiliateCheck = async () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${authState.user.email}`)
			.then(res => res.json())
			.then(userplan => {
				userplan.affliate_code ? setAffiliateCode(userplan.affliate_code) : setAffiliateCode('');
			});
	};
	const renderResults = (versesArr, collectionNotes) => {
		if (versesArr) {
			if (!_.isEmpty(versesArr)) {
				return (
					<Droppable key={props.uniqueKey} droppableId={props.uniqueKey} type='QUOTE'>
						{(dropProvided, snapshot) => (
							<div
								// <RenderResultsDisplayWrapper
								ref={dropProvided.innerRef}
								{...dropProvided.droppableProps}
								// {...dropProvided.droppablePlaceholder}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{versesArr.map((value, index) => {
									return (
										<Draggable
											disableInteractiveElementBlocking={true}
											key={`${props.uniqueKey}-${value.id}`}
											draggableId={`${props.uniqueKey}-${value.id}`}
											index={index}
										>
											{(dragProvided, snapshot) =>
												snapshot.isDragging ? (
													<DraggingItem ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
														<FontAwesomeIcon
															icon={
																bulkMoveState.length > 1 &&
																bulkMoveState.some(e => e.draggableId === `${props.uniqueKey}-${value.id}`)
																	? faCopy
																	: faFileAlt
															}
														/>
													</DraggingItem>
												) : (
													<div>
														{index === detailedVerseCount + 1 && (
															// <CreateCnBtn
															// 	type='button'
															// 	onClick={() => setDetailedVerseCount(detailedVerseCount + 50)}
															// 	style={{ marginBottom: '32px', marginTop: '24px' }}
															// >
															// 	Edit Next 50
															// </CreateCnBtn>
															<EditButtonsContainer>
																<CreateCnBtn
																	type='button'
																	onClick={() => setDetailedVerseCount(detailedVerseCount + 50)}
																	style={{ marginBottom: '32px', marginTop: '24px', marginRight: '5px' }}
																>
																	Edit Next 50
																</CreateCnBtn>
																<CreateCnBtn
																	type='button'
																	onClick={() => setDetailedVerseCount(verses.length)}
																	style={{ marginBottom: '32px', marginTop: '24px' }}
																>
																	Edit all
																</CreateCnBtn>
															</EditButtonsContainer>
														)}

														<CollectionNoteVerse
															key={value.id}
															verseScrollRef={verseScrollRef}
															textFormatter={textFormatter}
															index={index}
															verse={value}
															user={props.user}
															dragProvided={dragProvided}
															isDragging={snapshot.isDragging}
															toggleLdsFootnotes={toggleLdsFootnotes}
															uniqueKey={props.uniqueKey}
															isSimple={index > detailedVerseCount}
															isSearchResult={true}
															show={expandedVerses}
															socket={props.socket}
															bulkMoveState={bulkMoveState}
															addVerse={addVerse}
															removeVerse={removeVerse}
															draggableId={`${props.uniqueKey}-${value.id}`}
															bulkMove={bulkMove}
															selectAllVerses={selectAllVerses}
															bulkMoveModal={props.bulkMoveModal}
															canEdit={() => false}
															seenList={seenList}
															setSeenList={setSeenList}
															scrollRight={scrollRight}
															unique={unique}
															hideDone={props.hideDone}
															hideMine={props.hideMine}
															setHideDone={props.setHideDone}
															setHideMine={props.setHideMine}
														/>
													</div>
												)
											}
										</Draggable>
									);
								})}

								{dropProvided.placeholder}
							</div>
							// </RenderResultsDisplayWrapper>
						)}
					</Droppable>
				);
			} else {
				return <p>Empty</p>;
			}
		} else if (collectionNotes) {
			if (!_.isEmpty(collectionNotes)) {
				return (
					<CnDisplayContainer>
						<Scrollbars autoHide>
							<PaddingLeftNRightWrapper>
								<CnTitleNDescContainer>
									{!collectionNotes.length ? (
										<p>Collections not found</p>
									) : (
										collectionNotes.map(value => {
											return (
												<CnTitleNDescMap
													key={value.id}
													onClick={e => {
														if (e.ctrlKey || e.metaKey) {
															return window.open(
																`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&collectionId=${value.id}`,
																'_blank'
															);
														} else {
															handleAddCollection(value.id, props.uniqueKey);
														}
													}}
												>
													<CnTitle>{value.title}</CnTitle>
													<CnDescription>{fixText(value.text)}</CnDescription>
												</CnTitleNDescMap>
											);
										})
									)}
								</CnTitleNDescContainer>
							</PaddingLeftNRightWrapper>
						</Scrollbars>
					</CnDisplayContainer>
				);
			} else {
				return <p>Empty</p>;
			}
		} else {
			return <Loading />;
		}
	};

	return (
		<Resizable
			className='verses-resizable'
			size={{ width, height: '100%' }}
			style={{ zIndex: 0 }}
			minWidth={expandedVerses ? '45vw' : 400}
			maxWidth={'45vw'}
			enable={{ right: !expandedVerses ? true : false }}
			handleComponent={{
				right: !expandedVerses ? (
					<FontAwesomeIcon
						color='#b2b7be'
						icon={faEllipsisV}
						style={{
							top: '50%',
							right: '7px',
							position: 'absolute',
						}}
					/>
				) : null,
			}}
			onResizeStop={(e, direction, ref, d) => setWidth(width + d.width)}
		>
			<TextFormatter ref={textFormatter} style={{ display: 'none' }} className='TextFormatterClass'>
				<FormatBtn title='Websters 1828 Dict' onClick={findMeaning}>
					1828
				</FormatBtn>
				<FormatBtn title='Lexicon' onClick={findLexicon}>
					L
				</FormatBtn>
				<FormatBtn title='Thesaurus' onClick={findThesaurus}>
					T
				</FormatBtn>
				<FormatBtn title='Etymology' onClick={findEtymology}>
					E
				</FormatBtn>
				<FormatBtnBold id='toggleBold' data-do-bold='true' onClick={makeBold}>
					B
				</FormatBtnBold>
				<FormatBtnItalic id='toggleItalic' data-do-italic='true' onClick={makeItalic}>
					I
				</FormatBtnItalic>
				<FormatBtnUnderline id='toggleUnderline' data-do-underline='true' onClick={makeUnderline}>
					U
				</FormatBtnUnderline>
				<FormatBtnDobUnderline id='toggleStrike' data-do-strike='true' onClick={makeDobUnderline}>
					U
				</FormatBtnDobUnderline>
				<FormatBtnColor onClick={showColors}>
					A
					<TextColorWrapper ref={showTextColors}>
						<TextColorHolder
							onClick={() => {
								adTextColors('rgb(0, 0, 0)');
							}}
							style={{ backgroundColor: 'rgb(0, 0, 0)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextColors('rgb(0, 0, 255)');
							}}
							style={{ backgroundColor: 'rgb(0, 0, 255)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextColors('rgb(0, 176, 0)');
							}}
							style={{ backgroundColor: 'rgb(0, 176, 0)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextColors('rgb(255, 0, 0)');
							}}
							style={{ backgroundColor: 'rgb(255, 0, 0)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextColors('rgb(102, 0, 255)');
							}}
							style={{ backgroundColor: 'rgb(102, 0, 255)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextColors('rgb(227, 122, 7)');
							}}
							style={{ backgroundColor: 'rgb(227, 122, 7)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextColors('rgb(255, 255, 255)');
							}}
							style={{ backgroundColor: 'rgb(255, 255, 255)' }}
						></TextColorHolder>
					</TextColorWrapper>
				</FormatBtnColor>
				<FormatBtnBgColor onClick={showBgColors}>
					A
					<TextColorWrapper ref={showTextBgColors} style={{ width: '105px' }}>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(255, 123, 123)');
							}}
							style={{ backgroundColor: 'rgb(255, 123, 123)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(255, 219, 166)');
							}}
							style={{ backgroundColor: 'rgb(255, 219, 166)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(255, 255, 174)');
							}}
							style={{ backgroundColor: 'rgb(255, 255, 174)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(204, 254, 180)');
							}}
							style={{ backgroundColor: 'rgb(204, 254, 180)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(204, 224, 255)');
							}}
							style={{ backgroundColor: 'rgb(204, 224, 255)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(232, 198, 255)');
							}}
							style={{ backgroundColor: 'rgb(232, 198, 255)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(253, 200, 201)');
							}}
							style={{ backgroundColor: 'rgb(253, 200, 201)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(217, 179, 179)');
							}}
							style={{ backgroundColor: 'rgb(217, 179, 179)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(221, 221, 221)');
							}}
							style={{ backgroundColor: 'rgb(221, 221, 221)' }}
						></TextColorHolder>
						<TextColorHolder
							onClick={() => {
								adTextBgColors('rgb(0, 0, 0)');
							}}
							style={{ backgroundColor: 'rgb(0, 0, 0)' }}
						></TextColorHolder>
					</TextColorWrapper>
				</FormatBtnBgColor>
				<FormatBtnClear onClick={makeClear} title='Clean'>
					T<span style={{ fontSize: '10px', fontStyle: 'normal', fontWeight: '600' }}>x</span>
				</FormatBtnClear>
			</TextFormatter>
			<ReactCSSTransitionGroup
				transitionName='verse'
				transitionEnterTimeout={500}
				transitionLeaveTimeout={500}
				transitionAppear={true}
				transitionAppearTimeout={500}
			>
				<ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='left' forceDirection={true}>
						<FontAwesomeIcon
							icon={faTimes}
							onClick={() => {
								props.closePane(props.id);
								searchDispatch({ type: `DELETE_SEARCH_PANE`, payload: { uniqueKey: props.uniqueKey } });
							}}
						/>
					</Tooltip>
				</ClosePaneWrapper>
				<div>
					<PrintIconWrapper>
						<Tooltip content='Print Report' direction='left' forceDirection={true}>
							<FontAwesomeIcon icon={faPrint} onClick={() => (isProUser() ? handleOnPrint() : alert('Must be a Pro user for this feature'))} />
						</Tooltip>
					</PrintIconWrapper>
				</div>
				<div>
					<PortalWithState closeOnOutsideClick closeOnEsc>
						{({ openPortal, closePortal, isOpen, portal }) => (
							<>
								<LinkIconWrapper>
									<Tooltip content='Share Link' direction='left' forceDirection={true}>
										<FontAwesomeIcon icon={faShareAlt} onClick={openPortal} />
									</Tooltip>
								</LinkIconWrapper>
								{portal(
									<>
										<div
											style={{
												top: 0,
												zIndex: 9999999,
												background: '#ddd',
												width: '100%',
												height: '100%',
												minHeight: '392px',
												opacity: '0.6',
												position: 'absolute',
											}}
										/>

										<DeletePopUpWrapper>
											<ShareLinkTitle>Share this link.</ShareLinkTitle>
											<ShareLink
												className='share-link'
												onClick={() => {
													copyToClipBoard();
													closePortal();
												}}
											>
												{`https://app.scripturenotes.com/?pane=search&searchQuery=${searchText.split(' ').join('%20')}${
													affiliateCode.length > 0 ? `&via=${affiliateCode}` : ''
												}`}
											</ShareLink>
											<DeletePopUpBtnWrapper>
												<ClosePopUpBtn
													style={{ marginRight: '10px' }}
													onClick={() => {
														copyToClipBoard();
														closePortal();
													}}
												>
													Copy
												</ClosePopUpBtn>
												{/* <DeletePopUpBtn onClick={closePortal}>Close</DeletePopUpBtn> */}
											</DeletePopUpBtnWrapper>
										</DeletePopUpWrapper>
									</>
								)}
							</>
						)}
					</PortalWithState>
				</div>
				<div>
					<DraggableAreaWrapper {...props.dragHandleProps}>
						{/* <LdsFootnoteSwitch>
							Footnotes: User{' '}
							<Switch
								// onChange={() => setToggleLdsFootnotes(!toggleLdsFootnotes)}
								onChange={() => alert('Coming Soon')}
								checked={toggleLdsFootnotes}
								uncheckedIcon={false}
								checkedIcon={false}
								height={18}
								width={32}
							/>
							LDS
						</LdsFootnoteSwitch> */}
						<div>
							<SearchResultWrapper>
								{/* {verses && !verses.loading && (
									<SearchResultNumberText>
										Your search is displaying {this.props.verses.data.length} of {verses.found} total result&#40;s&#41;.
									</SearchResultNumberText>
								)}
								{collectionsList && collectionsList[this.props.componentId] && !collectionsList[this.props.componentId].loading && (
									<SearchResultNumberText>
										Your search returned {collectionsList[this.props.componentId].data.length} total result&#40;s&#41;.
									</SearchResultNumberText>
								)} */}
								{expandedVerses ? (
									<ToggleOpenPaneWrapper>
										<TotalResults>
											{props.searchSection === 'collectionNotesOnly'
												? collectionList
													? collectionList.length
													: 0
												: verses
												? verses.length
												: 0}{' '}
											Results
										</TotalResults>
										<MultiDragWrapper>
											<Tooltip content='Bulk Move' direction='up' forceDirection={true}>
												<FontAwesomeIcon icon={faClone} onClick={() => authState.user.id && handleBulkMove()} />
											</Tooltip>
											{bulkMove && (
												<Tooltip content='Select All Verses' direction='up' forceDirection={true}>
													<input
														style={{ marginLeft: '5px' }}
														type='checkbox'
														checked={selectAllVerses}
														onChange={() => setSelectAllVerses(!selectAllVerses)}
													/>
												</Tooltip>
											)}
										</MultiDragWrapper>
										<ExpandPaneIcon>
											<FontAwesomeIcon
												icon={faChevronLeft}
												onClick={() => {
													setShow(false);
													setExpandedVerses(false);
													setWidth(430);
													changeDropperWidth();
												}}
											/>
										</ExpandPaneIcon>
									</ToggleOpenPaneWrapper>
								) : (
									<ToggleOpenPaneWrapper>
										<TotalResults>
											{props.searchSection === 'collectionNotesOnly'
												? collectionList
													? collectionList.length
													: 0
												: verses
												? verses.length
												: 0}{' '}
											Verses
										</TotalResults>
										<MultiDragWrapper>
											<Tooltip content='Bulk Move' direction='up' forceDirection={true}>
												<FontAwesomeIcon icon={faClone} onClick={() => handleBulkMove()} />
											</Tooltip>
											{bulkMove && (
												<Tooltip content='Select All Verses' direction='up' forceDirection={true}>
													<input
														style={{ marginLeft: '5px' }}
														type='checkbox'
														checked={selectAllVerses}
														onChange={() => setSelectAllVerses(!selectAllVerses)}
													/>
												</Tooltip>
											)}
										</MultiDragWrapper>
										<ExpandPaneIcon>
											<FontAwesomeIcon
												style={{ '&hover': { cursor: 'help' } }}
												icon={faChevronRight}
												onClick={() => {
													if (authState.user.id) {
														setShow(true);
														setExpandedVerses(true);
														setWidth(1170);
														scrollit(props.uniqueKey);
														changeDropperWidth();
													}
												}}
											/>
										</ExpandPaneIcon>
									</ToggleOpenPaneWrapper>
								)}
							</SearchResultWrapper>
						</div>
						<div>
							<SearchNCreateWrapper>
								<SearchWrapper>
									<SearchForm>
										<SearchBarContainer>
											<form
												onSubmit={e => {
													e.preventDefault();
													e.stopPropagation();
													redoSearch();
												}}
											>
												<SearchInput
													id='redo-search-input'
													type='text'
													name='searchText'
													value={searchText || ''}
													onChange={e => {
														setSearchText(e.target.value);
													}}
													autoComplete='off'
													autoFocus={false}
												/>
											</form>
											<SearchIconWrapper>
												<FontAwesomeIcon
													icon={faSearch}
													onClick={e => {
														if (e.ctrlKey || e.metaKey) {
															return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=search&searchQuery=${searchText}`, '_blank');
														} else if (e.altKey && searchText) {
															handleAddSearchResult(
																props.uniqueKey,
																searchText,
																props.searchSection,
																props.categories,
																props.tags,
																props.books
															);
														} else {
															redoSearch();
														}
													}}
												/>
											</SearchIconWrapper>
										</SearchBarContainer>
									</SearchForm>
								</SearchWrapper>

								{/* {verses && verses.data.length !== 0 && verses.dataType === 'verses' ? ( */}
								{/* {props.searchSection === 'collectionNotesOnly' ? */}
								<CreateCnWrapper>
									<CreateCnBtn
										type='button'
										onClick={() => {
											authState.user.id ? createCollectionNote() : alert('Please sign in or sign up to use Scripture Notes.');
										}}
										// onClick={() => {
										// 	{
										// 		this.props.user.data.stripePlanName === 'Free'
										// 			? alert(`To be able to create new Collection Notes or edit existing ones, you'll need to subscribe.`)
										// 			: this.lessThanTotalPopUp();
										// 	}
										// }}
									>
										Create CN
									</CreateCnBtn>
								</CreateCnWrapper>
								{/* : null} */}
							</SearchNCreateWrapper>
						</div>
					</DraggableAreaWrapper>
				</div>
				{/* <div>Filters: {props.volumesFilter === 'scripturesOnly' ? 'Scripures Only' : 'All Volumes'}</div> */}
				<RenderResultsWrapper>
					<Scrollbars autoHide ref={verseScrollRef}>
						{renderResults(orderVerses(verses), collectionList)}
					</Scrollbars>
				</RenderResultsWrapper>
			</ReactCSSTransitionGroup>
		</Resizable>
	);
};

export default SearchResultList;

// Menu.js
import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCaretRight,
	faCaretLeft,
	faBookmark,
	faBookOpen,
	faNewspaper,
	faSearch,
	faSignOutAlt,
	faQuestionCircle,
	faTimes,
	faChartPie,
	faList,
	faMicrophone,
	faTv,
	faDice,
} from '@fortawesome/fontawesome-free-solid/';
import styled from 'styled-components';
import dashboardImage from '../../assets/logo_light.svg';
import { NavContext } from '../../ContextProviders/NavProvider';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import Modal from 'react-modal';
import '../../assets/css/menu.css';
import TrainingModal from './TrainingModal';
import AnalyticsModal from '../../components/Analytics/Analytics';
import axios from 'axios';

const NavContainer = styled.div`
	height: 100%;
	width: 85px;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #282c37;
	z-index: 99999;
	// border: 5px solid purple;
	display: flex;
	flex-direction: column;
`;

const ModalTop = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
`;

const NavLogoContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 80px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	text-align: center;
	background-color: #282c37;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.35);
	flex-shrink: 0; /* Prevent shrinking */
	&:hover {
		border-left: 4px solid #fff;
	}
`;

const NavLink = styled.div`
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	transition: background-color 0.15s, border-left-color 0.15s;
`;

const MainLinksContainer = styled.div`
	flex: 1;
	overflow: auto;
	// border: 3px solid green;
`;

const SecondaryLinksContainer = styled.div`
	min-height: 260px; /* Adjust this value as needed */
	height: auto;
	// border: 3px solid blue;
	// padding-bottom: 30px;
	width: 100%;
`;

const LinksWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
`;
const AILink = styled.p`
	color: #7e8087;
	font-weight: 700;
	font-size: 20px;
`;

const scrollToLeft = () => {
	const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
	panelsBlock.scroll({
		left: panelsBlock.scrollLeft - 300,
		behavior: 'smooth',
	});
};

const scrollToRight = () => {
	const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
	panelsBlock.scroll({
		left: panelsBlock.scrollLeft + 300,
		behavior: 'smooth',
	});
};

const navScroll = menukey => {
	if (menukey !== 'videoPlayer') {
		setTimeout(() => {
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			panelsBlock.scroll({
				left: panelsBlock.scrollLeft + draggablewrapper.offsetWidth,
				behavior: 'smooth',
			});
		}, 100);
	} else {
		setTimeout(() => {
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			panelsBlock.scroll({
				left: panelsBlock.scrollLeft - draggablewrapper.offsetWidth,
				behavior: 'smooth',
			});
		}, 100);
	}
};

const unique = () => {
	return new Date().getTime().toString(36);
};

const Menu = props => {
	const [isShow, setIsShow] = useState(false);
	const [isPodcastAdmin, setIsPodcastAdmin] = useState(false);
	const { dispatch } = useContext(NavContext);
	const { authState } = useContext(AuthContext);
	const [tutorials, setTutorials] = useState([]);
	const [helpModal, setHelpModal] = useState(false);
	const menuLinks = [
		{ name: 'Library', id: 'library', type: 'HANDLE_ADD_PANE', icon: faBookOpen, styleLine: 'library' },
		{ name: 'Search', id: 'search', type: 'HANDLE_ADD_PANE', icon: faSearch, styleLine: 'search' },
		{ name: 'Collection List', id: 'collectionList', type: 'HANDLE_ADD_PANE', icon: faNewspaper, styleLine: 'notes' },
		{ name: 'Tag Tree', id: 'tags', type: 'HANDLE_ADD_PANE', icon: faBookmark, styleLine: 'tags' },
		{ name: 'Todo List', id: 'todo', type: 'HANDLE_ADD_PANE', icon: faList, styleLine: 'todo' },
		{ name: 'Media Player', id: 'videoPlayer', type: 'HANDLE_ADD_PANE', icon: faTv, styleLine: 'videoPlayer' },
		{ name: 'Games', id: 'games', type: 'HANDLE_ADD_PANE', icon: faDice, styleLine: 'games' },
		{ name: 'Daniel', id: 'chatgpt', type: 'HANDLE_ADD_PANE', storyLine: 'chatgpt' },
	];

	const dateChange = new Date(authState.user?.created_at || '01 January 1970');
	const changedTime = dateChange.toISOString().slice(0, 23) + 0;
	const changedTimeMil = new Date(changedTime);
	const currentEpoch = Date.now();
	const startEpoch = changedTimeMil.getTime();

	useEffect(() => {
		if (
			authState.user.email == 'oak@oaknorton.com' ||
			authState.user.email == 'kashif@axx.com' ||
			authState.user.email == 'jmaxt12@gmail.com' ||
			authState.user.email == 'todd@brightbridgeweb.com' ||
			authState.user.email == 'aspennortonrocks@gmail.com' ||
			authState.user.email == 'willowtree013@gmail.com' ||
			authState.user.email == 'tardis904@gmail.com'
		) {
			setIsShow(true);
		}
		authState.user.id && adminCheck();
	}, []);

	useEffect(() => {
		if (authState.user?.id) {
			newUser();
			getTutorials();
		}
	}, []);

	const newUser = () => {
		currentEpoch - startEpoch < 604800000 && setHelpModal(true);
	};

	const getTutorials = async () => {
		await axios.get(`${process.env.REACT_APP_BASE_URL}/webHelp/getAllTutorials`).then(res => setTutorials(res.data));
	};
	const openTrainingPane = async url => {
		dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `videoPlayer_${unique()}`, type: 'videoPlayer', url } });
	};
	const adminCheck = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/adminCheck/${authState.user.email.toLowerCase()}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(res => {
				setIsPodcastAdmin(res);
			});
	};

	const logout = () => {
		localStorage.clear();
		localStorage.setItem('prev-email', authState.user.email);
		props.history.push('/login');
	};

	const menuItems = menuLinks.map((item, i) => {
		if (item.id == 'analytics') {
			if (authState.user.email == authState.user.email) {
				return (
					<NavLink
						key={i}
						className={item.styleLine}
						onClick={e => {
							if (authState.user.id) {
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=${item.id}`);
								} else {
									dispatch({ type: item.type, payload: { key: `${item.id}_${unique()}`, type: item.id } });
								}
								navScroll();
							}
						}}
						data-tip={item.name}
					>
						<FontAwesomeIcon icon={item.icon} color='#7e8087' />
						<ReactTooltip place='right' effect='solid' />
					</NavLink>
				);
			}
		} else if (item.id === 'chatgpt') {
			return (
				<NavLink
					key={i}
					className={item.styleLine}
					onClick={e => {
						if (authState.user.id) {
							e.stopPropagation();
							e.persist();
							if (e.ctrlKey || e.metaKey) {
								return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=${item.id}`);
							} else {
								dispatch({ type: item.type, payload: { key: `${item.id}_${unique()}`, type: item.id, verse: {} } });
							}
							item.id !== 'videoPlayer' ? navScroll('5454') : navScroll('videoPlayer');
						} else alert('Please sign in or sign up to use Scripture Notes.');
					}}
					data-tip={item.name}
				>
					<AILink>AI</AILink>
				</NavLink>
			);
		} else {
			return (
				<NavLink
					key={i}
					className={item.styleLine}
					onClick={e => {
						if (authState.user.id) {
							e.stopPropagation();
							e.persist();
							if (e.ctrlKey || e.metaKey) {
								return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=${item.id}`);
							} else {
								dispatch({ type: item.type, payload: { key: `${item.id}_${unique()}`, type: item.id } });
							}
							item.id !== 'videoPlayer' ? navScroll('5454') : navScroll('videoPlayer');
						} else alert('Please sign in or sign up to use Scripture Notes.');
					}}
					data-tip={item.name}
				>
					<FontAwesomeIcon icon={item.icon} color='#7e8087' />
					<ReactTooltip place='right' effect='solid' />
				</NavLink>
			);
		}
	});

	const [modalIsOpen, setIsOpen] = useState(false);
	function openModal() {
		setIsOpen(true);
	}

	function helpModalOpen() {
		setHelpModal(true);
	}

	function helpModalClosed() {
		setHelpModal(false);
	}

	function afterOpenModal() {}

	function closeModal() {
		setIsOpen(false);
	}

	const [trainingModalIsOpen, setTrainingModalIsOpen] = useState(false);
	const [analyticsModalIsOpen, setAnalyticsModalIsOpen] = useState(false);

	function handleTrainingModal() {
		setTrainingModalIsOpen(!trainingModalIsOpen);
	}

	function handleAnalyticsModal() {
		setAnalyticsModalIsOpen(!analyticsModalIsOpen);
	}

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			width: '50%',
		},
		overlay: { zIndex: 500 },
	};

	const trainingModalStyle = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-start',
		},
		overlay: { zIndex: 500 },
	};

	const analyticsModalStyle = {
		content: {
			top: '50%',
			width: '700px',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			height: '500px',
			padding: '30px',
			transform: 'translate(-50%, -50%)',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			outline: '0',
		},
		overlay: { zIndex: 500 },
	};

	const mappedTrainingModal = tutorials.map((e, i) => {
		return (
			<TrainingModal
				data={e}
				key={i}
				handleTrainingModal={handleTrainingModal}
				openTrainingPane={openTrainingPane}
				setTrainingModalIsOpen={setTrainingModalIsOpen}
			/>
		);
	});

	return (
		<NavContainer>
			<NavLogoContainer
				onClick={e => {
					e.stopPropagation();
					e.persist();
					if (e.ctrlKey || e.metaKey) {
						return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=dashboard`);
					} else {
						dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `dashboard_${unique()}`, type: 'dashboard' } });
						navScroll();
					}
				}}
				data-tip='Dashboard'
			>
				<img src={dashboardImage} alt='Dashboard Logo' width={70} />
			</NavLogoContainer>
			<ReactTooltip place='right' effect='solid' />
			<LinksWrapper>
				<MainLinksContainer>
					{menuItems}
					<div>
						<NavLink>
							<FontAwesomeIcon
								icon={faCaretLeft}
								color='#7e8087'
								data-tip='Scroll Left'
								style={{
									margin: '0 4px',
									fontSize: '1.8em',
								}}
								onClick={() => scrollToLeft()}
							/>
							<FontAwesomeIcon
								icon={faCaretRight}
								color='#7e8087'
								data-tip='Scroll Right'
								style={{
									margin: '0 4px',
									fontSize: '1.8em',
								}}
								onClick={() => scrollToRight()}
							/>
						</NavLink>
					</div>
					<NavLink>
						<FontAwesomeIcon
							icon={faTimes}
							color='#7e8087'
							data-tip='Close Panes'
							style={{
								margin: '0 4px',
								fontSize: '1.8em',
							}}
							onClick={() => (authState.user.id ? openModal() : alert('Please sign in or sign up to use Scripture Notes.'))}
						/>
						<Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}>
							<div>Are you sure you want to close all panes?</div>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<button
									style={{ margin: 10 }}
									onClick={() => {
										props.closeAllPanes(props.id);
										closeModal();
									}}
								>
									YES
								</button>
								<button style={{ margin: 10 }} onClick={closeModal}>
									NO
								</button>
							</div>
						</Modal>
						<Modal isOpen={helpModal} onAfterOpen={afterOpenModal} onRequestClose={helpModalClosed} style={customStyles}>
							Welcome to Scripture Notes. To get started, click the ? icon on the left and watch the training videos. This message will appear
							when you log in for your first week to remind you to do the training videos.
							<br />
							<br /> Then look for emails to arrive from oak@scripturenotes.com that contain tutorials and guidance on how to enhance your use
							of this powerful tool. <br />
							<br />
							If you don’t get emails from that address, check your spam or promotions folder and whitelist the address by adding it to your
							address book.
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<button style={{ margin: 10 }} onClick={helpModalClosed}>
									Okay
								</button>
							</div>
						</Modal>
					</NavLink>
				</MainLinksContainer>
				<SecondaryLinksContainer>
					{isShow ? (
						<NavLink>
							<FontAwesomeIcon
								data-tip='Podcasts'
								icon={faMicrophone}
								color='#7e8087'
								style={{
									height: '22px',
									width: '22px',
								}}
								onClick={e => {
									e.stopPropagation();
									e.persist();
									if (e.ctrlKey || e.metaKey) {
										return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcasts_list`);
									} else {
										dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `podcasts_list_${unique()}`, type: 'podcasts_list' } });
										navScroll();
									}
								}}
							/>
						</NavLink>
					) : (
						<NavLink></NavLink>
					)}
					{isShow ? (
						<NavLink>
							<FontAwesomeIcon
								data-tip='Analytics'
								icon={faChartPie}
								color='#7e8087'
								onClick={handleAnalyticsModal}
								style={{
									height: '22px',
									width: '22px',
								}}
							/>
						</NavLink>
					) : (
						<NavLink></NavLink>
					)}
					<NavLink>
						<FontAwesomeIcon
							data-tip='Help'
							icon={faQuestionCircle}
							color='#7e8087'
							onClick={handleTrainingModal}
							style={{
								height: '22px',
								width: '22px',
							}}
						/>
						<Modal isOpen={trainingModalIsOpen} style={trainingModalStyle}>
							<ModalTop>
								<h3>Training & Help Resources</h3>
								<FontAwesomeIcon icon={faTimes} color='#7e8087' className='back-btn' onClick={handleTrainingModal} />
							</ModalTop>
							{mappedTrainingModal}
						</Modal>
						<Modal isOpen={analyticsModalIsOpen} style={analyticsModalStyle}>
							<AnalyticsModal handleAnalyticsModal={handleAnalyticsModal} />
						</Modal>
					</NavLink>
					<NavLink>
						{authState.user.id && (
							<FontAwesomeIcon
								icon={faSignOutAlt}
								color='#7e8087'
								data-tip='Sign Out'
								style={{
									height: '30px',
									width: '30px',
								}}
								onClick={() => logout()}
							/>
						)}
					</NavLink>
				</SecondaryLinksContainer>
			</LinksWrapper>
		</NavContainer>
	);
};

export default Menu;

import React, { useState, useContext } from 'react';

// import ReactDOM from 'react-dom';

import { loadStripe } from '@stripe/stripe-js';

import styled from 'styled-components';

import { AuthContext } from '../../ContextProviders/AuthProvider';

// Make sure to call `loadStripe` outside of a component’s render to avoid

// recreating the `Stripe` object on every render.

const StripeBtn = styled.button`
	cursor: pointer;

	color: #000;

	border: 2px solid black;

	font-weight: 400;

	text-align: center;

	width: 100%;

	padding: 5px 10px;

	background-color: #fff;

	&:hover {
		color: #fff;

		background-color: #000;
	}
`;
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);



function MonthlyStripe() {
	const { authState } = useContext(AuthContext);

	const handleClick = async event => {
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/createCheckoutSession/upgradeMonthly`, { 
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ customer_email: `${authState.user.email}`,customer_name: `${authState.user.email}` }),
		 });
		const session = await response.json();
		
		
		stripe.redirectToCheckout({
			sessionId: session.sessionId,
		});
	};

	return (
		<StripeBtn role='link' onClick={handleClick}>
			Monthly Subscription - $4.95
		</StripeBtn>
	);
}

export default MonthlyStripe;

import React, { useReducer, createContext } from 'react';

export const DashboardContext = createContext();

const initialState = {};

const reducer = (state, action) => {
	switch (action.type) {
		case 'GET_DASHBOARD_HISTORY':
			return {
				...state,
				[action.payload.uniqueKey]: {
					chaptersRead: action.payload.chaptersRead,
					notesViewed: action.payload.notesViewed,
					searchHistory: action.payload.searchHistory,
					bookmarks: action.payload.bookmarks,
				},
			};
		case 'CLEAR_CHAPTERS_HISTORY':
			return {
				...state,
				[action.payload.uniqueKey]: {
					bookmarks: state[action.payload.uniqueKey].bookmarks,
					chaptersRead: action.payload.chaptersRead,
					notesViewed: state[action.payload.uniqueKey].notesViewed,
					searchHistory: state[action.payload.uniqueKey].searchHistory,
				},
			};
		case 'CLEAR_NOTES_VIEWED_HISTORY':
			return {
				...state,
				[action.payload.uniqueKey]: {
					bookmarks: state[action.payload.uniqueKey].bookmarks,
					chaptersRead: state[action.payload.uniqueKey].chaptersRead,
					notesViewed: action.payload.notesViewed,
					searchHistory: state[action.payload.uniqueKey].searchHistory,
				},
			};
		case 'CLEAR_SEARCH_HISTORY':
			return {
				...state,
				[action.payload.uniqueKey]: {
					bookmarks: state[action.payload.uniqueKey].bookmarks,
					chaptersRead: state[action.payload.uniqueKey].chaptersRead,
					notesViewed: state[action.payload.uniqueKey].notesViewed,
					searchHistory: [],
				},
			};
		case 'DELETE_BOOKMARK':
			return {
				...state,
				[action.payload.uniqueKey]: {
					bookmarks: action.payload.bookmarks,
					chaptersRead: state[action.payload.uniqueKey].chaptersRead,
					notesViewed: state[action.payload.uniqueKey].notesViewed,
					searchHistory: state[action.payload.uniqueKey].searchHistory,
				},
			};
		case 'REMOVE_ALL_HISTORY':
			return {
				...state,
				[action.payload.uniqueKey]: {
					chaptersRead: [],
					notesViewed: [],
					searchHistory: [],
				},
			};
		case 'UPDATE_NOTES_VIEWED':
			let { collectionId, collectionTitle } = action.payload;
			let stateCopy = { ...state };

			Object.entries(stateCopy).forEach(([key, value]) => {
				let notesViewedCopy = [...value.notesViewed];
				for (let i = 0; i < notesViewedCopy.length; i++) {
					let note = notesViewedCopy[i];
					if (collectionId === note.collection_id) note.title = collectionTitle;
				}
				stateCopy[key].notesViewed = notesViewedCopy;
			});

			return stateCopy;
		default:
			return state;
	}
};

export const DashboardProvider = ({ children }) => {
	const [dashboardState, dashboardDispatch] = useReducer(reducer, initialState);

	const fetchUserHistory = async obj => {
		// Pulls chaptersRead, searchHistory, CollectionNotesViewed history to add to global state.
		const chaptersRead = await fetch(`${process.env.REACT_APP_BASE_URL}/chapterHistory/getChapterHistory/${obj.userId}`)
			.then(res => res.json())
			.then(chaptersRead => chaptersRead);
		const notesViewed = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionHistory/getCollectionHistory/${obj.userId}`)
			.then(res => res.json())
			.then(notesViewed => notesViewed);
		const searchHistory = await fetch(`${process.env.REACT_APP_BASE_URL}/searchHistory/getSearchHistory/${obj.userId}`)
			.then(res => res.json())
			.then(searchHistory => searchHistory);
		const bookmarks = await fetch(`${process.env.REACT_APP_BASE_URL}/verses/getBookmarks/${obj.userId}`)
			.then(res => res.json())
			.then(bookmarks => bookmarks);

		function removeDuplicates(array, key) {
			let lookup = new Set();
			return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
		}
		dashboardDispatch({
			type: 'GET_DASHBOARD_HISTORY',
			payload: {
				uniqueKey: obj.uniqueKey,
				notesViewed: removeDuplicates(notesViewed, 'id'),
				searchHistory: removeDuplicates(searchHistory, 'searchKeyword'),
				chaptersRead: removeDuplicates(chaptersRead, 'id'),
				bookmarks: bookmarks,
			},
		});
	};

	/** Update Collection Notes when the title is updated */
	const updateNotesViewed = ({ collectionId, collectionTitle }) => {
		dashboardDispatch({ type: 'UPDATE_NOTES_VIEWED', payload: { collectionId, collectionTitle } });
	};

	const deleteBookmark = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/deleteBookmark/${obj.userId}/${obj.bookmarkId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(bookmarks => {
				dashboardDispatch({ type: 'DELETE_BOOKMARK', payload: { uniqueKey: obj.uniqueKey, bookmarks } });
			});
	};

	const removeHistory = obj => {
		// Removes History from database & clears the global state so it clears the dashboard.
		switch (obj.title) {
			case 'All':
				fetch(`${process.env.REACT_APP_BASE_URL}/chapterHistory/deleteChapterHistory/${obj.userId}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				fetch(`${process.env.REACT_APP_BASE_URL}/collectionHistory/deleteCollectionHistory/${obj.userId}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				fetch(`${process.env.REACT_APP_BASE_URL}/searchHistory/deleteSearchHistory/${obj.userId}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				return dashboardDispatch({ type: 'REMOVE_ALL_HISTORY', payload: { uniqueKey: obj.uniqueKey } });

			case 'Chapters Read':
				fetch(`${process.env.REACT_APP_BASE_URL}/chapterHistory/deleteChapterHistory/${obj.userId}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				return dashboardDispatch({ type: 'CLEAR_CHAPTERS_HISTORY', payload: { chaptersRead: [], uniqueKey: obj.uniqueKey } });
			case 'Notes Viewed':
				fetch(`${process.env.REACT_APP_BASE_URL}/collectionHistory/deleteCollectionHistory/${obj.userId}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				return dashboardDispatch({ type: 'CLEAR_NOTES_VIEWED_HISTORY', payload: { notesViewed: [], uniqueKey: obj.uniqueKey } });

			case 'Search History':
				fetch(`${process.env.REACT_APP_BASE_URL}/searchHistory/deleteSearchHistory/${obj.userId}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				});
				return dashboardDispatch({ type: 'CLEAR_SEARCH_HISTORY', payload: { searchHistory: [], uniqueKey: obj.uniqueKey } });
		}
	};

	return (
		<DashboardContext.Provider value={{ dashboardState, dashboardDispatch, fetchUserHistory, removeHistory, updateNotesViewed, deleteBookmark }}>
			{children}
		</DashboardContext.Provider>
	);
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { NavContext } from '../../ContextProviders/NavProvider';

const PremiumAccountBanner = styled.h2`
	background: rgb(255, 246, 151);
	border: 1px solid rgb(255, 162, 162);
	padding: 5px;
	margin-top: 10px;
`;

const TextWrapper = styled.p`
	font-size: 12px;
	color: #000;
	text-align: center;
	margin: 0;

	
`;

const unique = () => {
	return new Date().getTime().toString(36);
};

const ViewModeBanner = props => {
	const { dispatch } = useContext(NavContext);

	return (
		<PremiumAccountBanner>
			<TextWrapper>This Collection is in "View Only" mode.</TextWrapper>
		</PremiumAccountBanner>
	);
};

export default ViewModeBanner;
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PodcastChapter from './PodcastChapter';
import { NavContext } from '../../ContextProviders/NavProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';

const queryString = require('query-string');

const PaneWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const ChaptersWrapper = styled.ul`
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	font-size: 0;
	margin-bottom: 0;
	display: flex;
	flex-direction: column;
	padding-left: 0;
	list-style: none;
	font-size: 0;
`;

const ChaptersNumbersWrapper = styled.li`
	// padding: 3px;
	// 	display: inline-block;
	// 	min-width: 40px;
	// 	cursor: pointer;
	// 	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	// 	font-size: 14px;
	// 	line-height: 20px;

	padding: 3px;
	display: flex;
	flex-direction: row;
	width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
`;

const ChapterNumber = styled.a`
	display: block;
	padding: 3px;
	font-weight: 400;
	text-align: center;
	border-radius: 3px;
	min-width: 85px;
	background: #f3f3f3;
	&:hover {
		background-color: #ececec;
	}
`;
const ToggleOpenPaneWrapper = styled.div`
	display: block;
	z-index: 1;
	font-size: 22px;
	color: #7d8085;
	cursor: pointer;
	&:hover {
		color: #1a56eb;
	}
`;

const Chapters = props => {
	// const [selectedChapters, setSelectedChapters] = useState([])

	useEffect(() => {}, []);

	/** Returns the chapter number or OD1/OD2 for the Official Declarations */

	const handleSelectChapter = obj => {
		if (props.selectedChapters.some(e => e === obj.id)) {
			const index = props.selectedChapters.findIndex(e => e === obj.id);
			let selectedChaptersCopy = [...props.selectedChapters];
			selectedChaptersCopy.splice(index, 1);
			props.setSelectedChapters(selectedChaptersCopy);
		} else {
			props.setSelectedChapters([...props.selectedChapters, obj.id]);
		}
	};
	// console.log(props.selectedChapters)
	return (
		<PaneWrapper>
			{/* Had to use an inline style below for the text color, it wouldn't work on the styled component.  */}

			<ChaptersWrapper style={{ color: '#6a7583' }}>
				{props.chapters.map((value, key) => {
					return (
						// <ChaptersNumbersWrapper
						// 	key={key}
						// 	onClick={e => {
						// 		handleSelectChapter(value)
						// 	}}
						// >
						// 	<ChapterNumber style={props.selectedChapters.some(e => e === value.id) ? { backgroundColor: '#bdbdbd' } : null}>{retrieveChapterNumber(value)}</ChapterNumber>
						// 	<ToggleOpenPaneWrapper>
						// 		<FontAwesomeIcon icon={faChevronRight} />
						// 	</ToggleOpenPaneWrapper>

						// </ChaptersNumbersWrapper>
						<PodcastChapter
							key={key}
							chapter={value}
							selectedChapters={props.selectedChapters}
							handleSelectChapter={handleSelectChapter}
							podcastId={props.podcastId}
							pullLinks={props.pullLinks}
							setPullLinks={props.setPullLinks}
							chaptersWithLinks={props.chaptersWithLinks}
							fetchChaptersWithLinks={props.fetchChaptersWithLinks}
						/>
					);
				})}
			</ChaptersWrapper>
		</PaneWrapper>
	);
};

export default Chapters;

/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faEdit, faTrash } from '@fortawesome/fontawesome-free-solid/';
import ClearHistory from '../Helpers/ClearHistory';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './PopupMember.css';
import "react-datepicker/dist/react-datepicker.css";
/* Popup style */
const PopupWrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999;
`;

const Box = styled.div`
	position: relative;
	width: 85%;
	max-width: 700px;
	margin: 0 auto;
	height: auto;
	max-height: 70vh;
	margin-top: calc(100vh - 85vh - 20px);
	background: #fff;
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #999;
	overflow: auto;
`;

const CloseIcon = styled.span`
	content: 'x';
	cursor: pointer;
	position: fixed;
	right: calc(15% - 30px);
	top: calc(100vh - 85vh - 33px);
	background: #ededed;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	border: 1px solid #999;
	font-size: 20px;
`;
const SignupText = styled.div`
	color: #000;
	cursor: auto;
	position: relative;
	display: inline-block;
	font-size: 26px;
	font-weight: normal;
	text-align: center;
	border: none;
	margin-bottom: 15px;
`;
const FlexRow = styled.div`
	display:flex;
`;
const FlexColumn = styled.div`
	flex:1;
`;
const FormGroup = styled.div`
	margin-bottom: 10px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

const InputLabel = styled.label`
	padding-left: 10px;
	margin-bottom: 10px;
	color: #000;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 18px;
`;

const FormInput = styled.input`
	border: #d3d3d3 solid 1px;
	height: 40px;
	border-radius: 4px;
	margin: 0 4px;
	padding: 0 7px;
	flex: 1;
	&:focus {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
		outline-width: 0;
	}
`;

const FormInputLabel = styled.label`
	font-size: 14px;
	width: 100%;
	margin-top: 10px;
	font-weight: 500;
`;
const FormCardInput = styled.input`
	width: 100%;
	border: #d3d3d3 solid 1px;
	height: 40px;
	border-radius: 4px;
	margin: 0px 4px 10px 4px;
	padding: 0 7px;
	&:focus {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
		outline-width: 0;
	}
`;
const PermissionInputWrapper = styled.div`
	padding-bottom: 1rem;
	width: 100%;
	text-align: left;
`;

const FormBtn = styled.button`
	color: #fff;
	font-size: 18px;
	cursor: auto;
	position: relative;
	display: inline-block;
	padding: 8px 18px;
	border: #007bff solid 1px;
	border-radius: 5px;
	background-color: #007bff;
	margin-top: 20px;
	width: 100%;
	&:hover {
		color: #f8f8ff;
		background-color: #000;
	}
`;
const GiftText = styled.div`
	margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
`;
const GiftLabel = styled.div`
	font-weight: 400;
    font-size: 14px;
    color: #607D8B;
`;
const ButtonHolder = styled.div`
	position: relative;
`;
const ErrorWrapper = styled.div`
	color: #721c24;
	background-color: #f8d7da;
	border-color: #f5c6cb;
	padding: 0.75rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
`;

const PopupUpgrade = props => {
	const [userId, setUserId] = useState('');
	const { authState, isProUser, isTrialUser, hasProAccount } = useContext(AuthContext);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [giverEmail, setGiverEmail] = useState('');
	const [giverName, setGiverName] = useState('');
	const [cardNum, setCardNum] = useState('');
	const [cardExp, setCardExp] = useState('');
	const [cardCvc, setCardCvc] = useState('');
	const [cardHolder, setCardHolder] = useState('');
	const [country, setCountry] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [acknowledgement, setAcknowledgement] = useState(false);
	const [gift, setGift] = useState(false);
	const [error, setError] = useState('');
	const [checkMonthly, setCheckMonthly] = useState(true);
	const [checkYearly, setCheckYearly] = useState(false);

	const [checkYearly3, setCheckYearly3] = useState(false);
	const [checkYearly5, setCheckYearly5] = useState(false);
	const [checkYearly10, setCheckYearly10] = useState(false);
	const [checkYearlyGift, setCheckYearlyGift] = useState(false);
	const [checkMonthlyGift, setCheckMonthlyGift] = useState(false);
	const [renewGift, setRenewGift] = useState(false);

	const [select, setSelect] = useState('');

	const [showLoader, setShowLoader] = useState(false);

	const handleChangeYearly = e => {
		setCheckMonthly(false);
		setCheckYearly(true);
		setCheckYearly3(false)
		setCheckYearly5(false)
		setCheckYearly10(false)
		
	};

	const handleChangeMonthly = e => {
		setCheckMonthly(true);
		setCheckYearly(false);
		setCheckYearly3(false)
		setCheckYearly5(false)
		setCheckYearly10(false)
		
	};

	const handleChangeYearly3 = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(true)
		setCheckYearly5(false)
		setCheckYearly10(false)
		
	};

	const handleChangeYearly5 = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(false)
		setCheckYearly5(true)
		setCheckYearly10(false)
		
	};

	const handleChangeYearly10 = e => {
		setCheckMonthly(false);
		setCheckYearly(false);
		setCheckYearly3(false)
		setCheckYearly5(false)
		setCheckYearly10(true)
		
	};
	const cardExpInput = ele => {
		
		if (ele.length === 2) ele = ele + '/';
		else if (ele.length === 3 && ele.charAt(2) === '/') ele = ele.replace('/', '');

		setCardExp(ele);
	};
	
	const moment = require('moment');
	const logout = () => {
		localStorage.clear();
		localStorage.setItem('prev-email', authState.user.email);
		props.history.push('/login');
	};
	// let memberPrice=0;
	useEffect(() => {
		setEmail(authState.user.email);
		setName(authState.user.name);
		
		
	}, []);
	




	
	// const todayDate=moment().format('MM/DD/YYYY');
	const maxLengthCheck = e => {
		
		if (e.value.length > e.maxLength) {
			e.value = e.value.slice(0, e.maxLength);
		}
	};
	
	const handleExistingUser = async (selection, e) => {
		e.persist();
		e.target.disabled = true;
			if (cardCvc === '') {
				e.target.disabled = false;
				return setError('Please fill out all fields.');
			}else{
				if (selection === 'monthly') {
					handleClickMonthly(e);
				}
				if (selection === 'yearly') {
					handleClickYearly(e);
				}
				if (selection === 'yearly3') {
					handleClickYearly3(e);
				}
				if (selection === 'yearly5') {
					handleClickYearly5(e);
				}
				if (selection === 'yearly10') {
					handleClickYearly10(e);
				}
			}
		
	};

	const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

	const handleClickMonthly = async event => {
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/upgradeMonthlyCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${authState.user.email}`,
				customer_name: `${authState.user.name}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
			}),
		})
			.then(res => res.json())
			.then(response => {
				
				if (response.id) {
					// register(event);
					logout();

				} else {
					
					setShowLoader(false);
					event.target.disabled = false;
					
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};

	const handleClickYearly = async event => {
		const stripe = await stripePromise;
		
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/upgradeYearlyCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${authState.user.email}`,
				customer_name: `${authState.user.name}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
			}),
		})
			.then(res => res.json())
			.then(response => {
				
				if (response.id) {
					// register(event);
					logout();
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickYearly3 = async event => {
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/create3YearlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${authState.user.email}`,
				customer_name: `${authState.user.name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				gift: `${gift}`,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					logout();
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickYearly5 = async event => {
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/create5YearlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${authState.user.email}`,
				customer_name: `${authState.user.name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				gift: `${gift}`,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					logout();
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};
	const handleClickYearly10 = async event => {
		const stripe = await stripePromise;
		const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stripe/create10YearlyGiftCheckout`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customer_email: `${authState.user.email}`,
				customer_name: `${authState.user.name}`,
				giver_email: `${giverEmail}`,
				giver_name: `${giverName}`,
				card_number: `${cardNum}`,
				card_exp: `${cardExp}`,
				card_cvc: `${cardCvc}`,
				card_holder: `${cardHolder}`,
				renew_gift: `${renewGift}`,
				password: `${password}`,
				gift: `${gift}`,
			}),
		})
			.then(res => res.json())
			.then(response => {
				if (response.id) {
					// register(event);
					logout();
				} else {
					setShowLoader(false);
					event.target.disabled = false;
					
					if (response.message.trim() == 'Invalid integer:') {
						return setError('Invalid Card Expiry');
					} else {
						return setError(response.message);
					}
				}
			});
	};


	
	return (
		<>
			<PopupWrapper>
				<Box>
					{showLoader && (
						<Loader
							type='TailSpin'
							color='#fff'
							height={25}
							width={25}
							style={{
								position: 'absolute',
								right: '34px',
								bottom: '31px',
							}}
						/>
					)}
					<FontAwesomeIcon
						icon={faTimes}
						style={{
							float: 'right',
							cursor: 'pointer',
							margin: '0px 0px 15px 0px',
						}}
						onClick={props.handleClose}
					/>
					
					<SignupText>Upgrade your plan</SignupText>
					{error !== '' && (
									<ErrorWrapper>
										<p>{error}</p>
									</ErrorWrapper>
								)}
					<FlexRow>
						
						<FlexColumn>
							<FormGroup>
								
									<GiftText>
										Single-Year: (automatically renewed)
									</GiftText>
								
									<PermissionInputWrapper id='checkMonthly'>
										<input type='radio' name='checkMonthly' checked={checkMonthly} onChange={handleChangeMonthly} /> Billed Monthly $4.95
									</PermissionInputWrapper>
								
									<PermissionInputWrapper id='chekcYearly'>
										<input type='radio' name='chekcYearly' checked={checkYearly} onChange={handleChangeYearly} /> Billed Annually $49.95 (Get 2 Months
										Free)
									</PermissionInputWrapper>
								
									<GiftText>
										Multi-Year: (not automatically renewed)
									</GiftText>
								
								
									<PermissionInputWrapper id='checkYearly3'>
										<input type='radio' name='checkYearly3' checked={checkYearly3} onChange={handleChangeYearly3} /> 3 Years - $129.95 (28% savings)
									</PermissionInputWrapper>
								
								
									<PermissionInputWrapper id='checkYearly5'>
										<input type='radio' name='checkYearly5' checked={checkYearly5} onChange={handleChangeYearly5} /> 5 Years - $199.95 (33% savings)
									</PermissionInputWrapper>
								
								
									<PermissionInputWrapper id='checkYearly10'>
										<input type='radio' name='checkYearly10' checked={checkYearly10} onChange={handleChangeYearly10} /> 10 Years - $349.95 (42% savings)
									</PermissionInputWrapper>
							</FormGroup>
						</FlexColumn>
						<FlexColumn>
							<FormGroup>
								<FormInputLabel>Card Information</FormInputLabel>
								<FormCardInput
									style={{ width: '100%' }}
									placeholder='1234 1234 1234 1234'
									maxLength='16'
									name='card_num'
									type='tel'
									id='card_num'
									value={cardNum}
									onChange={e => setCardNum(e.target.value.toLowerCase())}
									autoFocus={false}
								/>
								<FormInput
									placeholder='MM/YY'
									name='card_exp'
									type='tel'
									id='card_exp'
									value={cardExp}
									onKeyUp={e => cardExpInput(e.target.value)}
									onChange={e => setCardExp(e.target.value)}
								/>
								<FormInput placeholder='CVC' name='card_cvc' type='tel' id='card_cvc' value={cardCvc} onChange={e => setCardCvc(e.target.value)} />
							</FormGroup>
							<FormGroup>
								<ButtonHolder>
									{showLoader && (
										<Loader
											type='TailSpin'
											color='#fff'
											height={25}
											width={25}
											style={{
												position: 'absolute',
												right: '34px',
												bottom: '10px',
												zIndex: '999',
											}}
										/>
									)}
									{checkMonthly ? (
										<FormBtn
											onClick={e => {
												handleExistingUser('monthly', e);
											}}
											type='submit'
										>
											Upgrade Now
										</FormBtn>
									) : checkYearly ? (
										<FormBtn
											onClick={e => {
												handleExistingUser('yearly', e);
											}}
											type='submit'
										>
											Upgrade Now
										</FormBtn>
									) : checkYearlyGift ? (
										<FormBtn
											onClick={e => {
												handleExistingUser('yearly_gift', e);
											}}
											type='submit'
										>
											Upgrade Now
										</FormBtn>
									) : checkMonthlyGift ? (
										<FormBtn
											onClick={e => {
												handleExistingUser('monthly_gift', e);
											}}
											type='submit'
										>
											Upgrade Now
										</FormBtn>
									) : checkYearly3 ? (
										<FormBtn
											onClick={e => {
												handleExistingUser('yearly3', e);
											}}
											type='submit'
										>
											Upgrade Now
										</FormBtn>
									) : checkYearly5 ? (
										<FormBtn
											onClick={e => {
												handleExistingUser('yearly5', e);
											}}
											type='submit'
										>
											Upgrade Now
										</FormBtn>
									) : checkYearly10 ? (
										<FormBtn
											onClick={e => {
												handleExistingUser('yearly10', e);
											}}
											type='submit'
										>
											Upgrade Now
										</FormBtn>
									) : (
										<FormBtn disabled={true}>Upgrade Now</FormBtn>
									)}
								</ButtonHolder>
							</FormGroup>
						</FlexColumn>
					</FlexRow>

				</Box>
			</PopupWrapper>
		</>
	);
};

export default PopupUpgrade;

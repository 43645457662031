import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
// Create a State Context and a Dispatch Context so that you can import them separately if needed
// Not all components that need access to state need to be able to update it; and vice-versa
export const AuthContext = React.createContext();

// Initialize Context State
const initialState = {
	user: localStorage.getItem('sn-user') ? JSON.parse(localStorage.getItem('sn-user')) : { id: null, stripe_plan_name: '' },
};

// Reducer accepts action types and payloads and executes functions accordingly
const reducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN_USER': {
			window.mobileCheck = function () {
				let check = false;
				(function (a) {
					if (
						/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
							a
						) ||
						/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
							a.substr(0, 4)
						)
					)
						check = true;
				})(navigator.userAgent || navigator.vendor || window.opera);
				return check;
			};
			window.mobileCheck() &&
				alert(
					'Thank you for using Scripture Notes. Using our browser app is fantastic on a larger screen but not the best experience on a mobile device. Please download our mobile app for a better experience.'
				);

			window.localStorage.setItem('sn-user', JSON.stringify(action.payload));
			return {
				user: action.payload,
			};
		}
		case 'LOGOUT_USER': {
			window.localStorage.removeItem('sn-user');
			return {
				user: null,
			};
		}
		case 'UPDATE_USER': {
			window.localStorage.removeItem('sn-user');
			window.localStorage.setItem('sn-user', JSON.stringify(action.payload));
			return {
				user: action.payload,
			};
		}
		case 'UPDATE_NAME': {
			let stateCopy = { ...state.user };
			stateCopy.name = action.payload.name;
			window.localStorage.removeItem('sn-user');
			window.localStorage.setItem('sn-user', JSON.stringify(stateCopy));

			return {
				user: stateCopy,
			};
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

// Main Context Provider Wrapper to be imported and Wrapped around components that need access to it
export const AuthProvider = ({ children }) => {
	const [authState, authDispatch] = useReducer(reducer, initialState);

	const loginUser = (email, password, props) => {
		email = email.toLowerCase();
		return fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email, password }),
		})
			.then(res => res.json())
			.then(user => {
				if (user.info) {
					return user.info;
				} else {
					authDispatch({ type: 'LOGIN_USER', payload: user });
					props.history.push('/');
				}
			});
	};
	const inAppLoginUser = (email, password, props) => {
		email = email.toLowerCase();
		return fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email, password }),
		})
			.then(res => res.json())
			.then(user => {
				if (user.info) {
					return user.info;
				} else {
					authDispatch({ type: 'LOGIN_USER', payload: user });
				}
			});
	};

	// const loginFacebookUser = (email, name, accessToken) => {
	const loginFacebookUser = (facebookInfo, history) => {
		return fetch(`${process.env.REACT_APP_BASE_URL}/auth/facebook`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(facebookInfo),
		})
			.then(res => res.json())
			.then(user => {
				if (!user) return 'Unknown error';
				if (user.error) return user.error;

				authDispatch({ type: 'LOGIN_USER', payload: user });

				history.push('/');

				return null;
			})
			.catch(err => console.log('Error with facebook login:', err));
	};

	/** Returns true if the user has a paid/pro account or if they're in a free trial period */
	const isProUser = () => {
		if (authState.user) {
			let trialExpires = new Date(authState.user.stripe_trial_ends_at);
			let currentDate = new Date();

			return (
				(authState.user && authState.user.stripe_plan_name && authState.user.stripe_plan_name.toLowerCase().includes('pro')) ||
				authState.user.stripe_plan_name.toLowerCase().includes('family-member') ||
				currentDate < trialExpires ||
				authState.user.isAppleSubscribed
			);
		} else return false;
	};

	/** Returns true if the user has a pro account */
	const hasProAccount = () => {
		return (
			(authState.user && authState.user.stripe_plan_name && authState.user.stripe_plan_name.toLowerCase().includes('family-member')) ||
			authState.user.stripe_plan_name.toLowerCase().includes('pro') ||
			authState.user.isAppleSubscribed
		);
	};

	const hasProCancelled = () => {
		if (authState.user.meta_data) {
			var decoded = JSON.parse(authState.user.meta_data);
			var accountStatus = decoded.accountStatus;
		} else {
			var accountStatus = '';
		}

		return (
			authState.user &&
			authState.user.stripe_plan_name &&
			authState.user.stripe_plan_name.toLowerCase().includes('pro') &&
			accountStatus == 'Cancelled'
		);
	};

	const hasCancelled = () => {
		if (authState.user.meta_data) {
			var decoded = JSON.parse(authState.user.meta_data);
			var accountStatus = decoded.accountStatus;
		} else {
			var accountStatus = '';
		}

		return authState.user && authState.user.stripe_plan_name && accountStatus == 'Cancelled';
	};

	/** Returns true if the user is still in their initial trial */
	const isTrialUser = () => {
		let trialExpires = new Date(authState.user.stripe_trial_ends_at);
		let currentDate = new Date();
		return currentDate < trialExpires;
	};

	const registerUser = (name, email, password, props) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ name, email, password }),
		})
			.then(res => res.json())
			.then(user => {
				// authDispatch({ type: 'LOGIN_USER', payload: user });
				// props.history.push('/login');
				// window.location.replace('https://scripturenotes.com/thank-you');
			});
	};

	return (
		<AuthContext.Provider
			value={{
				authState,
				authDispatch,
				registerUser,
				loginUser,
				loginFacebookUser,
				isProUser,
				hasProAccount,
				hasProCancelled,
				hasCancelled,
				isTrialUser,
				inAppLoginUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

import React, { useReducer, createContext } from 'react';

export const BulkMoveContext = createContext();

const initialState = [];

const reducer = (state, action) => {
	switch (action.type) {
		case 'HANDLE_ADD_VERSE': {
			return [...state, action.payload];
		}
		case 'HANDLE_REMOVE_VERSE': {
			let stateCopy = [...state];

			const index = stateCopy.findIndex(({ draggableId }) => draggableId === action.payload);
			index > -1 && stateCopy.splice(index, 1);

			return stateCopy;
		}
		case 'HANDLE_CLEAR_VERSES': {
			return [];
		}
	}
};

export const BulkMoveProvider = ({ children }) => {
	const [bulkMoveState, bulkMoveDispatch] = useReducer(reducer, initialState);
	const addVerse = verse => {
		bulkMoveDispatch({ type: 'HANDLE_ADD_VERSE', payload: verse });
	};
	const removeVerse = verse => {
		bulkMoveDispatch({ type: 'HANDLE_REMOVE_VERSE', payload: verse });
	};

	return (
		<BulkMoveContext.Provider
			value={{
				bulkMoveState,
				bulkMoveDispatch,
				addVerse,
				removeVerse,
			}}
		>
			{children}
		</BulkMoveContext.Provider>
	);
};

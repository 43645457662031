import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import { faTimes } from '@fortawesome/fontawesome-free-solid/';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import TimeSkimmer from '../../assets/TimeSkimmerTrans-300.png'

const {REACT_APP_BASE_URL} = process.env

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const PaneContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 100%;
`;

const PaneTitle = styled.h3`
	font-size: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center
`;
const GameName = styled.a`
	padding: 3px;
	display: flex;
	// justify-content: space-;
	min-width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
	padding: 3px;
	font-weight: 400;
	border-radius: 3px;
	background: #f3f3f3;
	margin-bottom: 5px;
	width: 95%;
	box-shadow: 5px 5px 5px grey;
	&:hover {
		background-color: #ececec;
	}
`;


const Games = props => {
    // Global Storage
    const { handleOpenGame } = useContext(NavContext);
    //State
    const [games, setGames] = useState([])

    useEffect(() => {
        fetchGames()
    }, [])
    
    //Methods
    const fetchGames = async () => {
        await fetch(`${REACT_APP_BASE_URL}/games/getAllGames`)
			.then(res => res.json())
            .then(games=> setGames(games))
			.catch(err => console.log('Error in fetching games:', err));
    }
	const gameLogo = (game) => {
		if(game === 'Time Skimmer') return TimeSkimmer
	}

    //Mapped Items
    const mappedGames = games.length > 0 && games.map((game, ind ) => {
        return (
            <div 
              key={ind}
            >
                <GameName
							key={ind}
							onClick={e => {
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									// return window.open(
									// 	`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
									// 	'_blank'
									// );
								} else {
									handleOpenGame({ originPaneKey: props.uniqueKey, gameName: game.game_name});
								}
							}}>
                                {/* {game.game_name} */}
								<img src={gameLogo(game.game_name)} />
						</GameName>
            </div>
        )
    })

    return (
        <>
            <PaneContainer>
                <ClosePaneWrapper>
                    <Tooltip content='Close Pane' direction='up' forceDirection={true}>
                        <FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
                    </Tooltip>
                </ClosePaneWrapper>
                <TitleDiv {...props.dragHandleProps}>
                    <TitleContainer>
                        <PaneTitle>Games</PaneTitle>
                    </TitleContainer>
                </TitleDiv>
                <Scrollbars autohide='true'>
                    {mappedGames}
                    </Scrollbars>
            </PaneContainer>
        </>
    );
};

export default Games;
import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleDown, faCheckSquare, faSquareFull } from '@fortawesome/fontawesome-free-solid/';
import { faSquare, faMinusSquare, faDotCircle } from '@fortawesome/fontawesome-free-regular';
import styled from 'styled-components';

const TreeContainer = styled.div`
	// background-color: 'blue';
`;

const TreeItem = styled.div`
	display: block;
`;

const TreeItemHeader = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

const TreeItemLabel = styled.div`
	margin-left: 16px;
	font-weight: 600;
	color: #6a7583;
	&:hover {
		background-color: #eaecec;
	}
`;

const TreeItemChildren = styled.div`
	margin-left: 16px;
`;

const IconContainer = styled.div`
	display: flex;
	width: 12px;
`;

/**
 * This React Component is a Custom Checkbox Tree made for displaying lists of items
 * with nested lists and select/deselect capabilities
 */
const CustomCheckboxTree = props => {
	const [nodes, setNodes] = useState([]);
	const [selectedNodes, setSelectedNodes] = useState(props.checked.length > 0 ? props.checked : []);
	const [expandedNodes, setExpandedNodes] = useState([]);
	useEffect(() => {
		if (props.checked && props.checked.length > 0 && props.checked[0].label === 'scripturesOnly') {
			const scriptureNodes = props.nodes.filter(e => e.scripture);
			let selectedNodesCopy = [];
			scriptureNodes.forEach((e, i) => {
				const allNodes = nestedNodes(e);
				selectedNodesCopy = [...selectedNodesCopy, ...allNodes];
			});
			setSelectedNodes(selectedNodesCopy);
			props.onCheck(selectedNodesCopy);
			return;
		}
		if (props.checked && props.checked.length > 0 && props.checked[0].label === 'all') {
			const scriptureNodes = props.nodes;
			let selectedNodesCopy = [];
			scriptureNodes.forEach((e, i) => {
				const allNodes = nestedNodes(e);
				selectedNodesCopy = [...selectedNodesCopy, ...allNodes];
			});
			setSelectedNodes(selectedNodesCopy);
			props.onCheck(selectedNodesCopy);
			return;
		}
		if (props.checked && !props.checked.length) {
			setSelectedNodes([]);
			// NOTE: All this used to do was close a volume if no books were selected. I don't think we need it anymore
			// but I'm going to keep it just in case. (todd)
			setExpandedNodes([]);

			props.toggleFilters && props.toggleFilters(false);
		} else {
			// console.log('props.toggleFilters:', props.toggleFilters);
			props.toggleFilters && props.toggleFilters(true);
		}
	}, [props.checked]);

	useEffect(() => {
		let formattedNodes =
			props.nodes &&
			props.nodes.map(node => ({
				...node,
				expanded: false,
			}));
		setNodes(formattedNodes);
	}, [props.nodes]);
	// useEffect(() => {
	// 	if (props.scripturesOnly === true) {
	// 		const scriptureNodes = props.nodes.filter(e => e.scripture)
	// 		let selectedNodesCopy = []
	// 		scriptureNodes.forEach((e, i) => {
	// 			selectedNodesCopy = [...selectedNodesCopy, ...adjustSelectedNodes(e)]
	// 		})
	// 		setSelectedNodes(selectedNodesCopy)
	// 		props.onCheck(selectedNodesCopy)
	// 	}
	// }, [props.scripturesOnly])
	/** Returns a string with the first character capitalized */
	const firstCharUpper = string => string.charAt(0).toUpperCase() + string.slice(1);
	/** Creates a flat array of a node and all of its children */
	const nestedNodes = (node, path = [], result = []) => {
		let { value = null, label = null, order = null } = node;
		result.push({ value, label, order });
		for (const child of node.children || []) {
			nestedNodes(child, [...path, node], result);
		}
		return result;
	};
	/** Adds or removes a node from the selectedNodes array */
	const adjustSelectedNodes = node => {
		let allNodes = nestedNodes(node);
		let selectedNodesCopy = [...selectedNodes];
		if (isNodeSelected(node.value, node.label)) {
			let updatedCopy = [];
			if (node.label === 'Old Testament') {
				const volumes = props.nodes.map(e => e.label).filter(e => e !== 'Old Testament');
				for (const child of selectedNodesCopy) {
					if (!allNodes.find(c => c.value === child.value && !volumes.includes(child.label))) updatedCopy.push(child);
				}
			} else {
				for (const child of selectedNodesCopy) {
					const volumes = props.nodes.map(e => e.label);
					if (child.value === node.value && volumes.includes(node.label)) {
						if (!allNodes.find(c => child.label === node.label && c.value === child.value)) updatedCopy.push(child);
					} else {
						if (!allNodes.find(c => c.value === child.value)) updatedCopy.push(child);
					}
					// if (!allNodes.find(c => child.label === node.label && c.value === child.value)) updatedCopy.push(child);
				}
			}
			selectedNodesCopy = updatedCopy;
		} else {
			allNodes.forEach(node => {
				if (!isNodeSelected(node.value, node.label)) selectedNodesCopy.push(node);
			});
		}
		return selectedNodesCopy;
	};
	/** Adds or removes a node from the expandedNodes array */
	const adjustExpandedNodes = node => {
		let expandedNodesCopy = [...expandedNodes];
		if (isNodeExpanded(node.value)) {
			let nodeIndex = expandedNodes.findIndex(n => n.value === node.value);
			if (nodeIndex === -1) return;
			expandedNodesCopy.splice(nodeIndex, 1);
		} else {
			expandedNodesCopy.push(node);
		}
		// console.log('\n\nsettingExpandedNodes:', expandedNodesCopy, '\n\n');
		setExpandedNodes(expandedNodesCopy);
	};

	/** Checks if the given node is selected or not */
	const isNodeSelected = (nodeValue, nodeLabel) => {
		let isSelected = selectedNodes.find(node => node.value === nodeValue && node.label === nodeLabel);
		if (!isSelected) {
			isSelected = props.checked.find(node => node.value === nodeValue && node.label === nodeLabel);
		}
		return !!isSelected;
	};

	/** Checks if the given node is expanded or not */
	const isNodeExpanded = nodeValue => !!expandedNodes.find(node => node.value === nodeValue);

	/** Specifically for sub tag formatting, removes everything in the label before the last ':' */
	const formatLabel = (label, isInitial = false) => {
		if (isInitial) return firstCharUpper(label);
		let splitLabel = label.split(':');
		if (props.capFirstChar) return firstCharUpper(splitLabel[splitLabel.length - 1]);
		return splitLabel[splitLabel.length - 1];
	};

	/** Recursively renders children until there are no more */
	const NodeChildren = props => {
		let { level = 1 } = props;
		const [children, setChildren] = useState([]);

		useLayoutEffect(() => {
			if (props.children) {
				let formattedNodes = props.children.map(node => ({
					...node,
					expanded: !!node.expanded,
				}));
				setChildren(formattedNodes);
			}
		}, []);

		if (!children || !children.length) return null;
		/** Returns the correct icon to indicate if the given node is selected,
		 * not selected, or has some children selected */
		const selectedNodeIcon = (nodeValue, nodeLabel, children) => {
			// console.log()
			const volumes = nodes.map(e => e.label);
			// console.log(volumes.some(e => e.toLowerCase() === nodeLabel.toLowerCase()), )
			// Conditional ensures that the children are books, not volumes
			// if (children && children.length === nodes.length && nodeValue <= nodes.length) {
			// if (volumes.some(e => e.toLowerCase() === nodeLabel.toLowerCase()) && nodeValue > nodes.length) {
			if (children && children.length === 5 && nodeValue <= 5) {
				let chldrn = children.find(({ value }) => value === nodeValue).children;
				if (chldrn.every(child => selectedNodes.find(n => n.value === child.value && n.label === child.label))) {
					return faCheckSquare;
				} else if (chldrn.some(child => selectedNodes.find(n => n.value === child.value && n.label === child.label))) {
					return faMinusSquare;
				} else {
					return faSquare;
				}
			}

			// This is for the book selection icons
			return isNodeSelected(nodeValue, nodeLabel) ? faCheckSquare : faSquare;
		};

		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				{children.map((node, i) => (
					<TreeItem key={i}>
						<TreeItemHeader>
							<IconContainer>
								<FontAwesomeIcon
									onClick={() => {
										if (node.children && node.children.length) adjustExpandedNodes(node);
									}}
									style={{ opacity: node.children && node.children.length ? 1 : 0 }}
									size='lg'
									icon={isNodeExpanded(node.value) ? faAngleDown : faAngleRight}
								/>
							</IconContainer>

							{props.useCheckboxes && (
								<IconContainer>
									<FontAwesomeIcon
										size='sm'
										style={{ marginLeft: '8px' }}
										icon={selectedNodeIcon(node.value, node.label, children)}
										onClick={() => {
											let updatedNodes = adjustSelectedNodes(node);
											// console.log(updatedNodes)
											props.onCheck(updatedNodes);
											setSelectedNodes(updatedNodes);
										}}
									/>
								</IconContainer>
							)}

							<TreeItemLabel onClick={() => (props && props.onClick ? props.onClick(node) : null)}>
								{formatLabel(node.label, props.parentId === 'initial')}
							</TreeItemLabel>
						</TreeItemHeader>

						<TreeItemChildren>
							{isNodeExpanded(node.value) && (
								<NodeChildren
									toggleFilters={props.toggleFilters || (() => {})}
									capFirstChar={props.capFirstChar || false}
									onCheck={props.onCheck}
									onClick={props.onClick}
									children={node.children}
									useCheckboxes={props.useCheckboxes || false}
									level={level + 1}
								/>
							)}
						</TreeItemChildren>
					</TreeItem>
				))}
			</div>
		);
	};

	return (
		<div>
			<TreeContainer>
				<NodeChildren
					onCheck={props.onCheck}
					toggleFilters={props.toggleFilters || (() => {})}
					onClick={props.onClick}
					parentId={'initial'}
					children={nodes}
					useCheckboxes={props.useCheckboxes || false}
				/>
			</TreeContainer>
		</div>
	);
};

export default CustomCheckboxTree;

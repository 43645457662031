import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faPlusCircle, faTrash } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavContext } from '../../ContextProviders/NavProvider';
import { CollectionListContext } from '../../ContextProviders/CollectionListProvider';
import '../../assets/css/animation.css';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import ReactPaginate from 'react-paginate';
import PodcastTitle from './PodcastTitle';

// The following styles are everything above the search bar.
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const CollectionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
`;

const CreateCnWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 45px;
	margin-bottom: 15px;
`;

const CollectionsText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 60%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 23px;
	padding-bottom: 10px;
`;
const NewPodcastButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 5px;
	line-height: 10px;
	width: 40%;
	font-weight: 700;
	font-size: 15px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	height: 80%;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;

const SearchBarContainer = styled.div`
	position: relative;
`;
const NewPodcastWrapper = styled.div`
	position: relative;
	margin-bottom: 5px;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
`;
const PodcastInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
`;

const SearchIconWrapper = styled.div`
	color: #7d8085;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;

// The following styles are everything under the search bar.
const CnDisplayContainer = styled.div`
	position: relative;
	padding-bottom: 13px;
	height: 100%;
	min-width: 315px;
`;

const PaddingLeftNRightWrapper = styled.div`
	padding-left: 15px;
	padding-right: 15px;
`;

const PodcastListContainer = styled.ul`
	padding-left: 0px;
	margin-bottom: 60px;
	list-style: none;
`;

const PodcastDescMap = styled.div`
	margin-bottom: 13px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	justify-content: space-between;
`;

// const PodcastTitle = styled.span`
// 	color: #6a7583;
// 	font-size: 14px;
// 	&:hover {
// 		background-color: #ececec;
// 	}
// `;
const DeleteIconWrapper = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const DeletePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 30%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
`;
const DeleteProjectWrapper = styled.div`
	display: block;
	cursor: pointer;
	background-size: cover;
	background-repeat: no-repeat;
	right: 55px;
`;

const Podcasts = props => {
	// Local State
	const [text, setText] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [data, setData] = useState([]);
	const [podcastName, setPodcastName] = useState('');
	const [hidden, setHidden] = useState(true);

	// Global State
	const { dispatch } = useContext(NavContext);
	const { handleAddCollection, createCollection } = useContext(NavContext);
	const { isProUser, authState } = useContext(AuthContext);

	useEffect(() => {
		if (
			authState.user.email == 'oak@oaknorton.com' ||
			authState.user.email == 'kashif@axx.com' ||
			authState.user.email == 'jmaxt12@gmail.com' ||
			authState.user.email == 'todd@brightbridgeweb.com' ||
			authState.user.email == 'test@email.com' ||
			authState.user.email == 'aspennortonrocks@gmail.com' ||
			authState.user.email == 'willowtree013@gmail.com' ||
			authState.user.email == 'tardis904@gmail.com'
		) {
			fetchPodcasts();
			console.log('FETCH ALL');
		} else {
			fetchAdminsPodcasts();
			console.log('FETCH ADMINS');
		}
	}, []);
	useEffect(() => {
		if (authState && authState.user && authState.user.id) {
			const socket = props.socket;

			socket.on('update_podcast_list_' + authState.user.id, () => {
				if (
					authState.user.email == 'oak@oaknorton.com' ||
					authState.user.email == 'kashif@axx.com' ||
					authState.user.email == 'jmaxt12@gmail.com' ||
					authState.user.email == 'todd@brightbridgeweb.com' ||
					authState.user.email == 'test@email.com' ||
					authState.user.email == 'aspennortonrocks@gmail.com' ||
					authState.user.email == 'willowtree013@gmail.com' ||
					authState.user.email == 'tardis904@gmail.com'
				) {
					fetchPodcasts();
					console.log('FETCH ALL');
				} else {
					fetchAdminsPodcasts();
					console.log('FETCH ADMINS');
				}
			});

			return () => socket.off('update_podcast_list_' + authState.user.id);
		}
	}, [authState]);

	const fetchPodcasts = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getPodcasts`)
			.then(res => res.json())
			.then(podcasts => setData(podcasts));
	};

	const fetchAdminsPodcasts = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getAdminPodcasts/${authState.user.email.toLowerCase()}`)
			.then(res => res.json())
			.then(podcasts => setData(podcasts));
	};

	const handleSubmit = e => {
		e.preventDefault();
	};
	const unique = () => {
		return new Date().getTime().toString(36);
	};
	const handlePageClick = ({ selected: selectedPage }) => {
		setCurrentPage(selectedPage);
	};

	const handleOpenPodcast = podcast => {
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey: props.uniqueKey,
			payload: { key: `podcast${unique()}`, type: 'podcast', podcast: podcast },
		});
		setTimeout(function () {
			const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			panelsBlock.scroll({
				left: panelsBlock.scrollLeft + 2000,
				behavior: 'smooth',
			});
		}, 100);
	};

	const addNewPodcast = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/addPodcast`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ podcastName }),
		})
			.then(res => res.json())
			.then(podcasts => {
				setData(podcasts);
				setPodcastName('');
				setHidden(true);
			});
	};

	const handleDeletePodcast = async obj => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/deletePodcast/${obj.id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(podcasts => setData(podcasts));
	};

	const PER_PAGE = 15;
	const offset = currentPage * PER_PAGE;

	const currentPageData = data.slice(offset, offset + PER_PAGE).map(value => (
		<PodcastTitle
			key={value.id}
			handleOpenPodcast={handleOpenPodcast}
			handleDeletePodcast={handleDeletePodcast}
			value={value}
			setData={setData}
			user={authState.user}
		/>
		// <PodcastDescMap
		//     key={value.id}
		// >
		//     <PodcastTitle
		//         onClick={e => {
		//             handleOpenPodcast(value);
		//         }}
		//     >{
		//             value.podcast_name}
		//     </PodcastTitle>
		//     <PortalWithState closeOnOutsideClick closeOnEsc>
		//         {({ openPortal, closePortal, isOpen, portal }) => (
		//             <>
		//                 <DeleteIconWrapper>
		//                     <Tooltip content='Delete Project'>
		//                         <FontAwesomeIcon key='ProjectPortal' icon={faTrash} onClick={openPortal} />
		//                     </Tooltip>
		//                 </DeleteIconWrapper>
		//                 {portal(
		//                     <div>
		//                         <div
		//                             style={{
		//                                 top: 0,
		//                                 zIndex: 9999999,
		//                                 background: '#ddd',
		//                                 width: '100%',
		//                                 height: '100%',
		//                                 minHeight: '392px',
		//                                 opacity: '0.6',
		//                                 position: 'absolute',
		//                             }}
		//                         />

		//                         <DeletePopUpWrapper>
		//                             <p>Are you sure, you want to delete this podcast?</p>
		//                             <DeletePopUpBtnWrapper>
		//                                 <DeletePopUpBtn
		//                                     onClick={closePortal}
		//                                     onClickCapture={() => {
		//                                         handleDeletePodcast(value)
		//                                     }}
		//                                 >
		//                                     Delete
		//                                 </DeletePopUpBtn>
		//                                 <ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
		//                             </DeletePopUpBtnWrapper>
		//                         </DeletePopUpWrapper>
		//                     </div>
		//                 )}
		//             </>
		//         )}
		//     </PortalWithState>
		//     {/* <DeleteIconWrapper onClick={() => handleDeletePodcast(value)}>
		//         <FontAwesomeIcon icon={faTrash} />
		//     </DeleteIconWrapper> */}
		// </PodcastDescMap>
	));
	const pageCount = Math.ceil(data.length / PER_PAGE);

	return (
		<div className='CollectionList' style={{ height: '100%' }}>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
				</Tooltip>
			</ClosePaneWrapper>
			<CollectionsContainer>
				<CreateCnWrapper>
					<CollectionsText {...props.dragHandleProps}>Podcasts</CollectionsText>
					<NewPodcastButton
						type='button'
						// disabled={!isProUser()}
						onClick={e => {
							setHidden(!hidden);
						}}
					>
						{hidden ? 'Add New' : 'Cancel'}
					</NewPodcastButton>
				</CreateCnWrapper>
				{/* SEARCH BAR */}
				{!hidden && (
					<NewPodcastWrapper>
						<form
							onSubmit={e => {
								e.preventDefault();
								addNewPodcast();
							}}
						>
							<PodcastInput
								type='text'
								placeholder='Add New Podcast'
								name='text'
								value={podcastName}
								onChange={e => setPodcastName(e.target.value)}
								autoComplete='off'
								autoFocus
							/>
							<SearchIconWrapper>
								<FontAwesomeIcon icon={faPlusCircle} onClick={() => addNewPodcast()} />
							</SearchIconWrapper>
						</form>
					</NewPodcastWrapper>
				)}
				<SearchBarContainer>
					<form onSubmit={handleSubmit}>
						<SearchInput
							type='text'
							placeholder='Title Search'
							name='text'
							value={text}
							onChange={e => setText(e.target.value)}
							autoComplete='off'
							autoFocus
						/>
						<SearchIconWrapper>
							<FontAwesomeIcon icon={faSearch} onClick={e => handleSubmit(e)} />
						</SearchIconWrapper>
					</form>
				</SearchBarContainer>
			</CollectionsContainer>

			<CnDisplayContainer>
				<Scrollbars autoHide>
					<PaddingLeftNRightWrapper>
						<PodcastListContainer>
							{currentPageData}
							{currentPageData.length > 0 ? (
								<div>
									{data && data.length >= 16 ? (
										<ReactPaginate
											previousLabel={'Prev'}
											nextLabel={'Next'}
											pageCount={pageCount}
											onPageChange={handlePageClick}
											containerClassName={'pagination'}
											previousLinkClassName={'pagination__link'}
											nextLinkClassName={'pagination__link'}
											disabledClassName={'pagination__link--disabled'}
											activeClassName={'pagination__link--active'}
											marginPagesDisplayed={1}
											pageRangeDisplayed={2}
										/>
									) : null}
								</div>
							) : (
								<p>No podcasts found</p>
							)}
						</PodcastListContainer>
					</PaddingLeftNRightWrapper>
				</Scrollbars>
			</CnDisplayContainer>
		</div>
	);
};

export default Podcasts;

import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faSearch, faThLarge, faBookmark, faSignInAlt, faTimes } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import '../Search/search.css';
import styled from 'styled-components';
import { SearchContext } from '../../ContextProviders/SearchProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import CustomCheckboxTree from './CustomCheckboxTree';
import Tooltip from 'react-tooltip-lite';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { AuthContext } from '../../ContextProviders/AuthProvider';

const queryString = require('query-string');

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const SearchFilterContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	position: absolute;
	height: 20px;
	top: 0;
	left: 0;
	width: calc(100% - 55px);
	overflow: hidden;
	z-index: 3;
`;

const SearchHelp = styled.a`
	font-size: 14px;
`;

const SearchText = styled.div`
	margin: 0;
	padding: 0;
	color: #6d7886;
	margin-right: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 18px;
	line-height: 20px;
	font-weight: 500;
	cursor: pointer;
`;

const ToggleMessageWrapper = styled.div`
	position: absolute;
	right: 0;
	top: 30px;
`;

const SearchBarContainer = styled.div`
	padding-top: 9px;
	margin-bottom: 25px;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	z-index: 3;
`;

const SearchInputWrapper = styled.div`
	position: relative;
	width: 100%;
`;

const SearchInput = styled.input`
	width: 100%;
	padding: 8px;
	padding-right: 30px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	font-size: 16px;
	line-height: 20px;
`;

const FilterSearchContainer = styled.div`
	display: flex;
	align-items: center;
	flex-flow: row nowrap;
	place-content: stretch space-between;
	z-index: 3;
`;

const RadioButtonContainer = styled.div`
	margin-bottom: 10px;
	height: 30px;
	z-index: 3;
`;

const RadioLabel = styled.label`
	font-weight: 500;
	margin-bottom: 0%;
	margin-left: 4px;
	color: #6a7583;
	font-size: 17px;
	line-height: 25px;
	cursor: pointer;
	transition: color 0.15s linear;
	z-index: 3;
	&:hover {
		color: #5e6773;
	}
`;

const HisFilResultContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 20px;
	z-index: 3;
`;

const HisFilResultButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 5px;
	margin: 0px 6px 10px 6px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 90%;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;

const SearchHistoryTextWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const ListWrapper = styled.div`
	padding-left: 0;
	margin-bottom: 60px;
	list-style: none;
`;

const ListItem = styled.li`
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 6px;
	cursor: pointer;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;

const PaddingRightWrapper = styled.div`
	width: 100%;
	padding-right: 15px;
`;

const SearchFilterWrapper = styled.div`
	// line-height: 35px;
`;

const CategoryWrapper = styled.div`
	color: #49a5f4;
	display: flex;
	margin-bottom: 15px;
	align-items: center;
`;

const FilterSpacing = styled.ul`
	margin-left: 3px;
	margin-bottom: 50px;
	padding-left: 25px;
	list-style: none;
`;

const TagsWrapper = styled.div`
	color: #2354f9;
	display: flex;
	margin-bottom: 15px;
	align-items: center;
`;

const TagsInputWrapper = styled.div`
	margin-bottom: 12px;
	position: relative;
	width: 100%;
`;

const TagsSearchInput = styled.input`
	padding: 0 30px 0 10px;
	font-size: 14px;
	width: 100%;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	line-height: 20px;
`;

const SearchAllTagsWrapper = styled.span`
	width: 14px;
	height: 22px;
	top: 10px;
	right: 5px;
	cursor: pointer;
	display: flex;
	position: absolute;
	color: #d1d2d2;
	&:hover {
		color: #b0b1b1;
	}
`;

const FilterFlex = styled.div`
	display: flex;
	align-items: center;
	color: #455199;
`;

const FilterText = styled.h3`
	font-size: 18px;
	margin-left: 8px;
`;

const SearchIconWrapper = styled.div`
	position: absolute;
	right: 10px;
	top: 6px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	color: #697275;
	&:hover {
		color: #1a56eb;
	}
`;

const SearchHistoryContainer = styled.div`
	position: relative;
	padding-bottom: 13px;
	height: 100%;
	min-width: 315px;
	padding: 200px 0px 0px 0px;
	// z-index: 1;
`;

const CategoryInputWrapper = styled.div`
	width: '100%';
	display: flex;
	align-items: center;
`;

const CategoryInput = styled.input`
	margin-left: 1.5rem;
`;

const CategoryInputLabel = styled.div`
	font-size: 0.9rem;
	margin-left: 0.6rem;
	font-weight: 600;
`;

const VolumeFilter = styled.div`
	cursor: pointer;
	color: #697275;
	&:hover {
		color: #1a56eb;
	}
`;
const ViewButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	flex: 2;
`;

const FrequencyContainer = styled.div`
	// position: relative;
	// padding-bottom: 13px;
	height: 100%;
	contain: strict;
	min-width: 315px;
	// line-height: 35px;
`;

const Search = props => {
	const [searchText, setSearchText] = useState(props.searchText ? props.searchText : '');
	const [selectedOption, setSelectedOption] = useState(props.selectedOption ? props.selectedOption : 'scripturesOnly');
	const [searchHistory, setSearchHistory] = useState(props.searchHistory ? props.searchHistory : true);
	const [filterTree, setFilterTree] = useState(props.filterTree ? props.filterTree : false);
	const [filterTreeCategoriesAndTags, setFilterTreeCategoriesAndTags] = useState(
		props.filterTreeCategoriesAndTags ? props.filterTreeCategoriesAndTags : false
	);
	const [display, setDisplay] = useState(props.display ? props.display : false);
	const [checkedCategories, setCheckedCategories] = useState(props.checkedCategories ? props.checkedCategories : []);
	const [tagText, setTagText] = useState(props.tagText ? props.tagText : '');
	const [userTags, setUserTags] = useState(props.userTags ? props.userTags : []);
	const [selectedBooks, setSelectedBooks] = useState(props.selectedBooks ? props.selectedBooks : []);
	const [resultPaneKey, setResultPaneKey] = useState(props.resultPaneKey ? props.resultPaneKey : null);
	const [resultsLibrary, setResultsLibrary] = useState(props.resultsLibrary ? props.resultsLibrary : []);
	const [filtersEnabled, setFiltersEnabled] = useState(props.filtersEnabled ? props.filtersEnabled : false);
	const [searchingFromResults, setSearchingFromResults] = useState(props.searchingFromResults ? props.searchingFromResults : false);
	const [scripturesOnly, setScripturesOnly] = useState(false);
	const [view, setView] = useState('search');
	const [categories, setCategories] = useState([]);

	const { searchState, searchDispatch, getLibraryNCategories, handleGetTags } = useContext(SearchContext);
	const { chapterHistoryDispatch, chapterHistoryState } = useContext(ChapterHistoryContext);

	const { handleAddSearchResult, state, handleUpdateSearchPane, handleOpenWordUseReport } = useContext(NavContext);
	const { isProUser, authState } = useContext(AuthContext);

	useEffect(() => {
		let parsed = queryString.parse(window.location.search);
		const shareLinks = JSON.parse(localStorage.getItem('share-links'));
		if (shareLinks) {
			chapterHistoryDispatch({ type: 'SET_SEARCH_URL_QUERY_RENDERED', payload: true });
			setSearchText(shareLinks.searchQuery);
			handleSearch(null, shareLinks.searchQuery, [], false, true);
			localStorage.setItem('share-links', null);
			return;
		}
		getLibraryNCategories({ uniqueKey: props.uniqueKey, userId: props.user.id });
		if (parsed && parsed['pane'] && parsed['searchQuery'] && !chapterHistoryState.hasSearchQueryRendered) {
			chapterHistoryDispatch({ type: 'SET_SEARCH_URL_QUERY_RENDERED', payload: true });
			setSearchText(parsed.searchQuery);
			handleSearch(null, parsed.searchQuery, [], false, true);
			return;
		}
		fetchCategories();
	}, []);
	useEffect(() => {
		handleUpdateSearchPane({
			paneKey: props.uniqueKey,
			searchText: searchText,
			selectedOption: selectedOption,
			searchHistory: searchHistory,
			filterTree: filterTree,
			filterTreeCategoriesAndTags: filterTreeCategoriesAndTags,
			display: display,
			checkedCategories: checkedCategories,
			tagText: tagText,
			userTags: userTags,
			selectedBooks: selectedBooks,
			resultPaneKey: resultPaneKey,
			resultsLibrary: resultsLibrary,
			filtersEnabled: filtersEnabled,
			searchingFromResults: searchingFromResults,
		});
	}, [
		selectedBooks,
		selectedOption,
		searchHistory,
		filterTree,
		filterTreeCategoriesAndTags,
		display,
		checkedCategories,
		tagText,
		userTags,
		resultPaneKey,
		resultsLibrary,
		filtersEnabled,
		searchingFromResults,
	]);

	useEffect(() => {
		const calculateResultQuantities = () => {
			let searchStateCopy = JSON.parse(JSON.stringify(searchState));
			if (searchStateCopy[props.uniqueKey]) {
				let volumes = [...searchStateCopy[props.uniqueKey].library];

				volumes.forEach((volume, index) => {
					// console.log(volume)
					volume.resultCount = 0;
					searchStateCopy &&
						searchStateCopy[resultPaneKey] &&
						searchStateCopy[resultPaneKey].forEach((verse, index) => {
							if (volume.id === verse?.chapter?.book.volume.id) {
								volume.resultCount++;
							}
						});

					volume.title = `${volume.title} (${volume.resultCount})`;

					volume.books.forEach((book, index) => {
						book.resultCount = 0;

						searchStateCopy &&
							searchStateCopy[resultPaneKey] &&
							searchStateCopy[resultPaneKey].forEach((verse, index) => {
								if (book.id === verse?.chapter?.book.id) book.resultCount++;
							});

						book.title = `${book.title} (${book.resultCount})`;
					});
				});

				return volumes;
			}
		};

		if (resultPaneKey) {
			let formattedVolumes = calculateResultQuantities();

			if (formattedVolumes) {
				let treeLibrary = formattedVolumes.map(({ id, title, order, books, resultCount }) => ({
					order,
					resultCount,
					value: id,
					label: title,
					children: books.map(({ id, title, order, resultCount }) => ({ value: id, label: title, order, resultCount })),
				}));

				!searchingFromResults && setResultsLibrary(treeLibrary);
			}
			// setResultsLibrary(treeLibrary);
		}
	}, [resultPaneKey, searchState]);

	/** Determines if two arrays are equal or not */
	const arraysEqual = (a, b) => {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (var i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	};

	// const resultClick = () => {
	// 	let resultPane = state.find((pane) => pane.originPaneKey && pane.originPaneKey === props.uniqueKey);
	// 	conditionalViews('Display');
	// 	console.log('HANDLESEARCH', state)
	// 	console.log('HANDLESEARCH resultPane', resultPane)
	// 	if (!resultPane) setResultPaneKey(null);
	// 	else if (resultPaneKey !== resultPane.key) {
	// 		setResultPaneKey(resultPane.key);
	// }
	// }

	useEffect(() => {
		// console.log('pane: ', props.id, {
		// 	uniqueKey: props.uniqueKey,
		// 	props: props,
		// 	paneArr: state,
		// 	searchState: searchState,
		// 	userTags
		// });

		if (
			searchState[props.uniqueKey] &&
			(!userTags.length ||
				!arraysEqual(
					userTags.map(({ value }) => value),
					searchState[props.uniqueKey].userTags.map(({ value }) => value)
				))
		) {
			let formattedUserTags = searchState[props.uniqueKey].userTags.map(({ value, label }) => ({ value, label, selected: false }));
			setUserTags(formattedUserTags);
		}
	}, [searchState[props.uniqueKey]]);
	useEffect(() => {
		if (authState.user.id) {
			getLibraryNCategories({ uniqueKey: props.uniqueKey, userId: props.user.id });
		}
	}, [authState.user.id]);
	useEffect(() => {
		let resultPane = state.find(pane => pane.originPaneKey && pane.originPaneKey === props.uniqueKey);
		// console.log('STATE', state)
		// console.log('HANDLESEARCH resultPane', resultPane)
		if (!resultPane) setResultPaneKey(null);
		else if (resultPaneKey !== resultPane.key) {
			setResultPaneKey(resultPane.key);
			conditionalViews('Display');
		}
	}, [state]);
	const handleSearch = (e, text, updatedSelectedBooks = [...selectedBooks], fromResultsTree = false, fromParsed = false) => {
		/** List of all the names of the volumes */
		// console.log(selectedBooks)
		// Ensure none of the selected books are actually a volume
		// updatedSelectedBooks = updatedSelectedBooks.filter(({ label }) => !volumeBooks.includes(label));
		if (e) e.preventDefault();

		if (fromResultsTree) {
			setSearchingFromResults(true);
		} else if (searchingFromResults) {
			setSearchingFromResults(false);
		}
		// console.log('pre ', updatedSelectedBooks, selectedBooks);
		const volumeTitles = fromParsed ? [] : searchState[props.uniqueKey].library.map(e => e.title);
		const volumeIds = fromParsed ? [] : searchState[props.uniqueKey].library.map(e => e.id);

		updatedSelectedBooks.forEach((e, i) => {
			if (volumeIds.includes(e.value) && volumeTitles.includes(e.label)) {
				updatedSelectedBooks.splice(i, 1);
			}
		});
		if (text) {
			/** A string of all the selected books separated by a '-' */
			let selectedBooksString = updatedSelectedBooks
				.map(({ value }) => value)
				.reduce((acc, cur) => (acc !== 'none' ? `${acc}-${cur}` : cur), 'none');

			// NOTE: I'm not sure why I was filtering out the ids that were also volume ids (the only thing it seemed to do was make the first 5 books of OT unsearchable with filters)
			// let selectedBooksString = updatedSelectedBooks.filter(({ value }) => !formatLibrary().map(({ value }) => value).includes(value)).map(({ value }) => value).reduce((acc, cur) => acc !== 'none' ? `${acc}-${cur}` : cur, 'none');

			/** A string of all the selected tags separated by a '-' */
			let selectedTagsString = userTags
				.filter(({ selected }) => selected)
				.map(({ value }) => value)
				.reduce((acc, cur) => (acc !== 'none' ? `${acc}-${cur}` : cur), 'none');
			/** A string of all the selected categories separated by a '-' */
			let checkedCategoriesString = checkedCategories.reduce((acc, cur) => (acc !== 'none' ? `${acc}-${cur}` : cur), 'none');
			handleAddSearchResult(
				props.uniqueKey,
				text,
				selectedOption,
				checkedCategoriesString,
				selectedTagsString,
				selectedBooksString,
				scripturesOnly
			);
		}
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
		var oldwidth = children.offsetWidth;
		console.log('oldwidth: ' + oldwidth);
		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - oldwidth;

		setTimeout(() => {
			var nwidth = oldwidth;
			var toscrollright = nwidth - rightposition;
			console.log('toscrollright: ' + toscrollright);
			if (remaingLeft > nwidth && toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 500);

		// setTimeout(() => {
		//   const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		// 	panelsBlock.scroll({
		// 		left: panelsBlock.scrollLeft + 500,
		// 		behavior: 'smooth',
		// 	});
		// }, 100);
	};
	const fetchCategories = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
			.then(res => res.json())
			.then(categories => setCategories(categories));
	};
	const conditionalViews = (message, e) => {
		if (message === 'History') {
			setSearchHistory(true);
			setFilterTree(false);
			setDisplay(false);
		} else if (message === 'Filter') {
			setSearchHistory(false);
			setFilterTree(true);
			setDisplay(false);
			setFilterTreeCategoriesAndTags(false);
		} else if (message === 'Display') {
			setSearchHistory(false);
			setFilterTree(false);
			// // TODO: Remove this if it doesn't work
			// setFilterTreeCategoriesAndTags(false);
			setDisplay(true);
		} else if (message === 'categoriesAndTags') {
			setSearchHistory(false);
			setFilterTree(true);
			setDisplay(false);
			setFilterTreeCategoriesAndTags(true);
		}
	};
	const handleCategoryCheck = e => {
		const targetValue = parseInt(e.target.value);
		const valueAlreadyInArr = checkedCategories.indexOf(targetValue);
		if (valueAlreadyInArr !== -1) {
			const filterOutId = checkedCategories.filter(value => value !== targetValue);
			setCheckedCategories(filterOutId);
		} else if (valueAlreadyInArr === -1) {
			setCheckedCategories([...checkedCategories, targetValue]);
		}
	};

	/** Formats the returned tags for checkbox format */
	const formatCheckboxNodes = tags => {
		let formattedTags = tags.map(({ label, value }) => ({ label, value, selected: false }));
		return formattedTags;
	};

	/** Updates userTags array selected status */
	const updateUserTags = tagIndex => {
		let userTagsCopy = [...userTags];
		userTagsCopy[tagIndex].selected = !userTagsCopy[tagIndex].selected;
		setUserTags(userTagsCopy);
	};

	/** Formats the library for the custom checkbox tree */
	const formatLibrary = () => {
		let formattedAllVolumes =
			searchState[props.uniqueKey] &&
			searchState[props.uniqueKey].library &&
			searchState[props.uniqueKey].library.map(({ id, title, order, books, scripture }) => ({
				value: id,
				label: title,
				order,
				scripture,
				children: books.map(({ id, title, order }) => ({ value: id, label: title, order })),
			}));

		return formattedAllVolumes;
	};
	const handleFrequency = async () => {
		if (selectedBooks.length > 0) {
			let updatedSelectedBooks = [...selectedBooks];
			const volumeTitles = searchState[props.uniqueKey].library.map(e => e.title);
			const volumeIds = searchState[props.uniqueKey].library.map(e => e.id);
			let volumes = [];
			let books = [];
			updatedSelectedBooks.forEach((e, i) => {
				if (volumeIds.includes(e.value) && volumeTitles.includes(e.label)) {
					volumes.push(e);
					updatedSelectedBooks.splice(i, 1);
				} else {
					books.push(e);
				}
			});
			await fetch(`${process.env.REACT_APP_BASE_URL}/verses/getWordCount/${updatedSelectedBooks.map(e => e.value).join('-')}`)
				.then(response => response.json())
				.then(documents => {
					handleOpenWordUseReport({
						originPaneKey: props.uniqueKey,
						documents,
						booksString: updatedSelectedBooks.map(e => e.value).join('-'),
						volumes,
						books,
					});
				});
			return;
		}
		// await fetch(`${process.env.REACT_APP_BASE_URL}/verses/getTsVectors/${updatedSelectedBooks.join('-')}`)
		// 	.then(response => response.json())
		// 	.then(documents => {
		// 		console.log(documents);

		// const vectors = documents.map(e =>
		// 	e.document.split(' ').map(e => {
		// 		e = e.split(':');
		// 		let word = e[0];
		// 		let count = e[1].split(',').length;
		// 		return { word, count };
		// 	})
		// );
		// let wordCounts = {};

		// vectors.forEach(innerArray => {
		// 	innerArray.forEach(obj => {
		// 		let word = obj.word;
		// 		let count = obj.count;

		// 		if (wordCounts[word]) {
		// 			wordCounts[word] += count;
		// 		} else {
		// 			wordCounts[word] = count;
		// 		}
		// 	});
		// });

		// let wordCountsArray = Object.keys(wordCounts).map(word => {
		// 	return { word: word, count: wordCounts[word] };
		// });

		// 			console.log(documents);

		// 			setView('frequency');
		// 		});
	};

	/** Function for the checkbox tree to call when filters are enabled/disabled */
	const toggleFilters = enabled => {
		if (enabled !== filtersEnabled) {
			setFiltersEnabled(enabled);
		}
	};

	const mappedCategories = categories.map((e, i) => {
		return (
			<CategoryInputWrapper key={i}>
				<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value={e.id} checked={checkedCategories.some(id => id === e.id)} />
				<CategoryInputLabel>{e.value}</CategoryInputLabel>
			</CategoryInputWrapper>
		);
	});
	return (
		<>
			{/* {console.log('TESTING', state)} */}
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='left' forceDirection={true}>
					<FontAwesomeIcon
						icon={faTimes}
						onClick={async () => {
							props.closePane(props.id);
							searchDispatch({ type: `DELETE_SEARCH_PANE`, payload: { uniqueKey: props.uniqueKey } });
						}}
					/>
				</Tooltip>
			</ClosePaneWrapper>
			<SearchFilterContainer style={view === 'search' ? { height: '290px' } : { height: '60px' }}>
				<SearchHelp target='_blank' rel='noopener noreferrer' href='https://scripturenotes.com/search-cheat-sheet'>
					search help
				</SearchHelp>
				<ViewButtonsContainer {...props.dragHandleProps}>
					<SearchText
						onClick={() => {
							setSelectedBooks([]);
							setView('search');
						}}
						style={{ color: view === 'search' ? '#324599' : '#6d7886', fontWeight: view === 'search' ? '800' : '400' }}
					>
						{' '}
						Search
					</SearchText>
					<SearchText> |</SearchText>
					<SearchText
						onClick={() => {
							setSelectedBooks([]);
							setView('frequency');
						}}
						style={{ color: view === 'frequency' ? '#324599' : '#6d7886', fontWeight: view === 'frequency' ? '800' : '400' }}
					>
						{' '}
						Frequency
					</SearchText>
				</ViewButtonsContainer>
				{view === 'search' && (
					<form
						onSubmit={e => (authState.user.id ? handleSearch(e, searchText || '') : alert('Please sign in or sign up to use Scripture Notes.'))}
					>
						<SearchBarContainer>
							<SearchInputWrapper>
								<SearchInput
									type='text'
									name='searchText'
									value={searchText || ''}
									onChange={e => setSearchText(e.target.value)}
									onBlur={e => {
										handleUpdateSearchPane({
											paneKey: props.uniqueKey,
											searchText: searchText,
											selectedOption: selectedOption,
											searchHistory: searchHistory,
											filterTree: filterTree,
											filterTreeCategoriesAndTags: filterTreeCategoriesAndTags,
											display: display,
											checkedCategories: checkedCategories,
											tagText: tagText,
											userTags: userTags,
											selectedBooks: selectedBooks,
											resultPaneKey: resultPaneKey,
											resultsLibrary: resultsLibrary,
											filtersEnabled: filtersEnabled,
											searchingFromResults: searchingFromResults,
										});
									}}
									autoComplete='off'
									autoFocus={false}
									// autoFocus={false}
								/>
								<SearchIconWrapper>
									<FontAwesomeIcon
										icon={faSearch}
										onClick={e => {
											if (authState.user.id) {
												e.stopPropagation();
												e.persist();
												if (e.ctrlKey || e.metaKey) {
													return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=search&searchQuery=${searchText}`, '_blank');
												} else {
													handleSearch(e, searchText || '');
												}
											} else alert('Please sign in or sign up to use Scripture Notes.');
										}}
									/>
								</SearchIconWrapper>
							</SearchInputWrapper>
						</SearchBarContainer>

						<FilterSearchContainer>
							<div>
								<RadioButtonContainer>
									<input
										id={`${props.uniqueKey} radio-1`}
										value='scripturesOnly'
										type='radio'
										checked={selectedOption === 'scripturesOnly'}
										onChange={() => setSelectedOption('scripturesOnly')}
										onClick={e => conditionalViews('Filter', e)}
									/>
									<RadioLabel htmlFor={`${props.uniqueKey} radio-1`}>Scriptures & Books</RadioLabel>
								</RadioButtonContainer>

								<RadioButtonContainer>
									<input
										// disabled={stripePlanName === 'Free' ? true : false}
										id={`${props.uniqueKey} radio-4`}
										value='verseNotesOnly'
										type='radio'
										checked={selectedOption === 'verseNotesOnly'}
										onChange={e => setSelectedOption('verseNotesOnly')}
										onClick={e => conditionalViews('Filter', e)}
										// disabled={!isProUser()}
									/>
									<RadioLabel
										// style={stripePlanName !== 'Free' ? null : { color: '#d3d3d3' }}
										htmlFor={`${props.uniqueKey} radio-4`}
									>
										Verse Notes
									</RadioLabel>
								</RadioButtonContainer>
							</div>

							<div>
								{/* <RadioButtonContainer>
								<input
									// disabled={stripePlanName === 'Free' ? true : false}
									id={`${props.uniqueKey} radio-2`}
									value='footnote'
									type='radio'
									checked={selectedOption === 'footnote'}
									onChange={e => setSelectedOption('footnote')}
									onClick={e => conditionalViews('Filter', e)}
								// disabled={!isProUser()}
								/>
								<RadioLabel
									// style={stripePlanName !== 'Free' ? null : { color: '#d3d3d3' }}
									htmlFor={`${props.uniqueKey} radio-2`}
								>
									Footnotes
								</RadioLabel>
							</RadioButtonContainer> */}

								<RadioButtonContainer>
									<input
										// disabled={stripePlanName === 'Free' ? true : false}
										id={`${props.uniqueKey} radio-3`}
										value='collectionNotesOnly'
										type='radio'
										checked={selectedOption === 'collectionNotesOnly'}
										onChange={e => setSelectedOption('collectionNotesOnly')}
										onClick={e => conditionalViews('categoriesAndTags', e)}
										// disabled={!isProUser()}
									/>
									<RadioLabel
										// style={stripePlanName !== 'Free' ? null : { color: '#d3d3d3' }}
										htmlFor={`${props.uniqueKey} radio-3`}
									>
										Collection Note
									</RadioLabel>
								</RadioButtonContainer>
							</div>
						</FilterSearchContainer>

						<HisFilResultContainer>
							<HisFilResultButton
								type='button'
								className={`${searchHistory && 'button-highlight'}`}
								onClick={e => conditionalViews('History')}
							>
								History
							</HisFilResultButton>
							<HisFilResultButton
								type='button'
								className={`${filterTree && 'button-highlight'}`}
								onClick={
									selectedOption === 'collectionNotesOnly' ? e => conditionalViews('categoriesAndTags') : e => conditionalViews('Filter')
								}
							>
								{filtersEnabled ? 'Filters - On' : 'Filters'}
							</HisFilResultButton>
							<HisFilResultButton
								type='button'
								className={`${display && 'button-highlight'}`}
								onClick={e => {
									let resultPane = state.find(pane => pane.originPaneKey && pane.originPaneKey === props.uniqueKey);

									conditionalViews('Display');
									if (!resultPane) setResultPaneKey(null);
									else if (resultPaneKey !== resultPane.key) {
										setResultPaneKey(resultPane.key);
									}
								}}
							>
								Results
							</HisFilResultButton>
						</HisFilResultContainer>
					</form>
				)}
			</SearchFilterContainer>

			{view === 'search' ? (
				<SearchHistoryContainer>
					<Scrollbars autoHide>
						{searchHistory ? (
							<SearchHistoryTextWrapper>
								<ListWrapper>
									<ListItem className='history-header'>Search History</ListItem>
									<ListWrapper>
										{searchState[props.uniqueKey] &&
											searchState[props.uniqueKey].searchHistory &&
											searchState[props.uniqueKey].searchHistory.map((value, index) => {
												return (
													<ListItem
														key={index}
														onClick={e =>
															authState.user.id
																? handleSearch(e, value.searchKeyword)
																: alert('Please sign in or sign up to use Scripture Notes.')
														}
													>
														<span>{value.searchKeyword}</span>
													</ListItem>
												);
											})}
									</ListWrapper>
								</ListWrapper>
							</SearchHistoryTextWrapper>
						) : filterTree ? (
							<SearchFilterWrapper>
								{filterTreeCategoriesAndTags ? (
									<>
										<PaddingRightWrapper>
											<CategoryWrapper>
												<FontAwesomeIcon
													icon={faThLarge}
													color='#49A5F4'
													style={{ marginRight: '8px', fontSize: '20px', marginBottom: '7px' }}
												/>
												<h5>Categories</h5>
											</CategoryWrapper>
											{mappedCategories}
											{/* <CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='1' />
												<CategoryInputLabel>Article</CategoryInputLabel>
											</CategoryInputWrapper>
											<CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='2' />
												<CategoryInputLabel>Audio/Video/Image</CategoryInputLabel>
											</CategoryInputWrapper>
											<CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='3' />
												<CategoryInputLabel>Inspiring/Related Story</CategoryInputLabel>
											</CategoryInputWrapper>
											<CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='4' />
												<CategoryInputLabel>Parable/Allegory</CategoryInputLabel>
											</CategoryInputWrapper>
											<CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='5' />
												<CategoryInputLabel>Personal Experience</CategoryInputLabel>
											</CategoryInputWrapper>
											<CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='6' />
												<CategoryInputLabel>Question & Answer</CategoryInputLabel>
											</CategoryInputWrapper>
											<CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='7' />
												<CategoryInputLabel>Quote</CategoryInputLabel>
											</CategoryInputWrapper>
											<CategoryInputWrapper>
												<CategoryInput onChange={e => handleCategoryCheck(e)} type='checkbox' value='8' />
												<CategoryInputLabel>Meeting Notes</CategoryInputLabel>
											</CategoryInputWrapper> */}
											<FilterSpacing />
										</PaddingRightWrapper>

										<PaddingRightWrapper>
											<TagsWrapper>
												<FontAwesomeIcon
													icon={faBookmark}
													color={'#455199'}
													style={{ marginRight: '8px', fontSize: '20px', marginBottom: '7px' }}
												/>
												<h5 style={{ color: '#455199' }}>Tags</h5>
											</TagsWrapper>

											<TagsInputWrapper>
												<form
													onSubmit={e => {
														e.preventDefault();
														handleGetTags({ uniqueKey: props.uniqueKey, text: tagText, userId: props.user.id });
													}}
												>
													<TagsSearchInput
														value={tagText}
														onChange={e => {
															if (e.target.value !== '') {
																handleGetTags({ uniqueKey: props.uniqueKey, text: e.target.value, userId: props.user.id });
															}
															setTagText(e.target.value);
														}}
														type='text'
													/>{' '}
													<SearchAllTagsWrapper
														onClick={() => handleGetTags({ uniqueKey: props.uniqueKey, text: tagText, userId: props.user.id })}
													>
														<FontAwesomeIcon icon={faSignInAlt} />
													</SearchAllTagsWrapper>
												</form>
											</TagsInputWrapper>

											{props.uniqueKey && searchState[props.uniqueKey] && searchState[props.uniqueKey].userTags && (
												<div>
													{searchState[props.uniqueKey].userTags.length === 0 ? (
														<p>Tags not found</p>
													) : (
														<div>
															{userTags.map((tag, i) => (
																<CategoryInputWrapper key={i}>
																	<CategoryInput onChange={e => updateUserTags(i)} type='checkbox' value={tag.value} />
																	<CategoryInputLabel>{tag.label}</CategoryInputLabel>
																</CategoryInputWrapper>
															))}
														</div>
													)}
												</div>
											)}
										</PaddingRightWrapper>
									</>
								) : (
									<>
										<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
											<FilterFlex>
												<FontAwesomeIcon
													style={{ marginBottom: '6px' }}
													icon={faBookOpen}
													color='#455199'
													className='font-awesome-book'
												/>
												<FilterText>Filters</FilterText>
											</FilterFlex>
											<VolumeFilter onClick={() => setSelectedBooks([{ label: 'all' }])} style={{ marginBottom: '6px' }}>
												All
											</VolumeFilter>
											<VolumeFilter onClick={() => setSelectedBooks([{ label: 'scripturesOnly' }])} style={{ marginBottom: '6px' }}>
												Scriptures Only
											</VolumeFilter>
											{filtersEnabled ? (
												<HisFilResultButton
													style={{ width: '26%', padding: 0, marginBottom: 15, marginRight: 16, fontSize: '90%' }}
													type='button'
													onClick={e => {
														setSelectedBooks([]);
														setScripturesOnly(false);
													}}
												>
													Clear Filter
												</HisFilResultButton>
											) : (
												<div style={{ width: '26%', marginRight: 16 }}></div>
											)}
										</div>

										{searchState[props.uniqueKey] && (
											<CustomCheckboxTree
												nodes={formatLibrary()}
												onClick={null}
												onCheck={checked => setSelectedBooks(checked)}
												checked={selectedBooks}
												useCheckboxes={true}
												shouldClear={false}
												toggleFilters={toggleFilters}
												scripturesOnly={scripturesOnly}
											/>
										)}
									</>
								)}
							</SearchFilterWrapper>
						) : (
							display && (
								<SearchFilterWrapper>
									<h5>All</h5>

									{searchState[props.uniqueKey] && resultsLibrary.length ? (
										<CustomCheckboxTree
											nodes={resultsLibrary}
											onClick={book => {
												if (authState.user.id) {
													let newFilteredBooks;
													if (book.children) {
														newFilteredBooks = book.children.map(({ value, label, order }) => ({
															value,
															order,
															label: label.slice(0, label.indexOf(' (')),
														}));
													} else {
														let adjustedLabel = book.label.slice(0, book.label.indexOf(' ('));
														newFilteredBooks = [{ label: adjustedLabel, value: book.value, order: book.order }];
													}

													// setSelectedBooks(newFilteredBooks, (selectedBooks) => handleSearch(null, searchText));
													handleSearch(null, searchText, newFilteredBooks, true);
												} else alert('Please sign in or sign up to use Scripture Notes.');
											}}
											onCheck={checked => setSelectedBooks(checked)}
											checked={selectedBooks}
											useCheckboxes={false}
											shouldClear={false}
										/>
									) : null}
								</SearchFilterWrapper>
							)
						)}
					</Scrollbars>
				</SearchHistoryContainer>
			) : (
				<FrequencyContainer>
					<HisFilResultButton onClick={handleFrequency}>GO</HisFilResultButton>
					<Scrollbars>
						<CustomCheckboxTree
							nodes={formatLibrary()}
							onClick={null}
							onCheck={checked => setSelectedBooks(checked)}
							checked={selectedBooks}
							useCheckboxes={true}
							shouldClear={false}
							toggleFilters={toggleFilters}
							scripturesOnly={scripturesOnly}
							frequency={true}
						/>
					</Scrollbars>
				</FrequencyContainer>
			)}
		</>
	);
};

export default Search;

import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';

const SectionTitle = styled.h3`
	color: #94b3db;

	font-size: 22px;

	margin-top: 10px;
`;
const SearchButton = styled.button`
	width: 100%;
	padding: 5px 5px;
	font-weight: 700;
	font-size: 12px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;
const EmailInput = styled.input`
	display: block;

	width: 100%;

	padding: 6px 10px;

	border: none;

	border-radius: 0;

	color: #000;

	background-color: #ebebeb;
	margin-bottom: 10px;
`;
const ErrorMessage = styled.div`
	color: red;

	margin: 8px 0;

	font-size: 15px;
`;

const SuccessMessage = styled.div`
	color: green;

	margin: 8px 0;

	font-size: 15px;
`;
const UserPlanChange = props => {
	// Global Storage
	//State
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [email, setEmail] = useState('');
	//Ref
	//Methods
	const handleSwitchUserToFree = async () => {
		setError(null);
		setSuccess(null);
		if (email.length > 0) {
			try {
				await fetch(`${process.env.REACT_APP_BASE_URL}/auth/switchUserToFree/${email}`)
					.then(res => res.json())
					.then(res => {
						if (res === 'Could not find user by that email.') {
							setError(res);
							setSuccess(null);
						} else {
							setError(null);
							setSuccess(res);
						}
					});
			} catch (err) {
				console.log(err);
			}
		}
	};
	return (
		<div>
			<SectionTitle>Switch User to Free</SectionTitle>
			<EmailInput type='text' name='email' value={email} placeholder={'Enter user email...'} onChange={e => setEmail(e.target.value)} />
			{success && <SuccessMessage>{success}</SuccessMessage>}
			{error && <ErrorMessage>{error}</ErrorMessage>}
			<SearchButton onClick={() => handleSwitchUserToFree()}>GO</SearchButton>
		</div>
	);
};

export default UserPlanChange;

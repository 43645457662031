import React, { useState, useEffect, useContext } from 'react';
import CustomCheckboxTree from '../Search/CustomCheckboxTree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import '../../assets/css/tags.css';
import { NavContext } from '../../ContextProviders/NavProvider';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { PortalWithState } from 'react-portal';
import faTrashAlt from '@fortawesome/fontawesome-free-solid/faTrashAlt';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';

// The following styles are above the Tags & CN Titles Text.
const CnTitleContainer = styled.div`
	display: flex;
`;
const CnTitle = styled.span`
	color: #6a7583;
	font-size: 14px;
	&:hover {
		background-color: #ececec;
	}
`;
const DateSpan = styled.span`
	font-size: 12px;
	margin-right: 5px;
`;
const DeleteCnWrapper = styled.div`
	margin-left: 3px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: #b2b7be;
	&:hover {
		color: #1a56eb;
	}
`;

const PublicCollection = props => {
	const { value, scrollRight, unique, date, seenList, toggleSeen, hideSeen, uniqueKey, removePublicStatus } = props;
	// Local State
	const [seen, setSeen] = useState(seenList.includes(value.id));
	// Global State
	const { handleAddCollection, dispatch } = useContext(NavContext);
	const { isProUser, authState } = useContext(AuthContext);
	useEffect(() => {
		if (seenList.includes(value.id)) {
			setSeen(true);
		} else setSeen(false);
	}, [seenList]);
	// const isVisable = () => {
	//     if(!hideSeen) return true
	//     if(hideSeen && !seen) return true
	//     return false
	// }

	return (
		<CnTitleContainer>
			<input type='checkbox' value={seen} checked={seen} onChange={() => toggleSeen(seen, value.id)} style={{ marginRight: '5px' }} />
			<CnTitle
				onClick={e => {
					// if (!isProUser()) return alert('This feature is for Pro users only. Please subscribe to support the development of this platform.')
					if (e.ctrlKey || e.metaKey) {
						if (value.owner_id)
							return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&shareCode=${value.share_code}`, '_blank');
						return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=collectionNote&shareCode=${value.share_code}`, '_blank');
					} else {
						scrollRight();
						dispatch({
							type: 'HANDLE_ADD_PANE',
							originPaneKey: uniqueKey,
							payload: { key: `collectionNote_${unique()}`, type: 'collectionNote', collectionId: null, shareCode: value.share_code },
						});
					}
				}}
			>
				<DateSpan style={{ marginRight: '5px' }}>{date}</DateSpan>
				{value.title}
				{` (`}
				{/* {view === 'shared' && value.public ? <span style={{ color: 'red' }}>{`(Public)`}</span> : ''} */}
				<span style={{ color: 'blue' }}> {`${value.name}`}</span>
				{parseInt(value.verse_count) > 0 && (
					<span style={{ color: 'blue' }}> {`- ${value.verse_count} ${parseInt(value.verse_count) > 1 ? 'verses' : 'verse'}`}</span>
				)}
				{parseInt(value.collection_saves) > 0 && (
					<span style={{ color: 'blue' }}> {`- ${value.collection_saves} ${parseInt(value.collection_saves) > 1 ? 'saves' : 'save'}`}</span>
				)}
				{value.share_expires && (
					<span style={{ color: 'red' }}> {`- Expires ${new Date(Date.parse(value.share_expires)).toLocaleDateString('en-US')}`}</span>
				)}
				{`)`}
			</CnTitle>
			{authState.user.id === '37b9bf58-a1f2-46f5-83e8-1dc02d1b2dc0' && (
				<PortalWithState closeOnOutsideClick closeOnEsc>
					{({ openPortal, closePortal, isOpen, portal }) => (
						<>
							<DeleteCnWrapper>
								<Tooltip content='Delete Collection Note'>
									<FontAwesomeIcon key='CollectionNotePortal' icon={faTrashAlt} onClick={openPortal} />
								</Tooltip>
							</DeleteCnWrapper>
							{portal(
								<div>
									<div
										style={{
											top: 0,
											zIndex: 9999999,
											background: '#ddd',
											width: '100%',
											height: '100%',
											minHeight: '392px',
											opacity: '0.6',
											position: 'absolute',
										}}
									/>

									<DeletePopUpWrapper>
										<p>Are you sure you want to remove this from public collections?</p>
										<DeletePopUpBtnWrapper>
											<DeletePopUpBtn
												onClick={closePortal}
												onClickCapture={() => {
													removePublicStatus(value.share_code);
												}}
											>
												Remove
											</DeletePopUpBtn>
											<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
										</DeletePopUpBtnWrapper>
									</DeletePopUpWrapper>
								</div>
							)}
						</>
					)}
				</PortalWithState>
			)}
		</CnTitleContainer>
	);
};

export default PublicCollection;

import React, { useReducer, createContext } from 'react';

export const AnalyticsProviderState = createContext();

const initialState = {};
const reducer = (state, action) => {
	switch (action.type) {
		case `FETCH_VOLUMES_BOOKS_CHAPTERS`: {
			return {
				...state,
				[action.payload.uniqueKey]: {
					volumes: action.payload.volumes,
					books: action.payload.books,
					chapters: action.payload.chapters,
					search: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].search !== '' ? state[action.payload.uniqueKey].search : '',
				},
			};
		}
		case `SHOW_USERS`: {
			return {
				...state,
				[action.payload.uniqueKey]: {
					users: action.payload.users,
				},
			};
		}
		case `SET_SEARCH`: {
			return {
				...state,
				[action.payload.uniqueKey]: {
					volumes: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].volumes ? state[action.payload.uniqueKey].volumes : [],
					books: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].books ? state[action.payload.uniqueKey].books : [],
					chapters: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].chapters ? state[action.payload.uniqueKey].chapters : [],
					search: action.payload.search,
				},
			};
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

export const AnalyticsProvider = ({ children }) => {
	const [analyticsState, analyticsDispatch] = useReducer(reducer, initialState);

	const fetchAnalytics = async key => {
		let users = await fetch(`${process.env.REACT_APP_BASE_URL}/analytics/getAllUsers`)
			.then(response => response.json())
			.then(users => {
				return users;
			});

		analyticsDispatch({ type: 'SHOW_USERS', payload: { users } });
		// analyticsDispatch({ type: 'FETCH_VOLUMES_BOOKS_CHAPTERS', payload: { volumes, books, chapters, topicalGuide, uniqueKey: key } });
	};

	return <AnalyticsProviderState.Provider value={{ analyticsState, analyticsDispatch, fetchAnalytics }}>{children}</AnalyticsProviderState.Provider>;
};

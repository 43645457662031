import React, { useState, useEffect, useContext } from 'react';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import StudyHelps from '../StudyHelps/StudyHelps';
import Chapters from './Chapters.js';
import UserLibrary from './UserLibrary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faInfoCircle } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import Fuse from 'fuse.js';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { PortalWithState } from 'react-portal';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
// import { StudyHelpsContext } from '../../ContextProviders/StudyHelpsProvider';

const queryString = require('query-string');

// The following styles are everything above the Old Testament Text.
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const SearchInputContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
`;

const LibraryText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 23px;
`;

const SearchBarContainer = styled.div`
	position: relative;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
	font-size: 15.5px;
`;

const SearchIconWrapper = styled.button`
	color: #7d8085;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
// The following styles are everything below the search input.
const LibraryVolumeContainer = styled.div`
	padding-left: 25px;
	position: relative;
	padding-bottom: 13px;
	height: 97%;
	min-width: 315px;
`;

const BackButtonDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;

const VolumeDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	font-weight: 500;
	&:hover {
		background-color: #ececec;
	}
	display: flex;
	align-items: center;
`;

const BookTitle = styled.div``;

const UserLibraryContainer = styled.div`
	display: flex;
	padding-left: 25px;
	padding-bottom: 13px;
`;

const UserLibraryButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 5px;
	line-height: 10px;
	font-weight: 700;
	font-size: 15px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	border-radius: 10px;
	cursor: pointer;
	height: 80%;
	&:hover {
		background-color: #324599;
		color: rgb(255, 255, 255);
	}
	&:focus {
		outline: none;
	}
`;

const HeaderContainer = styled.div`
	display: flex;
`;

const ManageLibraryButton = styled.button``;

const VolumeTitleContainer = styled.div`
	display: flex;
`;
const TitleContainer = styled.div`
	display: flex;
`;

const InfoIconContainer = styled.div`
	padding-top: 5px;
	margin-left: 10px;
	&:hover {
		color: #324599;
	}
`;

const AboutWrapper = styled.div``;
const Library = props => {
	const [volumes, setVolumes] = useState([]);
	const [books, setBooks] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [search, setSearch] = useState('');
	const [focused, setFocused] = useState(false);
	const [showStudyHelps, setShowStudyHelps] = useState(false);
	const [displayUserLibrary, setDisplayUserLibrary] = useState(false);
	// Global Storage
	const { libraryState, libraryDispatch, fetchVolumesBooksNChapters } = useContext(LibraryProviderState);
	const { handleOpenVolume, handleOpenBook, handleAddStudyHelpPane, state, dispatch } = useContext(NavContext);
	const { chapterHistoryDispatch, chapterHistoryState } = useContext(ChapterHistoryContext);
	const { authState, isProUser } = useContext(AuthContext);
	// const {studyHelpsState, studyHelpsDispatch} = useContext(StudyHelpsContext)
	useEffect(() => {
		fetchVolumesBooksNChapters(props.uniqueKey, props.user.id);

		let currentPaneIndex = state.findIndex(({ key }) => key === props.uniqueKey);
		if (currentPaneIndex === -1) currentPaneIndex = state.length - 1;

		if (state[currentPaneIndex]['volumeTitle']) {
			if (state[currentPaneIndex]['bookName']) {
				libraryDispatch({
					type: 'SET_SEARCH',
					payload: { search: `${state[currentPaneIndex]['volumeTitle']} / ${state[currentPaneIndex]['bookName']}`, uniqueKey: props.uniqueKey },
				});
			} else {
				libraryDispatch({ type: 'SET_SEARCH', payload: { search: state[currentPaneIndex]['volumeTitle'], uniqueKey: props.uniqueKey } });
			}
		}

		if (state[currentPaneIndex]['selectedTopic']) {
			// setShowStudyHelps(true)
		}

		let parsed = queryString.parse(window.location.search);
		const shareLinks = JSON.parse(localStorage.getItem('share-links'));

		if (shareLinks) {
			chapterHistoryDispatch({ type: 'SET_URL_QUERY_RENDERED', payload: true });
			let { libsearch } = shareLinks;
			libraryDispatch({ type: 'SET_SEARCH', payload: { search: libsearch, uniqueKey: props.uniqueKey } });
		}
		if (parsed && parsed['libsearch'] && !chapterHistoryState.hasLibraryQueryRendered) {
			chapterHistoryDispatch({ type: 'SET_LIBRARY_URL_QUERY_RENDERED', payload: true });
			let { libsearch } = parsed;
			libraryDispatch({ type: 'SET_SEARCH', payload: { search: libsearch, uniqueKey: props.uniqueKey } });
		}
	}, []);
	useEffect(() => {
		if (libraryState[props.uniqueKey]) {
			addProperties();
			setSearch(libraryState[props.uniqueKey].search);
		}
	}, [libraryState[props.uniqueKey]]);
	useEffect(() => {
		if (authState.user.id) {
			fetchVolumesBooksNChapters(props.uniqueKey, authState.user.id);
		}
	}, [authState.user.id]);
	const addProperties = () => {
		let volumes = libraryState[props.uniqueKey].volumes;
		let books = libraryState[props.uniqueKey].books;
		let chapters = libraryState[props.uniqueKey].chapters;
		volumes = _.map(volumes, volume => {
			volume.books = _(books)
				.filter({ volume_id: volume.id })
				.map(book => {
					book.best = false;
					book.chapters = _(chapters)
						.filter({ book_id: book.id })
						.map(chapter => {
							if (!chapter.search) chapter.search = book.title + ' / ' + chapter.order;
							if (!chapter.full) chapter.full = volume.title + ' / ' + book.title + ' / ' + chapter.order;
							return chapter;
						})
						.value();
					return book;
				})
				.value();
			return volume;
		});
		setVolumes(volumes);
		setBooks(books);
		setChapters(chapters);
	};

	const handleSetVolumes = data => {
		let volumes = data.volumes;
		let books = data.books;
		let chapters = data.chapters;
		volumes = _.map(volumes, volume => {
			volume.books = _(books)
				.filter({ volume_id: volume.id })
				.map(book => {
					book.best = false;
					book.chapters = _(chapters)
						.filter({ book_id: book.id })
						.map(chapter => {
							if (!chapter.search) chapter.search = book.title + ' / ' + chapter.order;
							if (!chapter.full) chapter.full = volume.title + ' / ' + book.title + ' / ' + chapter.order;
							return chapter;
						})
						.value();
					return book;
				})
				.value();
			return volume;
		});
		setVolumes(volumes);
		setBooks(books);
		setChapters(chapters);
	};

	const handleSubmit = event => {
		event.preventDefault();

		if (search.length > 0) {
			// term = first part of the string / verse term is the last part
			var parts = search.match(/^([\d\s]*[a-zA-Z\'\s]+)(\d+)?([\s\:\,\.\-\;]?)(\d+)?$/);

			var bookTerm = parts ? parts[1] : null;
			var chapterTerm = parts ? parts[2] : null;
			var colonTerm = parts ? parts[3] : null;
			var verseTerm = parts ? parts[4] : null;
		}
	};

	const handleChooseBook = (e, volume, book) => {
		e.preventDefault();
		if (book.id) {
			libraryDispatch({ type: 'SET_SEARCH', payload: { search: volume.title + ' / ' + book.title, uniqueKey: props.uniqueKey } });
		}
	};

	const handleBack = e => {
		e.preventDefault();
		libraryDispatch({ type: 'SET_SEARCH', payload: { search: '', uniqueKey: props.uniqueKey } });
	};

	const handleChooseVolume = (e, volume) => {
		
		e.preventDefault();
		if (volume.id) {
			libraryDispatch({ type: 'SET_SEARCH', payload: { search: volume.title, uniqueKey: props.uniqueKey } });
		}
	};

	const searchVolumes = (term, volumes, books, chapters) => {
		term = term ? term.toLowerCase().trim() : '';
		// term = first part of the string / verse term is the last part
		term = term.replace(/(\d*)([\s\:\,\.\-\;]*)(\d*)$/, '');

		if (term.length > 0) {
			let exact_chapters_by_book = searchExactBook(term, volumes, books, chapters);
			if (exact_chapters_by_book.length >= 1) {
				chapters = exact_chapters_by_book;
			} else {
				// chapters = [];
				let exact_chapters_by_volume = searchExactVolume(term, volumes, books, chapters);
				if (exact_chapters_by_volume.length >= 1) {
					let mainVolumes = volumes.map(volume => volume.title.toLowerCase());
					let match = mainVolumes.find(v => term.includes(v));

					chapters = match ? exact_chapters_by_volume : [];

					// chapters = mainVolumes.includes(term) ? exact_chapters_by_volume : [];
					// chapters = exact_chapters_by_volume;
				} else {
					chapters = searchFuzzy(term, chapters);
				}
			}

			// Book of "Mormon"
			// "Mormon"
		} else {
			chapters = searchFuzzy(term, chapters);
		}

		let book_ids = _(chapters).map('book_id').uniq().value();
		books = _.filter(books, x => _.includes(book_ids, x.id));

		let volume_ids = _(books).map('volume_id').uniq().value();
		// volumes = _.filter(volumes, x => _.includes(volume_ids, x.id) || x.title === 'Topical Guide');
		volumes = _.filter(volumes, x => _.includes(volume_ids, x.id));

		volumes = _.map(volumes, volume => {
			volume.books = _(books)
				.filter({ volume_id: volume.id })
				.map(book => {
					book.chapters = _(chapters).filter({ book_id: book.id }).value();
					book.best = !!_.find(book.chapters, { best: true });
					return book;
				})
				.value();
			return volume;
		});

		return volumes;
	};

	const unique = () => {
		return new Date().getTime().toString(36);
	};
	const searchExactBook = (term, volumes, books, chapters) => {
		term = term ? term.toLowerCase().trim() : '';
		if (term.length > 0) {
			if (term !== 'doctrine and covenants') {
				chapters = _.filter(chapters, x => x.search.toLowerCase().indexOf(term) === 0);
				// loop through the chapters and assign a score from 0 to 1
				chapters = chapters.map((x, i) => {
					x.best = i === 0;
					x.score = 1;
					return x;
				});
			} else {
				return [];
			}
		}

		return chapters;
	};

	const searchExactVolume = (term, volumes, books, chapters) => {
		term = term ? term.toLowerCase().trim() : '';

		if (term.length > 0) {
			chapters = _.filter(chapters, x => x.full.toLowerCase().indexOf(term) >= 0);

			// loop through the chapters and assign a score from 0 to 1
			chapters = chapters.map((x, i) => {
				x.best = i === 0;
				x.score = 1;
				return x;
			});
		}
		return chapters;
	};

	const searchFuzzy = (term, chapters) => {
		term = term ? term.toLowerCase().trim() : '';
		if (term.length > 0) {
			let options = {
				tokenize: true,
				matchAllTokens: true,
				includeScore: true,
				threshold: 0.5,
				location: 0,
				distance: 20,
				maxPatternLength: 32,
				minMatchCharLength: 0,
				keys: ['search'],
			};

			let fuse = new Fuse(chapters, options); // "list" is the item array
			chapters = _(fuse.search(term))
				.map((x, i) => {
					x.item.best = i === 0;
					x.item.score = x.score;
					return x;
				})
				.map('item')
				.value();
		}
		return chapters;
	};
	let scriptureVolumes = volumes.filter(e => !e.pro_volume);
	let proVolumes = volumes.filter(e => e.pro_volume);
	let volumesAndBooks = volumes.length !== 0 ? searchVolumes(search, scriptureVolumes, books, chapters) : [];
	let proVolumesAndBooks = volumes.length !== 0 ? searchVolumes(search, proVolumes, books, chapters) : [];
	let currentPaneIndex = state.findIndex(({ key }) => key === props.uniqueKey);
	// const handleCloseSHPane = (id, selectedTopic = false) => {
	// 	if(!selectedTopic){
	// 		studyHelpsDispatch({type: 'TOGGLE_SH_CLOSE'})
	// 		props.closePane(id)
	// 	} else{
	// 		props.closePane(id)
	// 	}
	// }
	return (
		<>
			{props.studyPaneOpen ? (
				<StudyHelps
					id={props.id}
					user={authState.user}
					uniqueKey={props.uniqueKey}
					dragHandleProps={props.dragHandleProps}
					closePane={id => props.closePane(id)}
					backToMain={() => handleAddStudyHelpPane({ originPaneKey: props.uniqueKey, open: false })}
					selectedOption={
						(currentPaneIndex || currentPaneIndex === 0) && state[currentPaneIndex] && state[currentPaneIndex]['selectedOption']
							? state[currentPaneIndex]['selectedOption']
							: null
					}
					selectedTopic={
						(currentPaneIndex || currentPaneIndex === 0) && state[currentPaneIndex] && state[currentPaneIndex]['selectedTopic']
							? state[currentPaneIndex]['selectedTopic']
							: null
					}
					selectedLetter={
						(currentPaneIndex || currentPaneIndex === 0) && state[currentPaneIndex] && state[currentPaneIndex]['selectedLetter']
							? state[currentPaneIndex]['selectedLetter']
							: null
					}
					proUser={isProUser()}
				/>
			) : (
				<>
					<ClosePaneWrapper>
						<Tooltip content='Close Pane' direction='up' forceDirection={true}>
							<FontAwesomeIcon
								icon={faTimes}
								onClick={() => {
									props.closePane(props.id);
									//libraryDispatch({ type: `DELETE_LIBRARY_PANE`, payload: { uniqueKey: props.uniqueKey } })
								}}
							/>
						</Tooltip>
					</ClosePaneWrapper>
					<SearchInputContainer>
						<HeaderContainer>
							<LibraryText {...props.dragHandleProps}>Library</LibraryText>
							{!displayUserLibrary ? (
								<UserLibraryButton onClick={() => authState.user.id && setDisplayUserLibrary(true)}>Manage</UserLibraryButton>
							) : (
								<UserLibraryButton onClick={() => authState.user.id && setDisplayUserLibrary(false)}>Back</UserLibraryButton>
							)}
						</HeaderContainer>
						<SearchBarContainer>
							<form onSubmit={handleSubmit}>
								<SearchInput
									type='text'
									className='input'
									value={search}
									onChange={e => setSearch(e.target.value)}
									autoComplete='off'
									onFocus={() => setFocused(true)}
									// onBlur={() => libraryDispatch({ type: 'SET_SEARCH', payload: { search, uniqueKey: props.uniqueKey } })}
									autoFocus={false}
									name='text'
								/>

								<SearchIconWrapper type='submit'>
									<FontAwesomeIcon icon={faSearch} />
								</SearchIconWrapper>
							</form>
						</SearchBarContainer>
					</SearchInputContainer>
					{/* <UserLibraryContainer>
						<UserLibraryButton onClick={() => setDisplayUserLibrary(false)}>
							My Library
						</UserLibraryButton>
						<UserLibraryButton style={{ 'marginLeft': 5 }} onClick={() => setDisplayUserLibrary(true)}>
							Change Library
						</UserLibraryButton>
					</UserLibraryContainer> */}
					{!displayUserLibrary ? (
						<LibraryVolumeContainer>
							{search.length > 0 ? (
								<BackButtonDisplay
									onClick={e => {
										e.stopPropagation();
										e.persist();
										if (e.altKey) {
											dispatch({ type: 'HANDLE_ADD_PANE', payload: { key: `library_${unique()}`, type: 'library' } });
										} else if (e.ctrlKey || e.metaKey) {
											window.open(`${process.env.REACT_APP_MAIN_URL}?pane=library`, '_blank');
										} else {
											handleBack(e);
										}
									}}
								>
									◀ Library
								</BackButtonDisplay>
							) : null}
							<Scrollbars autoHide>
								{volumesAndBooks ? (
									<div>
										{volumesAndBooks.map((volume, id) => {
											if (!volume.pro_volume) {
												return (
													<div key={id}>
														<VolumeDisplay>
															<VolumeTitleContainer>
																<h5
																	onClick={e => {
																		e.stopPropagation();
																		e.persist();
																		if (e.altKey) {
																			handleOpenVolume({ volumeTitle: volume.title, originPaneKey: props.uniqueKey });
																		} else if (e.ctrlKey || e.metaKey) {
																			window.open(
																				`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${volume.title}`,
																				'_blank'
																			);
																		} else {
																			handleChooseVolume(e, volume);
																		}
																	}}
																>
																	{volume.title}
																</h5>
																<PortalWithState closeOnOutsideClick closeOnEsc>
																	{({ openPortal, closePortal, isOpen, portal }) => (
																		<>
																			{volume.about !== null && (
																				<InfoIconContainer>
																					<Tooltip content='About'>
																						<FontAwesomeIcon key='ProjectPortal' icon={faInfoCircle} onClick={openPortal} />
																					</Tooltip>
																				</InfoIconContainer>
																			)}
																			{portal(
																				<div>
																					<div
																						style={{
																							top: 0,
																							zIndex: 9999999,
																							background: '#ddd',
																							width: '100%',
																							height: '100%',
																							minHeight: '392px',
																							opacity: '0.6',
																							position: 'absolute',
																						}}
																					/>

																					<DeletePopUpWrapper>
																						<p dangerouslySetInnerHTML={{ __html: volume.about }}></p>
																						{/* <DeletePopUpBtnWrapper> */}
																						<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
																						{/* </DeletePopUpBtnWrapper> */}
																					</DeletePopUpWrapper>
																				</div>
																			)}
																		</>
																	)}
																</PortalWithState>
															</VolumeTitleContainer>
														</VolumeDisplay>
														<div>
															{volume.books && search.length > 0
																? volume.books.map((book, id) => {
																		return (
																			<div key={id}>
																				{/* Fix styling */}
																				{/* Add a click event to update the search string to be the title of the book we clicked */}
																				<TitleContainer>
																					<VolumeDisplay
																						onClick={e => {
																							e.stopPropagation();
																							e.persist();
																							if (e.altKey) {
																								handleOpenBook({
																									bookName: book.title,
																									volumeTitle: volume.title,
																									originPaneKey: props.uniqueKey,
																									//open: true,
																								});
																							} else if (e.ctrlKey || e.metaKey) {
																								window.open(
																									`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${volume.title} / ${book.title}`,
																									'_blank'
																								);
																							} else {
																								handleChooseBook(e, volume, book);
																							}
																						}}
																					>
																						{volume.books.length === 1 ? null : '— '}
																						{book.title}
																						{/* {book.title.includes(volume.title) ? null : book.title} */}
																						<PortalWithState closeOnOutsideClick closeOnEsc>
																							{({ openPortal, closePortal, isOpen, portal }) => (
																								<>
																									{book.about !== null && (
																										<InfoIconContainer>
																											<Tooltip content='About'>
																												<FontAwesomeIcon
																													key='ProjectPortal'
																													icon={faInfoCircle}
																													onClick={openPortal}
																												/>
																											</Tooltip>
																										</InfoIconContainer>
																									)}
																									{portal(
																										<div>
																											<div
																												style={{
																													top: 0,
																													zIndex: 9999999,
																													background: '#ddd',
																													width: '100%',
																													height: '100%',
																													minHeight: '392px',
																													opacity: '0.6',
																													position: 'absolute',
																												}}
																											/>

																											<DeletePopUpWrapper>
																												<p dangerouslySetInnerHTML={{ __html: book.about }}></p>
																												<DeletePopUpBtnWrapper>
																													<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
																												</DeletePopUpBtnWrapper>
																											</DeletePopUpWrapper>
																										</div>
																									)}
																								</>
																							)}
																						</PortalWithState>
																						{/* {lds_book.name} */}
																					</VolumeDisplay>
																				</TitleContainer>

																				<div>
																					{volume.books.length === 1 ? (
																						<Chapters
																							chapters={book.chapters}
																							uniqueKey={props.uniqueKey}
																							proUser={isProUser()}
																							userId={authState.user.id ? true : false}
																						/>
																					) : null}
																				</div>
																			</div>
																		);
																  })
																: null}
														</div>
													</div>
												);
											}
										})}

										{!search && authState.user.id && (
											<VolumeDisplay
												onClick={e => {
													e.stopPropagation();
													e.persist();
													handleAddStudyHelpPane({ originPaneKey: props.uniqueKey, open: true });
												}}
											>
												<h5>Study Helps</h5>
											</VolumeDisplay>
										)}
										{proVolumesAndBooks.map((volume, id) => {
											if (
												volume.public ||
												props.user.email === 'test@email.com' ||
												props.user.email === 'oak@oaknorton.com' ||
												props.user.email === 'todd@brightbridgeweb.com'
											) {
												return (
													<div key={id}>
														<VolumeDisplay>
															<VolumeTitleContainer>
																<h5
																	onClick={e => {
																		if (isProUser()) {
																			
																			e.stopPropagation();
																			e.persist();
																			if (e.altKey) {
																				handleOpenVolume({ volumeTitle: volume.title, originPaneKey: props.uniqueKey });
																			} else if (e.ctrlKey || e.metaKey) {
																				window.open(
																					`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${volume.title}`,
																					'_blank'
																				);
																			} else {
																				handleChooseVolume(e, volume);
																			}
																		} else {
																			alert('You must be a pro user to access this title.');
																		}
																	}}
																>
																	{volume.title}
																</h5>

																<PortalWithState closeOnOutsideClick closeOnEsc>
																	{({ openPortal, closePortal, isOpen, portal }) => (
																		<>
																			{volume.about !== null && (
																				<InfoIconContainer>
																					<Tooltip content='About'>
																						<FontAwesomeIcon key='ProjectPortal' icon={faInfoCircle} onClick={openPortal} />
																					</Tooltip>
																				</InfoIconContainer>
																			)}
																			{portal(
																				<div>
																					<div
																						style={{
																							top: 0,
																							zIndex: 9999999,
																							background: '#ddd',
																							width: '100%',
																							height: '100%',
																							minHeight: '392px',
																							opacity: '0.6',
																							position: 'absolute',
																						}}
																					/>

																					<DeletePopUpWrapper>
																						<p dangerouslySetInnerHTML={{ __html: volume.about }}></p>
																						<DeletePopUpBtnWrapper>
																							<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
																						</DeletePopUpBtnWrapper>
																					</DeletePopUpWrapper>
																				</div>
																			)}
																		</>
																	)}
																</PortalWithState>
															</VolumeTitleContainer>
														</VolumeDisplay>
														<div>
															{volume.books && search.length > 0
																? volume.books.map((book, id) => {
																		return (
																			<div key={id}>
																				{/* Fix styling */}
																				{/* Add a click event to update the search string to be the title of the book we clicked */}
																				<VolumeDisplay>
																					<BookTitle
																						onClick={e => {
																							if (isProUser()) {
																								e.stopPropagation();
																								e.persist();
																								if (e.altKey) {
																									handleOpenBook({
																										bookName: book.title,
																										volumeTitle: volume.title,
																										originPaneKey: props.uniqueKey,
																										//open: true,
																									});
																								} else if (e.ctrlKey || e.metaKey) {
																									window.open(
																										`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${volume.title} / ${book.title}`,
																										'_blank'
																									);
																								} else {
																									handleChooseBook(e, volume, book);
																								}
																							} else {
																								alert('You must be a pro user to access this title.');
																							}
																						}}
																					>
																						{volume.books.length === 1 && isProUser() ? null : '— '}
																						{book.title}
																						{/* {book.title.includes(volume.title) ? null : book.title} */}
																						{/* {book.title.includes('Doctrine and Covenants') && isProUser() ? null : book.title} */}
																					</BookTitle>
																					<PortalWithState closeOnOutsideClick closeOnEsc>
																						{({ openPortal, closePortal, isOpen, portal }) => (
																							<>
																								{book.about !== null && (
																									<InfoIconContainer>
																										<Tooltip content='About'>
																											<FontAwesomeIcon
																												key='ProjectPortal'
																												icon={faInfoCircle}
																												onClick={openPortal}
																											/>
																										</Tooltip>
																									</InfoIconContainer>
																								)}
																								{portal(
																									<div>
																										<div
																											style={{
																												top: 0,
																												zIndex: 9999999,
																												background: '#ddd',
																												width: '100%',
																												height: '100%',
																												minHeight: '392px',

																												opacity: '0.6',
																												position: 'absolute',
																											}}
																										/>

																										<DeletePopUpWrapper>
																											<p dangerouslySetInnerHTML={{ __html: book.about }}></p>
																											{/* <DeletePopUpBtnWrapper> */}
																											<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
																											{/* </DeletePopUpBtnWrapper> */}
																										</DeletePopUpWrapper>
																									</div>
																								)}
																							</>
																						)}
																					</PortalWithState>
																					{/* {lds_book.name} */}
																				</VolumeDisplay>

																				<div>
																					{volume.books.length === 1 && isProUser() ? (
																						<Chapters
																							chapters={book.chapters}
																							uniqueKey={props.uniqueKey}
																							proUser={isProUser()}
																							userId={authState.user.id ? true : false}
																						/>
																					) : null}
																				</div>
																			</div>
																		);
																  })
																: null}
														</div>
													</div>
												);
											}
										})}
									</div>
								) : null}
								<div></div>
							</Scrollbars>
						</LibraryVolumeContainer>
					) : (
						<UserLibrary
							volumes={libraryState[props.uniqueKey].volumes}
							user={props.user}
							fetchVolumesBooksNChapters={fetchVolumesBooksNChapters}
							setDisplayUserLibrary={setDisplayUserLibrary}
							uniqueKey={props.uniqueKey}
							handleSetVolumes={handleSetVolumes}
							libraryDispatch={libraryDispatch}
						/>
					)}
				</>
			)}
		</>
	);
};

export default Library;

import { Children } from 'react';

export const goodToProceedToggle = () => {
	if (window.getSelection().rangeCount > 0) {
		if (window.getSelection().getRangeAt(0).commonAncestorContainer.nodeName == 'UL') {
			window.getSelection().removeAllRanges();
			return false;
		}
		if (window.getSelection().getRangeAt(0).commonAncestorContainer.nodeName == 'LI') {
			window.getSelection().removeAllRanges();
			return false;
		}
	}
	return true;
};

const replaceAll = (str, find, replace) => {
	return str.replace(new RegExp(find, 'g'), replace);
};

export const replaceAllMarks = (str, findArr, replace) => {
	for (var i = 0; i < findArr.length; i++) {
		while (true) {
			str = str.replace(new RegExp(findArr[i], 'g'), replace);
			if (str.indexOf(findArr[i]) == -1) break;
		}
	}
	return str;
};

const getPreviousSiblings = (elem, filter) => {
	var sibs = [];
	while (true) {
		if (elem.parentElement.nodeName == 'DIV') break;
		elem = elem.parentElement;
	}
	while ((elem = elem.previousSibling)) {
		sibs.push(elem);
	}
	return sibs;
};

const getNextSiblings = (elem, filter) => {
	var sibs = [];
	while (true) {
		if (elem.parentElement.nodeName == 'DIV') break;
		elem = elem.parentElement;
	}
	while ((elem = elem.nextSibling)) {
		sibs.push(elem);
	}
	return sibs;
};

const getFirstNodeLength = elem => {
	elem = elem.firstChild;
	var tmpVal = 0;
	if (elem.nodeType != 3) {
		//ignore the text length as text do not use any tag
		console.log('in1');
		tmpVal = elem.nodeName.length + 2;
	}
	return tmpVal;
};

const getLastNodeLength = elem => {
	elem = elem.lastChild;
	var tmpVal = 0;
	if (elem.nodeType != 3) {
		//ignore the text length as text do not use any tag
		console.log('in2');
		tmpVal = elem.nodeName.length + 2;
	}
	return tmpVal;
};

const unwrap = elem => {
	var pa = elem.parentNode;
	while (elem.firstChild) {
		pa.insertBefore(elem.firstChild, elem);
	}
};

const bbwContentGetter = () => {
	const elems = {
		mainContainer: document.querySelector('[data-bbw-content]'),
		tempContainer: document.createElement('div'),
	};
	return elems;
};

/*const doClear=()=>{

    //UNBOLD CODE -------------------------------------- START HERE
    var underlineTag="span";
    var selection = window.getSelection().getRangeAt(0);
    let main=bbwContentGetter().mainContainer;
    let out=document.createElement('div');

    console.log("DO UNDO-UNDERLINE...");
    var thisNode=selection.startContainer;
    while(true){
      console.log(">"+thisNode.nodeName)
      if(thisNode.nodeName==underlineTag.toUpperCase()){
        
        var style=thisNode.getAttribute('style');
        console.log(style);

        let selectedText=selection.extractContents();
        var mark=document.createElement("mark");
        mark.append(selectedText);
        out.innerHTML="";
        out.append(mark);

        out.innerHTML=replaceAll(out.innerHTML,"<"+underlineTag+" style=\""+style+"\" >","");
        out.innerHTML=replaceAll(out.innerHTML,"</"+underlineTag+">","");


        selection.insertNode(out.firstChild);

        var tmp=thisNode.innerHTML;
        tmp="<"+underlineTag+" style=\""+style+"\" >"+tmp+"</"+underlineTag+">";
        tmp=replaceAll(tmp,"<mark>","</"+underlineTag+"><mark>");
        tmp=replaceAll(tmp,"</mark>","</mark><"+underlineTag+" style=\""+style+"\" >");
        out.innerHTML=tmp;
      
        thisNode.innerHTML=out.innerHTML;
        var selectedLength=main.querySelector("mark").textContent.length;
        unwrap(thisNode)

        var tmpMarks=main.querySelector("mark")
        const range = document.createRange();
        range.selectNodeContents(tmpMarks);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        setToggleButtons(tmpMarks)
        
      }

      thisNode=thisNode.parentNode;
      if(thisNode.nodeName=="DIV" && thisNode.getAttribute('data-bbw-content')=="true"){      
        break;
      }
    }
    out.remove();
    //UNBOLD CODE -------------------------------------- END HERE
  
}*/

const doClear = () => {
	const tagsToClear = ['<strong>', '</strong>', '<b>', '</b>', '<em>', '</em>', '<u>', '</u>', '<s>', '</s>', '<mark>', '</mark>'];

	//doClear CODE -------------------------------------- START HERE
	var underlineTag = 'strong';
	var selection = window.getSelection().getRangeAt(0);
	console.log(selection);
	//return;
	let main = bbwContentGetter().mainContainer;
	var thisNode = selection.startContainer;

	console.log('>' + thisNode.nodeName);

	let selectedText = selection.extractContents();
	var mark = document.createElement('span');
	mark.append(selectedText);
	mark.innerHTML = mark.innerText;
	mark.setAttribute('style', 'background-color:#fafafa; color:#212529;');

	selection.insertNode(mark);

	try {
		doUnBold();
	} catch {}
	try {
		doUndoItalic();
	} catch {}
	try {
		doUndoUnderline();
	} catch {}
	try {
		doUndoStrike();
	} catch {}
};

const doBold = () => {
	//BOLD CODE -------------------------------------- START HERE
	console.log('DO BOLD...');
	let final = document.getElementById('final');
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');
	var selection = window.getSelection().getRangeAt(0);
	console.log(window.getSelection().getRangeAt(0));
	let selectedText = selection.extractContents();

	var newElem = document.createElement('strong');

	newElem.append(selectedText);
	out.append(newElem);
	selection.insertNode(out.firstChild);

	var tmpMarks = main.querySelector('mark');
	const range = document.createRange();
	range.selectNodeContents(newElem);
	window.getSelection().removeAllRanges();
	window.getSelection().addRange(range);

	console.log(window.getSelection().getRangeAt(0));
	//BOLD CODE -------------------------------------- END HERE
};
const doUnBold = () => {
	//UNBOLD CODE -------------------------------------- START HERE
	var underlineTag = 'strong';
	var selection = window.getSelection().getRangeAt(0);
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');

	console.log('DO UNDO-UNDERLINE...');
	var thisNode = selection.startContainer;
	while (true) {
		console.log('>' + thisNode.nodeName);
		if (thisNode.nodeName == underlineTag.toUpperCase()) {
			let selectedText = selection.extractContents();
			var mark = document.createElement('mark');
			mark.append(selectedText);
			out.innerHTML = '';
			out.append(mark);

			out.innerHTML = replaceAll(out.innerHTML, '<' + underlineTag + '>', '');
			out.innerHTML = replaceAll(out.innerHTML, '</' + underlineTag + '>', '');

			selection.insertNode(out.firstChild);

			var tmp = thisNode.innerHTML;
			tmp = '<' + underlineTag + '>' + tmp + '</' + underlineTag + '>';
			tmp = replaceAll(tmp, '<mark>', '</' + underlineTag + '><mark>');
			tmp = replaceAll(tmp, '</mark>', '</mark><' + underlineTag + '>');
			out.innerHTML = tmp;

			thisNode.innerHTML = out.innerHTML;
			var selectedLength = main.querySelector('mark').textContent.length;
			unwrap(thisNode);

			var tmpMarks = main.querySelector('mark');
			const range = document.createRange();
			range.selectNodeContents(tmpMarks);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			setToggleButtons(tmpMarks);
		}

		thisNode = thisNode.parentNode;
		if (thisNode.nodeName == 'DIV' && thisNode.getAttribute('data-bbw-content') == 'true') {
			break;
		}
	}
	out.remove();
	//UNBOLD CODE -------------------------------------- END HERE
};

const doUnderline = () => {
	//BOLD CODE -------------------------------------- START HERE
	console.log('DO UNDERLINE...');
	var underlineTag = 'u';
	let final = document.getElementById('final');
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');
	var selection = window.getSelection().getRangeAt(0);
	console.log(window.getSelection().getRangeAt(0));
	let selectedText = selection.extractContents();

	var newElem = document.createElement(underlineTag);

	newElem.append(selectedText);
	out.append(newElem);
	selection.insertNode(out.firstChild);

	var tmpMarks = main.querySelector('mark');
	const range = document.createRange();
	range.selectNodeContents(newElem);
	window.getSelection().removeAllRanges();
	window.getSelection().addRange(range);

	console.log(window.getSelection().getRangeAt(0));
	//BOLD CODE -------------------------------------- END HERE
};
const doUndoUnderline = () => {
	//UNDO UNDERLINE CODE -------------------------------------- START HERE
	var underlineTag = 'u';
	var selection = window.getSelection().getRangeAt(0);
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');

	console.log('DO UNDO-UNDERLINE...');
	var thisNode = selection.startContainer;
	while (true) {
		console.log('>' + thisNode.nodeName);
		if (thisNode.nodeName == underlineTag.toUpperCase()) {
			let selectedText = selection.extractContents();
			var mark = document.createElement('mark');
			mark.append(selectedText);
			out.innerHTML = '';
			out.append(mark);

			out.innerHTML = replaceAll(out.innerHTML, '<' + underlineTag + '>', '');
			out.innerHTML = replaceAll(out.innerHTML, '</' + underlineTag + '>', '');

			selection.insertNode(out.firstChild);

			var tmp = thisNode.innerHTML;
			tmp = '<' + underlineTag + '>' + tmp + '</' + underlineTag + '>';
			tmp = replaceAll(tmp, '<mark>', '</' + underlineTag + '><mark>');
			tmp = replaceAll(tmp, '</mark>', '</mark><' + underlineTag + '>');
			out.innerHTML = tmp;

			thisNode.innerHTML = out.innerHTML;
			var selectedLength = main.querySelector('mark').textContent.length;
			unwrap(thisNode);

			var tmpMarks = main.querySelector('mark');
			const range = document.createRange();
			range.selectNodeContents(tmpMarks);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			setToggleButtons(tmpMarks);
		}

		thisNode = thisNode.parentNode;
		if (thisNode.nodeName == 'DIV' && thisNode.getAttribute('data-bbw-content') == 'true') {
			break;
		}
	}
	out.remove();
	//UNDO UNDERLINE CODE -------------------------------------- END HERE
};

const doItalic = () => {
	//ITALIC CODE -------------------------------------- START HERE
	console.log('DO ITALIC...');
	var underlineTag = 'em';
	let final = document.getElementById('final');
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');
	var selection = window.getSelection().getRangeAt(0);
	console.log(window.getSelection().getRangeAt(0));
	let selectedText = selection.extractContents();

	var newElem = document.createElement(underlineTag);

	newElem.append(selectedText);
	out.append(newElem);
	selection.insertNode(out.firstChild);

	var tmpMarks = main.querySelector('mark');
	const range = document.createRange();
	range.selectNodeContents(newElem);
	window.getSelection().removeAllRanges();
	window.getSelection().addRange(range);

	console.log(window.getSelection().getRangeAt(0));
	//ITALIC CODE -------------------------------------- END HERE
};

const doUndoItalic = () => {
	//UNDO ITALIC CODE -------------------------------------- START HERE
	var underlineTag = 'em';
	var selection = window.getSelection().getRangeAt(0);
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');

	console.log('DO UNDO-ITALIC...');
	var thisNode = selection.startContainer;
	while (true) {
		console.log('>' + thisNode.nodeName);
		if (thisNode.nodeName == underlineTag.toUpperCase()) {
			let selectedText = selection.extractContents();
			var mark = document.createElement('mark');
			mark.append(selectedText);
			out.innerHTML = '';
			out.append(mark);

			out.innerHTML = replaceAll(out.innerHTML, '<' + underlineTag + '>', '');
			out.innerHTML = replaceAll(out.innerHTML, '</' + underlineTag + '>', '');

			selection.insertNode(out.firstChild);

			var tmp = thisNode.innerHTML;
			tmp = '<' + underlineTag + '>' + tmp + '</' + underlineTag + '>';
			tmp = replaceAll(tmp, '<mark>', '</' + underlineTag + '><mark>');
			tmp = replaceAll(tmp, '</mark>', '</mark><' + underlineTag + '>');
			out.innerHTML = tmp;

			thisNode.innerHTML = out.innerHTML;
			var selectedLength = main.querySelector('mark').textContent.length;
			unwrap(thisNode);

			var tmpMarks = main.querySelector('mark');
			const range = document.createRange();
			range.selectNodeContents(tmpMarks);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			setToggleButtons(tmpMarks);
		}

		thisNode = thisNode.parentNode;
		if (thisNode.nodeName == 'DIV' && thisNode.getAttribute('data-bbw-content') == 'true') {
			break;
		}
	}
	out.remove();
	//UNDO ITALIC CODE -------------------------------------- END HERE
};

const doStrike = () => {
	//STRIKE CODE -------------------------------------- START HERE
	console.log('DO STRIKE...');
	var targetTag = 's';
	let final = document.getElementById('final');
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');
	var selection = window.getSelection().getRangeAt(0);
	console.log(window.getSelection().getRangeAt(0));
	let selectedText = selection.extractContents();

	var newElem = document.createElement(targetTag);

	newElem.append(selectedText);
	out.append(newElem);
	selection.insertNode(out.firstChild);

	var tmpMarks = main.querySelector('mark');
	const range = document.createRange();
	range.selectNodeContents(newElem);
	window.getSelection().removeAllRanges();
	window.getSelection().addRange(range);

	console.log(window.getSelection().getRangeAt(0));
	//STRIKE CODE -------------------------------------- END HERE
};

const doUndoStrike = () => {
	//UNDO STRIKE CODE -------------------------------------- START HERE
	var targetTag = 's';
	var selection = window.getSelection().getRangeAt(0);
	let main = bbwContentGetter().mainContainer;
	let out = document.createElement('div');

	console.log('DO UNDO-ITALIC...');
	var thisNode = selection.startContainer;
	while (true) {
		console.log('>' + thisNode.nodeName);
		if (thisNode.nodeName == targetTag.toUpperCase()) {
			let selectedText = selection.extractContents();
			var mark = document.createElement('mark');
			mark.append(selectedText);
			out.innerHTML = '';
			out.append(mark);

			out.innerHTML = replaceAll(out.innerHTML, '<' + targetTag + '>', '');
			out.innerHTML = replaceAll(out.innerHTML, '</' + targetTag + '>', '');

			selection.insertNode(out.firstChild);

			var tmp = thisNode.innerHTML;
			tmp = '<' + targetTag + '>' + tmp + '</' + targetTag + '>';
			tmp = replaceAll(tmp, '<mark>', '</' + targetTag + '><mark>');
			tmp = replaceAll(tmp, '</mark>', '</mark><' + targetTag + '>');
			out.innerHTML = tmp;

			thisNode.innerHTML = out.innerHTML;
			var selectedLength = main.querySelector('mark').textContent.length;
			unwrap(thisNode);

			var tmpMarks = main.querySelector('mark');
			const range = document.createRange();
			range.selectNodeContents(tmpMarks);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			setToggleButtons(tmpMarks);
		}

		thisNode = thisNode.parentNode;
		if (thisNode.nodeName == 'DIV' && thisNode.getAttribute('data-bbw-content') == 'true') {
			break;
		}
	}
	out.remove();
	//UNDO STRIKE CODE -------------------------------------- END HERE
};

export const bbwBold = () => {
	if (window.getSelection().rangeCount <= 0) return;

	if (document.getElementById('toggleBold').getAttribute('data-do-bold') == 'true') {
		doBold();
	} else {
		doUnBold();
	}
	bbwProcessToggle();
};

export const bbwClear = () => {
	if (window.getSelection().rangeCount <= 0) return;

	doClear();
};

export const bbwUnderline = () => {
	if (window.getSelection().rangeCount <= 0) return;

	if (document.getElementById('toggleUnderline').getAttribute('data-do-underline') == 'true') {
		doUnderline();
	} else {
		doUndoUnderline();
	}
	bbwProcessToggle();
};

export const bbwItalic = () => {
	if (window.getSelection().rangeCount <= 0) return;

	if (document.getElementById('toggleItalic').getAttribute('data-do-italic') == 'true') {
		doItalic();
	} else {
		doUndoItalic();
	}
	bbwProcessToggle();
};

export const bbwStrike = () => {
	if (window.getSelection().rangeCount <= 0) return;

	if (document.getElementById('toggleStrike').getAttribute('data-do-strike') == 'true') {
		doStrike();
	} else {
		doUndoStrike();
	}
	bbwProcessToggle();
};

const normalizeToggleButtons = () => {
	document.getElementById('toggleBold').style.color = '#aaa';
	document.getElementById('toggleItalic').style.color = '#aaa';
	document.getElementById('toggleUnderline').style.color = '#aaa';
	document.getElementById('toggleStrike').style.color = '#aaa';

	document.getElementById('toggleBold').setAttribute('data-do-bold', 'true');
	document.getElementById('toggleItalic').setAttribute('data-do-italic', 'true');
	document.getElementById('toggleUnderline').setAttribute('data-do-underline', 'true');
	document.getElementById('toggleStrike').setAttribute('data-do-strike', 'true');
};

const setToggleButtons = node => {
	normalizeToggleButtons();

	var thisNode = node;
	while (true) {
		if (thisNode.nodeName == 'STRONG') {
			document.getElementById('toggleBold').style.color = '#fff';
			document.getElementById('toggleBold').setAttribute('data-do-bold', 'false');
		}
		if (thisNode.nodeName == 'U') {
			document.getElementById('toggleUnderline').style.color = '#fff';
			document.getElementById('toggleUnderline').setAttribute('data-do-underline', 'false');
		}
		if (thisNode.nodeName == 'EM') {
			document.getElementById('toggleItalic').style.color = '#fff';
			document.getElementById('toggleItalic').setAttribute('data-do-italic', 'false');
		}
		if (thisNode.nodeName == 'S') {
			document.getElementById('toggleStrike').style.color = '#fff';
			document.getElementById('toggleStrike').setAttribute('data-do-strike', 'false');
		}

		if (thisNode.nodeName == 'DIV' && thisNode.getAttribute('data-bbw-content') == 'true') {
			break;
		}
		thisNode = thisNode.parentNode;
	}
};

export const bbwProcessToggle = e => {
	console.log(window.getSelection().rangeCount);
	if (window.getSelection().rangeCount <= 0) return;

	//if(document.querySelector("[data-bbw-content]").querySelector("mark")!=null){

	var selection = window.getSelection().getRangeAt(0);
	console.log('bbwProcessToggle called!');
	console.log(selection);
	setToggleButtons(selection.startContainer);
};

export const removeMarkTag = e => {
	//var bbwContents=document.querySelectorAll("[data-bbw-content]");
	var bbwContents = document.querySelectorAll('.singleVerseText');
	console.log('MORE....' + bbwContents.length);
	for (var i = 0; i < bbwContents.length; i++) {
		var bbwContent = bbwContents[i];
		if (bbwContent.innerHTML.indexOf('<mark>') != -1) {
			bbwContent.innerHTML = replaceAll(bbwContent.innerHTML, '<mark>', '');
			bbwContent.innerHTML = replaceAll(bbwContent.innerHTML, '</mark>', '');
		}
	}
	bbwProcessToggle();

	/*var bbwContent=document.querySelector("[data-bbw-content]");  
    if(bbwContent.innerHTML.indexOf('<mark>')!=-1){

      bbwContent.innerHTML=replaceAll(bbwContent.innerHTML,"<mark>","");
      bbwContent.innerHTML=replaceAll(bbwContent.innerHTML,"</mark>","");

    }
    bbwProcessToggle();
  */
};

export const bbwIsSelectionValid = () => {
	var isValid = true;
	var html = '';
	if (typeof window.getSelection != 'undefined') {
		var sel = window.getSelection();
		if (sel.rangeCount > 0) {
			var container = document.createElement('div');
			container.append(sel.getRangeAt(0).cloneContents());
			html = container.innerHTML;
			//console.log(html);
			if (html.length == 0) {
				isValid = false;
			} else if (container.querySelectorAll('.singleVerseText').length > 0) {
				isValid = false;
			} else if (container.querySelectorAll('div').length > 0) {
				isValid = false;
			}
		} else {
			isValid = false;
		}
	} else {
		isValid = false;
	}

	return isValid;
};

export const unnecessaryTags = [
	'<span style="background-color:#fafafa; color:#212529;"></span>',
	'<span></span>',
	'<span style></span>',
	'<strong></strong>',
	'<s></s>',
	'<em></em>',
	'<u></u>',
	'<em></em>',
	'<div>',
	'</div>',
	'<mark>',
	'</mark>',
];

import React, { useState, useEffect, useContext, useRef } from 'react';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlay, faPause, faAngleRight, faAngleLeft, faStopwatch, faVolumeUp, faInfoCircle } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import ReactPlayer from 'react-player';
import socket from 'socket.io-client/lib/socket';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const SearchInputContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 100%;
`;

const LibraryText = styled.h3`
	font-size: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const EpisodeText = styled.h3`
	font-size: 15px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;
const InfoContainer = styled.div`
	margin-left: 10px;
	color: #324599;
`;
const PlayerButtons = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 75px;
	justify-content: space-between;
	// border: 1px solid pink
	// position: static;
`;
const PlayButton = styled.button`
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: #324599;
	color: #fff;
	text-align: center;
	margin: 0px 10px;
	border: none;
`;
const ProgressBar = styled.input`
	width: 100%;
`;
const PlayerControls = styled.div`
	display: flex;
	align-items: center;
	font-size: 20px;
	// border: 1px solid red;
	width: 33%;
	justify-content: center;
`;

const SeekButtons = styled.button`
	background-color: transparent;
	border: none;
	color: #7d8085;
	&:hover {
		color: #1a56eb;
	}
`;
const VolumeContainer = styled.div`
	color: #7d8085;
	display: flex;
	align-items: center;
	// border: 1px solid blue;
	width: 33%;
	justify-content: flex-start;
`;

const VolumeSlider = styled.input`
	width: 50px;
`;
const SettingsContainer = styled.div`
	color: #7d8085;
	display: flex;
	// border: 1px solid black;
	align-items: center;
	width: 33%;
	justify-content: flex-end;
	&:hover {
		cursor: pointer;
	}
`;

const PlaybackRateButtons = styled.div`
	width: 50px;
	height: 75px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #7e8084;
	justify-content: space-evenly;
	z-index: 100;
	margin-right: 5px;
	padding-right: 0px;
	justify-content: flex-start;
`;
const PlaybackRatebutton = styled.button`
	background-color: transparent;
	border: none;
	outline: none;
	width: 100%;
	font-size: 12px;
	&:hover {
		background-color: #ececec;
	}
`;
const SearchInputWrapper = styled.div`
	position: relative;
	width: 100%;
`;
const SearchBarContainer = styled.div`
	padding-top: 9px;
	margin-bottom: 25px;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`;
const SearchInput = styled.input`
	width: 100%;
	padding: 8px;
	padding-right: 30px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	font-size: 16px;
	line-height: 20px;
`;

const VideoPlayer = props => {
	// Global Storage
	//State
	const [playing, setPlaying] = useState(false);
	const [playbackRate, setPlaybackRate] = useState(1.0);
	const [played, setPlayed] = useState(0);
	const [volume, setVolume] = useState(0.5);
	const [volumeHidden, setVolumeHidden] = useState(true);
	const [pbRateHidden, setPbRateHidden] = useState(true);
	const [duration, setDuration] = useState('');
	const [progress, setProgress] = useState('');
	const [url, setUrl] = useState(props.url ? props.url : '');
	//Ref
	const player = useRef(null);

	const handleSeek = value => {
		player.current.seekTo(value);
	};
	const handleSkip = () => {
		const currentTime = player.current.getCurrentTime();
		player.current.seekTo(currentTime + 10, 'seconds');
	};
	const handleBack = () => {
		const currentTime = player.current.getCurrentTime();
		player.current.seekTo(currentTime - 10, 'seconds');
	};
	const handlePlaybackRate = value => {
		setPlaybackRate(value);
	};
	const getTimeHours = value => {
		//1:43
		// console.log(Math.floor(time % 60))
		// return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2)
		const sec = parseInt(value, 10); // convert value to number if it's string
		let hours = Math.floor(sec / 3600); // get hours
		let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
		let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
		// add 0 if value < 10; Example: 2 => 02
		if (hours < 10) {
			hours = hours;
		}
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		if (seconds < 10) {
			seconds = '0' + seconds;
		}
		return hours + ':' + minutes + ':' + seconds;
	};
	const getTimeMinutes = time => {
		return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
	};
	return (
		<>
			<SearchInputContainer>
				<ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='up' forceDirection={true}>
						<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
					</Tooltip>
				</ClosePaneWrapper>
				{/* <Scrollbars autohide> */}
				<TitleDiv {...props.dragHandleProps}>
					<TitleContainer>
						<LibraryText>Media Player</LibraryText>
						<InfoContainer>
							<Tooltip
								content={
									<p>
										Due to issues we're investigating media panes open on the left and only one of each at a time.
										<br />
										Close one to open a new one.
									</p>
								}
								direction='right'
								forceDirection={true}
							>
								<FontAwesomeIcon icon={faInfoCircle} />
							</Tooltip>
						</InfoContainer>
					</TitleContainer>
				</TitleDiv>
				<SearchBarContainer>
					<SearchInputWrapper>
						<SearchInput
							type='text'
							name='urlText'
							placeholder='Paste Youtube/Vimeo or .mp3 audio link.'
							value={url || ''}
							onChange={e => setUrl(e.target.value)}
						/>
					</SearchInputWrapper>
				</SearchBarContainer>
				{url.length > 0 && (
					<Scrollbars>
						<ReactPlayer
							ref={player}
							url={url}
							playing={playing}
							volume={volume}
							width='100%'
							// height={'250px'}
							onProgress={e => {
								setProgress(e.playedSeconds);
								setPlayed(e.played);
							}}
							onDuration={e => setDuration(e)}
							playbackRate={playbackRate}
							onPause={() => setPlaying(false)}
							onPlay={() => setPlaying(true)}
							controls
						/>
						<ProgressBar type='range' min={0} max={0.999999} step='any' value={played} onChange={e => handleSeek(e.target.value)} />
						<span>{`${progress > 3600 ? getTimeHours(progress) : getTimeMinutes(progress)} / ${
							duration > 3600 ? getTimeHours(duration) : getTimeMinutes(duration)
						}`}</span>
						<PlayerButtons>
							<VolumeContainer>
								<FontAwesomeIcon onClick={() => setVolumeHidden(!volumeHidden)} icon={faVolumeUp} />
								{!volumeHidden && (
									<VolumeSlider
										type='range'
										value={volume}
										onChange={e => setVolume(e.target.value)}
										step='any'
										min={0}
										max={0.999999}
										orient='vertical'
									/>
								)}
							</VolumeContainer>
							<PlayerControls>
								<SeekButtons onClick={() => handleBack()}>
									<FontAwesomeIcon icon={faAngleLeft} />
								</SeekButtons>
								<PlayButton onClick={() => setPlaying(!playing)}>
									<Tooltip content='Play/Pause'>
										<FontAwesomeIcon icon={!playing ? faPlay : faPause} />
									</Tooltip>
								</PlayButton>
								<SeekButtons onClick={() => handleSkip()}>
									<FontAwesomeIcon icon={faAngleRight} />
								</SeekButtons>
							</PlayerControls>
							<SettingsContainer>
								{!pbRateHidden && (
									<PlaybackRateButtons>
										<Scrollbars autoHideDuration={10}>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(0.8)}>.8</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(1)}>1.0</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(1.2)}>1.2</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(1.2)}>1.2</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(1.4)}>1.4</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(1.6)}>1.6</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(1.8)}>1.8</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(2)}>2</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(2.2)}>2.2</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(2.5)}>2.5</PlaybackRatebutton>
											<PlaybackRatebutton onClick={() => handlePlaybackRate(3.0)}>3.0</PlaybackRatebutton>
										</Scrollbars>
									</PlaybackRateButtons>
								)}
								<FontAwesomeIcon onClick={() => setPbRateHidden(!pbRateHidden)} icon={faStopwatch} />
							</SettingsContainer>
						</PlayerButtons>
						{/* <Scrollbars autoHide> */}
					</Scrollbars>
				)}
			</SearchInputContainer>
		</>
	);
};

export default VideoPlayer;

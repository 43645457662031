import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* Popup style */
const PopupWrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999999;
`;

const Box = styled.div`
	position: relative;
	width: 85%;
	max-width: 450px;
	margin: 0 auto;
	height: auto;
	max-height: 70vh;
	margin-top: calc(100vh - 85vh - 20px);
	background: #fff;
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #999;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const UpdateButton = styled.button`
	// width: 100%;
	// margin-top: 14px;
	// border: none;
	// padding: 10px 0px;
	// font-size: 17px;
	// background-color: #282c37;
	// color: #fff;
	// border-radius: 3px;
	padding: 13px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	margin-right: 15px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	color: #ac323d;
	background-color: #fff;
	border: 2px solid #ac323d;
	&:hover {
		background-color: #ac323d;
		color: #fff;
	}
`;
const CancelButton = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	margin-right: 0;
	padding: 13px;
	cursor: pointer;
	border-radius: 10px;
	font-size: 14px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
`;
const ChangeEmailLabel = styled.label`
	margin-bottom: 10px;
	color: #94b3db;
	font-size: 22px;
	margin-top: 10px;
	display: flex;
	justify-content: center;
`;

const ButtonsContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	margin-top: 10px;
`;

const SentMessageWrapper = styled.span``;
const ImportNotesContainer = styled.div``;

const ImportNotesTitle = styled.h3`
	color: #94b3db;

	font-size: 22px;

	margin-top: 10px;
`;

const ProgressBarContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const ImportNotesBtn = styled.button`
	cursor: pointer;

	width: 100%;

	margin-top: 10px;

	padding: 12px;

	color: #000;

	background-color: inherit;

	border-radius: 0;

	border: 2px solid black;

	&:hover {
		background-color: #000;

		color: #ffffff;
	}
`;
const ImportNotes = props => {
	const {
		dateCheck,
		setDateCheck,
		setCsvFile,
		progress,
		importLength,
		importComplete,
		importLoading,
		dispatch,
		handleImport,
		setImportIsOpen,
		csvFile,
		uniqueKey,
	} = props;
	const [importView, setImportView] = useState('instruction');
	return (
		<>
			<PopupWrapper>
				<>
					{importView === 'instruction' && (
						<Box>
							<ImportNotesContainer>
								<ImportNotesTitle>Import Gospel Library Notes</ImportNotesTitle>
								<p>(Read Instructions First)</p>
								<p>
									1) Instructions{' '}
									<a href='https://scripturenotes.com/how-to-import-gospel-library-notes-to-scripture-notes' target='_blank'>
										HERE
									</a>
								</p>
								<p>2) Click the button to locate import file</p>
								<div>
									<input
										id='Browse'
										type='file'
										accept='.csv'
										onChange={e => {
											setCsvFile(e.target.files[0]);
										}}
									/>
									<div style={{ marginTop: 10 }}>
										<label htmlFor='noDate'>3) Include Date of notes? (OPTIONAL)</label>
										<input
											style={{ marginLeft: 5 }}
											value={dateCheck}
											type='checkbox'
											label='noDate'
											name='noDate'
											onClick={() => setDateCheck(!dateCheck)}
										/>
									</div>
								</div>
								<ImportNotesBtn onClick={() => setImportView('continue')}>4) Import Notes</ImportNotesBtn>
								<p style={{ color: 'red' }}>
									CAUTION: ONLY DO THIS ONE TIME FOR YOUR NOTES OR YOU WILL WIND UP WITH DOUBLE NOTES ON VERSES AND WE CANNOT REMOVE OR UNDO
									THIS PROCESS.
								</p>
							</ImportNotesContainer>
							<ButtonsContainer>
								<CancelButton onClick={() => setImportIsOpen(false)}>Cancel</CancelButton>
							</ButtonsContainer>
						</Box>
					)}
					{importView === 'continue' && (
						<Box>
							{importLoading ? (
								<ProgressBarContainer>
									<h1 style={{ color: '#324599' }}>Importing</h1>
									<input
										type='range'
										max={importLength}
										value={progress}
										// disabled
										style={{ width: '100%', color: '#324599' }}
									/>
									<h3>{`${progress} out of ${importLength}`}</h3>
								</ProgressBarContainer>
							) : importComplete ? (
								<div>
									<p>Your import is complete</p>
									<CancelButton onClick={() => setImportIsOpen(false)}>Close</CancelButton>
								</div>
							) : (
								<div>
									<p>
										Are you sure you'd like to continue? This may take some time. We will now close all panes while the import file
										processes.
									</p>
									<ButtonsContainer>
										<CancelButton
											onClick={() => {
												if (csvFile) {
													dispatch({
														type: 'CLOSE_OTHER_PANES',
														payload: { paneId: props.uniqueKey },
													});
													handleImport();
												} else alert('Please add a valid CSV file.');
											}}
										>
											Continue
										</CancelButton>
										<UpdateButton onClick={() => setImportIsOpen(false)}>Close</UpdateButton>
									</ButtonsContainer>
								</div>
							)}
							{/* <DeletePopUpBtnWrapper> */}
							{/* <DeletePopUpBtn onClick={handleImport}>Continue</DeletePopUpBtn> */}
							{/* </DeletePopUpBtnWrapper> */}
						</Box>
					)}
				</>
			</PopupWrapper>
		</>
	);
};

export default ImportNotes;

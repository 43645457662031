 import React, { useState, useEffect, useContext, useRef } from 'react';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faPlayCircle, faVideo, faPencilAlt, faInfoCircle, faFile } from '@fortawesome/fontawesome-free-solid/';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, ClosePopUpBtn, SaveBtn } from '../Shared/SharedStyles';
import { PortalWithState } from 'react-portal';
import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';

const PodcastTitle = styled.div`
	display: flex;
	margin: 10px 0px;
`;
const Podcast = styled.span`
	color: #6a7583;
	font-size: 20px;
	margin-right: 5px;
	&:hover {
		background-color: #ececec;
		cursor: pointer;
	}
`;
const OpenPodcastWrapper = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	outline: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const DeleteIconWrapper = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const DeletePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 20%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	display: flex;
	flex-direction: column;
`;

const EpisodeTitle = styled.h4`
	color: #6a7583;
	font-size: 14px;
	width: 85%;
	margin-bottom: 0;
	
`;

const PodcastEpisode = styled.div`
	width: 98%;
	display: flex;
	// justify-content: space-between;
	margin: 0px 10px;
	padding: 5px 0px;
	border-bottom: 0.5px solid #324599;
`;

const EpisodeButtonsContainer = styled.div`
	display: flex;
	width: 27%;
	justify-content: flex-end;
`;

const InactiveButton = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	outline: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:focus {
		outline: none;
	}
`;
const ActiveButton = styled.button`
	color: #324599;
	font-size: 17px;
	border: none;
	outline: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
	&:focus {
		outline: none;
	}
`;
const EpisodeContainer = styled.div`
	justify-content: space-between;
`;

const TitleContainer = styled.div`
	width: 70%;
	display: flex;
	align-items: center;
`;

const Checkbox = styled.input`
	margin-right: 5px;
`;

const PodcastDescription = styled.p`
	max-height: 280px;
	overflow: scroll;
	border: 1px solid black;
`;

const PodcastWebsite = styled.a`
	margin-bottom: 5px;
`;

const ResourceSpace = styled.div`
	margin-bottom: 5px;
	border-bottom: 1px sodid blue;
`

const ResourcePodcast = props => {
	const [open, setOpen] = useState(false);
	const [podcastEpisodes, setPodcastEpisodes] = useState([]);
	const [userWatched, setUserWatched] = useState([]);
	const { data, chapter, authState } = props;
	const { dispatch } = useContext(NavContext);
	useEffect(() => {
		if (authState && authState.user && authState.user.id) {
			const socket = props.socket;

			socket.on('update_watched_status_' + authState.user.id, ({ status }) => {
				console.log('HIT SOCKET');
				fetchUserWatched();
			});

			return () => socket.off('update_watched_status_' + authState.user.id);
		}
	}, [authState]);
	const openPodcast = async () => {
		if (!open) {
			await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getChaptersPodcasts/${data.podcast_id}/${chapter.id}`)
				.then(res => res.json())
				.then(podcasts => {
					console.log(podcasts)
					setPodcastEpisodes(podcasts);

				});
			fetchUserWatched();
			setOpen(true);
		} else {
			setPodcastEpisodes([]);
			setOpen(false);
		}
	};
	const fetchUserWatched = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getUserWatchedEpisodes/${props.userId}/${data.podcast_id}`)
			.then(res => res.json())
			.then(podcasts => {
				setUserWatched(podcasts);
			});
	};
	const navScroll = () => {
		setTimeout(function () {
			const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			panelsBlock.scroll({
				left: panelsBlock.scrollLeft + 500,
				behavior: 'smooth',
			});
		}, 100);
	};

	const unique = () => {
		return new Date().getTime().toString(36);
	};
	const openPlayer = (event, link, type, episode) => {
		console.log(props);
		if (link !== '') {
			if (event.ctrlKey || event.metaKey) {
				return window.open(`${process.env.REACT_APP_MAIN_URL}?pane=podcastPreview`);
			} else {
				dispatch({
					type: 'HANDLE_ADD_PANE',
					originPaneKey: props.uniqueKey,
					payload: {
						key: `podcastPreview_${unique()}`,
						type: 'podcastPreview',
						link,
						podcast: data.podcast_name,
						episode: episode,
						preview: false,
						linkType: type,
						checked: userWatched.some(obj => obj.episode_name.trim() === episode.show_title.trim()),
					},
				});
				setTimeout(() => {
					const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
					const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
					panelsBlock.scroll({
						left: panelsBlock.scrollLeft - draggablewrapper.offsetWidth,
						behavior: 'smooth',
					});
				}, 100);
				// navScroll();
				// const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
				// const children = document.querySelector("[data-rbd-draggable-id='" + props.uniqueKey + "']");
				// var panewidth = 500;

				// var oldwidth = 425;
				// var nwidth = panewidth;

				// var exlude100 = children.offsetLeft - 100;
				// var remaingLeft = exlude100 - panelsBlock.scrollLeft;
				// var screenwidth = panelsBlock.offsetWidth - 100;
				// var rightposition = screenwidth - remaingLeft;
				// rightposition = rightposition - nwidth;
				// var toscrollright = nwidth - rightposition;

				// console.log('toscrollright: ' + toscrollright);

				// if (toscrollright > 0) {
				// 	console.log('Should be scroll:');
				// 	setTimeout(function () {
				// 		panelsBlock.scroll({
				// 			left: panelsBlock.scrollLeft - toscrollright,
				// 			behavior: 'smooth',
				// 		});
				// 	}, 100);
				// }
			}
		} else alert(`We're sorry but theres no ${type} link for this episode.`);
	};
	const openShowNotes = link => {
		link !== '' ? window.open(link) : alert(`We're sorry but there are no show notes for this episode.`);
	};
	const openOtherLinks = link => {
		link !== '' ? window.open(link) : alert(`We're sorry but there are no show notes for this episode.`);
	};

	const toggleChecked = async showTitle => {
		userWatched.some(obj => obj.episode_name.trim() === showTitle.trim())
			? await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/removeUserWatchedEpisode/${props.userId}/${data.podcast_id}/${showTitle}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(episodes => {
					setUserWatched(episodes);
					props.socket.emit('update_watched_status', { userId: props.userId, episodeName: showTitle, status: false });
				})
			: await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/addUserWatchedEpisode/${props.userId}/${data.podcast_id}/${showTitle}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(episodes => {
					setUserWatched(episodes);
					props.socket.emit('update_watched_status', { userId: props.userId, episodeName: showTitle, status: true });
				});
	};

	const mappedEpisodes =
		podcastEpisodes.length > 0 &&
		podcastEpisodes.map((e, i) => {
			return (
				<PodcastEpisode key={i}>
					<TitleContainer>
						<Checkbox
							onChange={() => toggleChecked(e.show_title)}
							type='checkbox'
							checked={userWatched.some(obj => obj.episode_name.trim() === e.show_title.trim())}
						/>
						{/* <EpisodeButton> */}
						{/* <FontAwesomeIcon icon={userWatched.some(obj => obj.episode_name.trim() === e.show_title.trim()) ? faCheckSquare : faSquare} onClick={(event) => toggleChecked(e.show_title)} /> */}
						{/* </EpisodeButton> */}
						<EpisodeTitle>{e.show_title}</EpisodeTitle>
					</TitleContainer>
					<EpisodeButtonsContainer>
						{e.audio_link && e.audio_link.length > 0 ? (
							<ActiveButton>
								<Tooltip content='Play Audio'>
									<FontAwesomeIcon icon={faPlayCircle} onClick={event => openPlayer(event, e.audio_link, 'audio', e)} />
								</Tooltip>
							</ActiveButton>
						) : (
							<InactiveButton>
								<FontAwesomeIcon icon={faPlayCircle} />
							</InactiveButton>
						)}
						{e.video_link && e.video_link.length > 0 ? (
							<ActiveButton>
								<Tooltip content='Play Video'>
									<FontAwesomeIcon icon={faVideo} onClick={event => openPlayer(event, e.video_link, 'video', e)} />
								</Tooltip>
							</ActiveButton>
						) : (
							<InactiveButton>
								<FontAwesomeIcon icon={faVideo} />
							</InactiveButton>
						)}
						{e.show_notes_link && e.show_notes_link.length > 0 ? (
							<ActiveButton>
								<Tooltip content='Show Notes'>
									<FontAwesomeIcon icon={faPencilAlt} onClick={() => openShowNotes(e.show_notes_link)} />
								</Tooltip>
							</ActiveButton>
						) : (
							<InactiveButton>
								<FontAwesomeIcon icon={faPencilAlt} />
							</InactiveButton>
						)}
						{e.other_link.length && e.other_link.length > 0 ? (
							<ActiveButton>
								<Tooltip content='Extra Content'>
									<FontAwesomeIcon icon={faFile} onClick={() => openOtherLinks(e.other_link)} />
								</Tooltip>
							</ActiveButton>
						) : (
							<InactiveButton>
								<FontAwesomeIcon icon={faFile} />
							</InactiveButton>
						)}
					</EpisodeButtonsContainer>
				</PodcastEpisode>
			);
			// <ResourceSpace/>
		});
	return (
		<div>
			<PodcastTitle>
				<Podcast onClick={() => openPodcast()}>
					<FontAwesomeIcon style={{marginRight: 5}} icon={open ? faChevronDown : faChevronRight} />
					{data.podcast_name}
				</Podcast>
				<PortalWithState closeOnOutsideClick closeOnEsc>
					{({ openPortal, closePortal, isOpen, portal }) => (
						<>
							<DeleteIconWrapper>
								<Tooltip content='Podcast Info'>
									<FontAwesomeIcon key='ProjectPortal' icon={faInfoCircle} onClick={openPortal} />
								</Tooltip>
							</DeleteIconWrapper>
							{portal(
								<div>
									<div
										style={{
											top: 0,
											zIndex: 9999999,
											background: '#ddd',
											width: '100%',
											height: '100%',
											minHeight: '392px',
											opacity: '0.6',
											position: 'absolute',
										}}
									/>
									<DeletePopUpWrapper>
										<p>{data.podcast_name}</p>
										<PodcastDescription>{data.description}</PodcastDescription>
										<PodcastWebsite href={data.website} target='_blank'>
											{data.website}
										</PodcastWebsite>

										<DeletePopUpBtnWrapper>
											<DeletePopUpBtn
												onClick={() => {
													closePortal();
												}}
											>
												Close
											</DeletePopUpBtn>
										</DeletePopUpBtnWrapper>
									</DeletePopUpWrapper>
									
								</div>
							)}
						</>
					)}
				</PortalWithState>
				{/* <OpenPodcastWrapper onClick={() => openPodcast()}>
                    <FontAwesomeIcon icon={faEye} style={open && { color: '#324599' }} />
                </OpenPodcastWrapper> */}
			</PodcastTitle>
			{open && <EpisodeContainer>{mappedEpisodes}</EpisodeContainer>}
		</div>
	);
};

export default ResourcePodcast;

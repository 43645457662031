import React, { useState, useEffect, useContext } from 'react';
import TodoExpandable from './TodoExpandable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid/';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import './todo.css';
import { Scrollbars } from 'react-custom-scrollbars';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { NavContext } from '../../ContextProviders/NavProvider';

//*STYLES
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;
const ProjectsContainer = styled.div`
	width: 97%;
	height: 86%;
	position: absolute;
	top: 75px;
	left: 0;
`;
const TodoTitleContainer = styled.div`
	position: absolute;
	top: 30px;
	left: 25px;
	width: calc(100% - 25px - 50px);
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const TodoTitle = styled.h3`
	font-size: 21px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;
const ShowCompleted = styled.div`
	display: flex;
	align-items: center;
	&:hover {
		cursor: pointer;
	}
`;
const NewProjectForm = styled.form`
	position: absolute;
	top: 75px;
	border: 2px black solid;
	border-radius: 10px;
	background-color: #fff;
	height: 20%;
	width: 85%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 15px;
	z-index: 1;
`;
const CancelProjectTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	// border: 1px red solid;
`;
const NewProjectInput = styled.input`
	width: 78%;
	height: 36px;
	// margin-left: 5px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
`;
const SaveNewProjectBtn = styled.button`
	font-weight: 500;
	margin-top: 5px;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 5px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 100%;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const CancelButton = styled.button`
	font-weight: 500;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	padding: 5px 5px;
	font-size: 14px;
	line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	width: 20%;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const ActionItem = styled.div`
	margin-right: 10px;
	margin-left: 5px;
	font-size: 14px;
	line-height: 32px;
`;

const Todo = props => {
	// Local State
	const [quickTasks, setQuickTasks] = useState([]);
	const [projects, setProjects] = useState([]);
	const [showCompleted, setShowCompleted] = useState(props.showCompleted ? props.showCompleted : false);
	const [hideNewProjectInput, setHideNewProjectInput] = useState(true);
	const [projectTitle, setProjectTitle] = useState('');
	const [expandedArr, setExpandedArr] = useState(props.expandedArr ? props.expandedArr : ['Quick Tasks']);
	const { authState } = useContext(AuthContext);

	const { handleUpdateTodoPane } = useContext(NavContext);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/getQuickTasks/${props.user.id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(quickTasks => {
				setQuickTasks(quickTasks);
			});
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/getAllProjects/${props.user.id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(projects => {
				setProjects(projects);
			});
	}, []);
	useEffect(() => {
		const socket = props.socket;
		socket.on('get_updated_quick_tasks_' + authState.user.id, () => {
			fetch(`${process.env.REACT_APP_BASE_URL}/todo/getQuickTasks/${props.user.id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(quickTasks => {
					setQuickTasks(quickTasks);
				});
		});

		socket.on('get_updated_project_list_' + authState.user.id, () => {
			fetch(`${process.env.REACT_APP_BASE_URL}/todo/getAllProjects/${props.user.id}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(res => res.json())
				.then(projects => {
					setProjects(projects);
				});
		});
		return () => {
			socket.off('get_updated_quick_tasks_' + authState.user.id);
			socket.off('get_updated_project_list' + authState.user.id);
		};
	}, [authState]);
	useEffect(() => {
		handleUpdateTodoPane({
			paneKey: props.uniqueKey,
			expandedArr: expandedArr,
			showCompleted: showCompleted,
		});
	}, [expandedArr, showCompleted]);
	//*METHODS

	const handleChange = () => {
		setShowCompleted(!showCompleted);
	};
	const addExpanded = projectId => {
		setExpandedArr([...expandedArr, projectId]);
	};
	const removeExpanded = projectId => {
		const index = expandedArr.findIndex(e => e.id === projectId);
		const expandedCopy = expandedArr;

		expandedCopy.splice(index, 1);
		setExpandedArr(expandedCopy);
	};
	const handleAddQuickTask = task => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/addQuickTask/${props.user.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ task }),
		})
			.then(res => res.json())
			.then(tasks => {
				setQuickTasks(tasks);
				props.socket.emit(`update_quick_tasks`, { userId: props.user.id });
			});
	};
	const handleDeleteQuickTask = taskId => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/deleteQuickTask/${props.user.id}/${taskId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(tasks => {
				setQuickTasks(tasks);
				props.socket.emit(`update_quick_tasks`, { userId: props.user.id });
			});
	};
	const deleteProject = projectId => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/deleteProject/${props.user.id}/${projectId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(projects => {
				setProjects(projects);
				props.socket.emit(`update_project_list`, { userId: props.user.id });
			});
	};

	const handleChangeQuickTaskStatus = (taskId, completed) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/changeQuickTaskStatus/${props.user.id}/${taskId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ completed }),
		})
			.then(res => res.json())
			.then(tasks => {
				setQuickTasks(tasks);
				props.socket.emit(`update_quick_tasks`, { userId: props.user.id });
			});
	};

	const handleSaveNewProject = e => {
		e.preventDefault();
		if (projectTitle) {
			fetch(`${process.env.REACT_APP_BASE_URL}/todo/addProject/${props.user.id}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ projectTitle }),
			})
				.then(res => res.json())
				.then(projects => {
					setHideNewProjectInput(true);
					setProjectTitle('');
					props.socket.emit(`update_project_list`, { userId: props.user.id });
					setProjects(projects);
				});
		}
	};
	const handleEditProjectTitle = (projectId, projectTitle) => {
		if (projectTitle) {
			fetch(`${process.env.REACT_APP_BASE_URL}/todo/editProjectTitle/${props.user.id}/${projectId}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ projectTitle }),
			})
				.then(res => res.json())
				.then(projects => {
					setProjects(projects);
					props.socket.emit(`update_project_list`, { userId: props.user.id });
				});
		}
	};
	const handleEditQuickTaskText = (taskId, taskText) => {
		
		fetch(`${process.env.REACT_APP_BASE_URL}/todo/editQuickTaskText/${props.user.id}/${taskId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ taskText }),
		})
			.then(res => res.json())
			.then(tasks => {
				setQuickTasks(tasks);
				props.socket.emit(`update_quick_tasks`, { userId: props.user.id });
			});
	};
	//*MAPPED PROJECTS
	const mappedProjects = projects.map((project, index) => {
		return (
			<TodoExpandable
				key={index}
				id={project.id}
				index={index}
				title={project.project_title}
				tasks={project.tasks}
				deleteProject={deleteProject}
				userId={props.user.id}
				showCompleted={showCompleted}
				handleEditProjectTitle={handleEditProjectTitle}
				socket={props.socket}
				addExpanded={addExpanded}
				removeExpanded={removeExpanded}
				expandedArr={expandedArr}
			/>
		);
	});

	//*RENDER
	return (
		<>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
				</Tooltip>
			</ClosePaneWrapper>

			<TodoTitleContainer>
				<TodoTitle {...props.dragHandleProps}>To-Dos</TodoTitle>
				<ShowCompleted>
					<input type='checkbox' checked={showCompleted} onChange={handleChange} onClick={() => setShowCompleted(!showCompleted)} />
					<ActionItem>Show Completed</ActionItem>
				</ShowCompleted>
				<ShowCompleted>
					<Tooltip content='Add New Project' direction='up' forceDirection={true}>
						<FontAwesomeIcon
							icon={['fas', 'plus-circle']}
							size={'2x'}
							color={'#324599'}
							onClick={() => setHideNewProjectInput(!hideNewProjectInput)}
						/>
					</Tooltip>
				</ShowCompleted>
			</TodoTitleContainer>

			{!hideNewProjectInput && (
				<NewProjectForm onSubmit={e => handleSaveNewProject(e)}>
					<CancelProjectTitle>
						<CancelButton
							type='button'
							onClick={() => {
								setProjectTitle('');
								setHideNewProjectInput(true);
							}}
						>
							Cancel
						</CancelButton>
						<NewProjectInput placeholder='Project Title' type='text' value={projectTitle} onChange={e => setProjectTitle(e.target.value)} />
					</CancelProjectTitle>
					<SaveNewProjectBtn type='submit' onClick={e => handleSaveNewProject(e)}>
						+ Add new Project
					</SaveNewProjectBtn>
				</NewProjectForm>
			)}

			<ProjectsContainer>
				<Scrollbars autoHide>
					<TodoExpandable
						title='Quick Tasks'
						tasks={quickTasks}
						handleDeleteQuickTask={handleDeleteQuickTask}
						handleChangeQuickTaskStatus={handleChangeQuickTaskStatus}
						handleAddQuickTask={handleAddQuickTask}
						showCompleted={showCompleted}
						handleEditQuickTaskText={handleEditQuickTaskText}
						socket={props.socket}
						addExpanded={addExpanded}
						removeExpanded={removeExpanded}
						expandedArr={expandedArr}
						id={'Quick Tasks'}
					/>
					{mappedProjects}
				</Scrollbars>
			</ProjectsContainer>
		</>
	);
};

export default Todo;

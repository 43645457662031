import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faInfoCircle } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { PortalWithState } from 'react-portal';
import TimeSkimmerImg from '../../assets/TimeSkimmerTrans-300.png'
import { AuthContext } from '../../ContextProviders/AuthProvider';


import { DeletePopUpBtnWrapper, ClosePopUpBtn, DeletePopUpWrapper } from '../Shared/SharedStyles';


import Players from './Players'
import TimerSkimmerGame from './TimerSkimmerGame'

const {REACT_APP_BASE_URL} = process.env

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const PaneContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	// padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	// overflow: scroll;
	height: 100%;
	// border: 1px solid red;

`;

const PaneTitle = styled.h3`
	font-size: 25px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover{
		cursor: pointer;
	}
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 15%;
`;
const TitleContainer = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

`;
const LandingContainer = styled.div`
	height: 75%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center
`
const PlayerSelectContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 25px 0px;
	font-size: 20px;
`

const InstructionPopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 20%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	display: flex;
	flex-direction: column;
`;

const DecksContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
`
const DeckStatsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 30px;
	font-size: 20px;
`
const PlayGameButton = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 20px;
	// line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	padding: 6px 12px;
	text-align: center;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`

const InstructionsBtn = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 20px;
	// line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	padding: 6px 12px;
	text-align: center;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`
const ChangePlayerBtn = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 12px;
	// line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	padding: 6px 6px;
	margin-left: 15px;
	text-align: center;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`
const DeckContainer = styled.div`
	margin-bottom: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	padding: 3px;
	width: 100%;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
	padding: 3px;
	font-weight: 400;
	border-radius: 3px;
	background: #f3f3f3;
	margin-bottom: 5px;
	width: 95%;
	&:hover {
		background-color: #ececec;
	}
`

const BackButtonDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;

const InfoIconContainer = styled.div`
	color: #6a7583;
	// padding-top: 5px;
	margin-right: 10px;
	&:hover {
		color: #324599;
	}
`;








const TimeSkimmer = props => {
    // Global Storage
	const { isProUser } = useContext(AuthContext);
    //State
	const [view, setView] = useState('landing') 
	const [players, setPlayers] = useState([])
	const [selectedPlayer, setSelectedPlayer] = useState({})
	const [decks, setDecks] = useState([])
	const [selectedDeck, setSelectedDeck] = useState({})
	const [deckStats, setDeckStats] = useState(null)
	const [deckCards, setDeckCards] = useState([])
	const [showTimer, setShowTimer] = useState(true)
	const [versusMode, setVersusMode] = useState(false)
	const [learningMode, setLearningMode] = useState(false)
	
    //Ref

	useEffect(() => {
		fetchPlayers()
		fetchDecks()
	}, [])
	
	//Methods
	const handlePlayGame = () => {
		if(Object.keys(selectedPlayer).length === 0){

			alert('Please select a valid player.')
			return 
		}
		setView('deck_select')
	}
	const fetchPlayers = async () => {
		await fetch(`${REACT_APP_BASE_URL}/games/getPlayers/${props.user.id}`)
			.then(res => res.json())
            .then(players => {
				setPlayers(players)
				if(players.length > 0) setSelectedPlayer(players[0])
			})
			.catch(err => console.log('Error in fetching players:', err));
	} 
	const fetchDecks = async () => {
		await fetch(`${REACT_APP_BASE_URL}/games/getDecks`)
			.then(res => res.json())
            .then(decks => setDecks(decks))
			.catch(err => console.log('Error in fetching decks:', err));
	}
	const fetchDeckData = async (deck) => {
		await fetch(`${REACT_APP_BASE_URL}/games/getDeckData/${selectedPlayer.id}/${deck.id}`)
			.then(res => res.json())
            .then(deckData => {
				// console.log(deckData)
				const {playerStats, cards} =  deckData
				setSelectedDeck(deck)
				setDeckCards(cards)
				setView('deck_stats')
				playerStats.length > 0 && setDeckStats(playerStats[0])
			})
			.catch(err => console.log('Error in fetching decks:', err));
	}
	const handleShuffle = () => {
		setDeckCards(['reset'])
		setDeckCards(shuffle(deckCards))
	}
	const goToDeckSelect = () => {
		setView('deck_select')
		setSelectedDeck({})
		setDeckStats(null)
		setDeckCards([])
	}
	const goToDeckStats = async (reload) => {
		// if(reload) {
			await fetch(`${REACT_APP_BASE_URL}/games/getDeckData/${selectedPlayer.id}/${selectedDeck.id}`)
			.then(res => res.json())
            .then(deckData => {
				// console.log(deckData)
				const {playerStats, cards} =  deckData
				
				playerStats.length > 0 && setDeckStats(playerStats[0])
				setView('deck_stats')
			})
			.catch(err => console.log('Error in fetching decks:', err));
		// }else {
		// 	setView('deck_stats')
		// }
	}
	
	const handleUpdateScore = async (time, score, finished) => {
		if(!deckStats){
			await fetch(`${process.env.REACT_APP_BASE_URL}/games/addHighScore/${selectedPlayer.id}/${selectedDeck.id}`, {
			 method: 'POST',
			 headers: {
				 'Content-Type': 'application/json',
			 },
			 body: JSON.stringify({
				 time: finished ? time : null,
				 score
			 }),
		 })
			 .then(res => res.json())
			 .then(playerStats => {
				setDeckStats(playerStats[0])
			 })
			 .catch(err => console.log('Error in add score:', err));
			 return 
			}else {
					await fetch(`${process.env.REACT_APP_BASE_URL}/games/updateHighScore/${selectedPlayer.id}/${selectedDeck.id}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						time: !finished ? null : deckStats.deck_time ? time < deckStats.deck_time ? time : deckStats.deck_time : time,
						score: score > deckStats.highest_streak ? score : deckStats.highest_streak
					}),
				})
					.then(res => res.json())
					.then(playerStats => {
						setDeckStats(playerStats[0])
					 })
					.catch(err => console.log('Error in update score:', err));
					return 
			}
	 }
	const handleGameStart = (gameMode) => {
		if(gameMode === 'single'){
			setVersusMode(false)
			setLearningMode(false)
			setDeckCards(shuffle(deckCards))
			setView('game')
		}
		if(gameMode === 'versus') {
			setVersusMode(true)
			setDeckCards(shuffle(deckCards))
			setView('game')
		}
		if(gameMode === 'learning'){
			setLearningMode(true)
			setDeckCards(shuffle(deckCards))
			setView('game')
		}

	}
	const timer = deckStats && deckStats.deck_time
	const minutes = () => {
        return ("0" + Math.floor((timer/60000) % 60)).slice(-2)
    }
    const seconds = () => {
        return ("0" + Math.floor((timer/1000) % 60)).slice(-2)
    }
	const shuffle =(array) =>  {
		let currentIndex = array.length,  randomIndex;
	  
		// While there remain elements to shuffle.
		while (currentIndex > 0) {
	  
		  // Pick a remaining element.
		  randomIndex = Math.floor(Math.random() * currentIndex);
		  currentIndex--;
	  
		  // And swap it with the current element.
		  [array[currentIndex], array[randomIndex]] = [
			array[randomIndex], array[currentIndex]];
		}
	  
		return array;
	  }
	//Mapped Items
	
	const mappedDecks = decks.length > 0 && decks.map((deck, ind) => {
		return (
			<DeckContainer
			key={ind}
			 
			 >
				<div onClick={() => {
				if(!deck.pro_deck){
					fetchDeckData(deck)
					// setView('deck_stats')
					return 
				}
				if(deck.pro_deck && isProUser()){
					fetchDeckData(deck)
					// setView('deck_stats')
				}else alert('That game deck is for pro members. Please consider subscribing to support the development of this software');

			}}>- {deck.deck_name}{!deck.pro_deck && <span style={{color: '#324599', fontWeight: 800}}> (Free)</span>}</div>
				<PortalWithState closeOnOutsideClick closeOnEsc>
										{({ openPortal, closePortal, isOpen, portal }) => (
											<>
												{deck.deck_description !== null && (
													<InfoIconContainer>
														<Tooltip content='About'>
															<FontAwesomeIcon key='ProjectPortal' icon={faInfoCircle} onClick={openPortal} />
														</Tooltip>
													</InfoIconContainer>
												)}
												{portal(
													<div>
														<div
															style={{
																top: 0,
																zIndex: 9999999,
																background: '#ddd',
																width: '100%',
																height: '100%',
																minHeight: '392px',
																opacity: '0.6',
																position: 'absolute',
															}}
														/>
														<DeletePopUpWrapper>
															<p dangerouslySetInnerHTML={{ __html: deck.deck_description }}></p>
															<DeletePopUpBtnWrapper>
																<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
															</DeletePopUpBtnWrapper>
														</DeletePopUpWrapper>
													</div>
												)}
											</>
										)}
									</PortalWithState>
			</DeckContainer>
		)
	})
	const handleResetToLanding = () => {
		setSelectedDeck({})
		setDeckStats({})
		setDeckCards([])
		setView('landing')
	}
    return (
        <>
            <PaneContainer>
                <ClosePaneWrapper>
                    <Tooltip content='Close Pane' direction='up' forceDirection={true}>
                        <FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
                    </Tooltip>
                </ClosePaneWrapper>
                <TitleDiv {...props.dragHandleProps}>
                    <TitleContainer>
                        {/* {view !== 'landing' && <PaneTitle onClick={() => view !== 'landing' && handleResetToLanding()}>Time Skimmer</PaneTitle>} */}
						{<img src={TimeSkimmerImg} alt='Time Skimmer Logo' width="100%" />}
                    </TitleContainer>
                </TitleDiv>
                <div style={{height: '85%',}} >
					{view === 'landing' && 
						<LandingContainer>
							<PlayGameButton 
							onClick={handlePlayGame}
							>
								Play Game
							</PlayGameButton>
							<PlayerSelectContainer>
								Player: {Object.keys(selectedPlayer).length > 0 ? selectedPlayer.player_name : ''} 
								<span>
								<ChangePlayerBtn onClick={() => setView('player_select')}>
								{Object.keys(selectedPlayer).length > 0 ? 'Change' : 'Please select a player'} 
								</ChangePlayerBtn>
								</span>
							</PlayerSelectContainer>
							<PortalWithState closeOnOutsideClick closeOnEsc>
							{({ openPortal, closePortal, isOpen, portal }) => (
								<>
									<InstructionsBtn onClick={openPortal} >Instructions</InstructionsBtn>
									{portal(
										<div>
											<div
												style={{
													top: 0,
													zIndex: 9999999,
													background: '#ddd',
													width: '100%',
													height: '100%',
													minHeight: '392px',
													opacity: '0.6',
													position: 'absolute',
												}}
											/>

											<InstructionPopUpWrapper>
												{/* <textarea>{e.time_stamps}</textarea> */}
												<p>Time Skimmer makes learning historical facts fun. Knowing what events took place before and after others helps you put them in context for greater learning and retention.</p>
												<p>To play, select a player, then click play game. Then select a deck and number of players. As each item is displayed on your screen, scroll to the place you believe it should be inserted and click the + mark to place it. Oldest/first items go at the top, last/newest items at the bottom. For example, Genesis is at the top and Malachi at the bottom. Try to improve your time and accuracy with each game.</p>
												<DeletePopUpBtnWrapper>
													<ClosePopUpBtn onClick={closePortal}>Okay</ClosePopUpBtn>
												</DeletePopUpBtnWrapper>
											</InstructionPopUpWrapper>
										</div>
									)}
								</>
							)}
						</PortalWithState>
						</LandingContainer>
					}
					{view === 'player_select' &&
						<Players
							setSelectedPlayer={setSelectedPlayer}
							players={players}
							setView={setView}
							setPlayers={setPlayers}
							userId={props.user.id}
						/>
					}
					{view === 'deck_select' &&
						<DecksContainer>
							
							<div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
								<BackButtonDisplay onClick={handleResetToLanding}>◀ Back</BackButtonDisplay>
								<h5 style={{textAlign: 'center', marginLeft: 16}}>Choose Deck</h5>
							</div>
							{mappedDecks}
						</DecksContainer>
					}
					{
						view === 'deck_stats' && 
						<DeckStatsContainer>
							{/* <h4>Play Game</h4> */}
							<div style={{display: 'flex', flexWrap: 'nowrap', width: '100%', alignItems: 'center'}}>
								<BackButtonDisplay onClick={goToDeckSelect}>◀ Back</BackButtonDisplay>
								<h5 style={{textAlign: 'center', marginLeft: 16}}>{selectedDeck.deck_name}</h5>
							</div>
							
								<p>Deck: {deckCards.length} cards</p>
								<p>Longest Streak:	 {deckStats ? deckStats.highest_streak : 'n/a'}</p>
								<p>Best Time:	 {deckStats ? `${minutes()}:${seconds()}`: 'n/a'}</p>
								<div>
								<label htmlFor='show-timer'>
										Show Timer
									</label>
									<input
									style={{marginLeft: '5px'}}
									type='checkbox'
									checked={showTimer}
									id='show-timer'
									onChange={()=>setShowTimer(!showTimer)}
									  />
								</div>
								<PlayGameButton onClick={() => handleGameStart('single')}>1 Player</PlayGameButton>
								<p>Versus mode</p>
								<PlayGameButton onClick={() => handleGameStart('versus')}>2 Player</PlayGameButton>
								<p>Learning mode</p>
								<PlayGameButton onClick={() => handleGameStart('learning')}>Learn</PlayGameButton>
							
						</DeckStatsContainer>
					}
					{
						view === 'game' &&
							<TimerSkimmerGame
								showTimer={showTimer}
								player={selectedPlayer}
								cards={deckCards}
								handleUpdateScore={handleUpdateScore}
								handleShuffle={handleShuffle}
								goToDeckSelect={goToDeckSelect}
								goToDeckStats={goToDeckStats}
								versusMode={versusMode}
								learningMode={learningMode}
							/>
					}
					
				</div>
            </PaneContainer>
        </>
    );
};

export default TimeSkimmer;
/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faEdit, faTrash, faStar, faCrown } from '@fortawesome/fontawesome-free-solid/';
import ClearHistory from '../Helpers/ClearHistory';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './PopupMember.css';
import "react-datepicker/dist/react-datepicker.css";
/* Popup style */
const PopupWrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999;
`;

const Box = styled.div`
	position: relative;
	width: 85%;
	max-width: 600px;
	margin: 0 auto;
	height: auto;
	max-height: 80vh;
	margin-top: calc(100vh - 85vh - 20px);
	background: #fff;
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #999;
	overflow: auto;
`;

const CloseIcon = styled.span`
	content: 'x';
	cursor: pointer;
	position: fixed;
	right: calc(15% - 30px);
	top: calc(100vh - 85vh - 33px);
	background: #ededed;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	border: 1px solid #999;
	font-size: 20px;
`;
const SubCheckBox = styled.input`
	width: 46%;
	height: 45px;
	background-color: #f1f1f1;
	border: #979797 solid 1px;
	padding: 0px 10px;
	margin:1%;
`;
const UpdateButton = styled.button`
	width: 48%;
	margin: 1%;
	border: none;
	padding: 10px 0px;
	font-size: 17px;
	background-color: #282c37;
	color: #fff;
	border-radius: 3px;
`;
const NumberInput = styled.input`
	width: calc(100% - 6px);
	height: 45px;
	background-color: #f1f1f1;
	border: #979797 solid 1px;
	padding: 0px 10px;
	margin:5px 3px;
`;

const InputGroup = styled.div`
	width: calc(50% - 6px);
	display:inline-block;
`;
const InputCheckedbox = styled.div`
	margin-top:15px;
	& strong {
		font-weight: 600;
    vertical-align: text-bottom;
    font-size: 18px;
    margin-left: 4px;
	}
	& input {
		vertical-align: text-top;
    width: 15px;
    height: 15px;
	}
`;
const BirthDateInput = styled.input`
	width: calc(25% - 6px);
	height: 45px;
	background-color: #f1f1f1;
	border: #979797 solid 1px;
	padding: 0px 10px;
	margin:5px 3px;
`;
const CancelButton = styled.button`
	width: 48%;
	margin: 1%;
	border: none;
	padding: 10px 0px;
	font-size: 17px;
	background-color: #282c37;
	color: #fff;
	border-radius: 3px;
`;
const SubCheckBoxLabel = styled.label`
	margin-top: 15px;
	margin-bottom: 10px;
	display: block;
	font-size: 16px;
	font-weight: bold;
`;
const CardListTable = styled.table`
	width: 100%;
`;
const SmallHeading = styled.label`
	margin-top: 15px;
	margin-bottom: 10px;
	display: block;
	font-size: 16px;
	font-weight: bold;
`;
const SmallHeading2 = styled.label`
	margin-top: 15px;
	margin-bottom: 10px;
	display: block;
	font-size: 16px;
	color:#ff0065;
`;
const SmallHeading3 = styled.label`
	margin-top: 15px;
	margin-bottom: 0px;
	display: block;
	font-size: 16px;
`;
const InfoLink = styled.a`
	color: #3674c1 !important;
    display: block;
    font-weight: 500;
`;

const OverAge =styled.span`
	color:red;
`;
const ChildUl = styled.ul`
	list-style:none;
	margin:0;
	padding:0;
`;
const ChildLiSpan =styled.span`
	display:block;
	flex:1 1 25%;
	text-align: left;
	self-align:left;
	&:last-child{
		flex:1 1 10%
	}
	&:nth-of-type(2){
		flex:1 1 40%
	}
	&:nth-of-type(3){
		flex:1 1 10%
	}
`;
const RemoveButton= styled.button` 
	font-size:12px;
	border: none;
    background-color: #ff8686;
    color: white;
    line-height: 23px;
    border-radius: 3px;
`;
const ChildLi = styled.li`
	display:flex;
	padding-bottom: 5px;
	padding-top: 5px;
    padding-left: 5px;
    border-bottom: #eee solid 1px;
    margin-bottom: 5px;
`;
const ChildHeaderLi = styled.li`
	display:flex;
	padding-bottom: 5px;
    border-bottom: #eee solid 1px;
    margin-bottom: 5px;
    background-color: blanchedalmond;
`;
const ChildHeaderSpan =styled.span`
	display:block;
	flex:1 1 25%;
	text-align: left;
	self-align:left;
	&:last-child{
		flex:1 1 10%
	}
	&:nth-of-type(2){
		flex:1 1 40%
	}
	&:nth-of-type(3){
		flex:1 1 10%
	}
`;

const SentMessageWrapper = styled.span``;
const ErrorMessageWrapper = styled.span` 
	color:red;
	font-weight:bold;
`;

const SetDefault = styled.span`
	cursor: pointer;
	color: white;
	background-color: #2e83ba;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 3px;
`;

const SuccessBox = styled.div`
	position: absolute;
    width: 300px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin-left: -150px;
    left: 50%;
    top: 50%;
    z-index:999;
`;
const SuccessBoxButton = styled.button`
	color: white;
    background-color: #282c37;
    border: none;
    width: 100%;
    font-size: 20px;
    padding: 9px 5px;
    border-radius: 5px;
    margin-top: 15px;
`;
const SuccessMessage = styled.p`
	font-weight:bold;
	text-align:center;
`;
const styles  = {
  datepicker: {
    width: "calc(100% - 6px)",
	height: "45px",
	backgroundColor: "#f1f1f1",
	border: "#979797 solid 1px",
	padding: "0px 10px",
	margin: "5px 3px"
  }
}

const PopupMember = props => {
	const [userId, setUserId] = useState('');
	const { authState, isProUser, isTrialUser, hasProAccount } = useContext(AuthContext);
	const [oldEmail, setOldEmail] = useState(authState.user.email);
	const [sentMessage, setSentMessage] = useState('');
	const [spouse, setSpouse] = useState('');
	const [action, setAction] = useState('add');
	const [readOnlyState, setReadOnlyState] = useState(false);
	
	const [showLoader, setShowLoader] = useState(false);
	const [showSpouseCheck, setShowSpouseCheck] = useState(true);
	const [showSuccess, setShowSuccess] = useState(false);
	const [isSpouse, setIsSpouse] = useState(false);
	const [defaultChecked, setDefaultChecked] = useState(false);
	const [currentCards, setCurrentCards] = useState('');
	const [memberNumber, setMemberNumber] = useState('');
	const [memberFirstName, setMemberFirstName] = useState('');
	const [memberLastName, setMemberLastName] = useState('');
	const [memberEmail, setMemberEmail] = useState('');
	const [memberBirthDate, setMemberBirthDate] = useState('');
	
	const [subPrice, setSubPrice] = useState(0);
	const [subPricePass, setSubPricePass] = useState(0);
	const [memberPrice, setMemberPrice] = useState(0);
	const [chargePrice, setChargePrice] = useState(0);
	const [chargePricePass, setChargePricePass] = useState(0);
	const [renewalCheck, setRenewalCheck] = useState(false);
	const [cvc, setcvc] = useState('');
	const [defaultPayment, setDefaultPayment] = useState('');
	const [expDate, setExpDate] = useState('');
	const [startDate, setStartDate] = useState('');
	const [todayDate, setTodayDate] = useState('');
	
	const [currentPlan, setCurrentPlan] = useState('noplan');
	const [currentDesc, setCurrentDesc] = useState('');
	const [currentAmount, setCurrentAmount] = useState(0);
	const [currentStatus, setCurrentStatus] = useState('');
	const [subscriptionId, setSubscriptionId] = useState('');
	const [billingCycleAnchor, setBillingCycleAnchor] = useState('');
	
	const [currentPriceId, setCurrentPriceId] = useState('');
	const [stripeId, setStripeId] = useState('');
	const [myMemberList, setMyMemberList] = useState('');
	const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState('');
	const [memberError, setMemberError] = useState('');
	const [parentMeta, setParentMeta] = useState('');
	const [error, setError] = useState('');
	const [childCount, setChildCount] = useState(0);
	
	const [subPricePassRemove, setSubPricePassRemove] = useState(0);
	const [childList, setChildList] = useState([]);
	
	

	const [newEmail, setNewEmail] = useState('');
	const stripePromise = loadStripe(`${process.env.STRIPE_API_KEY}`);
	
	const moment = require('moment');
	const logout = () => {
		localStorage.clear();
		localStorage.setItem('prev-email', authState.user.email);
		props.history.push('/login');
	};
	// let memberPrice=0;
	useEffect(() => {
		userplan();
		ShowChildsFunc();
		checkSpouse();
		
	}, []);
	
	useEffect(() => {
		const monthlyChildPrice=3;
		const yearlyChildPrice=30;
		
		if(currentPlan=="month"){
			// memberPriceVar="2.5";
			
			setMemberPrice(monthlyChildPrice);
			const currentDate=moment().format('MM/DD/YYYY');
			const remDuration = moment.duration(moment(expDate).diff(currentDate));
			const remDays = remDuration.asDays();
			const subDuration = moment.duration(moment(expDate).diff(moment(startDate)));
			// const subDays = subDuration.asDays();
			var subDays = 30;
			
			
			
			var daysper = (remDays*100)/subDays;
			var daysperDec=daysper/100;
			var chargeable=monthlyChildPrice*daysperDec;
			let chargePricePassVar=(chargeable*100).toFixed(0);
			var currentPerDayAmount=parseFloat(monthlyChildPrice) / subDays;
			var discountAmount=(remDays * currentPerDayAmount).toFixed(2);
			if(discountAmount > 2.5){
				discountAmount = 2.5;
			}
			var discountAmount2= String(discountAmount).replace(".","");
			if(authState.user.stripe_plan_name=="Pro"){
				discountAmount=0;
				discountAmount=discountAmount.toFixed(2);
			}
			if(!isNaN(discountAmount)){
				setChargePrice(discountAmount);
				setChargePricePass(discountAmount2);	
			}
			

		}else if(currentDesc=="3 Year One Time Subscription" || currentDesc=="5 Year One Time Subscription" || currentDesc=="10 Year One Time Subscription"){
			
			// memberPriceVar="20";
			setMemberPrice(yearlyChildPrice);
			const currentDate=moment().format('MM/DD/YYYY');
			const remDuration = moment.duration(moment(expDate).diff(currentDate));
			const remDays = remDuration.asDays();
			const subDuration = moment.duration(moment(expDate).diff(moment(startDate)));
			// const subDays = subDuration.asDays();
			var subDays = 365;
			
			
			
			var daysper = (remDays*100)/subDays;
			var daysperDec=daysper/100;
			var chargeable=yearlyChildPrice*daysperDec;
			let chargePricePassVar=(chargeable*100).toFixed(0);
			var currentPerDayAmount=parseFloat(yearlyChildPrice) / subDays;
			var discountAmount=(remDays * currentPerDayAmount).toFixed(2);
			if(discountAmount > 30){
				discountAmount = 30;
			}
			// if(remDays > 365){
			// 		discountAmount=30;
			// 		discountAmount=discountAmount.toFixed(2);
			// }
			
			if(!isNaN(discountAmount)){
				setChargePrice(discountAmount);
				var discountAmount2= String(discountAmount).replace(".","");
				setChargePricePass(discountAmount2);
			}
			
		}else{
			// memberPriceVar="20";
			
			setMemberPrice(yearlyChildPrice);
			const currentDate=moment().format('MM/DD/YYYY');
			const remDuration = moment.duration(moment(expDate).diff(currentDate));
			const remDays = remDuration.asDays();
			const subDuration = moment.duration(moment(expDate).diff(moment(startDate)));
			// const subDays = subDuration.asDays();
			var subDays = 365;
			
			
			
			var daysper = (remDays*100)/subDays;
			var daysperDec=daysper/100;
			var chargeable=yearlyChildPrice*daysperDec;
			let chargePricePassVar=(chargeable*100).toFixed(0);
			var currentPerDayAmount=parseFloat(yearlyChildPrice) / subDays;
			var discountAmount=(remDays * currentPerDayAmount).toFixed(2);
			if(discountAmount > 30){
				discountAmount = 30;
			}
			var discountAmount2= String(discountAmount).replace(".","");
			if(authState.user.stripe_plan_name=="Pro"){
				setChargePrice(0.00);	
			}else{
				if(!isNaN(discountAmount)){
					setChargePrice(discountAmount);
				}
				
			}
			if(!isNaN(discountAmount)){
				setChargePricePass(discountAmount2);	
			}
			
		}
		
		
		
	}, [currentAmount]);

	useEffect(()=>{
				
		
		
		
	},[memberPrice]);

	
	// const todayDate=moment().format('MM/DD/YYYY');
	const maxLengthCheck = e => {
		
		if (e.value.length > e.maxLength) {
			e.value = e.value.slice(0, e.maxLength);
		}
	};

	const checkSpouse = async () => {
		// console.log("setDefaultChecked",defaultChecked);
		let checkSpouse = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/checkSpouse/${authState.user.email}`)
			.then(res => res.json())
			.then(spouse => {
				// console.log("spouse data",spouse);
				if(spouse.length > 0){
					setShowSpouseCheck(false);
				}else{
					setShowSpouseCheck(true);
				}
			});
	}
	const userplan = async () => {
		if(authState.user.stripe_plan_name=="Pro"){
			setParentMeta("no");
		}else{
			setParentMeta("yes");
		}
		let plan = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/planByEmailFull/${authState.user.email}`)
			.then(res => res.json())
			.then(userplan => {
				if(userplan.id){
					setStripeId(userplan.id);
					if(userplan.description=="3 Year One Time Subscription" || userplan.description=="5 Year One Time Subscription" || userplan.description=="10 Year One Time Subscription"){
						var expireOn=authState.user.stripe_subscription_expires_at;
						const dbdate = moment(expireOn);
						var expireOndate =dbdate.format("MM/DD/YYYY");
						setExpDate(expireOndate);
						setCurrentDesc(userplan.description);
						setCurrentAmount(12999);
						
					}else{
						var expireOn=userplan.subscriptions.data[0].current_period_end;

						var expireOndate = moment.unix(expireOn).format("MM/DD/YYYY");
						const startOn=userplan.subscriptions.data[0].current_period_start;
						const startOndate = moment.unix(startOn).format("MM/DD/YYYY");
						

						setExpDate(expireOndate);
						setStartDate(startOndate);
						setCurrentPlan(userplan.subscriptions.data[0].items.data[0].plan.interval);
						
						setCurrentPriceId(userplan.subscriptions.data[0].items.data[0].id);
						
						
						setCurrentStatus(userplan.subscriptions.data[0].status);
						setSubscriptionId(userplan.subscriptions.data[0].id);
						setCurrentPaymentMethodId(userplan.invoice_settings.default_payment_method);
						const dec_amount=userplan.subscriptions.data[0].items.data[0].plan.amount;
						setCurrentAmount(dec_amount);
					}
					
					setCurrentPaymentMethodId(userplan.invoice_settings.default_payment_method);
					setBillingCycleAnchor(expireOn);
				}	
			});

		let childsCount = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getChildUsersCount/${authState.user.email}`, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			})
			.then(res => res.json())
			.then(count => {
				
				setChildCount(count[0].CNT)
			});

	};
	const editMember = async (email) => {

		const userDetail=await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/findByEmail/${email}`)
			.then(res => res.json())
			.then(userInfo => {
				var nameParts=userInfo.name.split(" ");
				var dob=new Date(userInfo.dob);
				
				setMemberFirstName(nameParts[0])
				setMemberLastName(nameParts[1])
				setMemberEmail(userInfo.email)
				setMemberBirthDate(dob)
				setReadOnlyState(true);
				
				if(userInfo.child_type=="spouse"){
					setIsSpouse(true);
					setDefaultChecked(true);
				}else{
					setIsSpouse(false);
					setDefaultChecked(false);
				}
				checkSpouse();
				setAction("update");
			});
		
	}
	const removeMember = async (email) => {
		setShowLoader(true);
		setError('');
		setMemberError('');
		
		const currentAmountTodec=(currentAmount/100).toFixed(2);
		
		const subPriceVarRemove =  Number(currentAmountTodec) - Number(memberPrice) ;
		const subPricePassVarRemove=(Number(subPriceVarRemove)*100).toFixed(0);
		setSubPricePassRemove(subPricePassVarRemove);
		
		
		
		
		

		if(currentDesc=="3 Year One Time Subscription" || currentDesc=="5 Year One Time Subscription" || currentDesc=="10 Year One Time Subscription"){
			let deleteUser= fetch(`${process.env.REACT_APP_BASE_URL}/stripe/cancelMember`, {
				method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ email: `${email}`,expDate: `${expDate}` }),
			})
			.then(res => res.json())
			.then(response=>{
				ShowChildsFunc();
				setShowLoader(false);
				userplan();
			})
		}else{
			let cancelSub = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/cancelSub`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ subId: `${stripeId}`, action: "adding_child" }),
			})
			.then(res => res.json())
			.then(cancelSub =>{
				let createSubscription = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createSubscription`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ 
						customer_id: `${stripeId}`, 
						currency: 'usd',
						product:`${process.env.REACT_APP_STRIPE_MEMBER_PRODUCT}`,
						interval:`${currentPlan}`,
						unit_amount_decimal:`${subPricePassVarRemove}`,
						tax_behavior:'inclusive',
						billing_cycle_anchor:`${billingCycleAnchor}`,
						parent_meta:`${parentMeta}`
					}),
				})
				.then(res => res.json())
				.then(createSubscription=>{
					let deleteUser= fetch(`${process.env.REACT_APP_BASE_URL}/stripe/cancelMember`, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ email: `${email}` }),
					})
					.then(res => res.json())
					.then(response=>{
						ShowChildsFunc();
						setShowLoader(false);
						userplan();
						checkSpouse();
					})
				});
			});
		}
	};

	const okShowSuccess = async () => {
		setMemberBirthDate('');
		setMemberFirstName('');
		setMemberLastName('');
		setMemberEmail('');
		ShowChildsFunc();
		setShowSuccess(false);
		setAction("add");
		checkSpouse();

	};

	const updateMembers =  async (email) =>{
		// console.log("Email to update",email)
		setShowLoader(true);
		var dob=moment(memberBirthDate).format('YYYY-MM-DD');
		let updateMember = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/updateMember`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ 
				email: `${email}`,
				fullName: `${memberFirstName} ${memberLastName}`,
				birthDate:dob,
				spouse:  `${spouse}`
			}),
		}).then(res => res.json()).then(updateMember => {
			setShowLoader(false);
			userplan();
			setShowSuccess(true);
			setMemberFirstName("")
			setMemberLastName("")
			setMemberEmail("")
			setMemberBirthDate(null)
			setReadOnlyState(false)
			checkSpouse();
			
			
		});
	}
	const addMembers = async () => {
		setMemberError('');
		setError('');
		if (memberFirstName === '' || memberLastName === '' || memberEmail === '' || memberBirthDate === '') {
					return setError('Please fill out all fields.');
		}
		setShowLoader(true);

		let existingUser= fetch(`${process.env.REACT_APP_BASE_URL}/auth/existingUser/${memberEmail}`, {
			method: 'Get',
		})
		.then(res => res.json())
		.then(response => {
			var accountStatus="";
			if(response.meta_data){
				var meta = JSON.parse(response.meta_data);
				accountStatus=meta.accountStatus;	
			}
			if ((response === 'OK' || response.stripe_plan_name === "noplan" || accountStatus === "Cancelled") && response.stripe_plan_name !== "family-member") {
				const currentAmountTodec=(currentAmount/100).toFixed(2);
				const subPriceVar =  Number(memberPrice) + Number(currentAmountTodec);
				setSubPrice(subPriceVar);
				let subPricePassVar=(Number(subPriceVar)*100).toFixed(0);
				setSubPricePass(subPricePassVar);
				
				const currentDate=moment().format('MM/DD/YYYY');
				// Remaining days difference
				const remDuration = moment.duration(moment(expDate).diff(currentDate));
				const remDays = remDuration.asDays();
				// Total month days
				const subDuration = moment.duration(moment(expDate).diff(moment(startDate)));
				// const subDays = subDuration.asDays();
				if(currentPlan=="month"){
					var subDays = 30;
				}else if(currentDesc=="3 Year One Time Subscription" || currentDesc=="5 Year One Time Subscription" || currentDesc=="10 Year One Time Subscription"){
					var subDays = 365;
				}else{
					var subDays = 365;
				}
				
				var daysper = (remDays*100)/subDays;
				var daysperDec=daysper/100;
				var chargeable=memberPrice*daysperDec;
				let chargePricePassVar=(chargeable*100).toFixed(0);
				var currentPerDayAmount=parseFloat(memberPrice) / subDays;
				var discountAmount=(remDays * currentPerDayAmount).toFixed(2);
				// var discountPercentage=(discountAmount/memberPrice) * 100;
				if(remDays > 365){
					discountAmount=30;
					discountAmount=discountAmount.toFixed(2);
				}
				if(authState.user.stripe_plan_name=="Pro"){
					setChargePrice(0.00);
					var discountAmount2= String(discountAmount).replace(".","");
					setChargePricePass(discountAmount2);
				}else{
					if(!isNaN(discountAmount)){
						setChargePrice(discountAmount);
						var discountAmount2= String(discountAmount).replace(".","");
						setChargePricePass(discountAmount2);	
					}	
				}
				
				
				if(currentDesc=="3 Year One Time Subscription" || currentDesc=="5 Year One Time Subscription" || currentDesc=="10 Year One Time Subscription"){
					let createPaymentIntents = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createPaymentIntents`, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ 
							customer: `${stripeId}`, 
							currency: 'usd',
							amount:`${discountAmount2}`,
						}),
					})
					.then(res => res.json())
					.then(createPaymentIntents => {
						if(createPaymentIntents){
							
							let confirmPaymentIntents = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/confirmPaymentIntents`, {
								method: 'POST',
								headers: { 'Content-Type': 'application/json' },
								body: JSON.stringify({ 
									payment_id: `${createPaymentIntents}`,
									method_id: `${currentPaymentMethodId}`,
								}),
							})
							.then(res => res.json());
						}
					});
					var dob=moment(memberBirthDate).format('YYYY-MM-DD');
					let registerMember = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/registerMember`, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ 
							email: `${memberEmail}`,
							fullName: `${memberFirstName} ${memberLastName}`,
							birthDate:dob,
							parentName: `${authState.user.name}`,
							parentEmail:`${authState.user.email}`,
							expDate:`${expDate}`,
							discountAmount: `${discountAmount}`,
							spouse:  `${spouse}`
						}),
					}).then(res => res.json()).then(registerMember => {
						setShowLoader(false);
						userplan();
						setShowSuccess(true);
					});
				}else{
					//Updating Price
					let cancelSub = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/cancelSub`, {
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ subId: `${stripeId}`, action: "adding_child" }),
					})
					.then(res => res.json())
					.then(cancelSub =>{

						let createSubscription = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createSubscription`, {
							method: 'POST',
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({ 
								customer_id: `${stripeId}`, 
								currency: 'usd',
								product:`${process.env.REACT_APP_STRIPE_MEMBER_PRODUCT}`,
								interval:`${currentPlan}`,
								unit_amount_decimal:`${subPricePassVar}`,
								tax_behavior:'inclusive',
								billing_cycle_anchor:`${billingCycleAnchor}`,
								parent_meta:`${parentMeta}`
							}),
						})
						.then(res => res.json())
						.then(createSubscription => {
							if(chargePricePass >= 1 && authState.user.stripe_plan_name!=="Pro"){
								
								let createPaymentIntents = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createPaymentIntents`, {
									method: 'POST',
									headers: { 'Content-Type': 'application/json' },
									body: JSON.stringify({ 
										customer: `${stripeId}`, 
										currency: 'usd',
										amount:`${chargePricePass}`,
									}),
								})
								.then(res => res.json())
								.then(createPaymentIntents => {
									if(createPaymentIntents){
										
										let confirmPaymentIntents = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/confirmPaymentIntents`, {
											method: 'POST',
											headers: { 'Content-Type': 'application/json' },
											body: JSON.stringify({ 
												payment_id: `${createPaymentIntents}`,
												method_id: `${currentPaymentMethodId}`,
											}),
										})
										.then(res => res.json());
									}
								});
							}
							var dob=moment(memberBirthDate).format('YYYY-MM-DD');
							let registerMember = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/registerMember`, {
								method: 'POST',
								headers: { 'Content-Type': 'application/json' },
								body: JSON.stringify({ 
									email: `${memberEmail}`,
									fullName: `${memberFirstName} ${memberLastName}`,
									birthDate:dob,
									parentName: `${authState.user.name}`,
									parentEmail:`${authState.user.email}`,
									expDate:`${expDate}`,
									discountAmount: `${discountAmount}`,
									spouse:  `${spouse}`
								}),
							}).then(res => res.json()).then(registerMember => {
								setShowLoader(false);
								userplan();
								setShowSuccess(true);
								
							});
						})
							
					});
				}
				
			} else {
				
				if(response.stripe_plan_name=="family-member"){
					setMemberError("This member could not be added because of his/her remaining subscription days");
				}else if(response.stripe_plan_name=="Pro" || response.stripe_plan_name=="Pro - Monthly" || response.stripe_plan_name=="Pro - Yearly" || response.stripe_plan_name=="Pro - 3Yearly" || response.stripe_plan_name=="Pro - 5Yearly" || response.stripe_plan_name=="Pro - 10Yearly"){
					setMemberError("This email is on a "+response.stripe_plan_name+" account already. Have them cancel before adding them here.");	
				}else {
					setMemberError("Email already exists");	
				}
				
				setShowLoader(false);
			}
		});
		
		
	};
	const ShowChildsFunc =() =>{
		let childs = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getChildUsers/${authState.user.email}`, {
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			})
			.then(res => res.json())
			.then(childsres => {
				
				setChildList(childsres)
			});
	};
	const ShowChilds = () => {
		 		const memberList = childList.map(child => {
		 			
		 			const childDOB = moment(child.dob).format("DD/MM/YYYY");
		 			// var age=moment(childDOB, "DD/MM/YYYY").month(0).from(moment().month(0))
		 			var age=moment().diff(moment(childDOB, "DD/MM/YYYY"), 'years');
					
					return(
						<ChildLi key={child.id} > 
							<ChildLiSpan>{child.name}</ChildLiSpan> 
							<ChildLiSpan>{child.email}</ChildLiSpan>
							<ChildLiSpan>
								{(age > 25 ) ? (<OverAge>{age}</OverAge>) : (age)}
							</ChildLiSpan>
							<ChildLiSpan>
								
								<FontAwesomeIcon
									icon={faEdit}
									style={{
										color: 'green',
										cursor: 'pointer',
										verticalAlign: 'middle',
										marginRight : '5px'
									}}
									onClick={() => { editMember(child.email) } }
								/>
								<FontAwesomeIcon
									icon={faTrash}
									style={{
										color: '#f87474',
										cursor: 'pointer',
										verticalAlign: 'middle'
									}}
									onClick={() => { if(window.confirm('Are you sure you want to remove this member? They can finish out the current term but will not be renewed until they do it from within their own account.')){removeMember(child.email)}}}
								/>

								{(child.child_type == "spouse") && 
									<FontAwesomeIcon
										icon={faCrown}
										style={{
											color: '#3a78ff',
											marginLeft: '10px'
										}}
									/>
								}
								

							</ChildLiSpan>
							
						</ChildLi>
						);
				});
				
			 
		
		return (
			<div>
				<SmallHeading>Manage Family Plans</SmallHeading>
				
				<ChildUl> 
					<ChildHeaderLi>
						<ChildHeaderSpan>Name</ChildHeaderSpan>
						<ChildHeaderSpan>Email</ChildHeaderSpan>
						<ChildHeaderSpan>Age</ChildHeaderSpan>
						<ChildHeaderSpan>Action</ChildHeaderSpan>
					</ChildHeaderLi>
					{memberList} 
				</ChildUl>
			</div>
		);
	};

	
	const ShowSpouseCheckField = props => {
		return (
			<div>
			<input 
				type='checkbox' 
				defaultChecked={defaultChecked} 
				id='spouseCheckbox' 
				name='spouse' 
				value={spouse} 
				onChange={e => setSpouse(e.target.checked)} 
			/> 
			<strong>This user is a spouse, not child</strong>
			</div>
		);
	}
	


	return (
		<>
			<PopupWrapper>
				<Box>
					{showLoader && (
						<Loader
							type='TailSpin'
							color='#fff'
							height={25}
							width={25}
							style={{
								position: 'absolute',
								right: '34px',
								bottom: '31px',
							}}
						/>
					)}
					{showSuccess && (
						<SuccessBox>
							{
								(action == "update") ?
								(<SuccessMessage>Family member updated successfully</SuccessMessage>) :
								(<SuccessMessage>Family member added successfully</SuccessMessage>)
							}
							
							<SuccessBoxButton onClick={() => okShowSuccess()}>OK</SuccessBoxButton>
						</SuccessBox>
					)}
					<FontAwesomeIcon
						icon={faTimes}
						style={{
							float: 'right',
							cursor: 'pointer',
							margin: '0px 0px 15px 0px',
						}}
						onClick={props.handleClose}
					/>
					{(childCount > 7 ) && (
						<ErrorMessageWrapper>You have reached max member limit</ErrorMessageWrapper>
					)}
					<ErrorMessageWrapper>{memberError}</ErrorMessageWrapper>
					<ErrorMessageWrapper>{error}</ErrorMessageWrapper>
					<ShowChilds />
					{(childCount < 8 ) && (
						<div>
							<SmallHeading>Add New Family Member (Spouse and dependent children under 26 who are full-time students only please. Once children have full-time employment they should be removed and activate a membership in their own account.)</SmallHeading>
							<InfoLink target="_blank" href="https://scripturenotes.com/family-plans">Click for Further Instructions/Information</InfoLink>
							<InputGroup>
								<SmallHeading3>First Name</SmallHeading3>
								<NumberInput
									type='text'
									maxLength='50'
									name='memberFirstName'
									placeholder='First Name'
									value={memberFirstName}
									onChange={e => setMemberFirstName(e.target.value)}
								/>
							</InputGroup>
							<InputGroup>
								<SmallHeading3>Last Name</SmallHeading3>
								<NumberInput
									type='text'
									maxLength='50'
									name='memberLastName'
									placeholder='Last Name'
									value={memberLastName}
									onChange={e => setMemberLastName(e.target.value)}
								/>
							</InputGroup>
							<InputGroup>
								<SmallHeading3>Email</SmallHeading3>
								<NumberInput
									type='text'
									maxLength='100'
									readOnly = {readOnlyState}
									name='memberEmail'
									placeholder='Email'
									value={memberEmail}
									onChange={e => setMemberEmail(e.target.value)}
								/>
							</InputGroup>
							<InputGroup>
								<SmallHeading3>Date of Birth</SmallHeading3>
								<DatePicker 
								style = {styles.datepicker}
								dateFormat="MM/dd/yyyy"
								selected={memberBirthDate} 
								onChange={(date) => setMemberBirthDate(date)} 
								className="datepicker"
								placeholderText='MM/DD/YYYY'
								/>
							</InputGroup>
							{(showSpouseCheck || (action=="update" && isSpouse)) && (
								<InputCheckedbox>
									<ShowSpouseCheckField />
								</InputCheckedbox>
							)}
							
							<SmallHeading>Note: ${(currentPlan=='month') ? (3).toFixed(2):(30).toFixed(2) } will be immediately charged for additional family accounts you set up.</SmallHeading>
							<SmallHeading2>Amount to be charged immediately: <span>${chargePrice}</span> (Prorated)</SmallHeading2>
							{
								(action=="update") ? 
								(<UpdateButton type='button' onClick={() => updateMembers(memberEmail)}>
									Update Now
								</UpdateButton>):
								(<UpdateButton type='button' onClick={() => addMembers()}>
									Add Now
								</UpdateButton>)
							}
							
							<CancelButton type='button' onClick={props.handleClose}>
								CANCEL
							</CancelButton>
						</div>
					)}
					

				</Box>
			</PopupWrapper>
		</>
	);
};

export default PopupMember;

import React, { useContext } from 'react';
import { PortalWithState } from 'react-portal';

import ReactDOM from 'react-dom';

import { loadStripe } from '@stripe/stripe-js';

import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';

import { DeletePopUpBtn, DeletePopUpBtnWrapper, DeletePopUpWrapper, ClosePopUpBtn } from '../Shared/SharedStyles';
// Make sure to call `loadStripe` outside of a component’s render to avoid

// recreating the `Stripe` object on every render.

const StripeBtn = styled.button`
	cursor: pointer;
	color: #000;
    border: none;
    font-weight: 400;
    text-align: center;
    background: none;
    margin-top: 50px;
    padding-left:0px;
	&:hover {
		text-decoration: underline;
	}
`;

function CancelStripe(props) {
	const { authState } = useContext(AuthContext);

	const logout = () => {
		localStorage.clear();

		localStorage.setItem('prev-email', authState.user.email);

		props.history.push('/login');
	};

	const handleClick = async event => {
		fetch(`${process.env.REACT_APP_BASE_URL}/webhook/unsubscribe`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ user: authState.user }),
		})
			.then(res => res.json())
			.then(res => {
				
				logout();
			})
			.catch(err => console.log('Error unsubscribing'));
	};

	return (
		<PortalWithState closeOnOutsideClick closeOnEsc>
			{({ openPortal, closePortal, isOpen, portal }) => (
				<>
					<StripeBtn role='link' onClick={openPortal}>
						Cancel Subscription
					</StripeBtn>
					{portal(
						<div>
							<div
								style={{
									top: 0,
									zIndex: 9999999,
									background: '#ddd',
									width: '100%',
									height: '100%',
									minHeight: '392px',
									opacity: '0.6',
									position: 'absolute',
								}}
							/>

							<DeletePopUpWrapper>
								<p>Are you sure you want cancel your subscription? (You will be signed out)</p>
								<DeletePopUpBtnWrapper>
									<DeletePopUpBtn
										onClick={closePortal}
										onClickCapture={() => {
											handleClick();
										}}
									>
										Cancel Membership
									</DeletePopUpBtn>
									<ClosePopUpBtn onClick={closePortal}>Keep Membership</ClosePopUpBtn>
								</DeletePopUpBtnWrapper>
							</DeletePopUpWrapper>
						</div>
					)}
				</>
			)}
		</PortalWithState>
	);
}

export default CancelStripe;

import React, { useReducer, createContext } from 'react';

export const CollectionListContext = createContext();

const initialState = {};

const reducer = (state, action) => {
	switch (action.type) {
		case 'GET_COLLECTION_LIST':
			let updatedCollections = action.payload.data;

			if (state[action.payload.uniqueKey] && state[action.payload.uniqueKey].text && state[action.payload.uniqueKey].collections) {
				let prevCollections = state[action.payload.uniqueKey].collections;
				let filteredCollections = updatedCollections.filter(c => prevCollections.map(({ id }) => id).includes(c.id));
				updatedCollections = filteredCollections;
			}
			return {
				...state,
				[action.payload.uniqueKey]: {
					tags: action.payload.tags,
					categories: action.payload.categories,
					collections: updatedCollections,
					// collections: action.payload.data,
					// state[action.payload.uniqueKey] && state[action.payload.uniqueKey].collections
					// 	? state[action.payload.uniqueKey].collections
					// 	: action.payload.data,
					// collections:
					// 	state[action.payload.uniqueKey] && state[action.payload.uniqueKey].collections
					// 		? state[action.payload.uniqueKey].collections
					// 		: action.payload.data,
					text: state[action.payload.uniqueKey] && state[action.payload.uniqueKey].text !== '' ? state[action.payload.uniqueKey].text : '',
				},
			};
		case 'UPDATE_COLLECTION_LIST':
			let { id, text, title, userId } = action.payload;

			Object.entries(state).forEach(([key, value]) => {
				let updatingCnIndex = value.collections.findIndex(c => c.id === action.payload.id);

				if (updatingCnIndex !== -1) {
					let collectionCopy = { ...value.collections[updatingCnIndex] };
					value.collections[updatingCnIndex] = {
						id,
						text,
						title,
						user_id: userId,
						created_at: collectionCopy.created_at,
						updated_at: collectionCopy.updated_at,
					};
					state[key] = value;
				}
			});

			return { ...state };
		case 'SEARCH_COLLECTION_LIST':
			
			const paneCopy = { ...state[action.payload.uniqueKey] };
			if (action.payload.tags) paneCopy.tags = action.payload.tags;
			if (action.payload.categories) paneCopy.categories = action.payload.categories;
			paneCopy.collections = action.payload.data;
			paneCopy.text = action.payload.text;
			return {
				...state,
				[action.payload.uniqueKey]: {
					...paneCopy,
				},
			};
		case 'DELETE_COLLECTION_LIST_PANE': {
			let stateCopy = { ...state };
			delete stateCopy[action.payload.uniqueKey];
			return stateCopy;
		}
		default:
			return state;
	}
};

export const CollectionListProvider = ({ children }) => {
	const [collectionState, collectionDispatch] = useReducer(reducer, initialState);

	const fetchUserCollectionList = async obj => {
		const ownerCollections = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getCollectionList/${obj.userId}/null/null/${obj.shared}`)
			.then(res => res.json())
			.then(collections => collections);
		const categories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
			.then(res => res.json())
			.then(categories => categories);
		const tags = await fetch(`${process.env.REACT_APP_BASE_URL}/tags/getAllTagsForUser/${obj.userId}`)
			.then(res => res.json())
			.then(tags => tags);
		// const sharedCollections = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getSharedCollections/${obj.userId}`).then(res => res.json())
		// 	.then(sharedCollections => sharedCollections);
		const collections = !obj.shared ? [...ownerCollections] : ownerCollections;
		collectionDispatch({ type: 'GET_COLLECTION_LIST', payload: { uniqueKey: obj.uniqueKey, data: collections, tags, categories } });
	};
	const fetchPublicCollections = async obj => {
		const publicCollections = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getPublicCollections/${obj.userId}`)
			.then(res => res.json())
			.then(collections => collections);
		const categories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
			.then(res => res.json())
			.then(categories => categories);
		const tags = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getPublicTags`)
			.then(res => res.json())
			.then(tags => tags);
		const tagsArr = tags
			.map(e => {
				const label = e.label.charAt(0).toUpperCase() + e.label.slice(1);
				return { label, value: label };
			})
			.sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));
		// const sharedCollections = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getSharedCollections/${obj.userId}`).then(res => res.json())
		// 	.then(sharedCollections => sharedCollections);
		const collections = publicCollections;
		collectionDispatch({ type: 'GET_COLLECTION_LIST', payload: { uniqueKey: obj.uniqueKey, data: collections, tags: tagsArr, categories } });
	};
	const searchPublicCollections = async obj => {
		const ownerCollections = await fetch(
			`${process.env.REACT_APP_BASE_URL}/collectionList/searchPublicCollections/${obj.userId}/${obj.text.length > 0 ? obj.text : null}/${
				obj.tagName
			}/${obj.authorName}`
		)
			.then(res => res.json())
			.then(collections => collections);
		const categories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
			.then(res => res.json())
			.then(categories => categories);
		const tags = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getPublicTags`)
			.then(res => res.json())
			.then(tags => tags);
		const tagsArr = tags.map(e => {
			const label = e.label.charAt(0).toUpperCase() + e.label.slice(1);
			return { label, value: label };
		});

		// const sharedCollections = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getSharedCollections/${obj.userId}`).then(res => res.json())
		// 	.then(sharedCollections => sharedCollections);
		const collections = !obj.shared ? [...ownerCollections] : ownerCollections;
		collectionDispatch({
			type: 'SEARCH_COLLECTION_LIST',
			payload: { uniqueKey: obj.uniqueKey, data: collections, tags: tagsArr, categories, text: obj.text },
		});
	};
	const updateNoteInList = obj => {
		collectionDispatch({ type: 'UPDATE_COLLECTION_LIST', payload: obj });
	};

	const searchCollectionList = async obj => {
		if (obj.text === '') {
			return fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/getCollectionList/${obj.userId}/${obj.tagId}/${obj.categoryId}/${obj.shared}`)
				.then(res => res.json())
				.then(collections => {
					collectionDispatch({ type: 'SEARCH_COLLECTION_LIST', payload: { uniqueKey: obj.uniqueKey, data: collections, text: obj.text } });
				});
		}

		const ownerCollections = await fetch(
			`${process.env.REACT_APP_BASE_URL}/collectionList/searchCollectionList/${obj.userId}/${obj.text}/${obj.tagId}/${obj.categoryId}/${obj.shared}`
		)
			.then(res => res.json())
			.then(collections => collections);
		// const sharedCollections = await fetch(`${process.env.REACT_APP_BASE_URL}/collectionList/searchSharedCollections/${obj.userId}/${obj.text}`).then(res => res.json())
		// 	.then(sharedCollections => sharedCollections);

		const collections = !obj.categoryId && !obj.tagId ? [...ownerCollections] : ownerCollections;
		collectionDispatch({ type: 'SEARCH_COLLECTION_LIST', payload: { uniqueKey: obj.uniqueKey, data: collections, text: obj.text } });
	};

	return (
		<CollectionListContext.Provider
			value={{
				collectionState,
				collectionDispatch,
				fetchUserCollectionList,
				searchCollectionList,
				updateNoteInList,
				fetchPublicCollections,
				searchPublicCollections,
			}}
		>
			{children}
		</CollectionListContext.Provider>
	);
};

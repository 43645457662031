import React, { useReducer, createContext } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import io from 'socket.io-client';
import _ from 'lodash';

export const NavContext = createContext();

const unique = () => {
	return new Date().getTime().toString(36);
};

const sortVerses = (verses = []) => {
	if (verses.length && verses[0].verses) return verses;
	verses = _.sortBy(
		verses,
		[verse => verse.chapter.book.volume.id, verse => verse.chapter.book.order, verse => verse.chapter.order, verse => verse.number],
		['asc', 'asc', 'asc', 'asc']
	);
	return verses;
};

const data = [];
// const data = [{ key: 'search_result_kb859f2l', type: 'search_result' }];

const reducer = (state, action) => {
	switch (action.type) {
		case 'HANDLE_ADD_PANE': {
			if (action.payload.type !== 'videoPlayer' && action.payload.type !== 'podcastPreview' && action.payload.type !== 'time_skimmer') {
				const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
				const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
				setTimeout(() => {
					droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
				}, 1500);

				let data = { ...action.payload };
				// if (action.payload.type === 'collectionList' || action.payload.type === 'collectionNote' || action.payload.type === 'verses' || action.payload.type === 'search_result') {
				data = { ...data, socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }) };
				// }
				if (action['originPaneKey']) {
					let originIndex = state.findIndex(({ key }) => key === action.originPaneKey);
					if (originIndex === -1) return [...state, data];
					let stateCopy = [...state];
					stateCopy.splice(originIndex + 1, 0, data);
					return stateCopy;
				} else {
					const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
					const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
					setTimeout(() => {
						droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
					}, 1500);
					return [...state, data];
				}
			} else {
				if (action.payload.type === 'videoPlayer') {
					if (!state.some(e => e.type === 'videoPlayer')) {
						return [{ ...action.payload }, ...state];
					} else return [...state];
				}
				if (action.payload.type === 'podcastPreview') {
					if (!state.some(e => e.type === 'podcastPreview')) {
						let data = { ...action.payload };
						data = { ...data, socket: io(process.env.REACT_APP_BASE_URL, { transports: ['websocket'] }) };
						if (!state.some(e => e.type === 'videoPlayer')) {
							return [{ ...data }, ...state];
						} else {
							let copy = [...state];
							copy.splice(1, 0, { ...data });
							return [...copy];
						}
					} else return [...state];
				}
				if (action.payload.type === 'time_skimmer') {
					if (!state.some(e => e.type === 'time_skimmer')) {
						return [{ ...action.payload }, ...state];
					} else return [...state];
				}
			}
		}
		case 'HANDLE_OPEN_SH_PANE': {
			if (action['originPaneKey']) {
				let selectedPane = state.find(({ key }) => key === action.originPaneKey);
				let stateCopy = [...state];
				selectedPane.open = action.payload.open;
				return stateCopy;
			} else {
				if (action.payload.versesOriginKey) {
					const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
					const children = document.querySelector("[data-rbd-draggable-id='" + action.payload.versesOriginKey + "']");
					var panewidth = children.offsetWidth;

					var oldwidth = 425;
					var nwidth = panewidth + 50;

					var exlude100 = children.offsetLeft - 100;
					var remaingLeft = exlude100 - panelsBlock.scrollLeft;
					var screenwidth = panelsBlock.offsetWidth - 100;
					var rightposition = screenwidth - remaingLeft;
					rightposition = rightposition - nwidth;
					var toscrollright = nwidth - rightposition;

					if (toscrollright > 0) {
						setTimeout(function () {
							panelsBlock.scroll({
								left: panelsBlock.scrollLeft + toscrollright,
								behavior: 'smooth',
							});
						}, 100);
					}
					const stateCopy = [...state];
					const versesIndex = stateCopy.findIndex(p => p.key === action.payload.versesOriginKey);
					stateCopy.splice(versesIndex + 1, 0, action.payload);
					return stateCopy;
				}
				return [...state, action.payload];
			}
		}
		case 'ADD_VERSE_TO_NEW_CN': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.uniqueKey);
			let existingVerses = stateCopy[paneIndex].verses || [];
			let versesWithoutDuplicates = [...existingVerses, action.payload.verse].reduce(
				(acc, cur) => (acc.find(v => v.id === cur.id) ? acc : [cur, ...acc]),
				[]
			);
			stateCopy[paneIndex].verses = sortVerses(versesWithoutDuplicates);
			return stateCopy;
		}
		case 'ADD_BULK_VERSES_TO_NEW_CN': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.uniqueKey);
			let existingVerses = stateCopy[paneIndex].verses || [];
			let versesWithoutDuplicates = [...existingVerses, ...action.payload.verses].reduce(
				(acc, cur) => (acc.find(v => v.id === cur.id) ? acc : [cur, ...acc]),
				[]
			);

			stateCopy[paneIndex].verses = sortVerses(versesWithoutDuplicates);
			return stateCopy;
		}
		case 'REMOVE_VERSE_FROM_CN_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.uniqueKey);
			if (stateCopy[paneIndex].verses && stateCopy[paneIndex].verses.length && stateCopy[paneIndex].type === 'collectionNote') {
				stateCopy[paneIndex].verses.splice(
					stateCopy[paneIndex].verses.findIndex(v => v.id === action.payload.verseId),
					1
				);
			}
			return stateCopy;
		}
		case 'UPDATE_LIBRARY_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.paneKey);
			stateCopy[paneIndex].search = action.payload.search;
			return stateCopy;
		}
		case 'UPDATE_SEARCH_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.paneKey);

			if (paneIndex !== -1) {
				stateCopy[paneIndex].searchText = action.payload.searchText;
				stateCopy[paneIndex].selectedOption = action.payload.selectedOption;
				stateCopy[paneIndex].searchHistory = action.payload.searchHistory;
				stateCopy[paneIndex].filterTree = action.payload.filterTree;
				stateCopy[paneIndex].filterTreeCategoriesAndTags = action.payload.filterTreeCategoriesAndTags;
				stateCopy[paneIndex].display = action.payload.display;
				stateCopy[paneIndex].checkedCategories = action.payload.checkedCategories;
				stateCopy[paneIndex].tagText = action.payload.tagText;
				stateCopy[paneIndex].userTags = action.payload.userTags;
				stateCopy[paneIndex].selectedBooks = action.payload.selectedBooks;
				stateCopy[paneIndex].resultPaneKey = action.payload.resultPaneKey;
				stateCopy[paneIndex].resultsLibrary = action.payload.resultsLibrary;
				stateCopy[paneIndex].filtersEnabled = action.payload.filtersEnabled;
				stateCopy[paneIndex].searchingFromResults = action.payload.searchingFromResults;
			}

			return stateCopy;
		}
		case 'RESET_PANES': {
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
			setTimeout(() => {
				droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
			}, 1500);
			return action.payload;
		}
		case 'DRAG_PANE': {
			return [action.payload, ...state];
		}
		case 'REMOVE_PANE': {
			const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
			const droppablewrapper = document.querySelector("[data-rbd-droppable-id='board']");
			setTimeout(() => {
				droppablewrapper.style.width = draggablewrapper.offsetWidth + 'px';
			}, 1500);
			return state.filter((pane, i) => i !== action.payload);
		}
		case 'UPDATE_CN_PANE': {
			state.forEach(pane => {
				if (pane.key === action.payload.uniqueKey && pane.verses) {
					pane.collectionId = action.payload.collectionId;
					pane.verses &&
						pane.verses.forEach(verse => {
							verse.collection_id = action.payload.collectionId;
							verse.user_id = action.payload.userId;
						});
				}
			});
			return [...state];
		}
		case 'UPDATE_SH_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.paneKey);
			stateCopy[paneIndex].selectedOption = action.payload.selectedOption;
			stateCopy[paneIndex].selectedLetter = action.payload.selectedLetter;
			stateCopy[paneIndex].selectedTopic = action.payload.selectedTopic;
			return stateCopy;
		}
		case 'HANDLE_UPDATE_VERSES_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.paneKey);
			stateCopy[paneIndex].bookId = action.payload.bookId;
			stateCopy[paneIndex].chapterId = action.payload.chapterId;
			delete stateCopy[paneIndex].initialChapterId;
			return stateCopy;
		}
		case 'HANDLE_UPDATE_TODO_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.paneKey);

			stateCopy[paneIndex].expandedArr = action.payload.expandedArr;
			stateCopy[paneIndex].showCompleted = action.payload.showCompleted;
			return stateCopy;
		}
		case 'UPDATE_PODCAST_PLAYER_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.paneKey);

			stateCopy[paneIndex].checked = action.payload.checked;

			return stateCopy;
		}
		case 'UPDATE_AI_PANE': {
			let stateCopy = [...state];
			let paneIndex = stateCopy.findIndex(p => p.key === action.payload.paneKey);

			stateCopy[paneIndex].question = action.payload.question;
			stateCopy[paneIndex].response = action.payload.response;
			stateCopy[paneIndex].threadId = action.payload.threadId;

			return stateCopy;
		}
		case 'CLOSE_OTHER_PANES': {
			const { paneId } = action.payload;
			const stateCopy = [...state];
			return stateCopy.filter(e => e.key === paneId);
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

export const NavProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, data);

	const removeVerseFromCollectionNote = (uniqueKey, verseId) => {
		// NOTE: I'm not sure why, but all this seems to do is affect search result lists when CN's are created from them.
		// dispatch({ type: 'REMOVE_VERSE_FROM_CN_PANE', payload: { uniqueKey, verseId } })
	};

	const handleAddCollection = (id, originPaneKey = null) => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + originPaneKey + "']");
		var panewidth = children.offsetWidth;

		var oldwidth = 425;
		var nwidth = panewidth;

		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - nwidth;
		var toscrollright = nwidth - rightposition;

		if (toscrollright > 0) {
			setTimeout(function () {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}, 100);
		}
		dispatch({ type: 'HANDLE_ADD_PANE', originPaneKey, payload: { key: `collectionNote_${unique()}`, type: 'collectionNote', collectionId: id } });
	};
	const handleAddSharedCollection = async (id, originPaneKey = null, ownerId, userId) => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + originPaneKey + "']");
		var panewidth = children.offsetWidth;

		var oldwidth = 425;
		var nwidth = panewidth;

		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - nwidth;
		var toscrollright = nwidth - rightposition;

		if (toscrollright > 0) {
			setTimeout(function () {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}, 100);
		}
		const [shareCode] = await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getShareCode/${ownerId}/${id}`)
			.then(res => res.json())
			.then(shareCode => shareCode);
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey,
			payload: { key: `collectionNote_${unique()}`, type: 'collectionNote', collectionId: null, shareCode: shareCode.share_code },
		});
	};

	const createCollection = (id, originPaneKey = null, text = null) => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + originPaneKey + "']");
		var panewidth = children.offsetWidth;

		var oldwidth = 425;
		var nwidth = panewidth;

		var exlude100 = children.offsetLeft - 100;
		var remaingLeft = exlude100 - panelsBlock.scrollLeft;
		var screenwidth = panelsBlock.offsetWidth - 100;
		var rightposition = screenwidth - remaingLeft;
		rightposition = rightposition - nwidth;
		var toscrollright = nwidth - rightposition;

		if (toscrollright > 0) {
			setTimeout(function () {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}, 100);
		}
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey,
			payload: { key: `collectionNote_${unique()}`, type: 'collectionNote', collectionId: id, text },
		});
	};

	const handleOpenVerses = obj => {
		if (obj.originPaneKey) {
			const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			const children = document.querySelector("[data-rbd-draggable-id='" + obj.originPaneKey + "']");
			var panewidth = children.offsetWidth;

			var oldwidth = 425;
			var nwidth = panewidth;

			var exlude100 = children.offsetLeft - 100;
			var remaingLeft = exlude100 - panelsBlock.scrollLeft;
			var screenwidth = panelsBlock.offsetWidth - 100;
			var rightposition = screenwidth - remaingLeft;
			rightposition = rightposition - nwidth;
			var toscrollright = nwidth - rightposition;

			if (toscrollright > 0) {
				setTimeout(function () {
					panelsBlock.scroll({
						left: panelsBlock.scrollLeft + toscrollright,
						behavior: 'smooth',
					});
				}, 100);
			}
		}

		if (obj.proUser || obj.bookId < 90) {
			dispatch({
				type: 'HANDLE_ADD_PANE',
				originPaneKey: obj.originPaneKey,
				payload: {
					key: `verses_${unique()}`,
					type: 'verses',
					chapterId: obj.chapterId,
					bookId: obj.bookId,
					verseNumber: obj.verseNumber,
					initialChapterId: obj.initialChapterId,
				},
			});
		} else {
			alert('Must be a pro user to access this title');
		}
	};
	const handleOpenGame = obj => {
		if (obj.originPaneKey) {
			// const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
			// const children = document.querySelector("[data-rbd-draggable-id='" + obj.originPaneKey + "']");
			// var panewidth = children.offsetWidth;

			// var oldwidth = 425;
			// var nwidth = panewidth;

			// var exlude100 = children.offsetLeft - 100;
			// var remaingLeft = exlude100 - panelsBlock.scrollLeft;
			// var screenwidth = panelsBlock.offsetWidth - 100;
			// var rightposition = screenwidth - remaingLeft;
			// rightposition = rightposition - nwidth;
			// var toscrollright = nwidth - rightposition;

			//

			setTimeout(() => {
				const draggablewrapper = document.getElementsByClassName('draggable-wrapper')[0];
				const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft - draggablewrapper.offsetWidth,
					behavior: 'smooth',
				});
			}, 100);
		}
		const game = obj.gameName.split(' ').join('_').toLowerCase();
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey: obj.originPaneKey,
			payload: { key: `${game}_${unique()}`, type: game },
		});
	};

	const handleOpenVolume = obj => {
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey: obj.originPaneKey,
			payload: { key: `library_${unique()}`, type: 'library', volumeTitle: obj.volumeTitle /*open: obj.open*/ },
		});
	};

	const handleAddStudyHelpPane = obj => {
		dispatch({
			type: 'HANDLE_OPEN_SH_PANE',
			originPaneKey: obj.originPaneKey,
			payload: { key: `library_${unique()}`, type: 'library', collectionId: null, open: obj.open, versesOriginKey: obj.versesOriginKey },
		});
	};

	const handleUpdateVersesPane = obj => {
		dispatch({
			type: 'HANDLE_UPDATE_VERSES_PANE',
			payload: {
				bookId: obj.bookId,
				chapterId: obj.chapterId,
				paneKey: obj.paneKey,
			},
		});
	};
	const handleOpenWordUseReport = obj => {
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + obj.originPaneKey + "']");
		setTimeout(function () {
			var panewidth = children.offsetWidth;
			if (panewidth > 500) {
				panewidth = panewidth - 160;
			} else {
				panewidth = panewidth + 40;
			}
			var oldwidth = 425;
			var nwidth = panewidth;

			var exlude100 = children.offsetLeft - 100;
			var remaingLeft = exlude100 - panelsBlock.scrollLeft;
			var screenwidth = panelsBlock.offsetWidth - 100;
			var rightposition = screenwidth - remaingLeft;
			rightposition = rightposition - nwidth;
			var toscrollright = nwidth - rightposition;

			if (toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 100);
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey: obj.originPaneKey,
			payload: {
				key: `wordUseReport_${unique()}`,
				type: 'wordUseReport',
				documents: obj.documents,
				booksString: obj.booksString,
				volumes: obj.volumes,
				books: obj.books,
			},
		});
	};

	const handleOpenStudyTopic = obj => {
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey: obj.originPaneKey,
			payload: {
				key: `library_${unique()}`,
				type: 'library',
				selectedLetter: obj.selectedLetter,
				selectedOption: obj.selectedOption,
				selectedTopic: obj.selectedTopic,
				open: obj.open,
			},
		});
	};

	const handleUpdateStudyTopic = obj => {
		dispatch({
			type: 'UPDATE_SH_PANE',
			payload: {
				paneKey: obj.paneKey,
				type: 'library',
				selectedTopic: obj.selectedTopic,
				open: obj.open,
				selectedOption: obj.selectedOption,
				selectedLetter: obj.selectedLetter,
			},
		});
	};
	const handleUpdateTodoPane = obj => {
		dispatch({
			type: 'HANDLE_UPDATE_TODO_PANE',
			payload: {
				paneKey: obj.paneKey,
				type: 'todo',
				expandedArr: obj.expandedArr,
				showCompleted: obj.showCompleted,
			},
		});
	};
	const handleUpdateSearchPane = obj => {
		dispatch({
			type: 'UPDATE_SEARCH_PANE',
			payload: {
				paneKey: obj.paneKey,
				type: 'search',
				searchText: obj.searchText,
				selectedOption: obj.selectedOption,
				searchHistory: obj.searchHistory,
				filterTree: obj.filterTree,
				filterTreeCategoriesAndTags: obj.filterTreeCategoriesAndTags,
				display: obj.display,
				checkedCategories: obj.checkedCategories,
				tagText: obj.tagText,
				userTags: obj.userTags,
				selectedBooks: obj.selectedBooks,
				resultPaneKey: obj.resultPaneKey,
				resultsLibrary: obj.resultsLibrary,
				filtersEnabled: obj.filtersEnabled,
				searchingFromResults: obj.searchingFromResults,
			},
		});
	};
	const handleUpdateLibraryPane = obj => {
		dispatch({
			type: 'UPDATE_LIBRARY_PANE',
			payload: {
				paneKey: obj.paneKey,
				type: 'library',
				open: false,
				search: obj.search,
			},
		});
	};
	const handleOpenBook = obj => {
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey: obj.originPaneKey,
			payload: { key: `library_${unique()}`, type: 'library', volumeTitle: obj.volumeTitle, bookName: obj.bookName },
		});
	};

	const handleAddNewCollectionNote = (verseArr, originPaneKey = null, studyHelpInfo = null) => {
		verseArr = verseArr.map(verse => {
			verse.promoted = false;
			return verse;
		});
		const panelsBlock = document.getElementsByClassName('panelsWrapper')[0];
		const children = document.querySelector("[data-rbd-draggable-id='" + originPaneKey + "']");
		setTimeout(function () {
			var panewidth = children.offsetWidth;
			if (panewidth > 500) {
				panewidth = panewidth - 160;
			} else {
				panewidth = panewidth + 40;
			}
			var oldwidth = 425;
			var nwidth = panewidth;

			var exlude100 = children.offsetLeft - 100;
			var remaingLeft = exlude100 - panelsBlock.scrollLeft;
			var screenwidth = panelsBlock.offsetWidth - 100;
			var rightposition = screenwidth - remaingLeft;
			rightposition = rightposition - nwidth;
			var toscrollright = nwidth - rightposition;

			if (toscrollright > 0) {
				panelsBlock.scroll({
					left: panelsBlock.scrollLeft + toscrollright,
					behavior: 'smooth',
				});
			}
		}, 100);
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey,
			payload: { key: `collectionNote_${unique()}`, type: 'collectionNote', verses: verseArr, collectionId: null, studyHelpInfo },
		});
	};

	const handleUpdateCn = obj => {
		dispatch({ type: 'UPDATE_CN_PANE', payload: { uniqueKey: obj.uniqueKey, collectionId: obj.collectionId, userId: obj.userId } });
	};

	const handleAddVerseToNewCollectionPane = (verse, uniqueKey) => {
		dispatch({ type: 'ADD_VERSE_TO_NEW_CN', payload: { uniqueKey, verse } });
	};

	const handleAddSearchResult = (originPaneKey, searchText, searchSection, categories = null, tags = null, books = null, volumesFilter) => {
		dispatch({
			type: 'HANDLE_ADD_PANE',
			originPaneKey,
			payload: {
				key: `search_result_${unique()}`,
				originPaneKey,
				type: 'search_result',
				searchText,
				searchSection,
				categories,
				tags,
				books,
				volumesFilter: volumesFilter ? 'scripturesOnly' : 'all',
			},
		});
	};

	return (
		<NavContext.Provider
			value={{
				state,
				dispatch,
				handleAddCollection,
				createCollection,
				handleOpenVerses,
				handleAddNewCollectionNote,
				handleOpenVolume,
				handleOpenStudyTopic,
				handleOpenBook,
				handleUpdateCn,
				handleAddSearchResult,
				handleAddStudyHelpPane,
				handleAddVerseToNewCollectionPane,
				handleUpdateStudyTopic,
				removeVerseFromCollectionNote,
				handleUpdateLibraryPane,
				handleUpdateSearchPane,
				handleUpdateVersesPane,
				handleUpdateTodoPane,
				handleAddSharedCollection,
				handleOpenGame,
				handleOpenWordUseReport,
			}}
		>
			{children}
		</NavContext.Provider>
	);
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { VersesContext } from '../../ContextProviders/VersesProvider';
import { NavContext } from '../../ContextProviders/NavProvider';

const PaneWrapper = styled.div`
	width: 100%;
	height: 100%;
	visibility: visible;
`;

const ChaptersWrapper = styled.ul`
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
	font-size: 0;
`;

const ChaptersNumbersWrapper = styled.li`
	padding: 3px;
	display: inline-block;
	min-width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
`;

const ChapterNumber = styled.a`
	display: block;
	padding: 3px;
	font-weight: 400;
	color: #6a7583;
	text-align: center;
	border-radius: 3px;
	background: #f3f3f3;
	&:hover {
		background-color: #ececec;
	}
`;

const ChapterNamesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 95%;
	cursor: pointer;
`;
const PageRange = styled.span`
	font-style: italic;
`;
const ChapterNameSpan = styled.span`
	width: 80%;
	margin-right: 10px;
`;
const ChapterName = styled.a`
	padding: 3px;
	display: flex;
	// justify-content: space-;
	min-width: 40px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 14px;
	line-height: 20px;
	padding: 3px;
	font-weight: 400;
	border-radius: 3px;
	background: #f3f3f3;
	margin-bottom: 5px;
	width: 95%;
	&:hover {
		background-color: #ececec;
	}
`;

const VersesChapters = props => {
	const { getVerses } = useContext(VersesContext);
	const { handleUpdateVersesPane } = useContext(NavContext);
	const handleChooseChapter = (e, value) => {
		e.preventDefault();
		getVerses({ uniqueKey: props.uniqueKey, bookId: value.book_id, chapterId: value.id, userId: props.userId });
		handleUpdateVersesPane({
			bookId: value.book_id,
			chapterId: value.id,
			paneKey: props.uniqueKey,
		});
		props.handleShowVerses(value.id);
	};
	// 1585 1586
	const retrieveChapterNumber = ({ order, chapterId, chapter_name }) => {
		if (chapter_name !== null && chapter_name.length <= 4) {
			return chapter_name;
		}
		if (chapterId === 1585 || chapterId === 1586) {
			if (order === 139) return 'OD1';
			return 'OD2';
		}
		return order;
	};
	const chapterNames = props.chapters
		.filter(e => e.chapter_name !== null && e.chapter_name.length > 4)
		.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
	const chapterNumbers = props.chapters.filter(e => e.chapter_name === null);
	return (
		<PaneWrapper>
			{props.chapters.map((value, key) => {
				return !value.chapter_name || value.chapter_name.length < 5 ? (
					<ChaptersNumbersWrapper
						key={key}
						onClick={e => {
							if (props.isSignedIn) {
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									return window.open(
										`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
										'_blank'
									);
								} else {
									handleChooseChapter(e, value);
								}
							} else alert('Please sign in or sign up to use Scripture Notes.');
						}}
					>
						<ChapterNumber>{retrieveChapterNumber(value)}</ChapterNumber>
					</ChaptersNumbersWrapper>
				) : (
					<ChapterName
						key={key}
						onClick={e => {
							if (props.isSignedIn) {
								e.stopPropagation();
								e.persist();
								if (e.ctrlKey || e.metaKey) {
									return window.open(
										`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
										'_blank'
									);
								} else {
									handleChooseChapter(e, value);
								}
							} else alert('Please sign in or sign up to use Scripture Notes.');
						}}
					>
						<ChapterNameSpan>{value.chapter_name}</ChapterNameSpan>
						{value.chapter_pages && <PageRange>pg: {value.chapter_pages.trim()}</PageRange>}
					</ChapterName>
				);
			})}
			{/* {chapterNames.length <= 0 ? (
				<ChaptersWrapper>
					{props.chapters.map((value, key) => {
						return (
							<ChaptersNumbersWrapper key={key} onClick={e => handleChooseChapter(e, value)}>
								<ChapterNumber>{retrieveChapterNumber(value)}</ChapterNumber>
							</ChaptersNumbersWrapper>
						);
					})}
				</ChaptersWrapper>
			) : (
				<ChapterNamesWrapper style={{ color: '#6a7583' }}>
					{chapterNumbers.length > 0 && (
						<ChaptersWrapper style={{ marginBottom: '10px' }}>
							{chapterNumbers.map((value, key) => {
								return (
									<ChaptersNumbersWrapper
										key={key}
										onClick={e => {
											e.stopPropagation();
											e.persist();
											if (e.ctrlKey || e.metaKey) {
												return window.open(
													`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
													'_blank'
												);
											} else {
												handleChooseChapter(e, value);
											}
										}}
									>
										<ChapterNumber>{retrieveChapterNumber(value)}</ChapterNumber>
									</ChaptersNumbersWrapper>
								);
							})}
						</ChaptersWrapper>
					)}
					{chapterNames.map((value, key) => {
						return (
							<ChapterName
								key={key}
								onClick={e => {
									e.stopPropagation();
									e.persist();
									if (e.ctrlKey || e.metaKey) {
										return window.open(
											`${process.env.REACT_APP_MAIN_URL}?pane=library&libsearch=${value.search}&chapter=${value.id}&book=${value.book_id}`,
											'_blank'
										);
									} else {
										handleChooseChapter(e, value);
									}
								}}
							>
								{value.chapter_name}
							</ChapterName>
						);
					})}
				</ChapterNamesWrapper>
			)} */}
		</PaneWrapper>
	);
};

export default VersesChapters;

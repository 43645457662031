import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { DeletePopUpBtn, DeletePopUpBtnWrapper, ClosePopUpBtn, SaveBtn } from '../Shared/SharedStyles';
import { PortalWithState } from 'react-portal';
import Tooltip from 'react-tooltip-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt, faInfoCircle } from '@fortawesome/fontawesome-free-solid/';

const PodcastDescMap = styled.div`
	margin-bottom: 13px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	justify-content: space-between;
`;

const Podcast = styled.span`
	color: #6a7583;
	font-size: 14px;
	&:hover {
		background-color: #ececec;
	}
`;
const DeleteIconWrapper = styled.button`
	color: #7d8085;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
const DeletePopUpWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 20%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
	display: flex;
	flex-direction: column;
`;
const TitleInput = styled.input`
	margin-bottom: 5px;
`;
const DescriptionInput = styled.textarea`
	margin-bottom: 5px;
	min-height: 55px;
`;

const TimeStampArea = styled.textarea`
	height: 300px;
	margin-bottom: 10px;
`;
const InputContainer = styled.div`
	width: 100%;
`;
const AddButton = styled.button`
	width: 10%;
	background: transparent;
	border: none;
	outline: none;
`;

const EmailInput = styled.input`
	width: 90%;
`;

const EmailContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
`;
const MappedEmails = styled.div`
	max-height: 55px;
	overflow: scroll;
	padding: 5px;
`;
const DeleteEmailButton = styled.button`
	background: transparent;
	border: none;
	color: red;
	outline: none;
`;

const PodcastTitle = props => {
	const { handleOpenPodcast, handleDeletePodcast, value, setData, user } = props;
	const [instructions, setInstructions] = useState(value.instructions);
	const [title, setTitle] = useState(value.podcast_name);
	const [description, setDescription] = useState(value.description);
	const [website, setWebsite] = useState(value.website);
	const [ownerEmail, setOwnerEmail] = useState('');
	const [assistantEmail, setAssistantEmail] = useState('');
	const [podcastAdmins, setPodcastAdmins] = useState([]);

	const editPodcastInfo = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/editPodcastInfo/${value.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ title, description, website }),
		})
			.then(res => res.json())
			.then(podcasts => {
				setData(podcasts);
			});
	};
	const addOwnerEmail = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/addOwnerEmail/${value.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email: ownerEmail.toLowerCase() }),
		})
			.then(res => res.json())
			.then(res => {
				if (res === 'The email you entered does not exist.' || res === 'Please enter a new email.') {
					alert(res);
				} else {
					setOwnerEmail('');
					setPodcastAdmins(res);
				}
			})
			.catch(err => console.log(err));
	};
	const addAssistantEmail = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/addAssistantEmail/${value.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email: assistantEmail.toLowerCase() }),
		})
			.then(res => res.json())
			.then(res => {
				if (res === 'The email you entered does not exist.' || res === 'Please enter a new email.') {
					alert(res);
				} else {
					setAssistantEmail('');
					setPodcastAdmins(res);
				}
			});
	};
	const deleteOwnerEmail = async email => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/deleteOwnerEmail/${value.id}/${email}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(res => {
				setPodcastAdmins(res);
			});
	};
	const deleteAssistantEmail = async email => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/deleteAssistantEmail/${value.id}/${email}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then(res => res.json())
			.then(res => {
				setPodcastAdmins(res);
			});
	};
	const editInstructions = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/editInstructions/${value.id}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ instructions }),
		})
			.then(res => res.json())
			.then(podcasts => {
				setData(podcasts);
			});
	};
	const fetchAdmins = async () => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/podcast/getPodcastAdmins/${value.id}`)
			.then(res => res.json())
			.then(admins => setPodcastAdmins(admins));
	};
	const ownerEmails = podcastAdmins
		.filter(e => e.role === 'owner')
		.map(e => {
			return (
				<EmailContainer>
					<div>{e.user_email}</div>
					<DeleteEmailButton onClick={event => deleteOwnerEmail(e.user_email)}>x</DeleteEmailButton>
				</EmailContainer>
			);
		});
	const assistantEmails = podcastAdmins
		.filter(e => e.role === 'assistant')
		.map(e => {
			return (
				<EmailContainer>
					<div>{e.user_email}</div>
					<DeleteEmailButton onClick={event => deleteAssistantEmail(e.user_email)}>x</DeleteEmailButton>
				</EmailContainer>
			);
		});

	return (
		<PodcastDescMap>
			<Podcast
				onClick={e => {
					handleOpenPodcast(value);
				}}
			>
				{title}
			</Podcast>
			<div>
				<PortalWithState closeOnOutsideClick closeOnEsc>
					{({ openPortal, closePortal, isOpen, portal }) => (
						<>
							<DeleteIconWrapper>
								<Tooltip content='Instructions'>
									<FontAwesomeIcon
										key='ProjectPortal'
										icon={faInfoCircle}
										style={instructions.length > 0 && { color: '#324599' }}
										onClick={openPortal}
									/>
								</Tooltip>
							</DeleteIconWrapper>
							{portal(
								<div>
									<div
										style={{
											top: 0,
											zIndex: 9999999,
											background: '#ddd',
											width: '100%',
											height: '100%',
											minHeight: '392px',
											opacity: '0.6',
											position: 'absolute',
										}}
									/>
									<DeletePopUpWrapper>
										<p>Podcast Instructions</p>
										<TimeStampArea onChange={e => setInstructions(e.target.value)} value={instructions}></TimeStampArea>
										<DeletePopUpBtnWrapper>
											<SaveBtn
												onClick={closePortal}
												onClickCapture={() => {
													editInstructions();
												}}
											>
												Save
											</SaveBtn>
											<DeletePopUpBtn
												onClick={() => {
													closePortal();
													setInstructions(value.instructions);
												}}
											>
												Cancel
											</DeletePopUpBtn>
										</DeletePopUpBtnWrapper>
									</DeletePopUpWrapper>
								</div>
							)}
						</>
					)}
				</PortalWithState>
				<PortalWithState closeOnOutsideClick closeOnEsc>
					{({ openPortal, closePortal, isOpen, portal }) => (
						<>
							<DeleteIconWrapper>
								<Tooltip content='Edit Podcast Info'>
									<FontAwesomeIcon
										key='ProjectPortal'
										icon={faPencilAlt}
										onClick={async () => {
											await fetchAdmins();
											openPortal();
										}}
									/>
								</Tooltip>
							</DeleteIconWrapper>
							{portal(
								<div>
									<div
										style={{
											top: 0,
											zIndex: 9999999,
											background: '#ddd',
											width: '100%',
											height: '100%',
											minHeight: '392px',
											opacity: '0.6',
											position: 'absolute',
										}}
									/>
									<DeletePopUpWrapper>
										<p>Title: </p>
										<TitleInput onChange={e => setTitle(e.target.value)} value={title}></TitleInput>
										<p>Website: </p>
										<TitleInput onChange={e => setWebsite(e.target.value)} value={website}></TitleInput>
										<p>Description: </p>
										<DescriptionInput onChange={e => setDescription(e.target.value)} value={description}></DescriptionInput>

										<InputContainer>
											<EmailInput
												placeholder='Add Owner Email...'
												onChange={e => setOwnerEmail(e.target.value)}
												value={ownerEmail}
											></EmailInput>
											<AddButton onClick={() => addOwnerEmail()}>
												<FontAwesomeIcon icon={['fas', 'plus-circle']} color={'#324599'} />
											</AddButton>
										</InputContainer>
										<MappedEmails>{ownerEmails}</MappedEmails>
										<InputContainer>
											<EmailInput
												placeholder='Add Assistant Email...'
												onChange={e => setAssistantEmail(e.target.value)}
												value={assistantEmail}
											></EmailInput>
											<AddButton onClick={() => addAssistantEmail()}>
												<FontAwesomeIcon icon={['fas', 'plus-circle']} color={'#324599'} />
											</AddButton>
										</InputContainer>
										<MappedEmails>{assistantEmails}</MappedEmails>
										<DeletePopUpBtnWrapper>
											<SaveBtn
												onClick={closePortal}
												onClickCapture={() => {
													editPodcastInfo();
												}}
											>
												Save
											</SaveBtn>
											<DeletePopUpBtn
												onClick={() => {
													closePortal();
													setTitle(value.podcast_name);
													setDescription(value.description);
													setWebsite(value.website);
												}}
											>
												Cancel
											</DeletePopUpBtn>
										</DeletePopUpBtnWrapper>
									</DeletePopUpWrapper>
								</div>
							)}
						</>
					)}
				</PortalWithState>
				<PortalWithState closeOnOutsideClick closeOnEsc>
					{({ openPortal, closePortal, isOpen, portal }) => (
						<>
							<DeleteIconWrapper>
								<Tooltip content='Delete Project'>
									<FontAwesomeIcon key='ProjectPortal' icon={faTrash} onClick={openPortal} />
								</Tooltip>
							</DeleteIconWrapper>
							{portal(
								<div>
									<div
										style={{
											top: 0,
											zIndex: 9999999,
											background: '#ddd',
											width: '100%',
											height: '100%',
											minHeight: '392px',
											opacity: '0.6',
											position: 'absolute',
										}}
									/>
									<DeletePopUpWrapper>
										<p>Are you sure, you want to delete this podcast?</p>
										<DeletePopUpBtnWrapper>
											<DeletePopUpBtn
												onClick={closePortal}
												onClickCapture={() => {
													handleDeletePodcast(value);
												}}
											>
												Delete
											</DeletePopUpBtn>
											<ClosePopUpBtn onClick={closePortal}>Close</ClosePopUpBtn>
										</DeletePopUpBtnWrapper>
									</DeletePopUpWrapper>
								</div>
							)}
						</>
					)}
				</PortalWithState>
			</div>
		</PodcastDescMap>
	);
};

export default PodcastTitle;

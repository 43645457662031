import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AuthProvider } from './ContextProviders/AuthProvider';
import { SearchHistoryProvider } from './ContextProviders/SearchHistoryProvider';
import { VerseOptionsProvider } from './ContextProviders/VerseOptionsProvider.js';
// import { ChapterHistoryProvider } from './ContextProviders/ChapterHistoryProvider';
import { NavProvider } from './ContextProviders/NavProvider';
import { LibraryProvider } from './ContextProviders/LibraryProvider';
import { AnalyticsProvider } from './ContextProviders/AnalyticsProvider';
import { CollectionListProvider } from './ContextProviders/CollectionListProvider';
import { TagsProvider } from './ContextProviders/TagsProvider';
import { DashboardProvider } from './ContextProviders/DashboardProvider';
import { CollectionsProvider } from './ContextProviders/CollectionsProvider';
import { VersesProvider } from './ContextProviders/VersesProvider';
import { ChapterHistoryProvider } from './ContextProviders/ChapterHistoryProvider';
import { AppContainer } from './components/App/AppContainer';
import { SearchProvider } from './ContextProviders/SearchProvider';
import { StudyHelpsProvider } from './ContextProviders/StudyHelpsProvider';
import { BulkMoveProvider } from './ContextProviders/BulkMoveProvider';
import Login from './components/Login/Login.js';
import Signup from './components/Signup/Signup';
import PasswordReset from './components/PasswordReset/PasswordReset.js';
import ChangePassword from './components/PasswordReset/ChangePassword.js';
import ChangeEmail from './components/PasswordReset/ChangeEmail.js';
import './assets/css/app.css';

const queryString = require('query-string');

if (localStorage.getItem('sn-user') === null && Object.keys(queryString.parse(window.location.search)).length > 0) {
	// const search = queryString.parse(window.location.search)

	// Object.keys(queryString.parse(window.location.search)).length > 0
	// localStorage.setItem('share-links', queryString.parse(window.location.search));
	queryString.parse(window.location.search).pane && localStorage.setItem('share-links', JSON.stringify(queryString.parse(window.location.search)));
}

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			localStorage.getItem('sn-user') !== null ? (
				<Component {...props} />
			) : //This ternary below is if theres a shared link but the user isnt logged in
			Object.keys(queryString.parse(window.location.search)).length > 1 && queryString.parse(window.location.search).pane ? (
				<Component {...props} />
			) : (
				<Redirect to='/login' />
			)
		}
	/>
);

const App = () => {
	return (
		<Router>
			<Switch>
				<TagsProvider>
					<NavProvider>
						<AuthProvider>
							<StudyHelpsProvider>
								<SearchHistoryProvider>
									<AnalyticsProvider>
										<LibraryProvider>
											<CollectionListProvider>
												<DashboardProvider>
													<CollectionsProvider>
														<ChapterHistoryProvider>
															<VersesProvider>
																<SearchProvider>
																	<BulkMoveProvider>
																		<VerseOptionsProvider>
																			<PrivateRoute exact path='/' component={AppContainer} />
																			<Route path='/login' component={Login} />
																			<Route path='/register' component={Signup} />
																			<Route path='/forgot' component={PasswordReset} />
																			<Route path='/reset-password/:token/:email' component={ChangePassword} />
																			<Route path='/reset-email/:token/:email' component={ChangeEmail} />
																			{/* {<Route exact component={() => <h3>404 Not Found. Try this link - <a href='https://app.scripturenotes.com/'>https://app.scripturenotes.com/</a></h3>} />} */}
																		</VerseOptionsProvider>
																	</BulkMoveProvider>
																</SearchProvider>
															</VersesProvider>
														</ChapterHistoryProvider>
													</CollectionsProvider>
												</DashboardProvider>
											</CollectionListProvider>
										</LibraryProvider>
									</AnalyticsProvider>
								</SearchHistoryProvider>
							</StudyHelpsProvider>
						</AuthProvider>
					</NavProvider>
				</TagsProvider>
			</Switch>
		</Router>
	);
};

export default App;

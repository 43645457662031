import React, { useState, useRef, useEffect, useContext } from 'react';
import { AnalyticsProviderState } from '../../ContextProviders/AnalyticsProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import StudyHelps from '../StudyHelps/StudyHelps';
import Chapters from './Chapters.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import Fuse from 'fuse.js';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import '../../assets/css/analyticsModal.css';
import moment from 'moment';
// import { StudyHelpsContext } from '../../ContextProviders/StudyHelpsProvider';

const queryString = require('query-string');

// The following styles are everything above the Old Testament Text.
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const SearchInputContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
`;

const LibraryText = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 23px;
`;

const SearchBarContainer = styled.div`
	position: relative;
`;

const SearchInput = styled.input`
	padding-right: 30px;
	padding: 8px;
	color: #6a7583;
	border: 1px solid #ebebeb;
	background-color: #ffffff;
	width: 100%;
	font-size: 15.5px;
`;

const SearchIconWrapper = styled.button`
	color: #7d8085;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 17px;
	border: none;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	&:hover {
		color: #1a56eb;
	}
`;
// The following styles are everything below the search input.
const LibraryVolumeContainer = styled.div`
	padding: 25px;
	padding-top: 0px;
	position: relative;
	padding-bottom: 13px;
	height: 90%;
	min-width: 300px;
	background-color: #fff;
	width: 100%;
`;

const BackButtonDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;

const VolumeDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	font-weight: 500;
	&:hover {
		background-color: #ececec;
	}
`;

const ProUsersDisplay = styled.ul`
	padding: 0px;
	margin: 0px;
	cursor: pointer;
	font-size: 15px;
	list-style: none;
	color: #6a7583;
	font-weight: 500;
`;
const ProUsersList = styled.li`
	margin-bottom: 5px;
	cursor: pointer;
	font-size: 15px;
	color: #6a7583;
	font-weight: 500;
	&:hover {
		background-color: #ececec;
	}
`;

const TableSection = styled.table`
	width: 90%;
`;
const TrSection = styled.tr``;
const TdSection = styled.td`
	border: #ccc solid 1px;
	box-sizing: border-box;
	padding: 10px;
`;
const TdSectionRight = styled.td`
	border: #ccc solid 1px;
	box-sizing: border-box;
	padding: 10px;
	text-align: center;
`;
const TableHeading = styled.h3`
	font-size: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
`;

const data = {
	labels: [],
	datasets: [
		{
			label: 'No-plan',
			data: [],
			fill: false,
			backgroundColor: 'rgb(255, 99, 132)',
			borderColor: 'rgba(255, 99, 132, 0.2)',
		},
		{
			label: 'Pro-plan',
			data: [],
			fill: false,
			backgroundColor: 'rgb(63, 146, 186)',
			borderColor: 'rgba(63, 146, 186, 0.2)',
		},
	],
};

const options = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};

//*********//
const data2 = {
	labels: [],
	datasets: [
		{
			label: ' Active Users',
			data: [],
			fill: false,
			backgroundColor: 'rgb(0, 255,196)',
			borderColor: 'rgba(0,255,196, 0.7)',
		},
	],
};

const options2 = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};

//BAR CHART************
const data3 = {
	labels: [],
	datasets: [
		{
			label: 'Search History',
			data: [],
			backgroundColor: [
				'rgba(255, 99, 132, 0.2)',
				'rgba(54, 162, 235, 0.2)',
				'rgba(255, 206, 86, 0.2)',
				'rgba(75, 192, 192, 0.2)',
				'rgba(153, 102, 255, 0.2)',
				'rgba(255, 159, 64, 0.2)',
				'rgba(155, 159, 64, 0.2)',
			],
			borderColor: [
				'rgba(255, 99, 132, 1)',
				'rgba(54, 162, 235, 1)',
				'rgba(255, 206, 86, 1)',
				'rgba(75, 192, 192, 1)',
				'rgba(153, 102, 255, 1)',
				'rgba(255, 159, 64, 1)',
				'rgba(155, 159, 64, 1)',
			],
			borderWidth: 1,
		},
	],
};

const options3 = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};

const Analytics = props => {
	const { handleAnalyticsModal } = props;
	const chartReference = {};
	const charRef = useRef(null);
	const [userdata, setUsers] = useState([]);
	const [newuserdata, setNewUsers] = useState([]);
	const [loggedinuserdata, setLoggedinUsers] = useState([]);
	const [usercount, setcount] = useState([]);
	const [mostSearchHistory, setmostsearch] = useState([]);
	const [chapterRead, setChapterRead] = useState([]);
	// Global Storage
	const { analyticsState, analyticsDispatch, fetchAnalytics } = useContext(AnalyticsProviderState);

	const { authState } = useContext(AuthContext);
	// const {studyHelpsState, studyHelpsDispatch} = useContext(StudyHelpsContext)

	//******STARING DATE FUNCTIONS
	const getDateArray = (start, end) => {
		var arr = new Array(),
			dt = new Date(start);

		// arr.push(10);
		// arr.push(11);
		// arr.push(12)

		while (dt <= end) {
			//KAS
			var dd = String(dt.getDate()).padStart(2, '0');
			var mm = String(dt.getMonth() + 1).padStart(2, '0'); //January is 0!
			var yyyy = dt.getFullYear();
			var today = yyyy + '-' + mm + '-' + dd;
			//KAS - END

			//    arr.push(new Date(dt));
			arr.push(today);
			dt.setDate(dt.getDate() + 1);
		}

		return arr;
	};
	var today = new Date();
	var startDate = new Date().setDate(today.getDate() - 30);
	var endDate = new Date();
	var dateArrNew = getDateArray(startDate, endDate);

	//************END DATE FUNCTIONS AND VARIABLE

	useEffect(() => {
		const fetchAnalytics = async key => {
			const today = new Date();
			const priorDate = new Date().setDate(today.getDate() - 30);
			

			let newDate = new Date();
			let date = newDate.getDate();
			let premonth = newDate.getMonth();
			let month = newDate.getMonth() + 1;
			let year = newDate.getFullYear();
			let predate = moment(priorDate).format('YYYY-MM-DD');
			let curdate = year + '-' + month + '-' + date;

			let users = await fetch(`${process.env.REACT_APP_BASE_URL}/analytics/getAllUsers`)
				.then(response => response.json())
				.then(users => {
					return users;
				});

			let newusers = await fetch(`${process.env.REACT_APP_BASE_URL}/analytics/getUsersForLastWeek`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ predate: `${predate}`, curdate: `${curdate}` }),
			})
				.then(response => response.json())
				.then(newusers => {
					return newusers;
				});

			let loggedinusers = await fetch(`${process.env.REACT_APP_BASE_URL}/analytics/getLoggedinUsers`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ predate: `${predate}`, curdate: `${curdate}` }),
			})
				.then(response => response.json())
				.then(loggedinusers => {
					return loggedinusers;
				});

			let procount = await fetch(`${process.env.REACT_APP_BASE_URL}/analytics/getProUsersCount`)
				.then(response => response.json())
				.then(procount => {
					return procount[0].count;
				});

			let mostsearches = await fetch(`${process.env.REACT_APP_BASE_URL}/searchHistory/mostSearchHistory`)
				.then(response => response.json())
				.then(mostsearch => {
					// 
					return mostsearch;
				});

			let chaptersRead = await fetch(`${process.env.REACT_APP_BASE_URL}/chapterHistory/getChapterRead`)
				.then(res => res.json())
				.then(chaptersRead => chaptersRead);

			setUsers(users);
			
			setcount(procount);
			setmostsearch(mostsearches);

			setNewUsers(newusers);
			setLoggedinUsers(loggedinusers);
			setChapterRead(chaptersRead);
		};

		fetchAnalytics();
	}, []);

	const unique = () => {
		return new Date().getTime().toString(36);
	};

	//Show the most search
	const ShowMostSearch = props => {
		var countArr = Array();
		var barLabelArr = Array();
		const list = mostSearchHistory.map(search => {
			var count = search.cnt;
			barLabelArr.push(search.search_keyword);
			countArr.push(count);

			return (
				<TrSection key='{search.search_keyword}'>
					<TdSection>{search.search_keyword}</TdSection>
					<TdSectionRight>{count}</TdSectionRight>
				</TrSection>
			);
		});
		data3.labels = barLabelArr;
		data3.datasets[0].data = countArr;

		return (
			<div>
				<TableSection>{list}</TableSection>
			</div>
		);
		// return (<div><Bar data={data3} options={options3} /></div>);
	};

	//Show the chapter read
	const ShowChapterRead = props => {
		// var testArr=Array();
		// var str='';
		// var countArr=[];
		// for(var i=0; i < dateArrNew.length; i++){
		// 	var wow='';
		// 	var isMatched2=false;
		// 	for(var j=0; j < chapterRead.length; j++){
		// 		var d=new Date(chapterRead[j].created_at).toLocaleDateString("fr-CA");
		// 		var count=chapterRead[j].logincount;
		// 		if(d==dateArrNew[i]){isMatched2=true;break;}

		// 	}
		// 	if(isMatched2){
		// 		countArr.push(count)
		// 		wow=<TrSection><TdSection>{dateArrNew[i]}</TdSection><TdSection>{count}</TdSection></TrSection>;
		// 	}
		// 	else{
		// 		countArr.push(0)
		// 		wow=<TrSection><TdSection>{dateArrNew[i]}</TdSection><TdSection>0</TdSection></TrSection>;
		// 	}
		// 	testArr.push(wow)
		// }

		// return (<TableSection>{wow}</TableSection>);
		return '';
	};

	//Show logged users
	const ShowLoggedinUsers = props => {
		var testArr = Array();
		var str = '';
		var countArr = [];

		for (var i = 0; i < dateArrNew.length; i++) {
			var wow = '';
			var isMatched2 = false;
			for (var j = 0; j < loggedinuserdata.length; j++) {
				var d = new Date(loggedinuserdata[j].created_at).toLocaleDateString('fr-CA');
				var count = loggedinuserdata[j].logincount;
				if (d == dateArrNew[i]) {
					isMatched2 = true;
					break;
				}
			}
			if (isMatched2) {
				countArr.push(count);
				wow = (
					<TrSection>
						<TdSection>{dateArrNew[i]}</TdSection>
						<TdSection>{count}</TdSection>
					</TrSection>
				);
			} else {
				countArr.push(0);
				wow = (
					<TrSection>
						<TdSection>{dateArrNew[i]}</TdSection>
						<TdSection>0</TdSection>
					</TrSection>
				);
			}
			testArr.push(wow);
		}

		data2.labels = dateArrNew;
		data2.datasets[0].data = countArr;

		//return (<TableSection>{testArr}</TableSection>);
		return (
			<div>
				<Line data={data2} options={options2} />
			</div>
		);
	};
	var labelArr = [];
	/*for(var x=0; x<10; x++){
			labelArr.push(x)
		}*/
	var countArr = [];
	var countArrPro = [];
	const ShowNewSignup = props => {
		var testArr = Array();
		var str = '';
		var dates = '';
		var uN = 0;
		for (var i = 0; i < dateArrNew.length; i++) {
			var wow = '';
			dates = dateArrNew[i].split('-');
			labelArr.push(dates[2]);
			var isMatched = false;

			for (var j = 0; j < newuserdata.length; j++) {
				var d = new Date(newuserdata[j].created_at).toLocaleDateString('fr-CA');
				var count = newuserdata[j].usercount;
				var countpro = newuserdata[j].usercountpro;
				if (d == dateArrNew[i]) {
					isMatched = true;
					break;
				}
			}
			if (isMatched) {
				countArr.push(count);
				countArrPro.push(countpro);
				wow = (
					<TrSection>
						<TdSection>{dateArrNew[i]}</TdSection>
						<TdSection>{count}</TdSection>
					</TrSection>
				);
			} else {
				countArr.push(0);
				countArrPro.push(0);
				wow = (
					<TrSection>
						<TdSection>{dateArrNew[i]}</TdSection>
						<TdSection>0</TdSection>
					</TrSection>
				);
			}
			testArr.push(wow);
		}

		
		data.labels = dateArrNew;
		data.datasets[0].data = countArr;
		data.datasets[1].data = countArrPro;
		//charRef.renderChart();

		return (
			<div>
				<Line data={data} ref={charRef} options={options} />
			</div>
		);
	};

	return (
		<>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon icon={faTimes} onClick={handleAnalyticsModal} />
				</Tooltip>
			</ClosePaneWrapper>

			<LibraryVolumeContainer>
				<LibraryText {...props.dragHandleProps}>Web Analytics </LibraryText>
				<Scrollbars autoHide>
					<div>
						<TableHeading>New Signup - last 30 days </TableHeading>

						<ShowNewSignup />

						<TableHeading>Unique Active Users - last 30 days </TableHeading>

						<ShowLoggedinUsers />

						<TableHeading>Most Search Keywords - last 30 days </TableHeading>
						<ShowMostSearch />
					</div>
				</Scrollbars>
			</LibraryVolumeContainer>
		</>
	);
};

export default Analytics;

import React, { useReducer, createContext, useState } from 'react';
import _ from 'lodash';
// import { CollectionListContext } from './CollectionListProvider';

export const CollectionsContext = createContext();

const initialState = {};

const sortVerses = (verses = []) => {
	if (!verses.length) return [];
	verses = _.sortBy(
		verses,
		[verse => verse.chapter.book.volume.order, verse => verse.chapter.book.order, verse => verse.chapter.order, verse => verse.number],
		['asc', 'asc', 'asc', 'asc']
	);
	return verses;
};

const reducer = (state, action) => {
	let sortedVerses;
	switch (action.type) {
		case 'GET_COLLECTION_NOTE':
			if (action.payload.collectionNote) {
				sortedVerses = sortVerses(action.payload.collectionNote ? action.payload.collectionNote.verses : []);
				action.payload.collectionNote.verses = sortedVerses;
				return {
					...state,
					[action.payload.uniqueKey]: {
						collection: action.payload.collectionNote,
						categories: action.payload.categories,
						collectionNoteTags: action.payload.collectionNoteTags,
						fromVerse: action.payload.fromVerse,
					},
				};
			} else return state;
		// if (action.payload.collectionNote) action.payload.collectionNote.verses = sortedVerses;
		case 'CREATE_COLLECTION_NOTE':
			sortedVerses = sortVerses(action.payload.collectionNote.verses);

			action.payload.collectionNote.verses = sortedVerses;
			return {
				...state,
				[action.payload.uniqueKey]: {
					collection: action.payload.collectionNote,
					categories: action.payload.categories,
					collectionNoteTags: action.payload.collectionNoteTags,
				},
			};
		case 'REMOVE_VERSE_FROM_CN':
			sortedVerses = sortVerses(state[action.payload.uniqueKey].collection.verses.filter(verse => verse.id !== action.payload.verseId));
			let collection = state[action.payload.uniqueKey].collection;
			collection.verses = sortedVerses;
			return {
				...state,
				[action.payload.uniqueKey]: {
					collection,
					fromVerse: true,
					categories: state[action.payload.uniqueKey].categories,
					collectionNoteTags: state[action.payload.uniqueKey].collectionNoteTags,
				},
			};
		case 'ADD_VERSE_TO_CN':
			if (action.payload.forNewCn) {
				let verses = [...state[action.payload.uniqueKey].collection.verses, action.payload.verse];
				sortedVerses = sortVerses(verses);
				let stateCopy = { ...state };
				stateCopy[action.payload.uniqueKey].collection.verses = sortedVerses;
				return stateCopy;
			} else {
				sortedVerses = sortVerses(action.payload.collectionNote.verses);

				action.payload.collectionNote.verses = sortedVerses;

				return {
					...state,
					[action.payload.uniqueKey]: {
						collection: action.payload.collectionNote,
						categories: state[action.payload.uniqueKey].categories,
						collectionNoteTags: state[action.payload.uniqueKey].collectionNoteTags,
						fromVerse: true,
					},
				};
			}
		case 'ADD_BULK_VERSES_TO_CN': {
			let verses = [...state[action.payload.uniqueKey].collection.verses, ...action.payload.verses];
			let versesWithoutDuplicates = verses.reduce((acc, cur) => (acc.find(v => v.id === cur.id) ? acc : [cur, ...acc]), []);
			sortedVerses = sortVerses(versesWithoutDuplicates);
			let stateCopy = { ...state };

			stateCopy[action.payload.uniqueKey].collection.verses = sortedVerses;
			return stateCopy;
		}

		case 'ADD_VERSE_TO_NEW_CN':
			let stateCopy = { ...state };
			let existingVerses = stateCopy[action.payload.uniqueKey].collection.verses || [];
			let versesWithoutDuplicates = [...existingVerses, action.payload.verse].reduce(
				(acc, cur) => (acc.find(v => v.id === cur.id) ? acc : [cur, ...acc]),
				[]
			);
			stateCopy[action.payload.uniqueKey].collection.verses = sortVerses(versesWithoutDuplicates);
			stateCopy[action.payload.uniqueKey]['fromVerse'] = true;
			return stateCopy;

		case 'GET_ALL_FOOTNOTES':
			let versesCopy = [...state[action.payload.uniqueKey].collection.verses];
			let verseIndex = versesCopy.findIndex(verse => action.payload.verseId === verse.id);
			versesCopy[verseIndex].allFootnotes = action.payload.allFootnotes;

			return {
				...state,
				[action.payload.uniqueKey]: {
					collection: state[action.payload.uniqueKey].collection,
					categories: state[action.payload.uniqueKey].categories,
					collectionNoteTags: state[action.payload.uniqueKey].collectionNoteTags,
				},
			};
		case 'UPDATE_COLLECTION_NOTE': {
			let stateCopy = { ...state };
			state[action.payload.uniqueKey].collection.text = action.payload.text;
			state[action.payload.uniqueKey].collection.title = action.payload.title;
			state[action.payload.uniqueKey].collection.categories = action.payload.categories;
			state[action.payload.uniqueKey].collection.tags = action.payload.tags;
			return stateCopy;
		}
		case 'UPDATE_CN_VERSE_NOTE': {
			const { uniqueKey, index, noteText } = action.payload;
			let stateCopy = { ...state };

			if (stateCopy[uniqueKey].collection.verses[index].verseNote) {
				stateCopy[uniqueKey].collection.verses[index].verseNote.verse_note = noteText;
			} else stateCopy[uniqueKey].collection.verses[index].verseNote = { verse_note: noteText };
			return stateCopy;
		}
		case 'UPDATE_CN_VERSE_MARKUP': {
			const { uniqueKey, verseId, text } = action.payload;
			let stateCopy = { ...state };
			const index = stateCopy[uniqueKey].collection.verses.findIndex(({ id }) => id === parseInt(verseId));
			if (stateCopy[uniqueKey].collection.verses[index].userMarkup) {
				stateCopy[uniqueKey].collection.verses[index].userMarkup.verse_text_with_markup = text;
			} else stateCopy[uniqueKey].collection.verses[index].userMarkup = { verse_text_with_markup: text };
			return stateCopy;
		}
		case 'UPDATE_CN_VERSE_BOOKMARK': {
			const { uniqueKey, index, bookmarkId } = action.payload;
			let stateCopy = { ...state };
			stateCopy[uniqueKey].collection.verses[index].bookmarkId = bookmarkId;
			return stateCopy;
		}
		case 'UPDATE_CN_SPECIFIC_MARKUP': {
			const { uniqueKey, verseId, text } = action.payload;
			let stateCopy = { ...state };
			const index = stateCopy[uniqueKey].collection.verses.findIndex(({ id }) => id === parseInt(verseId));

			stateCopy[uniqueKey].collection.verses[index].verse_markup = text;

			return stateCopy;
		}
		case 'TOGGLE_CUSTOM_MARKUP': {
			const { customMarkup, uniqueKey } = action.payload;
			let stateCopy = { ...state };
			stateCopy[uniqueKey].collection.custom_markup_active = customMarkup;
			return stateCopy;
		}
		case 'UPDATE_RECIPIENT_SAVE': {
			const { uniqueKey } = action.payload;
			let stateCopy = { ...state };
			stateCopy[uniqueKey].collection.recipient_saved = true;
			return stateCopy;
		}
		case 'UPDATE_SHARE_STAUTS': {
			const { uniqueKey, shareSettings, shareDate, can_save, shareCode, shareExpires } = action.payload;
			let stateCopy = { ...state };
			stateCopy[uniqueKey].collection.share_settings = shareSettings;
			stateCopy[uniqueKey].collection.share_date = shareDate;
			stateCopy[uniqueKey].collection.can_save = can_save;
			stateCopy[uniqueKey].collection.share_code = shareCode;
			stateCopy[uniqueKey].collection.share_expires = shareExpires;
			return stateCopy;
		}

		default:
			throw new Error('That action type does not exist.');
	}
};

export const CollectionsProvider = ({ children }) => {
	const [collectionState, collectionDispatch] = useReducer(reducer, initialState);

	const fetchUserCollectionNote = async obj => {
		const sendTime = new Date().getTime();
		try {
			let fromVerse = obj.fromVerse || false;

			const allCategories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
				.then(res => res.json())
				.then(categories => categories);
			const collectionNoteTags = await fetch(`${process.env.REACT_APP_BASE_URL}/tags/getAllTagsForUser/${obj.userId}`)
				.then(res => res.json())
				.then(tags => tags);
			const collectionNote = await fetch(
				`${process.env.REACT_APP_BASE_URL}/collections/getCollectionNoteByUserId/${obj.collectionId}/${obj.userId}${obj.noSave ? '/true' : ''}`
			)
				.then(res => res.json())
				.then(collectionNote => collectionNote);

			(!collectionNote || collectionNote !== 'Collection Note not found') &&
				collectionDispatch({
					type: 'GET_COLLECTION_NOTE',
					payload: {
						uniqueKey: obj.uniqueKey,
						collectionNote: collectionNote[0],
						categories: allCategories,
						collectionNoteTags: collectionNoteTags,
						fromVerse,
					},
				});
			const receiveTime = new Date().getTime();
			console.log('RECEIVED', (receiveTime - sendTime) / 1000, 'TIME');
		} catch (err) {
			console.log('Error in fetchUserCollectionNote:', err);
		}
	};
	const fetchSharedCollectionNote = async obj => {
		const sendTime = new Date().getTime();
		try {
			let fromVerse = obj.fromVerse || false;

			const allCategories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
				.then(res => res.json())
				.then(categories => categories);
			const collectionNoteTags = await fetch(`${process.env.REACT_APP_BASE_URL}/tags/getAllTagsForUser/${obj.userId}`)
				.then(res => res.json())
				.then(tags => tags);

			const collectionNote = await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getSharedCNByCode/${obj.userId}/${obj.shareCode}`)
				.then(res => res.json())
				.then(collection => collection)
				.catch(err => console.log('catch', err));
			if (collectionNote.message && collectionNote.message === `Cannot read property 'id' of undefined`)
				return alert('Sorry this collection is no longer being shared.');
			if (Date.parse(collectionNote[0].share_expires) < sendTime) return alert('Sorry this link has expired');
			// (!collectionNote || collectionNote !== 'Collection Note not found') &&
			collectionDispatch({
				type: 'GET_COLLECTION_NOTE',
				payload: {
					uniqueKey: obj.uniqueKey,
					collectionNote: collectionNote[0],
					categories: allCategories,
					collectionNoteTags: collectionNoteTags,
					fromVerse,
				},
			});
			const receiveTime = new Date().getTime();
			console.log('RECEIVED', (receiveTime - sendTime) / 1000, 'TIME');
		} catch (err) {
			console.log('Error in fetchSharedCollectionNote:', err);
		}
	};
	const fetchSharedNonUser = async obj => {
		const sendTime = new Date().getTime();
		try {
			let fromVerse = obj.fromVerse || false;

			const allCategories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
				.then(res => res.json())
				.then(categories => categories);
			const collectionNoteTags = [];

			const collectionNote = await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getSharedCNNonUser/${obj.shareCode}`)
				.then(res => res.json())
				.then(collection => collection)
				.catch(err => console.log('catch', err));
			if (collectionNote.message && collectionNote.message === `Cannot read property 'id' of undefined`)
				return alert('Sorry this collection is no longer being shared.');
			if (Date.parse(collectionNote[0].share_expires) < sendTime) return alert('Sorry this link has expired');
			// (!collectionNote || collectionNote !== 'Collection Note not found') &&
			collectionDispatch({
				type: 'GET_COLLECTION_NOTE',
				payload: {
					uniqueKey: obj.uniqueKey,
					collectionNote: collectionNote[0],
					categories: allCategories,
					collectionNoteTags: collectionNoteTags,
					fromVerse,
				},
			});
			const receiveTime = new Date().getTime();
			console.log('RECEIVED', (receiveTime - sendTime) / 1000, 'TIME');
		} catch (err) {
			console.log('Error in fetchSharedCollectionNote:', err);
		}
	};

	const getAllFootnotes = async obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/verses/getAllFootnotes/${obj.userId}/${obj.verseId}`)
			.then(res => res.json())
			.then(allFootnotes => {
				collectionDispatch({
					type: 'GET_ALL_FOOTNOTES',
					payload: {
						uniqueKey: obj.uniqueKey,
						allFootnotes: allFootnotes,
						verseId: obj.verseId,
					},
				});
			});
	};

	const handleAddVerseToNewCollectionNote = (verse, uniqueKey) => {
		collectionDispatch({ type: 'ADD_VERSE_TO_NEW_CN', payload: { uniqueKey, verse } });
	};
	const updateJournalDate = async obj => {
		const { date, userId, collectionId, uniqueKey } = obj;
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/updateJournalDate/${userId}/${collectionId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				date,
			}),
		})
			.then(res => res.json())
			.catch(err => console.log(err));

		await fetchUserCollectionNote({ collectionId, userId, uniqueKey });
	};

	// Maybe one place we can console log the collection state.
	const createNewCn = async obj => {
		const allCategories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
			.then(res => res.json())
			.then(categories => categories);
		const collectionNoteTags = await fetch(`${process.env.REACT_APP_BASE_URL}/tags/getAllTagsForUser/${obj.userId}`)
			.then(res => res.json())
			.then(tags => tags);

		return collectionDispatch({
			type: 'CREATE_COLLECTION_NOTE',
			payload: {
				uniqueKey: obj.uniqueKey,
				collectionNote: {
					categories: [],
					id: null,
					tags: [],
					text: `${obj.text}`,
					title: '',
					verses: [],
				},
				categories: allCategories,
				collectionNoteTags: collectionNoteTags,
			},
		});
	};

	// Maybe one place we can console log the collection state.
	const createCnWithVerse = async obj => {
		const allCategories = await fetch(`${process.env.REACT_APP_BASE_URL}/categories/getCategories`)
			.then(res => res.json())
			.then(categories => categories);
		const collectionNoteTags = await fetch(`${process.env.REACT_APP_BASE_URL}/tags/getAllTagsForUser/${obj.userId}`)
			.then(res => res.json())
			.then(tags => tags);

		return collectionDispatch({
			type: 'CREATE_COLLECTION_NOTE',
			payload: {
				uniqueKey: obj.uniqueKey,
				collectionNote: {
					categories: [],
					id: null,
					tags: [],
					text: '',
					title: '',
					verses: obj.verse,
				},
				categories: allCategories,
				collectionNoteTags: collectionNoteTags,
			},
		});
	};

	const saveCollectionNote = async (obj, handleUpdateCn, verseAdded) => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/saveCollectionNote`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				title: obj.title,
				text: obj.text,
				userId: obj.userId,
				tags: obj.tags,
				categories: obj.categories,
				studyHelpInfo: obj.studyHelpInfo,
			}),
		})
			.then(res => res.json())
			.then(collectionId => {
				fetchUserCollectionNote({ collectionId: collectionId, uniqueKey: obj.uniqueKey, userId: obj.userId });
				handleUpdateCn({ collectionId, uniqueKey: obj.uniqueKey, userId: obj.userId });
				verseAdded();
			})
			.catch(err => {
				alert('Could not save at this time.');
				console.log(err);
			});
	};
	const handleSaveSharedCN = async (obj, handleUpdateCn, verseAdded) => {
		const collectionId = await fetch(`${process.env.REACT_APP_BASE_URL}/collections/saveSharedCN`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				title: obj.title,
				text: obj.text,
				userId: obj.userId,
				tags: obj.tags,
				ownerId: obj.ownerId,
				originalCollectionId: obj.originCollectionId,
				categories: obj.categories,
				originatedFrom: obj.originatedFrom,
				read_only: obj.read_only,
				studyHelpInfo: obj.studyHelpInfo,
			}),
		})
			.then(res => res.json())
			.then(collectionId => {
				verseAdded();
				return collectionId;
			})
			.catch(err => {
				alert('Could not save at this time.');
				console.log(err);
			});
		if (obj.verses)
			await fetch(`${process.env.REACT_APP_BASE_URL}/collections/saveSharedCNVerses/${obj.userId}/${collectionId}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					verses: obj.verses.map(({ id, verse_markup, promoted, order }) => ({ id, verse_markup, promoted, order })),
				}),
			});
		fetchUserCollectionNote({ collectionId: collectionId, uniqueKey: obj.uniqueKey, userId: obj.userId });
		handleUpdateCn({ collectionId, uniqueKey: obj.uniqueKey, userId: obj.userId });
	};

	const saveCollectionNoteWithVerses = (obj, handleUpdateCn, verseAdded) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/saveCollectionNote`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				title: obj.title,
				text: obj.text,
				userId: obj.userId,
				tags: obj.tags,
				categories: obj.categories,
				verses: obj.verses.map(({ id, verse_markup }) => ({ id, verse_markup })),
				studyHelpInfo: obj.studyHelpInfo,
			}),
		})
			.then(res => res.json())
			.then(collectionId => {
				fetchUserCollectionNote({ collectionId: collectionId, uniqueKey: obj.uniqueKey, userId: obj.userId });
				handleUpdateCn({ collectionId, uniqueKey: obj.uniqueKey, userId: obj.userId });
				verseAdded();
			})
			.catch(err => {
				alert('Could not save at this time.');
				console.log(err);
			});
	};

	const deleteCollectionNote = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/deleteCollectionNote/${obj.userId}/${obj.id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	};
	const deleteSharedCollection = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/deleteSharedCollection/${obj.userId}/${obj.id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	};

	const updateCollectionNote = obj => {
		collectionDispatch({
			type: 'UPDATE_COLLECTION_NOTE',
			payload: obj,
		});
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/updateCollectionNote`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				title: obj.title,
				text: obj.text,
				userId: obj.userId,
				collectionId: obj.collectionId,
				categories: obj.categories,
				tags: obj.tags,
			}),
		})
			.then(res => res.json())
			.then(_cn => {
				// updateNoteInList(obj);
				obj.verseAdded();
				// collectionDispatch({ type: 'UPDATE_COLLECTION_NOTE', payload: { ...obj } });
			})
			.catch(err => {
				alert('Sorry, could not update at this time.');
				console.log('Error updating collection note:', err);
			});
	};

	const deleteVerseFromCn = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/deleteVerse/${obj.verseId}/${obj.collectionId}/${obj.userId}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		});
	};

	const filterVerseFromVerses = obj => {
		collectionDispatch({ type: 'REMOVE_VERSE_FROM_CN', payload: { uniqueKey: obj.uniqueKey, verseId: obj.verseId } });
	};

	const addNextVerseToCn = async obj => {
		await fetch(
			`${process.env.REACT_APP_BASE_URL}/collections/addVerse/${obj.verse.number + 1}/${obj.verse.chapter.id}/${obj.verse.id + 1}/${
				obj.verse.collection_id
			}/${obj.verse.user_id}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getCollectionNoteByUserId/${obj.verse.collection_id}/${obj.verse.user_id}/true`)
			.then(res => res.json())
			.then(collectionNote => {
				return collectionDispatch({ type: 'ADD_VERSE_TO_CN', payload: { uniqueKey: obj.uniqueKey, collectionNote: collectionNote[0] } });
			});
	};

	const addPrevVerseToCn = async obj => {
		await fetch(
			`${process.env.REACT_APP_BASE_URL}/collections/addVerse/${obj.verse.number - 1}/${obj.verse.chapter.id}/${obj.verse.id - 1}/${
				obj.verse.collection_id
			}/${obj.verse.user_id}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getCollectionNoteByUserId/${obj.verse.collection_id}/${obj.verse.user_id}/true`)
			.then(res => res.json())
			.then(collectionNote =>
				collectionDispatch({ type: 'ADD_VERSE_TO_CN', payload: { uniqueKey: obj.uniqueKey, collectionNote: collectionNote[0] } })
			);
	};

	const addVerseToCn = async ({ uniqueKey, verseNumber, chapterId, verseId, collectionId, userId }) => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/addVerse/${verseNumber}/${chapterId}/${verseId}/${collectionId}/${userId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		});

		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getCollectionNoteByUserId/${collectionId}/${userId}/true`)
			.then(res => res.json())
			.then(collectionNote => {
				return collectionDispatch({ type: 'ADD_VERSE_TO_CN', payload: { uniqueKey: uniqueKey, collectionNote: collectionNote[0] } });
			})
			.catch(err => console.log('Error in addVerseToCn:', err));
	};

	const addBulkVerses = async ({ userId, collectionId, bulkVerseArr, uniqueKey }) => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/addBulkVerses/${userId}/${collectionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				bulkVerseArr,
			}),
		});
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getCollectionNoteByUserId/${collectionId}/${userId}/true`)
			.then(res => res.json())
			.then(collectionNote => {
				console.log(collectionNote);
				return collectionDispatch({ type: 'ADD_VERSE_TO_CN', payload: { uniqueKey: uniqueKey, collectionNote: collectionNote[0] } });
			})
			.catch(err => console.log('Error in addVerseToCn:', err));
	};

	/** Toggles the promoted status of a verse in a collection note in the database */
	const togglePromotedVerseStatus = async (uniqueKey, verse) => {
		// Send PUT req to db to update the promoted status of a specific verse in a Collection Note
		await fetch(
			`${process.env.REACT_APP_BASE_URL}/collections/toggleVersePromotedInCn/${verse.collection_id}/${verse.user_id}/${verse.id}/${verse.promoted}`,
			{
				// put?
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				params: JSON.stringify({ userId: verse.user_id, collectionId: verse.collection_id, verseId: verse.id, promoted: verse.promoted }),
			}
		)
			.then(res => res.json())
			.catch(err => console.log('Error in updating the promoted verse status:', err));

		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/getCollectionNoteByUserId/${verse.collection_id}/${verse.user_id}/true`)
			.then(res => res.json())
			.then(collectionNote => {
				return collectionDispatch({ type: 'ADD_VERSE_TO_CN', payload: { uniqueKey: uniqueKey, collectionNote: collectionNote[0] } });
			});
	};

	const addVerseToNewCn = obj => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/collections/nextVerseNoCnId/${obj.verse.chapter.id}/${obj.verse.id + obj.offsetAmount || 1}/${obj.userId}`
		)
			.then(res => res.json())
			.then(verse => {
				return fetch(
					`${process.env.REACT_APP_BASE_URL}/verses/single/${obj.userId}/${verse[0].book_id}/${verse[0].chapter_id}/${verse[0].id}`
				).then(res => res.json());
			})
			.then(verse => {
				collectionDispatch({ type: 'ADD_VERSE_TO_CN', payload: { uniqueKey: obj.uniqueKey, verse: verse, forNewCn: true } });
			})
			.catch(err => console.log('Error in addVerseToNewCn:', err));
	};

	const getCollectionsForVerse = (obj, setVerseCollection) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/getCollectionForVerse/${obj.verseId}/${obj.userId}`)
			.then(res => res.json())
			.then(collectionNotes => setVerseCollection(collectionNotes))
			.catch(err => console.log('Error in getCollectionsForVerse:', err));
	};

	/*
	const handleGetPromotedVersesInCn = (obj) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/getPromotedVersesInCn/${obj.verseId}/${obj.userId}`)
			.then(res => res.json())
			.catch(err => console.log('Error in getCollectionsForVerse:', err));
	};
	*/

	const updateVerseNote = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/userVerseNote/saveUserVerseNote/${obj.userId}/${obj.verseId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: obj.noteText,
			}),
		})
			.then(res => res.json())
			.catch(err => console.log('Error in updateVerseNote:', err));
	};

	const saveUserFootnote = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/userFootNote/saveUserFootnote/${obj.userId}/${obj.verseId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: obj.text,
				delta: obj.delta,
			}),
		}).then(res => res.json());
	};
	const saveCnSpecificMarkup = obj => {
		fetch(`${process.env.REACT_APP_BASE_URL}/collections/saveCnSpecificMarkup/${obj.userId}/${obj.collectionId}/${obj.verseId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: obj.text,
				delta: obj.delta,
			}),
		}).then(res => res.json());
	};

	const toggleCustomMarkup = async obj => {
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/toggleCustomMarkup/${obj.userId}/${obj.collectionId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customMarkupActive: !obj.customMarkup,
			}),
		})
			.then(res => res.json())
			.then(res => {
				collectionDispatch({ type: 'TOGGLE_CUSTOM_MARKUP', payload: { customMarkup: !obj.customMarkup, uniqueKey: obj.uniqueKey } });
			});
	};
	const addCNToSharedList = async obj => {
		const { collectionId, ownerId, recipientId, sharedBy, uniqueKey } = obj;
		await fetch(`${process.env.REACT_APP_BASE_URL}/collections/addCNToSharedList/${recipientId}/${collectionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				sharedBy,
				ownerId,
			}),
		})
			.then(res => res.json())
			.then(res => {
				collectionDispatch({ type: 'UPDATE_RECIPIENT_SAVE', payload: { uniqueKey } });
			});
	};

	return (
		<CollectionsContext.Provider
			value={{
				collectionState,
				collectionDispatch,
				fetchUserCollectionNote,
				saveCollectionNote,
				deleteCollectionNote,
				updateCollectionNote,
				createCnWithVerse,
				createNewCn,
				saveCollectionNoteWithVerses,
				deleteVerseFromCn,
				filterVerseFromVerses,
				addNextVerseToCn,
				addVerseToNewCn,
				addPrevVerseToCn,
				addVerseToCn,
				getCollectionsForVerse,
				updateVerseNote,
				saveUserFootnote,
				handleAddVerseToNewCollectionNote,
				getAllFootnotes,
				togglePromotedVerseStatus,
				addBulkVerses,
				updateJournalDate,
				saveCnSpecificMarkup,
				toggleCustomMarkup,
				fetchSharedCollectionNote,
				fetchSharedNonUser,
				addCNToSharedList,
				handleSaveSharedCN,
				deleteSharedCollection,
			}}
		>
			{children}
		</CollectionsContext.Provider>
	);
};

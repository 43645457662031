import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch, faEdit, faTrash } from '@fortawesome/fontawesome-free-solid/';
import ClearHistory from '../Helpers/ClearHistory';
import Loader from 'react-loader-spinner';
import moment from 'moment';
/* Popup style */
const PopupWrapper = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 9999;
`;

const Box = styled.div`
	position: relative;
	width: 85%;
	max-width: 450px;
	margin: 0 auto;
	height: auto;
	max-height: 70vh;
	margin-top: calc(100vh - 85vh - 20px);
	background: #fff;
	border-radius: 4px;
	padding: 20px;
	border: 1px solid #999;
	overflow: auto;
`;

const CloseIcon = styled.span`
	content: 'x';
	cursor: pointer;
	position: fixed;
	right: calc(15% - 30px);
	top: calc(100vh - 85vh - 33px);
	background: #ededed;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	line-height: 20px;
	text-align: center;
	border: 1px solid #999;
	font-size: 20px;
`;
const SubCheckBox = styled.input`
	width: 100%;
	height: 45px;
	background-color: #f1f1f1;
	border: #979797 solid 1px;
	padding: 0px 10px;
`;
const UpdateButton = styled.button`
	width: 48%;
	margin: 1%;
	border: none;
	padding: 10px 0px;
	font-size: 17px;
	background-color: #282c37;
	color: #fff;
	border-radius: 3px;
`;
const CancelButton = styled.button`
	width: 48%;
	margin: 1%;
	border: none;
	padding: 10px 0px;
	font-size: 17px;
	background-color: #282c37;
	color: #fff;
	border-radius: 3px;
`;
const SubCheckBoxLabel = styled.label`
	margin-top: 15px;
	margin-bottom: 10px;
	display: block;
	font-size: 16px;
	font-weight: bold;
`;
const CardListTable = styled.table`
	width: 100%;
`;
const SmallHeading = styled.label`
	margin-top: 15px;
	margin-bottom: 10px;
	display: block;
	font-size: 14px;
	font-weight: bold;
`;
const SentMessageWrapper = styled.span``;

const SetDefault = styled.span`
	cursor: pointer;
	color: white;
	background-color: #2e83ba;
	display: inline-block;
	padding: 2px 10px;
	border-radius: 3px;
`;

const PopupSub = props => {
	const [userId, setUserId] = useState('');
	const [stripeId, setStripeId] = useState('');
	const { authState, isProUser, isTrialUser, hasProAccount } = useContext(AuthContext);
	const [oldEmail, setOldEmail] = useState(authState.user.email);
	const [sentMessage, setSentMessage] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const [currentCards, setCurrentCards] = useState('');
	const [renewalCheck, setRenewalCheck] = useState(false);
	const [expMonth, setexpMonth] = useState('');
	const [expYear, setexpyear] = useState('');
	const [cvc, setcvc] = useState('');
	const [defaultPayment, setDefaultPayment] = useState('');
	const [defaultPaymentMulti, setDefaultPaymentMulti] = useState('');
	const [tempState, setTempState] = useState('');
	const [planToUpgrade, setPlanToUpgrade] = useState('');
	const [planToUpgradeMulti, setPlanToUpgradeMulti] = useState('');
	const [planType, setPlanType] = useState('multi');
	const [newEmail, setNewEmail] = useState('');
	const [buttonEvent, setButtonEvent] = useState('');
	const [discountDuration, setDiscountDuration] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [totalSubDays, setTotalSubDays] = useState(0);
	console.log("defaultPaymentMulti",defaultPaymentMulti)
	useEffect(() => {
		console.log("defaultPaymentMulti1",defaultPaymentMulti)
		// userplan();
		var remainingSubdays=props.diffDays;
		var totalCurrentSubDay=0;
		var subscription_amount=0;
		if(props.upgradeTo=="Yearly"){
			var subscription_amount=49.95;
		}else if(props.upgradeTo=="3year"){
			var subscription_amount=129.95;
		}else if(props.upgradeTo=="5year"){
			var subscription_amount=199.95;
		}else if(props.upgradeTo=="10year"){
			var subscription_amount=349.95;
		}
		if(authState.user.stripe_plan_name=="Pro - Monthly"){
			totalCurrentSubDay=30;
		}else if(authState.user.stripe_plan_name=="Pro - Yearly"){
			totalCurrentSubDay=365;
		}else if(authState.user.stripe_plan_name=="Pro - 3Yearly"){
			totalCurrentSubDay=1095;
		}else if(authState.user.stripe_plan_name=="Pro - 5Yearly"){
			totalCurrentSubDay=1825;
		}
		
		if(remainingSubdays > totalCurrentSubDay){
			remainingSubdays =totalCurrentSubDay;
		}

		if(authState.user.stripe_plan_amount===null ){
			if(authState.user.stripe_plan_name=="Pro - Monthly"){
				stripePlanAmount=499;
			}else if(authState.user.stripe_plan_name=="Pro - Yearly"){
				stripePlanAmount=4995;
			}else if(authState.user.stripe_plan_name=="Pro - 3Yearly"){
				stripePlanAmount=12995;
			}else if(authState.user.stripe_plan_name=="Pro - 5Yearly"){
				stripePlanAmount=19995;
			}else if(authState.user.stripe_plan_name=="Pro - 10Yearly"){
				stripePlanAmount=34995;
			}

		}else{
			var stripePlanAmount=authState.user.stripe_plan_amount;
		}
		var currentPerDayAmount=(parseFloat(stripePlanAmount) / 100) / totalCurrentSubDay;
		var discountAmount=(remainingSubdays * currentPerDayAmount).toFixed(2);
		var amountToPay=(subscription_amount-discountAmount).toFixed(2);
		setDiscountAmount(amountToPay);
		setTotalSubDays(remainingSubdays);
	}, []);

	// const userplan = async () => {
	// 	
	// 	let plan = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/planByEmail/${authState.user.email}`)
	// 		.then(res => res.json())
	// 		.then(userplan => {
	// 			// if(userplan.discount!==null){
	// 			// 	if(typeof userplan.discount.coupon.duration_in_months!=='undefined'){
	// 			// 		
	// 			// 		setDiscountDuration(userplan.discount.coupon.duration_in_months);
	// 			// 	}
	// 			// }
				
	// 		});
	// };

	useEffect(() => {
		
		console.log("Oh no! useEffect triggered")
		
		
	}, [defaultPaymentMulti]);

	useEffect(() => {
		
		
		if(planToUpgrade!=="Pro - Yearly"){
			
		}
		
	}, [planToUpgradeMulti]);


	useEffect(() => {
		if(buttonEvent=="clicked"){
			localStorage.clear();
			localStorage.setItem('prev-email', authState.user.email);
			props.history.push('/login');	
		}
		
	}, [buttonEvent]);

	const maxLengthCheck = e => {
		
		if (e.value.length > e.maxLength) {
			e.value = e.value.slice(0, e.maxLength);
		}
	};


	const updateSub = async (e) => {
		e.currentTarget.disabled = true;
		
		
		if (props.upgradeTo == 'Yearly'){
			setPlanType('single');
		} 
			
		setShowLoader(true);
		// 
		if (renewalCheck) {
		} else {
			try {
				// setShowLoader(false);
				let payment = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getDefaultPayment/${authState.user.stripe_id}`).then(response =>
					response.json()
				);

				
				if (!defaultPayment) {
					
						var recent_card;
						var default_pay;
						let cards = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getCardList/${authState.user.stripe_id}`)
							.then(response => response.json())
							.then(cards => {
								
								// setCurrentCards(JSON.stringify(cards.data[0].id));
								recent_card = cards.data[0].id;

								//SETTING UP DEFAULT CC
								let defaultccRes = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/setDefaultCC`, {
									method: 'POST',
									headers: { 'Content-Type': 'application/json' },
									body: JSON.stringify({ customer: `${authState.user.stripe_id}`, paymentMethodId: recent_card }),
								})
									.then(res => res.json())
									.then(defaultccRes => {
										
										setDefaultPayment(defaultccRes);
										let createSubRes = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createSub`, {
											method: 'POST',
											headers: { 'Content-Type': 'application/json' },
											body: JSON.stringify({
											 cusId: `${authState.user.stripe_id}`, 
											 priceId: `${process.env.REACT_APP_STRIPE_YEARLY_PLAN}` ,
											 planType:`${props.planType}`,
											 paymentMethodId:`${defaultPayment}`,
											 diffDays: `${props.diffDays}`,
											 amount:`${authState.user.stripe_plan_amount}`
											}),
										})
											.then(res => res.json())
											.then(createSubRes => {
												
												let updateSubRes = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/cancelSub`, {
													method: 'POST',
													headers: { 'Content-Type': 'application/json' },
													body: JSON.stringify({ subId: `${authState.user.stripe_id}`, action: "upgrade" }),
												}).then(res => res.json());
												var current_period_end_date = new Date(createSubRes.current_period_end * 1000);
												
												const nyd=new Date(props.newYearlyDate);
												
												let updateUserRes = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/changeProYearly`, {
													method: 'POST',
													headers: { 'Content-Type': 'application/json' },
													body: JSON.stringify({
														email: `${authState.user.email}`,
														stripe_subscription_expires_at: nyd,
														stripe_plan_id: createSubRes.plan.id,
														stripe_subscription_id: createSubRes.id,
														stripe_plan_amount: createSubRes.plan.amount_decimal,
														stripe_plan_name: "Pro - Yearly",
													}),
												})
													.then(res => res.json())
													.then(logout => {
														localStorage.clear();
														localStorage.setItem('prev-email', authState.user.email);
														props.history.push('/login');
													});
											});
									});
							});
					

					
				}
			} catch (err) {
				
			}
		}
	};

	// ADDING MULTI UPDATE ACTION
	const updateMultiSub = async (e) => {
		e.currentTarget.disabled = true;
		
		
		if (props.upgradeTo == 'Yearly'){
			setPlanType('single');
		} 
			
		setShowLoader(true);
		// 
		if (renewalCheck) {
		} else {
			try {
				// setShowLoader(false);
				let payment = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getDefaultPayment/${authState.user.stripe_id}`).then(response =>
					response.json()
				);

				
				if (!defaultPayment) {
					
						var recent_card;
						var default_pay;
						let cards = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/getCardList/${authState.user.stripe_id}`)
							.then(response => response.json())
							.then(cards => {
								
								// setCurrentCards(JSON.stringify(cards.data[0].id));
								recent_card = cards.data[0].id;

								//SETTING UP DEFAULT CC
								let defaultccRes = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/setDefaultCC`, {
									method: 'POST',
									headers: { 'Content-Type': 'application/json' },
									body: JSON.stringify({ customer: `${authState.user.stripe_id}`, paymentMethodId: recent_card }),
								})
									.then(res => res.json())
									.then(defaultccRes => {
										// 
										setDefaultPaymentMulti(defaultccRes);
										
										let createSubRes = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/createMulti`, {
											method: 'POST',
											headers: { 'Content-Type': 'application/json' },
											body: JSON.stringify({
											 cusId: `${authState.user.stripe_id}`,
											 multiYears: `${props.upgradeTo}`,
											 paymentMethodId:`${defaultccRes}`,
											 diffDays: `${props.diffDays}`,
											 currentPlan: `${authState.user.stripe_plan_name}`,
											 amount:`${authState.user.stripe_plan_amount}`,
											 discountDuration: `${discountDuration}`
											}),
										})
											.then(res => res.json())
											.then(createSubRes => {
												// setSentMessage(createSubRes.message)
												// setSentMessage(defaultPayment)
												if (props.upgradeTo == 'Yearly') setPlanToUpgrade('Pro - Yearly');
												if (props.upgradeTo == '3year') setPlanToUpgradeMulti('Pro - 3Yearly');
												if (props.upgradeTo == '5year') setPlanToUpgradeMulti('Pro - 5Yearly');
												if (props.upgradeTo == '10year') setPlanToUpgradeMulti('Pro - 10Yearly');
												console.log("before cancel")
												if(authState.user.stripe_plan_name=="Pro - Yearly" || authState.user.stripe_plan_name=="Pro - Monthly"){
													let updateSubRes = fetch(`${process.env.REACT_APP_BASE_URL}/stripe/cancelSub`, {
														method: 'POST',
														headers: { 'Content-Type': 'application/json' },
														body: JSON.stringify({ subId: `${authState.user.stripe_id}`, action: "upgradeMulti" }),
													})
													.then(res => res.json())
													.then(cancelled => {
														localStorage.clear();
														localStorage.setItem('prev-email', authState.user.email);
														props.history.push('/login');
													});
												}else{
													localStorage.clear();
													localStorage.setItem('prev-email', authState.user.email);
													props.history.push('/login');
													
												}
												
											});
									});
							});
					

					
				}
			} catch (err) {
				
			}
		}
	};
	var updateButton = '';
	if(props.upgradeTo=="Yearly"){
		updateButton= <UpdateButton type='button' onClick={(e) => updateSub(e)}>PROCEED</UpdateButton>
	}else{
		updateButton= <UpdateButton type='button' onClick={(e) => updateMultiSub(e)}>PROCEED</UpdateButton>
	}
	const handleChange = event => {
		if (event.target.checked) {
			
			setRenewalCheck(true);
		} else {
			
			setRenewalCheck(false);
		}
	};
	return (
		<>
			<PopupWrapper>
				<Box>
					{showLoader && (
						<Loader
							type='TailSpin'
							color='#fff'
							height={25}
							width={25}
							style={{
								position: 'absolute',
								right: '34px',
								bottom: '31px',
							}}
						/>
					)}
					<FontAwesomeIcon
						icon={faTimes}
						style={{
							float: 'right',
							cursor: 'pointer',
							margin: '0px 0px 15px 0px',
						}}
						onClick={props.handleClose}
					/>
					<SentMessageWrapper>{sentMessage}</SentMessageWrapper>

					<SmallHeading>
					You are purchasing a discounted {props.upgradeToPlan} membership. With {totalSubDays} days left in your current subscription, your upgrade will cost ${discountAmount}
					</SmallHeading>
					{updateButton}
					
					<CancelButton type='button' onClick={props.handleClose}>
						CANCEL
					</CancelButton>
				</Box>
			</PopupWrapper>
		</>
	);
};

export default PopupSub;

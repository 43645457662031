import React, { useState, useEffect, useContext, useRef } from 'react';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faPlay,
    faPause,
    faAngleRight,
    faAngleLeft,
    faStopwatch,
    faVolumeUp,
    faInfoCircle
} from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import ReactPlayer from 'react-player';
import socket from 'socket.io-client/lib/socket';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const ImageContainer = styled.div`
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: hidden;
	height: 100%;
`;

const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
    &:hover{
        cursor: zoom-in;
    }
`
const ZoomImage = styled.img`
    width: 150%;
    &:hover{
        cursor: zoom-out;
    }
`
const ImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`



const Image = props => {
    // Global Storage
    //State
    const [zoom, setZoom] = useState(false)
    //Ref
    return (
        <>
            <ImageContainer>
                <ClosePaneWrapper>
                    <Tooltip content='Close Pane' direction='up' forceDirection={true}>
                        <FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
                    </Tooltip>

                </ClosePaneWrapper>
                {/* <Scrollbars autohide> */}
                <ImgContainer>
                    {!zoom ?
                        <Img src={props.imageUrl} onClick={() => setZoom(!zoom)} />
                        :
                        <Scrollbars>
                            <ZoomImage src={props.imageUrl} onClick={() => setZoom(!zoom)} />
                        </Scrollbars>
                    }
                </ImgContainer>


            </ImageContainer>
        </>
    );
};

export default Image;
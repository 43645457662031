import React, { useContext } from 'react';
import { PortalWithState } from 'react-portal';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { DashboardContext } from '../../ContextProviders/DashboardProvider';

const ClearHistoryWrapper = styled.div`
	left: 30%;
	right: 30%;
	top: 30%;
	z-index: 99999999;
	position: absolute;
	background: rgb(255, 255, 255);
	padding: 30px;
	border-radius: 15px;
	border: 2px solid rgb(221, 221, 221);
	text-align: center;
`;

const ClearHistoryUpBtnWrapper = styled.div`
	display: inline;
`;

const ConfirmClearHistoryPopUpBtn = styled.button`
	padding: 13px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	margin-right: 15px;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 14px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	color: #ac323d;
	background-color: #fff;
	border: 2px solid #ac323d;
	&:hover {
		background-color: #ac323d;
		color: #fff;
	}
`;

const CloseClearHistoryPopUpBtn = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	margin-right: 0;
	padding: 13px;
	cursor: pointer;
	border-radius: 10px;
	font-size: 14px;
	line-height: 10px;
	height: 100%;
	width: 125px;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
`;

const ClearHistory = props => {
	const { removeHistory } = useContext(DashboardContext);

	return (
		<>
			<ReactTooltip id='test' type='info' place='top' effect='float' />
			<PortalWithState key={props.title} closeOnOutsideClick closeOnEsc>
				{({ openPortal, closePortal, isOpen, portal }) => [
					<span
						data-tip={`Clear ${props.title}`}
						data-for='test'
						style={{
							color: 'rgb(213, 115, 113)',
							fontWeight: 700,
							cursor: 'pointer',
							fontSize: '12px',
						}}
						onClick={openPortal}
						key='dashboardPortal'
					>
						{' '}
						(X)
					</span>,
					portal(
						<div>
							<div
								style={{
									top: 0,
									zIndex: 9999999,
									background: '#ddd',
									width: '100%',
									height: '100%',
									minHeight: '392px',
									opacity: '0.6',
									position: 'absolute',
								}}
							/>
							<ClearHistoryWrapper>
								<p>Are you sure you want to clear all your history?</p>
								<ClearHistoryUpBtnWrapper>
									<ConfirmClearHistoryPopUpBtn
										onClick={closePortal}
										onClickCapture={() => {
											removeHistory({ title: props.title, userId: props.userId, uniqueKey: props.uniqueKey });
										}}
									>
										Clear History
									</ConfirmClearHistoryPopUpBtn>
									<CloseClearHistoryPopUpBtn onClick={closePortal}>Close</CloseClearHistoryPopUpBtn>
								</ClearHistoryUpBtnWrapper>
							</ClearHistoryWrapper>
						</div>
					),
				]}
			</PortalWithState>
		</>
	);
};

export default ClearHistory;

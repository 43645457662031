import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import DarkLogo from '../../assets/logo_dark.svg';

import styled from 'styled-components';

const LogoImage = styled.img`
	position: absolute;
	top: 32px;
	left: 32px;
	width: 84px;
	height: 41px;
`;

const ForgotPasswordWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100vh;
`;

const ContentWrapper = styled.div`
	padding: 0px 20px;
	width: 400px;
`;

const LoginSignupTextWrapper = styled.h1`
	display: flex;
	margin-bottom: 30px;
	font-family: SFUIDisplay-Bold;
	font-size: 32px;
	color: #dedede;
`;

const LoginSignupText = styled.h2`
	margin-left: 5px;
	padding: 0px 7px 0px 7px;
`;

const PasswordRecoveryText = styled.p`
	margin-bottom: 25px;
	font-family: OpenSansSemiBold;
	font-size: 14px;
	color: #7f7f7f;
`;

const PasswordRecoveryInput = styled.input`
	width: 100%;
	display: block;
	padding: 16px 10px;
	font-family: SFUIDisplay-Bold;
	font-size: 22px;
	line-height: 28px;
	border: none;
	border-radius: 0;
	color: #000;
	background-color: #ebebeb;
	margin-bottom: 20px;
`;

const EmailLabel = styled.label`
	font-size: 18px;
	font-weight: 500;
	font-family: SFUIDisplay-Bold;
	display: block;
`;

const PasswordResetBtn = styled.button`
	cursor: pointer;
	font-family: SFUIDisplay-Bold;
	background-color: inherit;
	border: 2px solid #000;
	width: 120px;
	height: 50px;
	&:hover {
		color: #f8f8ff;
		background-color: #000;
	}
`;

const ChangePassword = () => {
	const [newPassword, setNewPassword] = useState('');
	const [isValidToken, setIsValidToken] = useState(null);
	const [successfulReset, setSuccessfulReset] = useState(false);

	let { token, email } = useParams();

	useEffect(() => {
		const validateToken = async () => {
			try {
				let verifyToken = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/verifyToken/${token}/${email}`).then(res => res.json());

				if (verifyToken === 'ok') setIsValidToken(true);
				else setIsValidToken(false);
			} catch (err) {
				console.log('Error validating token:', err);
			}
		};

		validateToken();
	}, []);

	const resetPassword = async () => {
		try {
			let passwordResetRes = await fetch(`${process.env.REACT_APP_BASE_URL}/passwordReset/resetPassword`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ token, email, password: newPassword }),
			}).then(res => res.json());

			if (passwordResetRes === 'Password successfully updated') setSuccessfulReset(true);
		} catch (err) {
			console.log('Error resetting password:', err);
		}
	};

	if (isValidToken === null) return null;

	if (isValidToken === false)
		return (
			<div>
				<ForgotPasswordWrapper>
					<LoginSignupText>Invalid Link</LoginSignupText>
				</ForgotPasswordWrapper>
			</div>
		);

	return (
		<div>
			<LogoImage key='logo' src={DarkLogo} alt='Scripture Notes Icon' />
			<ForgotPasswordWrapper style={{ flexDirection: 'column', justifyContent: 'center' }} key='loginFrom'>
				<ContentWrapper>
					<LoginSignupTextWrapper>
						<Link to='/login'>
							<LoginSignupText>Log in</LoginSignupText>
						</Link>
					</LoginSignupTextWrapper>
					{successfulReset ? (
						<div>
							<EmailLabel>Your password has been reset! You can now sign in using your new password.</EmailLabel>
						</div>
					) : (
						<div>
							<PasswordRecoveryText>Password Reset</PasswordRecoveryText>
							<form>
								{/* {this.renderStatusMessage()} */}

								<EmailLabel htmlFor='inputPassword'>New Password</EmailLabel>
								<PasswordRecoveryInput
									name='New Password'
									type='password'
									id='inputPassword'
									value={newPassword}
									onChange={e => setNewPassword(e.target.value)}
									//   disabled={loading}
									autoFocus={false}
								/>
								{/* <PasswordResetBtn type="submit" disabled={loading}>Send</PasswordResetBtn> */}
								<PasswordResetBtn type='button' onClick={() => resetPassword()}>
									Reset Password
								</PasswordResetBtn>
							</form>
						</div>
					)}
				</ContentWrapper>
			</ForgotPasswordWrapper>
		</div>
	);
};

export default ChangePassword;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import { faTimes } from '@fortawesome/fontawesome-free-solid';
import { AuthContext } from '../../ContextProviders/AuthProvider';
const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
	}
`;

const PaneContainer = styled.div`
	margin: 0 30px 10px 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 25px - 30px);
	overflow: scroll;
	height: 100%;
`;

const PaneTitle = styled.h3`
	font-size: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	line-height: 32px;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TitleDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;
const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;
const ReviewsContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const GoogleReviews = props => {
	const { authState } = useContext(AuthContext);
	const reviewsRef = useRef(null);
	// Global Storage
	//UseEffects
	useEffect(() => {
		//Create a script element
		const script = document.createElement('script');
		script.src = 'https://cdn.trustindex.io/loader.js?5bd3d2630a2e3864f546342d4f4';
		script.defer = true;
		script.async = true;
		// Append the script element to the document head
		document.head.appendChild(script);
		// Cleanup the effect by removing the script when the component unmounts
		return () => {
			document.head.removeChild(script);
		};
	}, []);
	useEffect(() => {
		console.log('AuthState:', authState.user.id);
		if (authState.user.id) {
			console.log('ID:', authState.user.id);
			props.closePane(props.id - 1);
		}
	}, [authState.user.id]);
	//State
	//Ref
	return (
		<>
			<PaneContainer>
				<ClosePaneWrapper>
					<Tooltip content='Close Pane' direction='up' forceDirection={true}>
						<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
					</Tooltip>
				</ClosePaneWrapper>
				{/* <Scrollbars autohide> */}
				<TitleDiv {...props.dragHandleProps}>
					{/* <TitleContainer>
						<PaneTitle>GoogleReviews</PaneTitle>
					</TitleContainer> */}
				</TitleDiv>
				{/* <body> */}
				<div class='reviews-container' src='https://cdn.trustindex.io/loader.js?89bc6022644a3454dd8647f4f4d'></div>
				{/* </body> */}
			</PaneContainer>
		</>
	);
};

export default GoogleReviews;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { LibraryProviderState } from '../../ContextProviders/LibraryProvider';
import { ChapterHistoryContext } from '../../ContextProviders/ChapterHistoryProvider';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/fontawesome-free-solid/';
import { Scrollbars } from 'react-custom-scrollbars';
import { AuthContext } from '../../ContextProviders/AuthProvider';
import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';
import ResourcePodcast from './ResourcePodcast';

const ClosePaneWrapper = styled.div`
	display: block;
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 1;
	background-size: cover;
	background-repeat: no-repeat;
	color: rgb(178 183 190);
	&:hover {
		color: #1a56eb;
        cursor: pointer:
	}
`;

const ResourceTitle = styled.h3`
	font-size: 18px;
	cursor: grab;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	touch-action: manipulation;
	margin: 0;
	padding: 0;
	color: #6d7886;
	width: 90%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 23px;
	padding-bottom: 10px;
`;
const ResourcesContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 30px 0 25px;
	padding: 15px 0 10px;
	padding-top: 35px;
	position: absolute;
	top: 0;
	left: 0;
	width: 90%;
	height: 100%;
`;

const PodcastsContainer = styled.div`
	height: 100%;
	width: 100%;
`;
const PodcastTitle = styled.div``;
const Podcast = styled.span`
	color: #6a7583;
	font-size: 14px;
	&:hover {
		background-color: #ececec;
		cursor: pointer;
	}
`;

const ChapterResources = props => {
	// Global Storage
	const { libraryState, libraryDispatch, fetchVolumesBooksNChapters } = useContext(LibraryProviderState);
	const { handleOpenVolume, handleOpenBook, handleAddStudyHelpPane, state, dispatch } = useContext(NavContext);
	const { chapterHistoryDispatch, chapterHistoryState } = useContext(ChapterHistoryContext);
	const { authState } = useContext(AuthContext);
	const [podcasts, setPodcasts] = useState([]);
	//State
	const { resources, chapter } = props;
	const isaiahInst = resources.filter((e) => e.podcast_id === 5)
	const filtered = resources.filter((e) => e.podcast_id !== 5)
	const mappedResources = [...isaiahInst, ...filtered].map((e, i) => {
		return (
			<ResourcePodcast
				key={i}
				uniqueKey={props.uniqueKey}
				data={e}
				chapter={chapter}
				userId={authState.user.id}
				authState={authState}
				originPaneKey={props.uniqueKey}
				socket={props.socket}
			/>
		);
	});

	return (
		<>
			<ClosePaneWrapper>
				<Tooltip content='Close Pane' direction='up' forceDirection={true}>
					<FontAwesomeIcon icon={faTimes} onClick={() => props.closePane(props.id)} />
				</Tooltip>
			</ClosePaneWrapper>
			<ResourcesContainer>
				<ResourceTitle {...props.dragHandleProps}>
					{chapter.book.title} Chapter {chapter.order} Resources
				</ResourceTitle>
				<PodcastsContainer>
					<Scrollbars>{mappedResources}</Scrollbars>
				</PodcastsContainer>
			</ResourcesContainer>
		</>
	);
};

export default ChapterResources;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { NavContext } from '../../ContextProviders/NavProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scrollbars } from 'react-custom-scrollbars';
import _, { findIndex } from 'lodash';
import styled from 'styled-components';
import { faTimes, faCircleXmark } from '@fortawesome/fontawesome-free-solid/';

const GameContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	// border: 1px solid blue
`;
const EventContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	// min-height: 118px;
	// margin-right: 15px;
	padding: 0px 15px;
	// border: 1px solid green;
	align-items: center;
`;
const EventDateConatiner = styled.div`
    display: flex;
    height: 100%;
    width: 25%;
    justify-content: center;
    align-items center;
`;
const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	width: 75%;
	// padding: 5px 0px;
	position: relative;
`;
const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: #ececec;
	border-radius: 3px;
	// height: 100px;
	padding: 5px;
	box-shadow: 5px 5px 5px grey;
`;
const EventTitle = styled.h6`
	text-align: center;
	font-size: 15px;
`;
const EventSubtext = styled.p`
	text-align: center;
	font-size: 12px;
`;
const PlusButton = styled.button`
	// margin-top: 15px;
	border-radius: 50%;
	color: #fff;
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 12px;
	cursor: pointer;
	max-width: 155px;
	padding: 1px 6px;
	text-align: center;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;
const InPlayContainer = styled.div`
	padding: 5px 0px;
	position: relative;
	width: 100%;
	// height: 700px;
	height: 90%;
	// border: 1px solid blue;
	// overflow: scroll;
	// visibility: visible;
`;
const NewCardContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	// background-color: #ececec;
	// border-radius: 3px;
	width: 100%;
	height: 10%;
	margin: 5px 0px;
	padding: 0px 15px;
	border-bottom: 1px solid black;
	// border: 1px dotted green;
`;
const NewCardTitle = styled.h5`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ececec;
	border-radius: 3px;
	text-align: center;
	width: 100%;
	height: 55px;
	font-size: 15px;
	box-shadow: 5px 5px 5px grey;
`;
const WonModal = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0%;
	// right: 50%;
	height: 250px;
	width: 100%;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	border: 5px solid #22bb33;
	z-index: 10;
	text-align: center;
`;
const FailedModal = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0%;
	// right: 50%;
	height: 200px;
	width: 100%;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	border: 5px solid #bb2124;
	z-index: 10;
`;

const ModalButtons = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-evenly;
	margin-top: 20px;
`;
const ModalButton = styled.button`
	color: #324599;
	background-color: #fff;
	border: 2px solid #324599;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;
	font-size: 12px;
	// line-height: 24px;
	border-radius: 10px;
	cursor: pointer;
	max-width: 155px;
	padding: 6px 12px;
	text-align: center;
	&:hover {
		background-color: #324599;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
`;

const FirstIndicator = styled.p`
	position: absolute;
	top: -10px;
	left: 0;
	color: #324599;
	font-weight: 800;
	z-index: 1;
`;

const LastIndicator = styled.p`
	position: absolute;
	bottom: -20px;
	left: 0;
	color: #324599;
	font-weight: 800;
	z-index: 1;
`;
const BackButtonDisplay = styled.div`
	margin-bottom: 6px;
	cursor: pointer;
	font-family: -apple-system, BlinkMacSystemFont, sans-serif;

	font-size: 15px;
	line-height: 16px;
	color: #6a7583;
	&:hover {
		background-color: #ececec;
	}
`;
const TimerContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 5px;
`;
const CloseContainer = styled.div`
	position: absolute;
	top: 5px;
	right: 10px;
	&:hover {
		color: #1a56eb;
		cursor: pointer;
	}
`;
const CenterColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const XOverlay = styled.div`
	width: 110%;
	color: #bb2124;
	font-size: 200px;
	position: absolute;
	z-index: 5000;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	top: 0;
`;

const TimeSkimmerGame = props => {
	const { cards, player, showTimer, handleUpdateScore, handleShuffle, goToDeckSelect, goToDeckStats, versusMode, learningMode } = props;
	// Global Storage
	//State
	const [timer, setTimer] = useState(0);
	const [timerRunning, setTimerRunning] = useState(true);
	const [currentCard, setCurrentCard] = useState(1);
	const [inPlay, setInPlay] = useState([cards[0]]);
	const [hasLost, setHasLost] = useState(false);
	const [hasWon, setHasWon] = useState(false);
	const [failedModal, setFailedModal] = useState(false);
	const [wonModal, setWonModal] = useState(false);
	const [currentPlayer, setCurrentPlayer] = useState('Player 1');
	const [xOverlay, setXOverlay] = useState(false);
	console.log('render');
	//Ref
	useEffect(() => {
		if (!versusMode && !learningMode) {
			let interval;
			if (timerRunning) {
				interval = setInterval(() => {
					setTimer(prevTime => prevTime + 1000);
				}, 1000);
			}
			if (!timerRunning) clearInterval(interval);

			return () => clearInterval(interval);
		}
	}, [timerRunning]);
	useEffect(() => {
		setInPlay([cards[0]]);
	}, [cards[0]]);
	const minutes = () => {
		return ('0' + Math.floor((timer / 60000) % 60)).slice(-2);
	};
	const seconds = () => {
		return ('0' + Math.floor((timer / 1000) % 60)).slice(-2);
	};
	const newCard = cards[currentCard];
	const handleLessThan = (index, date) => {
		if (!hasWon && !hasLost) {
			let newArr = [...inPlay];
			const checkingDate = parseFloat(newCard.event_date_int);
			if (index > 0) {
				const greaterThanDate = parseFloat(inPlay[index - 1].event_date_int);
				if (checkingDate < date && checkingDate > greaterThanDate) {
					newArr.splice(index - 1, 0, newCard);
					setInPlay(newArr);
					handleNextCard();
					versusMode && handleChangePlayer();
				} else handleWrong();
			} else {
				if (checkingDate < date) {
					newArr.unshift(newCard);
					setInPlay(newArr);
					handleNextCard();
					versusMode && handleChangePlayer();
				} else handleWrong();
			}
		}
	};
	const handleGreaterThan = (index, date) => {
		if (!hasWon && !hasLost) {
			let newArr = [...inPlay];
			const checkingDate = parseFloat(newCard.event_date_int);
			if (index < inPlay.length - 1) {
				const greaterThanDate = parseFloat(inPlay[index + 1].event_date_int);
				if (checkingDate > date && checkingDate < greaterThanDate) {
					newArr.splice(index + 1, 0, newCard);
					setInPlay(newArr);
					handleNextCard();
					versusMode && handleChangePlayer();
				} else handleWrong();
			} else {
				if (checkingDate > date) {
					newArr.push(newCard);
					setInPlay(newArr);
					handleNextCard();
					versusMode && handleChangePlayer();
				} else handleWrong();
			}
		}
	};
	const handleChangePlayer = () => {
		if (currentPlayer === 'Player 1') {
			setCurrentPlayer('Player 2');
		} else setCurrentPlayer('Player 1');
	};
	const handleCorrectIndex = () => {
		const currentDate = parseFloat(cards[currentCard].event_date_int);
		let index;
		for (let i = 0; i < inPlay.length; i++) {
			const checkingDate = parseFloat(inPlay[i].event_date_int);
			const nextDate = inPlay[i + 1] && parseFloat(inPlay[i + 1].event_date_int);
			if (currentDate < checkingDate) {
				index = i === 0 ? 0 : i;
				break;
			}
			if (currentDate > checkingDate && nextDate && currentDate < nextDate) {
				index = i + 1;
				break;
			}
			if (i === inPlay.length - 1 && currentDate > checkingDate) {
				index = i + 1;
				break;
			}
		}
		return index;
	};
	const handleWrong = async () => {
		if (!learningMode) {
			let inPlayCopy = [...inPlay];
			const correctIndex = handleCorrectIndex();
			inPlayCopy.splice(correctIndex, 0, { ...cards[currentCard], redBorder: true });
			setInPlay(inPlayCopy);
			setHasLost(true);
			setTimerRunning(false);
			setFailedModal(true);
			!versusMode && handleUpdateScore(timer, currentCard, false);
			versusMode && handleChangePlayer();
		} else {
			wrongIndex();
		}
	};
	const wrongIndex = () => {
		setXOverlay(true);
		setTimeout(() => setXOverlay(false), 1000);
	};
	const handleNextCard = () => {
		if (currentCard < cards.length - 1) {
			setCurrentCard(currentCard + 1);
			return;
		} else {
			setCurrentCard(currentCard + 1);
			setTimerRunning(false);
			setHasWon(true);
			setWonModal(true);
			!learningMode && !versusMode && handleUpdateScore(timer, currentCard + 1, true);
		}
	};
	const handleTryAgain = () => {
		setCurrentCard(1);
		setTimer(0);
		setHasWon(false);
		setHasLost(false);
		setTimerRunning(true);
		setFailedModal(false);
		setWonModal(false);
		handleShuffle();
		versusMode && setCurrentPlayer('Player 1');
	};

	const mappedInPlay = inPlay.map((card, ind) => {
		const { event_label, event_date_label, subtext, event_date_int } = card;
		return (
			<EventContainer key={ind}>
				<EventDateConatiner>
					<div>
						<p>{event_date_label}</p>
					</div>
				</EventDateConatiner>
				<CardContainer style={{ margin: (hasLost || hasWon) && '15px' }}>
					{/* {<PlusButton style={{marginBottom: (!hasLost && !hasWon) && ind === 0 ? '5px' : '0px',  visibility: (!hasLost && !hasWon) && ind === 0 ? 'visable' : 'hidden'}}  onClick={() => handleLessThan(ind, event_date_int)}> */}
					{!hasLost && !hasWon && ind === 0 && (
						<PlusButton style={{ marginBottom: '10px' }} onClick={() => handleLessThan(ind, parseFloat(event_date_int))}>
							+
						</PlusButton>
					)}
					<Card style={{ border: card.redBorder && '3px solid #bb2124', height: subtext && '80px' }}>
						<EventTitle>{event_label}</EventTitle>
						{subtext && <EventSubtext>{subtext}</EventSubtext>}
					</Card>
					{!hasLost && !hasWon && (
						<PlusButton style={{ marginTop: '10px', marginBottom: '10px' }} onClick={() => handleGreaterThan(ind, parseFloat(event_date_int))}>
							+
						</PlusButton>
					)}
				</CardContainer>
			</EventContainer>
		);
	});
	return (
		<GameContainer>
			{xOverlay && (
				<XOverlay>
					{/* <FontAwesomeIcon
				    icon={faCircleXmark}
					style={{ height: '50px', width: '50px', marginBottom: '10px', backgroundColor: 'black', borderRadius: '50%' }}
				/> */}
					<p>x</p>
				</XOverlay>
			)}

			<NewCardContainer>
				<TimerContainer>
					<BackButtonDisplay onClick={() => goToDeckStats(hasWon || hasLost)}>◀ Back</BackButtonDisplay>
					{showTimer && !versusMode && !learningMode && (
						<h5 style={{ width: '25%' }}>
							{minutes()}:{seconds()}
						</h5>
					)}
					{versusMode && <h5>{currentPlayer}</h5>}
				</TimerContainer>
				<NewCardTitle style={{ width: '75%' }}>{currentCard < cards.length ? newCard.event_label : 'You Won!'}</NewCardTitle>
			</NewCardContainer>
			<InPlayContainer>
				<FirstIndicator>First</FirstIndicator>
				<Scrollbars style={{ height: '100%' }} autoHide>
					{/* <PlusButton style={{marginBottom: '5px', position: 'absolute', top: '0', right: '25px'  }}  onClick={() => handleLessThan(0, inPlay[0].event_date_int)}>+</PlusButton> */}
					{mappedInPlay}
				</Scrollbars>
				<LastIndicator>Last</LastIndicator>
			</InPlayContainer>
			{failedModal && (
				<FailedModal>
					<CloseContainer>
						<FontAwesomeIcon icon={faTimes} onClick={() => setFailedModal(false)} />
					</CloseContainer>
					{!versusMode && (
						<CenterColumnContainer>
							<h5>Try Again</h5>
							<h6>
								Time: {minutes()}:{seconds()}
							</h6>
							<h6>
								{currentCard}/{cards.length}
							</h6>
						</CenterColumnContainer>
					)}
					{versusMode && (
						<CenterColumnContainer>
							<h5>{currentPlayer} Won!</h5>
						</CenterColumnContainer>
					)}
					{/* <h6></h6> */}
					<ModalButtons>
						<ModalButton onClick={() => handleTryAgain()}>Try Again</ModalButton>
						<ModalButton onClick={() => goToDeckSelect()}>Deck Select</ModalButton>
					</ModalButtons>
				</FailedModal>
			)}
			{wonModal && (
				<WonModal>
					<CloseContainer>
						<FontAwesomeIcon icon={faTimes} onClick={() => setWonModal(false)} />
					</CloseContainer>
					{!versusMode && !learningMode && (
						<CenterColumnContainer>
							<h5>Congratulations!</h5>
							<h6>
								Time: {minutes()}:{seconds()}
							</h6>
							<h6>
								{currentCard}/{cards.length}
							</h6>
							<h6>Try again for a lower time</h6>
						</CenterColumnContainer>
					)}
					{versusMode && (
						<CenterColumnContainer>
							<h5>Its a tie!</h5>
							<h6>Try another deck!</h6>
						</CenterColumnContainer>
					)}
					{learningMode && (
						<CenterColumnContainer>
							<h5>Congratulations!</h5>
							{/* <h6>Time: {minutes()}:{seconds()}</h6> */}
							{/* <h6>{currentCard}/{cards.length}</h6> */}
							<p>
								Good job getting those cards placed! If you feel like you know them well, try playing the 1 player timed version to track your
								score.
							</p>
						</CenterColumnContainer>
					)}
					<ModalButtons>
						<ModalButton onClick={() => handleTryAgain()}>Try Again</ModalButton>
						<ModalButton onClick={() => goToDeckSelect()}>Deck Select</ModalButton>
					</ModalButtons>
				</WonModal>
			)}
		</GameContainer>
	);
};

export default TimeSkimmerGame;

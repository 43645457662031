import React, { useReducer } from 'react';

export const SearchHistoryState = React.createContext();
export const SearchHistoryDispatch = React.createContext();

const initialState = ['term1', 'term2'];

const reducer = (state, action) => {
	switch (action.type) {
		case 'ADD_SEARCH_TERM': {
			return [...state, action.payload];
		}
		default:
			throw new Error('That action type does not exist.');
	}
};

export const SearchHistoryProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<SearchHistoryState.Provider value={state}>
			<SearchHistoryDispatch.Provider value={dispatch}>{children}</SearchHistoryDispatch.Provider>
		</SearchHistoryState.Provider>
	);
};
